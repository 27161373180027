/** @jsxImportSource @emotion/react */
import { Tooltip as MuiTooltip, TooltipProps as MuiTooltipProps } from '@mui/material'
import { tooltipContainer } from './Tooltip.styles'

export type TooltipProps = MuiTooltipProps

export const Tooltip = ({ children, ...rest }: TooltipProps) => {
  return (
    <MuiTooltip arrow enterDelay={300} leaveDelay={200} {...rest} css={tooltipContainer}>
      {children}
    </MuiTooltip>
  )
}
