import { css, keyframes } from '@emotion/react'
import { Theme } from '@mui/material'

const fadein = keyframes`
  from { opacity: 0; top: 10px }
  to   { opacity: 1; top: 0px }
`
export const stepContainerParent = ({
  theme,
  isActive,
}: {
  theme: Theme
  isActive: boolean
}) => css`
  display: flex;
  flex-direction: column;
  border-radius: ${theme.spacing(1)};
  position: relative;
  align-items: stretch;
  overflow: hidden;
  background-color: ${theme.palette.white.main};
  ${isActive ? `box-shadow: 0 0 8px 0 #1e8d8b40;` : ''}
  animation: ${fadein} 0.3s ease;
  flex-shrink: 0;
  filter: ${isActive ? 'none' : 'grayscale(40%)'};
  transition: filter 0.3s;
  opacity: ${isActive ? 1 : 0.6};

  > * {
    opacity: ${isActive ? 1 : 0.4};
    transition: opacity 0.3s;
  }

  ${!isActive
    ? css`
        cursor: pointer;
        &:hover {
          box-shadow: 0 0 8px 0 #1e8d8b60;
        }
      `
    : ''}
`

export const chapterTitle = ({ theme }: { theme: Theme }) => css`
  background-color: ${theme.palette.primary.main};
  color: ${theme.palette.white.main};
  padding: ${theme.spacing(2)} ${theme.spacing(4)};

  p {
    font-size: 1.1rem;
    line-height: 1.3;
    font-weight: 700;
    letter-spacing: 0.6px;
  }
`

export const stepTitle = ({ theme }: { theme: Theme }) => css`
  padding: ${theme.spacing(2)} ${theme.spacing(4)};
  color: ${theme.palette.primary.main};
  font-size: 1rem;
  font-weight: 700;
  letter-spacing: 0.5px;
`

export const sectionTitle = ({ theme }: { theme: Theme }) => css`
  color: ${theme.palette.text.primary};
  font-size: 0.9rem;
  font-weight: 700;
`

export const sectionsContainer = ({ theme }: { theme: Theme }) => css`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing(2)};
  padding: ${theme.spacing(2)} ${theme.spacing(4)};
`

export const sectionTextContent = ({ theme }: { theme: Theme }) => css`
  color: ${theme.palette.text.primary};
  font-size: 0.9rem;
  line-height: 1.4;
  margin-bottom: ${theme.spacing(2)};
`

export const sectionInlineImage = ({ theme }: { theme: Theme }) => css`
  float: right;
  max-width: 40%;
  max-height: 120px;
  margin: 0 0 ${theme.spacing(1)} ${theme.spacing(4)};
  border-radius: ${theme.spacing(1)};
  border: 1px solid ${theme.palette.primary.main}20;
`

export const sectionWideImage = ({ theme }: { theme: Theme }) => css`
  width: 100%;
  margin-top: ${theme.spacing(2)};
  user-select: none;

  img {
    border-radius: ${theme.spacing(2)};
    border: 1px solid ${theme.palette.primary.main}20;
  }
  video {
    border-radius: ${theme.spacing(2)};
    margin-top: ${theme.spacing(2)};
    margin-bottom: ${theme.spacing(2)};
    border: 1px solid ${theme.palette.primary.main}20;

    &::-webkit-media-controls-panel {
      background: linear-gradient(
        0deg,
        ${theme.palette.primary.main} 0%,
        ${theme.palette.primary.main}C0 20%,
        ${theme.palette.primary.main}00 40%
      );
    }
    &::-webkit-media-controls-time-remaining-display,
    &::-webkit-media-controls-current-time-display {
      display: none;
    }
  }
`

export const sectionFooter = ({ theme }: { theme: Theme }) => css`
  padding: ${theme.spacing(2)} ${theme.spacing(4)};
  border-top: 1px solid ${theme.palette.primary.main}20;
  margin-top: ${theme.spacing(2)};
  display: flex;
  justify-content: space-between;
  gap: ${theme.spacing(4)};
  flex-direction: row-reverse;

  p {
    color: ${theme.palette.primary.main};
    font-size: 0.8rem;
  }
`

export const buttonsSection = ({ theme }: { theme: Theme }) => css`
  display: flex;
  flex-direction: column;
  padding-bottom: ${theme.spacing(4)};
`
