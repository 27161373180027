/** @jsxImportSource @emotion/react */
import { Autocomplete, FilterOptionsState, Paper, TextField } from '@mui/material'
import { useTheme } from '@mui/material/styles'

import { useCallback, useEffect, useState } from 'react'

import { useMap } from '@contexts/MapContext'
import { RootState } from '@redux/store'
import { useDispatch, useSelector } from 'react-redux'
import { BASE_API_URL } from '../../../app-constants'
import axios from '@src/utils/customAxios'
import { useTutorialContext } from '@contexts/TutorialContext'
import { autocompleteContainer, groupHeader, groupItems } from './RegionFilter.styles'
import { updateRegions } from '@redux/map/mapSlice'

export interface RegionOption {
  default: boolean
  max_x: number
  max_y: number
  min_x: number
  min_y: number
  region: string
  region_name: string
  group_name: string
}

export const RegionFilter = () => {
  const { map, fitToBounds, removeRegionMasksLayers, regionMasks, setRegionMasks } = useMap()
  const theme = useTheme()
  const dispatch = useDispatch()

  const { clientName } = useSelector((state: RootState) => state.user)

  const [regionsData, setRegionsData] = useState<RegionOption[] | null>(null)

  const handleRemoveRegion = useCallback(() => {
    if (!map) return
    removeRegionMasksLayers({ map })
  }, [map, removeRegionMasksLayers])

  const handleChangeRegions = useCallback(
    (regions: RegionOption[] | null) => {
      if (!regions || regions.length === 0) {
        handleRemoveRegion()
        return
      }

      const newValues = regions
        .map((region) => regionsData?.find((r) => r.region === region.region))
        .filter(Boolean) as RegionOption[]

      if (newValues.length === 0) return

      const boundingBoxes = newValues.map(
        ({ min_x, min_y, max_x, max_y }) =>
          [max_x, max_y, min_x, min_y] as [number, number, number, number],
      )

      setRegionMasks(newValues)

      if (!map) return
      boundingBoxes.forEach((boundingBox) => fitToBounds({ boundingBox, map }))
    },
    [setRegionMasks, map, handleRemoveRegion, regionsData, fitToBounds],
  )

  const GroupHeader = (props: React.HTMLAttributes<HTMLElement>) => <div {...props} />

  // TS is having a tiff with react portal vs react elements, it's seemingly non-sensical
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const GroupItems = (props: any) => <ul {...props} />

  useEffect(() => {
    if (!clientName) return
    const abortController = new AbortController()
    const fetchRegions = async () => {
      try {
        const response = await axios.get(`${BASE_API_URL}/api/regions`, {
          signal: abortController.signal,
        })

        setRegionsData(response.data)
        dispatch(updateRegions(response.data))
      } catch (error) {
        setRegionsData(null)
        dispatch(updateRegions([]))
        // eslint-disable-next-line no-console
        console.error(error)
      }
    }
    fetchRegions()
    return () => {
      abortController.abort()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientName])

  useEffect(() => {
    if (!clientName) {
      setRegionsData([])
      handleRemoveRegion()
      return
    }
  }, [clientName, handleRemoveRegion, setRegionsData])

  // Add setShowAddLayersModalTab to TutorialContext
  const { setTutorialItem } = useTutorialContext()
  useEffect(() => {
    setTutorialItem('regions', regionsData)
  }, [regionsData, setTutorialItem])

  if (!clientName || !map || !regionsData?.length || regionsData.length == 1) return null

  const filterSA1Regions = (options: RegionOption[], state: FilterOptionsState<RegionOption>) => {
    if (
      state.inputValue === '' &&
      options.some((option) => option.group_name == 'Statistical Area 1')
    ) {
      const newOptions = options.filter((option) => option.group_name != 'Statistical Area 1')

      // Add a dummy region just to show a message - it's unselectable somehow, dunno why but that's cool
      newOptions.unshift({
        group_name: 'Statistical Area 1',
        region_name: 'Please type in SA1 code above',
        region: '',
        default: false,
        min_x: 0,
        max_x: 0,
        min_y: 0,
        max_y: 0,
      })

      return newOptions
    }

    return options.filter((option) =>
      option.region_name.toLocaleLowerCase().includes(state.inputValue.toLocaleLowerCase()),
    )
  }

  return (
    <Paper>
      <Autocomplete
        sx={{ width: '100%', minWidth: '240px', height: '100%' }}
        css={autocompleteContainer({ theme })}
        value={regionMasks ? regionMasks : []}
        isOptionEqualToValue={(option, value) => option.region === value.region}
        size="small"
        fullWidth
        multiple
        disableCloseOnSelect
        limitTags={1}
        options={regionsData}
        groupBy={(option) => option.group_name}
        getOptionLabel={(option) => option.region_name}
        onChange={(_event, values) => handleChangeRegions(values)}
        renderInput={(params) => (
          <TextField
            {...params}
            className="tutorial-region-filter"
            placeholder={regionMasks === null || regionMasks.length == 0 ? 'Filter By Regions' : ''}
          />
        )}
        renderGroup={(params) => (
          <li key={params.key}>
            <GroupHeader css={groupHeader({ theme })}>{params.group}</GroupHeader>
            <GroupItems css={groupItems({ theme })}>{params.children}</GroupItems>
          </li>
        )}
        filterOptions={filterSA1Regions}
      />
    </Paper>
  )
}
