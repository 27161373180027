export const EarthOceaniaIcon = ({ ...rest }) => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M23.9082 16C24.6765 16.6779 24.9201 17.9647 24.4848 19.0211C24.2253 19.6501 23.1991 19.9984 22.611 20.6044C21.6698 21.5745 19.8695 24.9296 18 23.7709C18.0735 21.3716 21.6568 21.0434 22.4668 19.309C22.9713 18.231 22.167 16.2764 23.9082 16Z"
        fill="currentColor"
      />
      <path
        d="M18.0852 10.7947C19.0038 12.8037 20.5774 14.1352 19.218 16.7672C18.9606 17.2645 18.4302 17.8052 18.0852 18.3118C17.6578 18.9379 17.3159 19.6649 17.0554 19.8554C16.3747 20.3558 14.5395 20.5813 13.7599 19.9583C13.4726 19.7287 13.4664 19.2478 13.245 18.9296C13.0596 18.6609 12.6713 18.2438 12.3181 17.8999C11.9515 17.5415 11.5498 17.1492 11.1863 17.0761C9.5777 16.7486 7.80328 18.666 5.4192 17.8999C5.0948 17.3675 5.25854 16.7095 5.21323 16.0464C5.12054 14.6562 4.73023 13.3536 5.31621 12.4423C5.79406 11.7008 6.99382 11.6205 7.78783 11.2066C8.9083 10.6237 9.41601 9.60532 10.2595 9.14812C11.935 9.27581 12.6209 7.36564 13.9659 8.22135C14.6445 8.65179 14.2769 9.91837 15.4076 10.0749C16.1687 9.75876 15.8052 8.486 16.6434 8.4273C17.4704 8.36861 17.5857 9.70418 18.0852 10.7947Z"
        fill="currentColor"
      />
      <path
        d="M16.7082 3.76543C17.4826 4.29884 18.0655 5.21325 17.841 6.23681C17.4023 6.50145 17.0264 6.06999 16.5022 6.03086C15.9708 5.99173 15.4435 6.25637 14.9574 6.13384C14.392 5.9907 14.2963 5.42023 13.9276 5.00112C13.494 4.50787 12.9781 4.42756 13.0007 3.86841C13.0625 2.3691 15.7926 3.13523 16.7082 3.76543Z"
        fill="currentColor"
      />
      <path
        d="M15.7162 21.4C15.7574 21.9674 15.5689 22.1517 15.3043 22.2238C13.9727 22.5883 14.604 20.1674 15.7162 21.4Z"
        fill="currentColor"
      />
      <path
        d="M12.1636 2.78091C12.6219 2.7356 12.9298 2.83858 12.8845 3.29578C12.7846 3.29887 12.8052 3.42244 12.7815 3.50173H12.1636C12.0174 3.36477 12.0174 2.91889 12.1636 2.78091Z"
        fill="currentColor"
      />
      <circle cx="15" cy="15" r="12.2844" stroke="currentColor" strokeWidth="1.43119" />
    </svg>
  )
}

export default EarthOceaniaIcon
