/** @jsxImportSource @emotion/react */
import { useTheme } from '@mui/material/styles'
import { sideBoxBlock } from './SideBoxBlock.styles'
import { ButtonInBlockProps } from '../DataDrivenBlocks'
import UiMarkdown from '@src/components/Atoms/UiMarkdown/UiMarkdown'
import { Box } from '@mui/material'

export type SideBoxBlockProps = {
  type: 'side-box' | 'side-box-first'
  header?: string
  content?: string // warning
  boxHeader?: string // warning
  boxContent?: string // warning
  buttons?: ButtonInBlockProps[]
}

export const SideBoxBlock = ({
  type,
  header,
  content,
  boxHeader,
  boxContent,
  buttons,
}: SideBoxBlockProps) => {
  const theme = useTheme()

  const contentBox = (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
      <h1>{header}</h1>
      {content && (
        <p>
          <UiMarkdown>{content}</UiMarkdown>
        </p>
      )}
      {buttons && (
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: '8px' }} className="buttonList">
          {buttons.map((button, index) => (
            <button key={index} onClick={() => window.open(button.url)}>
              {button.text}
            </button>
          ))}
        </Box>
      )}
    </Box>
  )

  const boxBox = (
    <Box className="box">
      <h1>{boxHeader}</h1>
      {boxContent && (
        <p>
          <UiMarkdown>{boxContent}</UiMarkdown>
        </p>
      )}
    </Box>
  )

  return (
    <Box css={sideBoxBlock({ theme })}>
      <Box>
        {type === 'side-box' ? (
          <>
            {contentBox}
            {boxBox}
          </>
        ) : (
          <>
            {boxBox}
            {contentBox}
          </>
        )}
      </Box>
    </Box>
  )
}
