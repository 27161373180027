import { css } from '@emotion/react'
import { Theme } from '@mui/material/styles'

interface LandingPageContainerProps {
  theme: Theme
}

export const landingPageContainer = ({ theme }: LandingPageContainerProps) => css`
  display: flex;
  flex-direction: row-reverse;
  align-items: start;
  justify-content: start;

  width: 100%;
  height: 100svh;

  overflow: auto;

  margin: 0 auto;
  background-color: ${theme.palette.white.main};

  @media (max-width: 860px) {
    flex-direction: column;
    align-items: stretch;
  }
`

export const landingPageLeft = ({ theme }: { theme: Theme }) => css`
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  gap: ${theme.spacing(4)};

  background-color: ${theme.palette.primary.main};

  width: 100%;
  min-height: 100%;
  padding: ${theme.spacing(12)} ${theme.spacing(12)} ${theme.spacing(8)};

  @media (max-width: 860px) {
    padding: 40px;
  }
  @media (max-width: 460px) {
    padding: 20px;
  }

  > * {
    max-width: 650px;
  }
`

export const mediaContainer = () => css`
  width: 100%;
  display: flex;
  justify-content: center;

  img {
    width: 70%;
  }
`

export const landingPageRight = ({
  theme,
  withFAQ = false,
}: {
  theme: Theme
  withFAQ?: boolean
}) => css`
  display: flex;
  align-items: stretch;
  justify-content: center;
  flex-direction: column;
  gap: ${theme.spacing(2)};
  width: 100%;
  position: relative;

  background-color: ${theme.palette.secondary.main};

  min-height: 100%;
  height: auto;
  padding: 0 ${theme.spacing(12)};

  img {
    width: 100%;
    max-width: 1000px;
  }

  @media (max-width: 860px) {
    display: none;
  }

  > div {
    ${withFAQ ? `background-color: ${theme.palette.white.main};` : ''}
  }
`

export const landingPageLeftScroll = ({ theme }: { theme: Theme }) => css`
  width: 50%;
  overflow-y: auto;
  height: 100vh;
  max-height: 100vh;
  min-height: 100vh;
  flex-grow: 1;

  background-color: ${theme.palette.primary.main};

  &::-webkit-scrollbar {
    width: 12px !important;
  }

  @media (max-width: 1300px) {
    width: 45%;
  }
  @media (max-width: 860px) {
    width: 100%;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent !important;
    border-radius: 0 !important;
    border: none !important;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${theme.palette.secondary.main}40 !important;
    border-radius: 0px !important;
  }
`

export const heroImage = () => css`
  height: 35vh;
`

export const landingPageLeftCenterBox = () => css`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 3vh;
`

export const landingPageRightScroll = ({ theme }: { theme: Theme }) => css`
  width: ${theme.spacing(200)};
  max-width: 45%;
  align-items: stretch;
  justify-content: center;
  gap: ${theme.spacing(2)};
  background-color: ${theme.palette.secondary.main};
  overflow-y: auto;
  height: 100vh;
  max-height: 100vh;

  &::-webkit-scrollbar {
    width: 12px !important;
  }

  @media (max-width: 1300px) {
    width: 50%;
  }

  &::-webkit-scrollbar-track {
    background-color: ${theme.palette.primary.main}00 !important;
    border-radius: 0 !important;
    border: none !important;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${theme.palette.primary.main}40 !important;
    border-radius: 0px !important;
  }
`

export const mainText = ({ theme, align = 'center' }: { theme: Theme; align?: string }) => css`
  color: ${theme.palette.white.main};
  font-weight: 900;
  font-size: 2.8rem;
  line-height: 3.4rem;
  max-width: 650px;
  text-align: ${align};
  align-self: center;
  letter-spacing: 1px;

  @media (max-width: 1410px) {
    font-size: 2.4rem;
    line-height: 3rem;
  }

  @media (max-width: 460px) {
    font-size: 2rem;
    line-height: 2rem;
  }
`

export const content = css`
  color: white;

  a {
    text-decoration: none;
  }
`

export const demoButton = ({ theme }: { theme: Theme }) => css`
  display: flex;
  align-items: center;

  margin: ${theme.spacing(6)} 0px;

  @media (max-width: 460px) {
    justify-content: center;
  }
`

export const footer = ({ theme }: { theme: Theme }) => css`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  gap: ${theme.spacing(3)};
  width: 100%;

  @media (max-width: 460px) {
    justify-content: center;
  }
`

export const socialLinks = ({ theme }: { theme: Theme }) => css`
  display: flex;
  align-items: start;
  justify-content: center;

  gap: ${theme.spacing(3)};
  color: ${theme.palette.white.main};

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${theme.palette.white.main};
    height: 32px;
    width: 32px;

    @media (max-width: 1410px) {
      height: 28px;
      width: 28px;
    }

    svg {
      width: 100%;
      height: 100%;
      transition: color 0.2s ease-in-out;

      &:hover {
        cursor: pointer;
        color: ${theme.palette.white.dark};
      }
    }
  }
`

export const footerText = ({ theme }: { theme: Theme }) => css`
  color: ${theme.palette.white.dark};
  font-weight: 500;

  @media (max-width: 1410px) {
    font-size: 1rem;
  }
`

export const logo = css`
  height: 60px;
  margin-top: -18px;
  margin-bottom: -18px;

  @media (max-width: 1410px) {
    height: 56px;
  }
`

export const marketingButton = ({ theme }: { theme: Theme }) => css`
  margin-top: ${theme.spacing(6)};
  margin-bottom: ${theme.spacing(6)};
  color: ${theme.palette.white.main};
  border-color: ${theme.palette.white.main};

  @media (max-width: 1410px) {
    font-size: 1rem;
  }
`

export const faqContent = ({ theme }: { theme: Theme }) => css`
  box-sizing: border-box;
  border-radius: ${theme.spacing(2)};
  max-height: 44vh;
  overflow-y: auto;
  padding-right: ${theme.spacing(2)};

  &::-webkit-scrollbar {
    width: ${theme.spacing(2)} !important;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent !important;
    border-radius: 0 !important;
    border: none !important;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${theme.palette.secondary.main}40 !important;
  }
`

export const landingPageHeader = () => css`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const faqItemContainer = ({ theme }: { theme: Theme }) => css`
  padding: ${theme.spacing(2)} ${theme.spacing(4)};
  position: relative;
  border-radius: ${theme.spacing(1)};

  h6 {
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: ${theme.spacing(1)};
  }

  p {
    font-size: 0.9rem;
    font-weight: 400;
    margin-bottom: ${theme.spacing(2)};
  }
  border-left: 2px solid transparent;
  transition: border-left 0.2s ease, background-color 0.2s ease;
  box-sizing: border-box;
`

export const faqButton = ({ theme }: { theme: Theme }) => css`
  padding: ${theme.spacing(2)} ${theme.spacing(4)};
  border-radius: ${theme.spacing(2)};
  font-weight: 500;
  text-transform: none;
  box-sizing: border-box;
  font-size: 0.9rem;
  text-align: left;
  white-space: break-spaces;
  line-height: 1.4rem;
`

export const smallLogo = css`
  height: 32px;
  margin-top: -14px;
  margin-bottom: -8px;
  margin-right: -8px;

  @media (max-width: 1410px) {
    height: 28px;
  }
`

export const welcomeText = ({ theme, align = 'center' }: { theme: Theme; align?: string }) => css`
  color: ${theme.palette.white.main};
  font-weight: 500;
  font-size: 1.6rem;
  margin-bottom: ${theme.spacing(1)};
  text-align: ${align};
  letter-spacing: 0.4px;

  @media (max-width: 1410px) {
    font-size: 1.4rem;
  }

  @media (max-width: 460px) {
    font-size: 1.2rem;
  }
`

export const landingPageRightTop = ({ theme }: { theme: Theme }) => css`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${theme.spacing(8)};
  box-sizing: border-box;
  border-radius: ${theme.spacing(1)};
  height: 100vh;
`

export const landingPageRightBottom = ({ theme }: { theme: Theme }) => css`
  display: flex;
  align-items: stretch;
  justify-content: center;
  flex-grow: 1;
  flex-direction: column;
  border-radius: ${theme.spacing(1)};
  color: ${theme.palette.primary.main};
  gap: ${theme.spacing(4)};
  padding: ${theme.spacing(8)} 0;

  h3 {
    font-size: 1.8rem;
    font-weight: 900;
    padding: 0 ${theme.spacing(4)};
  }
`
