export const Horticulture = ({ ...rest }) => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M5.79376 7.54499C3.40501 9.93457 3.40501 13.8034 5.77626 16.193C7.0625 13.217 9.355 10.7311 12.2163 9.25183C9.7925 11.3 8.095 14.1623 7.5 17.4097C9.775 18.4863 12.575 18.0924 14.4563 16.2105C17.5012 13.1644 18 4 18 4C18 4 8.83875 4.49892 5.79376 7.54499Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default Horticulture
