export const BulbIcon = ({ ...rest }) => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M15.0818 28H15.4368C16.9281 27.9983 18.3579 27.4051 19.4124 26.3506C20.4669 25.2961 21.0601 23.8663 21.0618 22.375V19.4744C22.1416 18.6074 23.0129 17.509 23.6112 16.2601C24.2095 15.0113 24.5196 13.644 24.5185 12.2592C24.5185 7.1537 20.3648 3 15.2593 3C10.1537 3 6 7.1537 6 12.2592C5.99895 13.644 6.30905 15.0113 6.90737 16.2602C7.5057 17.509 8.37698 18.6075 9.45683 19.4744V22.375C9.45849 23.8663 10.0517 25.2961 11.1062 26.3506C12.1607 27.4051 13.5905 27.9983 15.0818 28ZM15.4368 26.3333H15.0818C14.2889 26.3323 13.5145 26.0936 12.8587 25.6479C12.2029 25.2023 11.6959 24.5702 11.4031 23.8333H19.1155C18.8227 24.5702 18.3156 25.2023 17.6598 25.6479C17.004 26.0936 16.2297 26.3323 15.4368 26.3333ZM19.3951 22.1667H11.1235V20.0833H19.3951V22.1667ZM7.66667 12.2592C7.66667 8.07292 11.0727 4.66667 15.2593 4.66667C19.4458 4.66667 22.8519 8.07292 22.8519 12.2592C22.8493 13.454 22.5661 14.6314 22.0249 15.6966C21.4837 16.7617 20.6998 17.6848 19.7364 18.3914L19.702 18.4167H10.8166L10.7819 18.3914C9.81855 17.6848 9.03469 16.7617 8.49356 15.6965C7.95244 14.6314 7.6692 13.4539 7.66667 12.2592Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default BulbIcon
