export const LayersIcon = ({ ...rest }) => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M24.6639 12.1488L15.3307 5.11582C15.23 5.03992 15.116 5 15 5C14.884 5 14.77 5.03992 14.6693 5.11582L5.33608 12.1488C5.23265 12.2267 5.14693 12.3397 5.08784 12.4761C5.02875 12.6124 4.99844 12.7672 5.00006 12.9242C5.00169 13.0813 5.03519 13.2349 5.09708 13.3691C5.15897 13.5033 5.247 13.6131 5.352 13.6873L14.6852 20.2807C14.782 20.3491 14.8901 20.3849 15 20.3849C15.1098 20.3849 15.218 20.3491 15.3148 20.2807L24.648 13.6873C24.753 13.6131 24.841 13.5033 24.9029 13.3691C24.9648 13.2349 24.9983 13.0813 24.9999 12.9242C25.0016 12.7672 24.9713 12.6124 24.9122 12.4761C24.8531 12.3397 24.7673 12.2267 24.6639 12.1488ZM15 18.5082L7.04343 12.8873L15 6.89165L22.9566 12.8873L15 18.5082Z"
        fill="currentColor"
      />

      <path
        d="M15.3185 24.8912L24.3304 18.355V16.3483L14.9972 23.1176L5.66406 16.3483V18.355L14.676 24.8912C14.7744 24.9626 14.8849 25 14.9972 25C15.1096 25 15.2201 24.9626 15.3185 24.8912Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default LayersIcon
