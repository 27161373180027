import { css, keyframes } from '@emotion/react'
import { Theme } from '@mui/material/styles'

const loadingAnimation = keyframes`
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
`
export const noResultsContainer = ({
  theme,
  isLoading,
}: {
  theme: Theme
  isLoading: boolean
}) => css`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: ${theme.spacing(1)};
  padding: ${theme.spacing(4)};

  border: 2px solid ${theme.palette.primary.main + '60'};
  border-radius: ${theme.shape.borderRadius}px;
  border-style: dashed;
  color: ${theme.palette.primary.dark};

  background-color: ${theme.palette.primary.main + '30'};
  animation: ${isLoading ? loadingAnimation : 'none'} 0.8s infinite;
`
