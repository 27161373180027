/** @jsxImportSource @emotion/react */
import {
  Box,
  Accordion as MuiAccordion,
  AccordionDetails as MuiAccordionDetails,
  AccordionDetailsProps as MuiAccordionDetailsProps,
  AccordionProps as MuiAccordionProps,
  AccordionSummary as MuiAccordionSummary,
  AccordionSummaryProps as MuiAccordionSummaryProps,
} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { useContext, useEffect, useState } from 'react'

import { Icon } from '../Icon'
import { draggableAccordionTitle } from '@src/components/Molecules/SideDrawerContent/tabs/InformativePanel/LayersTab/LayersOrganizer'
import { SortableList } from '../Sortable'
import { accordionClass } from './UiclAccordion.styles'
import { AccordionStateContext } from '@contexts/AccordionStateContext/AccordionStateContext'

export interface UiclAccordionProps extends Omit<MuiAccordionProps, 'children'> {
  header: JSX.Element
  body: JSX.Element
  accordionStateKey?: string
  fullHeight?: boolean
  disableExpand?: boolean
  isDraggable?: boolean
  lazyContent?: boolean
  className?: string
  defaultExpandedOnce?: boolean
}

export const UiclAccordion = ({
  header,
  body,
  accordionStateKey,
  className,
  fullHeight = false,
  disableExpand = false,
  isDraggable = false,
  lazyContent = false,
  defaultExpanded = false,
  defaultExpandedOnce = false,
  ...rest
}: UiclAccordionProps) => {
  const theme = useTheme()

  const { accordionState, setAccordionOpenState } = useContext(AccordionStateContext)

  let expandedState = defaultExpanded
  if (accordionStateKey !== undefined && accordionState[accordionStateKey])
    expandedState = accordionState[accordionStateKey]
  else if (
    defaultExpandedOnce &&
    accordionStateKey !== undefined &&
    accordionState[accordionStateKey] === undefined
  ) {
    expandedState = true
  }

  const [expanded, setExpanded] = useState(expandedState)

  const handleChange = (_event: React.SyntheticEvent, expanded: boolean) => {
    if (disableExpand) return
    setExpanded(expanded)
    if (accordionStateKey !== undefined) setAccordionOpenState(accordionStateKey, expanded)
  }

  useEffect(() => {
    if (
      defaultExpandedOnce &&
      accordionStateKey !== undefined &&
      accordionState[accordionStateKey] !== expanded
    ) {
      setAccordionOpenState(accordionStateKey, expanded)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (isDraggable) {
    return (
      <MuiAccordion
        {...rest}
        css={accordionClass({ theme, fullHeight, isExpanded: expanded, disableExpand })}
        expanded={expanded}
        onChange={handleChange}
        elevation={0}
        className={className}
        slotProps={{
          transition: {
            unmountOnExit: lazyContent ? true : false,
            mountOnEnter: lazyContent ? true : false,
          },
        }}
        disableGutters
      >
        <UiclAccordionSummary
          aria-controls={`accordion-header`}
          id={`accordion-header`}
          disableExpand={disableExpand}
        >
          <Box css={draggableAccordionTitle}>
            <SortableList.DragHandle />
            {header}
          </Box>
        </UiclAccordionSummary>
        <UiclAccordionDetails aria-controls={`accordion-content`} id={`accordion-header`}>
          {body}
        </UiclAccordionDetails>
      </MuiAccordion>
    )
  }

  return (
    <MuiAccordion
      {...rest}
      css={accordionClass({ theme, fullHeight, isExpanded: expanded, disableExpand })}
      expanded={expanded}
      onChange={handleChange}
      elevation={0}
      disableGutters
      className={className}
      TransitionProps={{ mountOnEnter: lazyContent ? true : false }}
    >
      <UiclAccordionSummary
        aria-controls={`accordion-header`}
        id={`accordion-header`}
        disableExpand={disableExpand}
      >
        {header}
      </UiclAccordionSummary>
      <UiclAccordionDetails aria-controls={`accordion-content`} id={`accordion-details`}>
        {body}
      </UiclAccordionDetails>
    </MuiAccordion>
  )
}

export interface UiclAccordionSummaryProps extends MuiAccordionSummaryProps {
  disableExpand?: boolean
}

export const UiclAccordionSummary = ({ disableExpand, ...rest }: UiclAccordionSummaryProps) => {
  const theme = useTheme()

  return (
    <MuiAccordionSummary
      data-test-id="AccordionSummary"
      expandIcon={
        !disableExpand && (
          <Icon iconName="AccordionArrowDown" colour={theme.palette.grey[600]} size="small" />
        )
      }
      {...rest}
    />
  )
}

export type UiclAccordionDetailsProps = MuiAccordionDetailsProps

export const UiclAccordionDetails = ({ ...rest }: UiclAccordionDetailsProps) => {
  return <MuiAccordionDetails data-test-id="AccordionDetails" {...rest} />
}
