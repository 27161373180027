import { css } from '@emotion/react'
import { Theme } from '@mui/material/styles'

export const informationLayersContainer = ({ theme }: { theme: Theme }) => css`
  display: flex;
  flex-direction: column;
  flex: 1;

  width: 100%;
  height: 100%;

  gap: ${theme.spacing(3)};
  padding-top: ${theme.spacing(1)};
`

export const informationLayerContent = () => css`
  height: 100%;
  overflow: auto;
`

export const informationLayersOption = ({
  theme,
  isSelected,
}: {
  theme: Theme
  isSelected: boolean
}) => css`
  padding: ${theme.spacing(1)} ${theme.spacing(2)};
  border-radius: ${theme.shape.borderRadius}px;
  background-color: ${isSelected && theme.palette.white.light};
  color: ${isSelected && theme.palette.grey[500]};

  display: flex;
  align-items: center;
  justify-content: space-between;

  transition: background-color 0.2s;

  &:hover {
    cursor: pointer;
    background-color: ${!isSelected && theme.palette.grey[100] + '30'};
  }
`

export const informationLayersOptionBody = ({ theme }: { theme: Theme }) => css`
  padding: ${theme.spacing(0.5)};
`

export const informationSelected = ({ theme }: { theme: Theme }) => css`
  font-style: italic;
  margin-left: ${theme.spacing(2)};
`

export const assetInternalAccordionIcon = css`
  min-width: 0;
`

export const informationLayersFormAccordions = ({ theme }: { theme: Theme }) => css`
  overflow: auto;
  display: flex;
  align-items: stretch;
  justify-content: start;
  flex-direction: column;
  gap: ${theme.spacing(2)};
  padding: ${theme.spacing(1)};
  padding-top: 0;
  overflow: visible;

  flex: 1;
`
export const informationLayersFormInternalAccordion = ({ theme }: { theme: Theme }) => css`
  display: flex;
  align-items: stretch;
  justify-content: center;
  flex-direction: column;
  gap: ${theme.spacing(1)};

  overflow: auto;
`

export const informationInternalAccordionTitle = css`
  font-weight: 700;
`
export const informationInternalAccordionBody = css`
  overflow: auto;
`

export const informationInternalAccordionLabel = ({
  isAlreadySelected,
  theme,
}: {
  isAlreadySelected: boolean
  theme: Theme
}) => css`
  padding: ${theme.spacing(1)};
  display: flex;
  align-items: center;
  justify-content: space-between;

  color: ${isAlreadySelected && theme.palette.grey[500]};

  border-radius: ${theme.shape.borderRadius}px;
  background-color: ${isAlreadySelected && theme.palette.white.light};

  transition: background-color 0.2s;

  & > div > p {
    color: ${isAlreadySelected && theme.palette.grey[500]};
  }

  &:hover {
    cursor: pointer;
    background-color: ${!isAlreadySelected && theme.palette.white.light};
  }
`

export const informationInternalAccordionIcon = css`
  min-width: 0;
`
