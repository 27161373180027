/** @jsxImportSource @emotion/react */

import { Box, useTheme } from '@mui/material'
import { sideDrawerDividerStyle } from './SideDrawerDivider.styles'
import { SerializedStyles } from '@emotion/react'

export const SideDrawerDivider = ({ css }: { css?: SerializedStyles }) => {
  const theme = useTheme()
  return <Box css={[sideDrawerDividerStyle({ theme }), css]} />
}
