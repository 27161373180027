import { css } from '@emotion/react'
import { Theme } from '@mui/material/styles'

export const draggableAccordionIcon = ({ theme }: { theme: Theme }) => css`
  margin-left: -6px;
  margin-right: 2px;

  border-radius: 4px;
  transition: background-color 0.15s;

  & :hover {
    background-color: ${theme.palette.white.light};
  }
`
