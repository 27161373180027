import { css } from '@emotion/react'
import { Theme } from '@mui/material'

export const body = ({ theme }: { theme: Theme }) => css`
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: ${theme.palette.primary.main}20;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 12px !important;
  }

  &::-webkit-scrollbar-track {
    background-color: ${theme.palette.primary.main}00 !important;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${theme.palette.primary.main}40 !important;
    border-radius: 0px !important;
  }
  &::-webkit-scrollbar-thumb:hover {
    background-color: ${theme.palette.primary.main}60 !important;
  }

  position: relative;
`

export const scrollingBody = css`
  display: flex;
  flex-direction: column;
  align-items: stretch;
`
