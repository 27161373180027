/** @jsxImportSource @emotion/react */
import { useSnackbars } from '@uintel/ui-component-library'
import { useMap } from '@contexts/MapContext'
import { MenuItem, useTheme } from '@mui/material'
import { useSelector } from 'react-redux'
import { RootState } from '@redux/store'
import { downloadAnchor, exportButton } from './ExportRiskSummaryXlxs.styles'
import { RegionOption } from '../../RegionFilter'
import { BASE_API_URL } from '@src/app-constants'
import { Polygon } from 'geojson'
import { useEffect, useState } from 'react'

export const ExportRiskSummaryXlsxButton = () => {
  const theme = useTheme()
  const { displaySnackbar } = useSnackbars()
  const { layers, drawAreas } = useSelector((state: RootState) => state.map)
  const { regionMasks } = useMap()
  const [xlsxUrl, setXlsxUrl] = useState('')

  useEffect(() => {
    const assetLayers = layers.filter((layer) => layer.layerType == 'asset')
    const hazardLayers = layers.filter((layer) => layer.layerType == 'hazard')
    const assetLayerTypes = assetLayers.map((assetLayer) => assetLayer.type)
    const hazardLayerAssetTags = hazardLayers.map((hazardLayer) => hazardLayer.assetTag)

    const regions = regionMasks ? regionMasks.map((mask: RegionOption) => mask.region) : []
    const query = []
    if (regions.length > 0) query.push(`regions=${regions.join(',')}`)

    const drawAreaCoordinateArray: Polygon | null =
      drawAreas.length > 0 ? (drawAreas[0].geometry as Polygon) : null
    const drawAreaCsv = drawAreaCoordinateArray
      ? drawAreaCoordinateArray.coordinates[0].flat().join(',')
      : ''

    setXlsxUrl(
      `${BASE_API_URL}/api/asset/export/risksummary/xlsx/${assetLayerTypes.join(
        ',',
      )}/${hazardLayerAssetTags.join(',')}/${drawAreaCsv}?${query.join('&')}`,
    )
  }, [layers, drawAreas, regionMasks])

  const handleClick = async () => {
    displaySnackbar({
      message: 'Generating data for export. File will download once complete.',
      type: 'info',
    })
  }

  return (
    <a href={xlsxUrl} download css={downloadAnchor()}>
      <MenuItem css={exportButton({ theme })} onClick={handleClick}>
        Spreadsheet (.xlsx)
      </MenuItem>
    </a>
  )
}
