export const passwordRules = [
  'Password must contain a lower case letter',
  'Password must contain an upper case letter',
  'Password must contain a number',
  'Password must contain a special character or a space',
  'Password must not contain a leading or trailing space',
  'Password must contain at least 8 characters',
]

export const checkIfPasswordHasValidFormat = (password: string) => {
  const hasLowerCase = /[a-z]/.test(password)
  const hasUpperCase = /[A-Z]/.test(password)
  const hasNumber = /\d/.test(password)
  const hasAtLeast8Characters = password.length >= 8
  const hasSpecialCharacter = /[^a-zA-Z\d\s:]/.test(password)
  const hasLeadingOrTrailingSpace = /^\s|\s$/.test(password)

  const passwordErrors = [
    !hasLowerCase && 'Password must contain a lower case letter',
    !hasUpperCase && 'Password must contain an upper case letter',
    !hasNumber && 'Password must contain a number',
    !hasSpecialCharacter && 'Password must contain a special character or a space',
    hasLeadingOrTrailingSpace && 'Password must not contain a leading or trailing space',
    !hasAtLeast8Characters && 'Password must contain at least 8 characters',
  ]

  const errors = passwordErrors.filter(Boolean) as string[]

  return errors
}
