import { Theme } from '@mui/material'
import { css, keyframes } from '@emotion/react'

export const header = ({ theme }: { theme: Theme }) => css`
  display: flex;
  align-items: center;
  overflow: hidden;
  min-height: 48px;
  box-shadow: 0 0 4px 0 ${theme.palette.primary.main}40;
  padding: ${theme.spacing(2)} ${theme.spacing(5)};
  background-color: ${theme.palette.secondary.main};
  padding-right: ${theme.spacing(12)};
`

export const titleContainer = ({ theme }: { theme: Theme }) => css`
  display: flex;
  align-items: center;
  justify-content: start;
  gap: ${theme.spacing(4)};
  color: ${theme.palette.primary.main};
  font-size: 0.95rem;
  letter-spacing: 0.6px;
  line-height: 1.2;
  font-weight: 700;
  user-select: none;
  vertical-align: middle;

  span {
    margin-top: 2px;
  }
`

const slideOut = keyframes`
0% {
  transform: translateX(4px);
  opacity: 0;
}
100% {
  transform: translateX(0);
  opacity: 1;
}
`

export const subtitleContainer = () => css`
  display: inline-block;
  margin-top: 0 !important;
  animation: ${slideOut} 0.1s ease-in-out forwards;
  letter-spacing: 0;
`

export const closeButton = ({ theme }: { theme: Theme }) => css`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 50%;
  width: 28px;
  height: 28px;
  transition: background-color 0.2s;
  background-color: ${theme.palette.primary.main}00;
  position: absolute;
  right: ${theme.spacing(4)};

  &:hover {
    background-color: ${theme.palette.primary.main}20;
  }
`

export const clickableTitle = css`
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`
