import { css } from '@emotion/react'
import { Theme } from '@mui/material/styles'

export const assetSpecificDetailContainer = ({ theme }: { theme: Theme }) => css`
  padding: ${theme.spacing(2)};
`

export const assetSpecificMainHeader = ({ theme }: { theme: Theme }) => css`
  display: flex;
  align-items: center;
  justify-content: start;
  gap: ${theme.spacing(2)};
`

export const assetSpecificMainHeaderIcon = ({ theme }: { theme: Theme }) => css`
  width: ${theme.spacing(5)};
  color: ${theme.palette.text.secondary};
`

export const assetSpecificMainBody = ({ theme }: { theme: Theme }) => css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${theme.spacing(2)} ${theme.spacing(1)} 0;
`

export const assetSpecificMainBodyTitle = ({ theme }: { theme: Theme }) => css`
  display: flex;
  align-items: center;
  justify-content: start;
  gap: ${theme.spacing(2)};
`

export const assetSpecificMainBodyID = ({ theme }: { theme: Theme }) => css`
  display: flex;
  align-items: center;
  justify-content: start;
  gap: ${theme.spacing(1)};
`

export const assetSpecificMainHTML = ({ theme }: { theme: Theme }) => css`
  padding: 0 ${theme.spacing(9)} ${theme.spacing(2)};
`

export const assetSpecificMainDivider = ({ theme }: { theme: Theme }) => css`
  margin-bottom: ${theme.spacing(3)};
`

export const assetSpecificLayerDetails = ({ theme }: { theme: Theme }) => css`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing(1)};
`

export const assetSpecificHazardsHeader = ({ theme }: { theme: Theme }) => css`
  display: flex;
  align-items: center;
  justify-content: start;
  gap: ${theme.spacing(2)};
`

export const assetSpecificHazardsBody = ({ theme }: { theme: Theme }) => css`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing(2)};
  margin: ${theme.spacing(1)} 0;
`

export const assetSpecificHazardsSentenceContainer = ({ theme }: { theme: Theme }) => css`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing(2)};

  background-color: ${theme.palette.background.paper};

  padding: ${theme.spacing(2)} ${theme.spacing(2)} ${theme.spacing(3)} ${theme.spacing(5)};

  border: 1px solid ${theme.palette.grey[100]};
  border-radius: ${theme.shape.borderRadius}px;
  font-size: 14px;

  position: relative;
`

export const assetSpecificHazardsSentenceSpan = ({ colour }: { colour: string }) => css`
  position: absolute;
  left: 0;
  top: 0;
  width: 6px;
  height: 100%;
  background-color: ${colour};
  border-radius: 4px 0 0 4px;
`

export const assetSpecificHazardsFooter = ({ theme }: { theme: Theme }) => css`
  display: flex;
  align-items: center;
  justify-content: start;
  margin-top: ${theme.spacing(1)};
  gap: ${theme.spacing(4)};
`

export const assetSpecificHazardsFooterMain = ({ theme }: { theme: Theme }) => css`
  display: flex;
  align-items: center;
  justify-content: start;
  gap: ${theme.spacing(4)};
`

export const assetSpecificHazardsFooterValue = ({ theme }: { theme: Theme }) => css`
  ${assetSpecificHazardsFooterMain({ theme })};
`

export const assetSpecificFooter = ({ theme }: { theme: Theme }) => css`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${theme.palette.grey[100]};
  border-radius: ${theme.shape.borderRadius}px;
  gap: ${theme.spacing(6)};
  padding: ${theme.spacing(1)} 0;
  background-color: ${theme.palette.background.paper};
`

export const assetSpecificFooterMain = ({ theme }: { theme: Theme }) => css`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  gap: ${theme.spacing(1)};
`

export const assetSpecificFooterResult = ({ theme }: { theme: Theme }) => css`
  display: flex;
  align-items: center;
  justify-content: start;
  gap: ${theme.spacing(1)};
`

export const assetSpecificFooterResultIcon = ({ theme }: { theme: Theme }) => css`
  width: ${theme.spacing(5)};
`

export const selectedHeader = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;

  button {
    padding-left: 12px;
  }

  svg {
    height: 20px !important;
    margin: 0 0 0 4px !important;
  }
`
