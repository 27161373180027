/** @jsxImportSource @emotion/react */
import SliderIndicatorSvg from '@assets/illustrations/sliderIndicator.svg'
import { Box, Typography } from '@mui/material'

import {
  indicatorContainer,
  labelContainer,
  rotateClass,
  sliderIndicatorArrow,
  sliderIndicatorContainer,
} from './SliderIndicator.styles'
import { Tooltip } from '@src/components/Atoms/Tooltip/Tooltip'

export interface SliderIndicatorProps {
  arrowPosition?: 'up' | 'down' | 'left' | 'right'
  label: string
  tooltip?: string
}

export const SliderIndicator = ({
  arrowPosition = 'up',
  label = '',
  tooltip = '',
}: SliderIndicatorProps) => {
  let initials = label.toUpperCase()

  if (label.length > 2) {
    initials = label.slice(0, 2).toUpperCase()
  }

  function getArrowRotation() {
    switch (arrowPosition) {
      case 'up':
        return 0
      case 'down':
        return 180
      case 'left':
        return -90
      case 'right':
        return 90
      default:
        return 0
    }
  }

  const indicator = (
    <Box css={sliderIndicatorContainer}>
      <Box component="span" css={[indicatorContainer, rotateClass(getArrowRotation())]}>
        <Box component="img" src={SliderIndicatorSvg} css={sliderIndicatorArrow} />
      </Box>
      <Typography css={labelContainer} align="center">
        {initials}
      </Typography>
    </Box>
  )

  if (!tooltip.length) return <>{indicator}</>

  function getTooltipPlacement() {
    switch (arrowPosition) {
      case 'up':
        return 'bottom'
      case 'down':
        return 'top'
      case 'left':
        return 'right'
      case 'right':
        return 'left'
      default:
        return 'bottom'
    }
  }

  return (
    <Tooltip title={tooltip} placement={getTooltipPlacement()}>
      {indicator}
    </Tooltip>
  )
}
