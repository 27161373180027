import { css } from '@emotion/react'
import { Theme } from '@mui/material'

export const buttonsParent = ({ theme }: { theme: Theme }) => css`
  display: flex;
  gap: ${theme.spacing(2)};

  > button {
    padding: 0;
    width: 24px;
    height: 24px;

    &:hover {
      background-color: rgba(0, 0, 0, 0.1);
    }

    svg {
      margin: 0 !important;
    }
  }
`
