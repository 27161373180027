/** @jsxImportSource @emotion/react */
import {
  background,
  desaturateFilter,
  hueFilter,
  imageWithFilters,
  lightenFilter,
} from './ImageWithFilters.styles'
import { Interpolation, Theme } from '@emotion/react'
import { Box } from '@mui/material'

export interface ImageWithFiltersProps {
  src: string
  alt: string
  color: string
  backgroundColor?: string
  hueStrength: number
  lightenStrength?: number
  desaturateStrength?: number
  customCss?: Interpolation<Theme>
}

// Warning: if the image has transparency, the filters will also apply to the transparent parts of the image, thus affecting the background. (unless the background is white)
export const ImageWithFilters = ({
  src,
  alt,
  color,
  backgroundColor = 'white',
  hueStrength,
  lightenStrength = 0,
  desaturateStrength = 0,
  customCss,
}: ImageWithFiltersProps) => {
  return (
    <Box css={[imageWithFilters(), customCss]}>
      <Box css={background(backgroundColor)} />
      <img src={src} alt={alt} css={desaturateFilter({ desaturateStrength })} />
      <Box css={hueFilter({ color, hueStrength })} />
      {lightenStrength > 0 && <Box css={lightenFilter({ color, lightenStrength })} />}
    </Box>
  )
}
