import { Theme } from '@mui/material'
import { css } from '@emotion/react'

export const accordionHazardsHeader = ({ theme }: { theme: Theme }) => css`
  display: flex;
  align-items: center;
  justify-content: space-between;

  flex: 1 1 auto;
  padding-right: ${theme.spacing(2)};
`

export const hiddenLayerContainer = ({ theme }: { theme: Theme }) => css`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${theme.spacing(2)};
`

export const hiddenLayerLabel = ({ theme, isVisible }: { theme: Theme; isVisible: boolean }) => css`
  text-decoration: ${!isVisible && 'line-through'};
  color: ${!isVisible && theme.palette.grey[300]};
`
export const accordionHazardContentContainer = ({ theme }: { theme: Theme }) => css`
  height: 100%;
  overflow: auto;
  padding: 0 0 ${theme.spacing(2)} 0;
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing(1)};
`

export const layerHeader = ({ canExpand }: { canExpand: boolean }) => css`
  padding-left: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
  flex-grow: 1;
  padding-right: ${canExpand ? 4 : 0}px;
  margin: -2px 0;
`
