import { css } from '@emotion/react'
import { Theme } from '@mui/material'

export const downloadAnchor = () => css`
  text-decoration: none;
`

export const exportButton = ({ theme }: { theme: Theme }) => css`
  flex-grow: 1;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0;
  margin: 0;
  font-size: 16px;
  font-weight: 500;
  color: ${theme.palette.primary.dark};
  border-bottom: 1px solid ${theme.palette.primary.dark}10;

  &:hover {
    cursor: pointer;
    background-color: ${theme.palette.primary.dark}10;
  }
`
