import { createContext, useState } from 'react'

interface AccordionState {
  [id: string]: boolean
}

export const AccordionStateContext = createContext(
  {} as {
    accordionState: AccordionState
    setAccordionOpenState: (accordionId: string, openState: boolean) => void
  },
)

export const AccordionStateProvider = ({ children }: { children: React.ReactNode }) => {
  const [accordionState, setAccordionState] = useState<AccordionState>({})

  const setAccordionOpenState = (accordionId: string, openState: boolean) => {
    setAccordionState((previousState) => {
      const newState = { ...previousState }
      newState[accordionId] = openState
      return newState
    })
  }
  return (
    <AccordionStateContext.Provider value={{ accordionState, setAccordionOpenState }}>
      {children}
    </AccordionStateContext.Provider>
  )
}
