/** @jsxImportSource @emotion/react */
import { SerializedStyles } from '@emotion/react'
import { Box, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { useMemo } from 'react'

import { sidebarButtonContainer, sidebarButtonLabel } from './SidebarButton.styles'
import { Icon } from '@src/components/Atoms/Icon'

export interface SidebarButtonProps {
  icon: Icon
  label: string
  onClick: (() => void | null) | ((e: React.MouseEvent<HTMLButtonElement>) => void)
  displayLabel?: boolean
  id?: string
  active?: boolean
  disabled?: boolean
  hidden?: boolean
  customClass?: {
    iconClass?: SerializedStyles
    labelClass?: SerializedStyles
    buttonClass?: SerializedStyles
  }
  testId?: string
}

export const SidebarButton = ({
  icon,
  label,
  onClick,
  displayLabel = false,
  id,
  disabled = false,
  active = false,
  hidden = false,
  customClass,
  testId,
}: SidebarButtonProps) => {
  const theme = useTheme()

  const iconColour = useMemo(() => {
    if (active) {
      return theme.palette.primary.main
    } else {
      return theme.palette.white?.main ?? 'white'
    }
  }, [theme, active])

  return (
    <Box
      component="button"
      css={[
        sidebarButtonContainer({ theme, active, displayLabel, disabled, hidden }),
        customClass?.buttonClass,
      ]}
      id={id}
      data-testid={testId}
      onClick={!disabled ? onClick : () => undefined}
    >
      <Box css={customClass?.iconClass}>
        <Icon iconName={icon} colour={iconColour} size="large" />
      </Box>

      <Typography
        variant="body1"
        css={[
          sidebarButtonLabel({ theme, active, disabled, displayLabel }),
          customClass?.labelClass,
        ]}
      >
        {label}
      </Typography>
    </Box>
  )
}
