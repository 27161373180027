import { Theme } from '@mui/material'
import { css } from '@emotion/react'

export const sourceButton = ({ theme, width }: { theme: Theme; width?: string }) => css`
  display: flex;
  align-items: center;
  gap: ${theme.spacing(1)};
  margin-top: ${theme.spacing(2)};
  flex: 1 1 auto;
  border: 1px solid ${theme.palette.primary.dark};
  ${width ? `width: ${width};` : ''}

  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 0.875rem;
  padding: ${theme.spacing(1)} ${theme.spacing(2)};
  color: ${theme.palette.primary.dark};

  & > a,
  & > svg {
    text-decoration: none;
    color: ${theme.palette.primary.dark};
  }

  &:hover {
    background-color: ${theme.palette.primary.dark}10;
    border-color: ${theme.palette.primary.dark};
  }
`

export const sourceButtonIcon = ({ theme }: { theme: Theme }) => css`
  color: ${theme.palette.primary.main};
  width: 18px;
  height: 18px;
  padding-top: 2px;
`
