/** @jsxImportSource @emotion/react */
import { useEffect, useState } from 'react'

import { Box, CircularProgress, useTheme, Typography } from '@mui/material'
import { MapLayer, MapLayerType } from '@redux/map/mapSlice'
import { RootState } from '@redux/store'
import { Button } from '@src/components/Atoms/Button'
import { useSelector } from 'react-redux'
import { ModalLayer } from '../../LayersControl/LayersControl'
import {
  addLayersDialogContainer,
  addLayersDialogTitle,
  addLayersFooter,
  loadingSection,
} from './AddLayersDialog.styles'
import { LayerSetup } from './LayerSetup'
import { addLayersSetupContainer } from './LayerSetup/LayerSetup.styles'
import { LayersPreview } from './LayersPreview'
import { useLayers } from '@contexts/LayerContext'
import { useLayerDataFetcher } from '../../SideDrawerContent/data_fetchers/layerDataFetcher'
import { useMap } from '@contexts/MapContext'
import { HazardOptionStateByHazard } from '../../SideDrawerContent/tabs/InformativePanel/LayersTab/HazardControls'

export interface AddLayersDialogProps {
  onClose: () => void
}

export const AddLayersDialog = ({ onClose }: AddLayersDialogProps) => {
  const theme = useTheme()

  const { prepareAndUpdateLayers } = useLayers()
  const { setHazardOptionState } = useMap()
  const { layers } = useSelector((state: RootState) => state.map)

  const mapLayerToModalLayerShape = (layers: MapLayer[]) => {
    const reducedLayersByType = layers.reduce((acc, layer) => {
      const layerType = layer.layerType
      return {
        ...acc,
        [layerType]: [...(acc[layerType] || []), layer],
      }
    }, {} as Record<MapLayerType, MapLayer[]>)

    return Object.keys(reducedLayersByType).map((layerType) => {
      return {
        id: layerType as MapLayerType,
        layers: reducedLayersByType[layerType as MapLayerType],
      }
    })
  }

  const [stagedLayers, setStagedLayers] = useState<ModalLayer[]>(mapLayerToModalLayerShape(layers))

  const [selectedHazards, setSelectedHazards] = useState<MapLayer[]>(
    stagedLayers.find((layer) => layer.id === 'hazard')?.layers || [],
  )
  const [selectedAssets, setSelectedAssets] = useState<MapLayer[]>(
    stagedLayers.find((layer) => layer.id === 'asset')?.layers || [],
  )
  const [selectedInformation, setSelectedInformation] = useState<MapLayer[]>(
    stagedLayers.find((layer) => layer.id === 'information')?.layers || [],
  )

  const dialogData = useLayerDataFetcher()

  const handleUpdateLayers = (newLayers: MapLayer[], type?: MapLayerType) => {
    // if there are no layers, clear the selected layers
    if (newLayers.length === 0) {
      if (selectedHazards.length > 0) setSelectedHazards([])
      if (selectedAssets.length > 0) setSelectedAssets([])
      if (selectedInformation.length > 0) setSelectedInformation([])
    }
    if (!type) {
      // group each layer in newLayers by type
      const layersByType = newLayers.reduce((acc, layer) => {
        const layerType = layer.layerType
        return {
          ...acc,
          [layerType]: [...(acc[layerType] || []), layer],
        }
      }, {} as Record<MapLayerType, MapLayer[]>)
      // for each layer type, update the selected layers

      const types = ['hazard', 'asset', 'information']

      for (const type of types) {
        const layers = layersByType[type as MapLayerType] || []
        if (type === 'hazard') setSelectedHazards(layers)
        if (type === 'asset') setSelectedAssets(layers)
        if (type === 'information') setSelectedInformation(layers)
      }

      const updatedNewLayers = mapLayerToModalLayerShape(newLayers)
      setStagedLayers(updatedNewLayers)
      return
    }

    const isExistingLayerGroup = stagedLayers.some((layer) => layer.id === type)

    let updatedLayers = [...stagedLayers]

    if (isExistingLayerGroup) {
      updatedLayers = updatedLayers.map((layer) => {
        if (layer.id === type) {
          return {
            ...layer,
            layers: newLayers,
          }
        }
        return { ...layer }
      })
    } else {
      updatedLayers.push({
        id: type,
        layers: newLayers,
      })
    }

    /* If there is no asset layer group, add the group as the first element in the array */
    const hasAssetGroupsInCurrentLayers = stagedLayers.some((layer) => layer.id === 'asset')
    if (!hasAssetGroupsInCurrentLayers) {
      const assetLayerGroup = updatedLayers.find((layer) => layer.id === 'asset')
      const remainingLayers = updatedLayers.filter((layer) => layer.id !== 'asset')

      if (assetLayerGroup) {
        updatedLayers = [assetLayerGroup, ...remainingLayers]
      }
    }

    setStagedLayers(updatedLayers)
  }

  const handleSelectHazardLayer = (selectedHazard: MapLayer) => {
    if (!selectedHazard) return
    const existingHazardLayers = stagedLayers.find((layer) => layer.id === 'hazard')?.layers || []

    const updatedHazards = [...existingHazardLayers, selectedHazard]
    setSelectedHazards(updatedHazards)
    handleUpdateLayers(updatedHazards, 'hazard')
  }

  const handleSelectAssetLayer = (newAssets: MapLayer[]) => {
    const existingAssetLayers = stagedLayers.find((layer) => layer.id === 'asset')?.layers || []
    const updatedAssets = [...existingAssetLayers, ...newAssets]
    setSelectedAssets(updatedAssets)
    handleUpdateLayers(updatedAssets, 'asset')
  }

  const handleSelectInformationLayer = (selectedInformationLayer: MapLayer) => {
    const existingInformationLayers =
      stagedLayers.find((layer) => layer.id === 'information')?.layers || []
    const updatedInfoLayers = [...existingInformationLayers, selectedInformationLayer]
    setSelectedInformation(updatedInfoLayers)
    handleUpdateLayers(updatedInfoLayers, 'information')
  }

  const handleToggleLayerVisibility = (selectedLayer: MapLayer) => {
    const layerType = selectedLayer.layerType

    const layerGroup = stagedLayers.find((layer) => layer.id === layerType)

    if (!layerGroup) return

    const updatedLayers = layerGroup.layers.map((layer) => {
      if (layer.display_name === selectedLayer.display_name) {
        return {
          ...layer,
          visible: !layer.visible,
        }
      }
      return { ...layer }
    })

    handleUpdateLayers(updatedLayers, layerType)
  }

  const handleCancelClick = () => {
    onClose()
  }

  const handleDoneClick = () => {
    const stagedHazardAssetTags: string[] = stagedLayers
      .filter((stagedLayer) => stagedLayer.id == 'hazard')
      .flatMap((hazardModalLayer) => hazardModalLayer.layers)
      .filter((hazardLayer) => hazardLayer.hazard_id)
      .map((hazardLayerWithHazardId) => hazardLayerWithHazardId.assetTag)

    const currentHazardLayers = layers.filter((bar) => bar.layerType == 'hazard')

    const deletedHazardIds = currentHazardLayers
      .filter(
        (currentHazard) =>
          currentHazard.hazard_id && !stagedHazardAssetTags.includes(currentHazard.assetTag),
      )
      .map((deletedHazardLayer) => deletedHazardLayer.hazard_id ?? '')

    deletedHazardIds.forEach((deletedHazardId) => {
      setHazardOptionState((prevState: HazardOptionStateByHazard) => {
        const newState = { ...prevState }
        delete newState[deletedHazardId]
        return newState
      })
    })

    const allLayers = stagedLayers.reduce((acc, layer) => {
      const updatedLayers = layer.layers.map((layer) => {
        if (layer.id) return layer

        const generateLayerId = layer.tilesets.map((tileset) => tileset.id).join('-')
        return {
          ...layer,
          id: generateLayerId,
        }
      })

      return [...acc, ...updatedLayers]
    }, [] as MapLayer[])
    prepareAndUpdateLayers({ layers: allLayers })
    onClose()
  }

  const handleRemoveLayer = (layer: MapLayer, type: MapLayerType) => {
    const updatedLayers = stagedLayers.map((layerGroup) => {
      if (layerGroup.id === type) {
        return {
          ...layerGroup,
          layers: layerGroup.layers.filter((l) => {
            // for hazards
            if (type == 'hazard') {
              if (layer.hazard_id === undefined && l.hazard_id === undefined)
                return l.assetTag !== layer.assetTag

              return l.hazard_id !== layer.hazard_id
            }

            if (layer.id) return l.id !== layer.id
            // for assets
            if (!layer.assetTag && layer.type) return l.type !== layer.type
            return false
          }),
        }
      }
      return { ...layerGroup }
    })

    handleUpdateLayers(updatedLayers.flatMap((uLayer) => uLayer.layers))
  }

  useEffect(() => {
    setStagedLayers(mapLayerToModalLayerShape(layers))
  }, [layers])

  return (
    <Box css={addLayersDialogContainer({ theme })} id="add-layers-dialog">
      <Typography variant="subtitle2" css={addLayersDialogTitle}>
        Add Layers
      </Typography>
      <Box css={addLayersSetupContainer({ theme })}>
        {!dialogData ? (
          <Box css={loadingSection({ theme })}>
            <CircularProgress color="primary" size="60px" />
          </Box>
        ) : (
          <LayerSetup
            dialogData={dialogData}
            selectedHazards={selectedHazards}
            selectedAssets={selectedAssets}
            selectedInformation={selectedInformation}
            onSelectHazardLayer={handleSelectHazardLayer}
            onSelectAssets={handleSelectAssetLayer}
            onSelectInformationLayer={handleSelectInformationLayer}
            onRemoveLayer={handleRemoveLayer}
          />
        )}

        <LayersPreview
          isDialogPreview
          dialogProps={{
            stagedLayers,
            handleUpdateLayers,
            handleToggleLayerVisibility,
          }}
        />
      </Box>

      <Box css={addLayersFooter({ theme })}>
        <Button onClick={handleCancelClick} variant="outlined" color="grey">
          Cancel
        </Button>
        <Button onClick={handleDoneClick}>Done</Button>
      </Box>
    </Box>
  )
}
