/** @jsxImportSource @emotion/react */
import { Box } from '@mui/material'
import { useState } from 'react'
import { ActiveStory } from './ActiveStory'
import { Story } from './Stories.utils'
import { StoryMenu } from './StoryMenu/StoryMenu'
import { sideDrawerContentParent } from '../../SideDrawerContent.styles'
import { useStoriesFetcher } from '../../data_fetchers/storiesFetcher'
import { SideDrawerHeader } from '../../components/SideDrawerHeader'

export const StoriesTab = () => {
  const [story, setStory] = useState<Story | null>(null)
  const stories: Story[] = useStoriesFetcher() ?? []

  return (
    <Box css={sideDrawerContentParent()}>
      <SideDrawerHeader
        title="RISK STORIES"
        subtitle={story ? story.title : undefined}
        iconName="s3://openbook.svg"
        onTitleClicked={story ? () => setStory(null) : undefined}
        onclose={story ? () => setStory(null) : undefined}
      />
      {story ? <ActiveStory story={story} /> : <StoryMenu stories={stories} setStory={setStory} />}
    </Box>
  )
}
