export const InfoIcon = ({ ...rest }) => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <circle cx="15" cy="15" r="12.1333" stroke="currentColor" strokeWidth="1.73333" />
      <circle cx="16.1986" cy="8.39868" r="2.06535" fill="currentColor" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.9399 23.146C12.3422 23.146 11.4848 21.2755 11.7966 20.1065C12.1084 18.9374 12.9657 15.5471 13.1215 14.9626C13.2774 14.3781 13.3943 13.6377 12.8488 13.2869C12.3032 12.9362 10.6665 13.4038 10.6665 13.4038C10.6665 13.4038 10.8224 12.7024 10.9003 12.3907C11.9491 12.0354 12.9006 11.596 14.0178 11.5333C16.0953 11.5705 17.0176 12.6097 16.7846 14.6508C16.418 16.2733 15.8104 18.4308 15.5376 19.483C15.2648 20.5351 16.2996 22.1185 18.0316 21.2755C17.9809 21.6098 17.9159 21.9476 17.8368 22.2887C16.5138 22.769 15.5376 23.146 13.9399 23.146Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default InfoIcon
