const mapToolsText = {
  MAPTOOLS: (
    <div className="row">
      <img src="./images/tutorial/SpannerTool.svg" alt="Spanner Tool Demo" className="m-image" />
      <p>
        The map toolbar is located in the top right corner of the map. Click the spanner icon to
        access the map tools.
      </p>
    </div>
  ),
  SEARCHADDRESS: (
    <div className="row">
      <img
        src="./images/tutorial/magnifyingGlass.svg"
        alt="magnifying glass Demo"
        className="m-image"
      />
      <p>
        This search bar can be used to search for a specific street address. As you type,
        suggestions will appear below. Click on a suggestion to focus on that location.
      </p>
    </div>
  ),
  APPLYREGIONFILTERS: (
    <div>
      <p>
        The &apos;Regions&apos; dropdown allows you to apply spatial filters to the map. It lets you
        select a specific suburb, ward, adaptation area, etc., to filter elements to only what is
        relevant to that region. This is valuable for examining risk to specific areas.
      </p>
    </div>
  ),
  CREATECUSTOMFILTERS: (
    <div className="row">
      <img src="./images/tutorial/PolygonTool.svg" alt="Export PNG Demo" className="m-image" />
      <p>
        The polygon drawing tool will select only element instances within your drawn polygon, and
        update the sidebar to only show data that is relevant to the subset of element instances you
        selected.
      </p>
    </div>
  ),
  COPYMAPVIEW: (
    <div className="column">
      <div className="row">
        <img src="./images/tutorial/PNGExportIcon.png" alt="Export PNG Demo" className="m-image" />
        <p>
          This tool allows you to copy the current map view to your clipboard in PNG format. You can
          use this to quickly share or save the current view of the map.
        </p>
      </div>
    </div>
  ),
  SAVEMAPVIEW: (
    <div className="column">
      <div className="row">
        <img src="./images/tutorial/PDFExportIcon.png" alt="Export PDF Demo" className="m-image" />
        <p>This tool can export your current map view to a PDF, and save it to your computer.</p>
      </div>
    </div>
  ),
  MEASURETOOL: (
    <div className="column">
      <div className="row">
        <img src="./images/tutorial/MeasureTool.svg" alt="Export PNG Demo" className="m-image" />
        <p>
          The measure tool allows you to measure distances and areas on the map. Click on the map to
          start drawing, and double-click to finish.
        </p>
      </div>
    </div>
  ),
  FULLSCREENTOOL: (
    <div className="column">
      <div className="row">
        <img src="./images/tutorial/FullscreenTool.svg" alt="Export PNG Demo" className="m-image" />
        <p>
          The full-screen tool allows you to expand the map to fill the entire screen. Click the
          button again to exit full-screen mode.
        </p>
      </div>
    </div>
  ),
}

export default mapToolsText
