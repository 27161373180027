/** @jsxImportSource @emotion/react */

import { ListItemButton, Box, Typography, ListItemText, useTheme } from '@mui/material'
import { HazardScenario } from '../../AddLayersDialog.types'
import {
  disallowedStyling,
  innerHtmlParentContainer,
  titlePlusMinusParentContainer,
} from './HazardListItem.styles'
import UiMarkdown from '@src/components/Atoms/UiMarkdown/UiMarkdown'
import { usePreferences } from '@contexts/PreferencesContext'

interface HazardListItemProps {
  selected: boolean
  disallowed: boolean
  scenario: HazardScenario
  addHazardScenario: (scenario: HazardScenario) => void
  isKnownRisks?: boolean
}

const HazardListItem = ({
  selected,
  disallowed,
  scenario,
  addHazardScenario,
  isKnownRisks = false,
}: HazardListItemProps) => {
  const theme = useTheme()
  const { use_hazard_name } = usePreferences()

  return (
    <ListItemButton
      selected={selected}
      key={scenario.assetTag}
      onClick={() => addHazardScenario(scenario)}
    >
      {isKnownRisks && scenario.hazard_sentence && scenario.hazard_sentence[0] === '<' ? (
        <>
          <Box css={innerHtmlParentContainer}>
            <Box
              css={titlePlusMinusParentContainer({ theme, disallowed: disallowed && !selected })}
            >
              <Typography>
                {use_hazard_name.flag
                  ? scenario.hazard_name ?? scenario.display_name
                  : scenario.display_name}
              </Typography>
              <Typography sx={{ textWrap: 'nowrap', fontSize: '12px' }}>
                {`${selected ? 'Remove -' : 'Add +'}`}
              </Typography>
            </Box>
            <UiMarkdown css={disallowedStyling({ theme, disallowed: disallowed && !selected })}>
              {scenario.hazard_sentence ?? ''}
            </UiMarkdown>
          </Box>
        </>
      ) : (
        <Box css={innerHtmlParentContainer}>
          <Box css={titlePlusMinusParentContainer({ theme, disallowed: disallowed && !selected })}>
            <Typography>
              {use_hazard_name.flag
                ? scenario.hazard_name ?? scenario.display_name
                : scenario.display_name}
            </Typography>
            <Typography sx={{ textWrap: 'nowrap', fontSize: '12px' }}>{`${
              selected ? 'Remove -' : 'Add +'
            }`}</Typography>
          </Box>
          <ListItemText
            css={disallowedStyling({ theme, disallowed: disallowed && !selected })}
            secondary={
              scenario.hazard_sentence && scenario.hazard_sentence[0] !== '<'
                ? scenario.hazard_sentence
                : null
            }
          />
        </Box>
      )}
    </ListItemButton>
  )
}

export default HazardListItem
