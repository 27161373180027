/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'

import { SIDEBAR_MENU_WIDTH } from '../../app-constants'

export const appContainer = css`
  display: flex;
  height: 100svh;
  width: 100vw;
`

export const sidebarContainer = css`
  height: 100svh;
  position: fixed;
  z-index: 120;
`

export const appContentContainer = css`
  height: 100%;
  width: calc(100vw - ${SIDEBAR_MENU_WIDTH}px);
  margin-left: ${SIDEBAR_MENU_WIDTH}px;
`

export const lowOpacitySidebarButton = css`
  opacity: 0.5;
`

export const removeHover = css`
  opacity: 0.7;

  &:hover {
    background-color: inherit;
    cursor: default;
  }
`
export const notificationRedDot = css`
  &:after {
    content: '';
    background: red;
    width: 8px;
    height: 8px;
    display: block;
    border-radius: 50%;
    position: absolute;
    left: 28px;
    top: 5px;
  }
`
