export const Corporation = ({ ...rest }) => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M11 7.11111V4H3V18H19V7.11111H11ZM6.2 16.4444H4.6V14.8889H6.2V16.4444ZM6.2 13.3333H4.6V11.7778H6.2V13.3333ZM6.2 10.2222H4.6V8.66667H6.2V10.2222ZM6.2 7.11111H4.6V5.55556H6.2V7.11111ZM9.4 16.4444H7.8V14.8889H9.4V16.4444ZM9.4 13.3333H7.8V11.7778H9.4V13.3333ZM9.4 10.2222H7.8V8.66667H9.4V10.2222ZM9.4 7.11111H7.8V5.55556H9.4V7.11111ZM17.4 16.4444H11V14.8889H12.6V13.3333H11V11.7778H12.6V10.2222H11V8.66667H17.4V16.4444ZM15.8 10.2222H14.2V11.7778H15.8V10.2222ZM15.8 13.3333H14.2V14.8889H15.8V13.3333Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default Corporation
