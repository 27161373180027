/** @jsxImportSource @emotion/react */

import { Box, css, ToggleButton, ToggleButtonGroup, useTheme } from '@mui/material'
import { MapLayer } from '@redux/map/mapSlice'
import { BASE_API_URL } from '@src/app-constants'
import {
  DataPoint,
  ProjectedRiskChart,
} from '@src/components/Molecules/Charts/ProjectedRiskChart/ProjectedRiskChart'
import {
  layerDataHazardIdTransformer,
  parameterKeyTitleConverter,
  useLayerDataFetcher,
} from '@src/components/Molecules/SideDrawerContent/data_fetchers/layerDataFetcher'
import axios from '@src/utils/customAxios'
import { convertToMetersOrCentimeters, RadioGroup } from '@uintel/ui-component-library'
import { Typography } from '@mui/material'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { hazardBoxSLRSlider } from '../../../LayersTab/HazardControls/HazardControls.styles'
import { chartContainer, chartHeader, chartSection } from '../RiskContent.styles'
import { radioGroup, radioGroupContent, toggleGroup } from './ProjectedRisk.styles'
import { useMap } from '@contexts/MapContext'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '@redux/store'
import { Polygon } from 'geojson'
import { EmptySection } from '@src/components/Molecules/SideDrawerContent/components/EmptySection'
import { Slider } from '@src/components/Molecules/Slider'
import { setProjectedRiskChartState } from '@redux/projectedRiskChartSlice'

interface ProjectedRiskProps {
  assetLayer: MapLayer
  hazardLayer: MapLayer
  yAxisTitle: string
  identifyingInformationKey?: string
}
type VulnerabilityLevel =
  | 'unspecified'
  | 'potential'
  | 'exposed'
  | 'insignificant'
  | 'low'
  | 'minor'
  | 'moderate'
  | 'major'
  | 'high'

// This likely goes against React's philosophy of dealing with state but after fighting
// many weird useEffect cascades I certify this as: good-enough.
type ProjectedRiskParameters = {
  primaryParameterKey: string
  secondaryParameter?: { key: string; value: string | number; availableValues: (number | string)[] }
}

export const ProjectedRisk = ({
  assetLayer,
  hazardLayer,
  yAxisTitle,
  identifyingInformationKey,
}: ProjectedRiskProps) => {
  const theme = useTheme()
  const dispatch = useDispatch()

  const layerData = useLayerDataFetcher()
  const transformedLayerData = layerDataHazardIdTransformer(layerData)

  const { regionMasks } = useMap()
  const { drawAreas } = useSelector((state: RootState) => state.map)

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true)
  const [projectedRiskData, setProjectedRiskData] = useState<DataPoint[]>([])

  const [projectedRiskParameters, setProjectedRiskParameters] =
    useState<ProjectedRiskParameters | null>(null)

  const hazardGroupedByHazardId = useMemo(
    () => (hazardLayer.hazard_id ? transformedLayerData?.[hazardLayer.hazard_id] : undefined),
    [hazardLayer.hazard_id, transformedLayerData],
  )

  const vulnerabilityList = useMemo(() => {
    const listedVulns = [
      ...new Set(projectedRiskData.flatMap((dataPoint) => Object.keys(dataPoint))),
    ].filter((vuln) => vuln !== projectedRiskParameters?.primaryParameterKey)
    const keys = listedVulns.sort((a: string, b: string) => {
      const order = {
        unspecified: 0,
        potential: 1,
        exposed: 2,
        insignificant: 3,
        low: 4,
        minor: 5,
        moderate: 6,
        major: 7,
        high: 8,
      }
      return (order[a as VulnerabilityLevel] ?? 0) - (order[b as VulnerabilityLevel] ?? 0)
    })
    return keys
  }, [projectedRiskData, projectedRiskParameters?.primaryParameterKey])

  const parameterUnit = projectedRiskParameters?.primaryParameterKey
    ? `${
        (
          hazardGroupedByHazardId?.parameters[projectedRiskParameters.primaryParameterKey]?.unit ??
          ''
        ).trim()
          ? ` (${
              hazardGroupedByHazardId?.parameters[projectedRiskParameters.primaryParameterKey]?.unit
            })`
          : ''
      }`
    : ''

  const parameterList: string[] = useMemo(() => {
    //Gets a list of at most two parameters that have numerical values and sorted by priority

    const parameterListWithNumericalValues = Object.keys(
      hazardGroupedByHazardId?.availableParameterValues ?? {},
    ).filter((key) => {
      const values = hazardGroupedByHazardId?.availableParameterValues[key].filter(
        (val) => !isNaN(parseFloat(`${val}`)),
      )
      return values && values.length > 1
    })

    const topTwoNumericalParameters =
      hazardGroupedByHazardId?.form
        .filter((formInput) => parameterListWithNumericalValues.includes(formInput.key))
        .sort((a, b) => a.priority - b.priority)
        .splice(0, 2)
        .map((formInput) => formInput.key) ?? []

    return topTwoNumericalParameters
  }, [hazardGroupedByHazardId?.availableParameterValues, hazardGroupedByHazardId?.form])

  const getProjectedRiskData = useCallback(
    async () => {
      if (
        !transformedLayerData ||
        !hazardGroupedByHazardId ||
        !projectedRiskParameters?.primaryParameterKey
      )
        return

      const regions = regionMasks ?? []
      const regionMap = regions.map((region) => region.region)
      const regionsQueryString = regionMap.length > 0 ? `?regions=${regionMap.join(',')}` : ''
      const drawAreaCoordinateArray: Polygon | null =
        drawAreas.length > 0 ? (drawAreas[0].geometry as Polygon) : null
      const drawAreaCsv = drawAreaCoordinateArray
        ? drawAreaCoordinateArray.coordinates[0].flat().join(',')
        : ''
      const drawAreaCsvQueryString = drawAreaCsv
        ? `${regionsQueryString ? '&' : '?'}draw_area=${drawAreaCsv}`
        : ''

      const filters = {
        ...hazardLayer.parameters,
      }

      // As we want all possible values for the primary parameter this gets removed from the filters
      delete filters[projectedRiskParameters.primaryParameterKey]

      if (
        projectedRiskParameters.secondaryParameter &&
        projectedRiskParameters.secondaryParameter?.key !== null
      )
        filters[projectedRiskParameters.secondaryParameter.key] =
          projectedRiskParameters.secondaryParameter.value

      const url = `${BASE_API_URL}/api/asset/projected_risk/${
        identifyingInformationKey ?? 'hazard_scenario_metric'
      }/${assetLayer.type}/${hazardLayer.hazard_id}/${
        projectedRiskParameters.primaryParameterKey
      }${regionsQueryString}${drawAreaCsvQueryString}`

      try {
        const res = await axios.post(url, filters)

        return res.data
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(`Error fetching projected risk data: ${e}`)
      } finally {
        setIsLoading(false)
        setIsFirstLoad(false)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      projectedRiskParameters?.primaryParameterKey,
      regionMasks,
      drawAreas,
      projectedRiskParameters?.secondaryParameter?.value,
      assetLayer.type,
      hazardLayer.hazard_id,
      identifyingInformationKey,
    ],
  )

  const handleSecondaryParameterChange = (newSecondaryParameterValue: number) => {
    setIsLoading(true)

    setProjectedRiskParameters((old): ProjectedRiskParameters | null => {
      if (!old) return null
      if (!old.secondaryParameter) return old

      return {
        primaryParameterKey: old.primaryParameterKey,
        secondaryParameter: {
          key: old.secondaryParameter.key,
          value: newSecondaryParameterValue,
          availableValues: old.secondaryParameter.availableValues,
        },
      }
    })
  }

  const handleParameterChange = (newPrimaryParameterKey: string | null) => {
    setIsLoading(true)

    if (
      newPrimaryParameterKey == projectedRiskParameters?.primaryParameterKey ||
      !newPrimaryParameterKey ||
      !hazardGroupedByHazardId
    )
      return

    let newSecondaryParameterKey = ''
    if (parameterList[0] == newPrimaryParameterKey) newSecondaryParameterKey = parameterList[1]
    else newSecondaryParameterKey = parameterList[0]

    const primaryParameterValue =
      hazardLayer.parameters && hazardLayer.parameters[newPrimaryParameterKey]

    const parametersToFilter = {
      ...hazardLayer.parameters,
      [newPrimaryParameterKey]: primaryParameterValue,
    }

    delete parametersToFilter[newSecondaryParameterKey]

    const secondaryParameterAvailableValues = [
      ...new Set(
        hazardGroupedByHazardId.scenarios
          .filter((scenario) => {
            for (const skey in scenario.parameters) {
              if (skey == newSecondaryParameterKey) continue
              if (scenario.parameters[skey] !== parametersToFilter[skey]) return false
            }
            return true
          })
          .map((scenario) => scenario.parameters[newSecondaryParameterKey]),
      ),
    ]

    setProjectedRiskParameters({
      primaryParameterKey: newPrimaryParameterKey,
      secondaryParameter: {
        key: newSecondaryParameterKey,
        value: secondaryParameterAvailableValues[0],
        availableValues: secondaryParameterAvailableValues,
      },
    })
  }

  useEffect(() => {
    getProjectedRiskData().then((data: DataPoint[]) => {
      if (!data) return

      // Remove all vulnerabilities that are zero for entire projection
      const vulnerabilityTotals: { [vulnerability: string]: number } = {}

      data.forEach((datum) => {
        Object.entries(datum).forEach(([vulnerability, value]) => {
          if (typeof value !== 'number') return

          if (Object.hasOwn(vulnerabilityTotals, vulnerability))
            vulnerabilityTotals[vulnerability] += value
          else vulnerabilityTotals[vulnerability] = value
        })
      })

      Object.entries(vulnerabilityTotals).forEach(([vulnerability, total]) => {
        if (!total) {
          data.forEach((datum) => {
            delete datum[vulnerability]
          })
        }
      })

      setProjectedRiskData(data)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    projectedRiskParameters?.primaryParameterKey,
    projectedRiskParameters?.secondaryParameter?.value,
    drawAreas,
    regionMasks,
    identifyingInformationKey,
  ])

  useEffect(() => {
    if (projectedRiskParameters || !hazardGroupedByHazardId) return

    if (parameterList.length == 1) {
      setProjectedRiskParameters({ primaryParameterKey: parameterList[0] })
      return
    }

    const primaryParameterKey = parameterList[0]
    const secondaryParameterKey = parameterList[1]

    const primaryParameterValue =
      hazardLayer.parameters && hazardLayer.parameters[primaryParameterKey]

    const parametersToFilter = {
      ...hazardLayer.parameters,
      [primaryParameterKey]: primaryParameterValue,
    }

    delete parametersToFilter[secondaryParameterKey]

    const secondaryParameterAvailableValues = [
      ...new Set(
        hazardGroupedByHazardId.scenarios
          .filter((scenario) => {
            for (const skey in scenario.parameters) {
              if (skey == secondaryParameterKey) continue
              if (scenario.parameters[skey] !== parametersToFilter[skey]) return false
            }
            return true
          })
          .map((scenario) => scenario.parameters[secondaryParameterKey]),
      ),
    ].map((value) => parseFloat(`${value}`))

    setProjectedRiskParameters({
      primaryParameterKey,
      secondaryParameter: {
        key: secondaryParameterKey,
        value: secondaryParameterAvailableValues[0],
        availableValues: secondaryParameterAvailableValues,
      },
    })
  }, [hazardGroupedByHazardId, hazardLayer.parameters, parameterList, projectedRiskParameters])

  const secondaryParameterFormInput = hazardGroupedByHazardId?.form.find(
    (input) => input.key == projectedRiskParameters?.secondaryParameter?.key,
  )

  useEffect(() => {
    if (!hazardLayer.hazard_id) return

    const readableSecondaryParameterValue = formatValue(
      secondaryParameterFormInput?.key,
      projectedRiskParameters?.secondaryParameter?.value,
    )

    dispatch(
      setProjectedRiskChartState({
        element: assetLayer.id,
        risk: hazardLayer.hazard_id,
        projectedRiskChartStateParameters: {
          assetId: assetLayer.id,
          yAxisTitle,
          data: projectedRiskData,
          parameter: projectedRiskParameters?.primaryParameterKey ?? '',
          parameterUnit,
          vulnerabilityList,
          parameterTitle: parameterKeyTitleConverter(
            projectedRiskParameters?.primaryParameterKey ?? '',
            layerData,
          ),
          secondaryParameter: secondaryParameterFormInput?.title ?? null,
          secondaryParameterValue: readableSecondaryParameterValue,
        },
      }),
    )

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    assetLayer.id,
    dispatch,
    hazardGroupedByHazardId,
    hazardLayer.hazard_id,
    layerData,
    parameterUnit,
    projectedRiskData,
    projectedRiskParameters?.primaryParameterKey,
    secondaryParameterFormInput,
    projectedRiskParameters?.secondaryParameter?.value,
    vulnerabilityList,
  ])

  if (!parameterList.length) return null

  function formatValue(key: string | undefined, value: number | string | null | undefined): string {
    if (!key || value == null) return ''

    const parameter = hazardGroupedByHazardId?.parameters[key]
    const type = parameter?.type
    if (!type || !parameter) return ''

    switch (type) {
      case 'number':
        return convertToMetersOrCentimeters(+value, parameter.unit)
      case 'keyValue': {
        const keyValues = hazardGroupedByHazardId?.parameters[key]?.keyValues ?? {}

        return keyValues[value] ?? ''
      }
    }

    return `${value}`
  }

  return (
    <Box sx={{ margin: theme.spacing(1), minWidth: '400px', width: '100%' }}>
      {isFirstLoad ? (
        <EmptySection isLoading={isLoading} title="Loading..." />
      ) : (
        <>
          <Box css={chartHeader({ theme })}>
            <Typography>Projected Risk</Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              paddingLeft: '32px',
              paddingRight: '32px',
              paddingTop: '4px',
            }}
          >
            {parameterList.length > 1 && (
              <>
                <Box
                  sx={{
                    paddingRight: theme.spacing(2),
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    width: '100%',
                    margin: '4px 0px 12px 0px',
                  }}
                >
                  <Box css={radioGroup({ theme })}>
                    <Typography
                      sx={{
                        marginRight: theme.spacing(6),
                        fontSize: '12px',
                        color: '#0b2948',
                      }}
                    >
                      Parameter
                    </Typography>
                  </Box>
                  <ToggleButtonGroup
                    css={toggleGroup({ theme })}
                    value={projectedRiskParameters?.primaryParameterKey}
                    exclusive
                    size="small"
                    onChange={(_, value) => handleParameterChange(value)}
                  >
                    {parameterList.map((parameter, index) => {
                      if (index < 2)
                        return (
                          <ToggleButton key={parameter} value={parameter}>
                            <Typography
                              sx={{
                                fontSize: '12px',
                                color: '#0b2948',
                              }}
                            >
                              {parameterKeyTitleConverter(parameter, layerData)}
                            </Typography>
                          </ToggleButton>
                        )
                    })}
                  </ToggleButtonGroup>
                </Box>

                <Box
                  css={[
                    radioGroup({ theme }),
                    css`
                      flex-grow: 1;
                      width: 50%;
                      flex-direction: column;
                      align-items: flex-start;
                    `,
                  ]}
                >
                  <Typography
                    sx={{
                      marginRight: theme.spacing(6),
                      fontSize: '12px',
                      color: '#0b2948',
                    }}
                  >
                    {secondaryParameterFormInput?.title}
                  </Typography>
                  {projectedRiskParameters?.secondaryParameter?.availableValues &&
                    projectedRiskParameters?.secondaryParameter?.availableValues?.length > 1 && (
                      <>
                        {(secondaryParameterFormInput?.type == 'slider' ||
                          secondaryParameterFormInput?.type == 'sealevelSlider') && (
                          <Box css={hazardBoxSLRSlider({ theme })}>
                            <Slider
                              key={`${secondaryParameterFormInput.key}-slider`}
                              value={parseFloat(
                                `${projectedRiskParameters.secondaryParameter.value ?? 0}`,
                              )}
                              handleValueChange={handleSecondaryParameterChange}
                              marks={projectedRiskParameters.secondaryParameter.availableValues.map(
                                (value) => {
                                  const parameter =
                                    hazardGroupedByHazardId?.parameters[
                                      secondaryParameterFormInput.key
                                    ]

                                  return {
                                    label: convertToMetersOrCentimeters(
                                      value as number,
                                      parameter?.unit,
                                    ),
                                    value: +value,
                                  }
                                },
                              )}
                              useTriangleTicks
                              minimalLabels
                              disabled={
                                projectedRiskParameters.secondaryParameter.availableValues.length ==
                                1
                              }
                              useOnChangeCommitted
                            />
                          </Box>
                        )}

                        {secondaryParameterFormInput?.type == 'radioGroup' && (
                          <RadioGroup
                            css={radioGroupContent({ theme })}
                            value={projectedRiskParameters.secondaryParameter.value ?? 0}
                            key={`${secondaryParameterFormInput.key}-radioGroup`}
                            onChange={(event) =>
                              handleSecondaryParameterChange(+event.target.value)
                            }
                            options={projectedRiskParameters.secondaryParameter.availableValues
                              .sort()
                              .map((value) => {
                                return {
                                  label: formatValue(secondaryParameterFormInput.key, value),
                                  value: +value,
                                  selected:
                                    projectedRiskParameters.secondaryParameter?.value == value,
                                }
                              })}
                          />
                        )}
                      </>
                    )}
                  {projectedRiskParameters?.secondaryParameter?.availableValues?.length == 1 && (
                    <Typography
                      sx={{
                        marginLeft: theme.spacing(2),
                        fontSize: '12px',
                        color: '#0b2948',
                      }}
                    >
                      {`• ${formatValue(
                        secondaryParameterFormInput?.key,
                        projectedRiskParameters.secondaryParameter.availableValues[0],
                      )}`}
                    </Typography>
                  )}
                </Box>
              </>
            )}
          </Box>

          {projectedRiskData.length > 0 && projectedRiskParameters?.primaryParameterKey && (
            <Box css={chartSection({ theme })}>
              <Box
                css={css`
                  ${chartContainer()}
                  min-height: 200px;
                `}
              >
                <Box sx={{ width: '100%' }}>
                  {isLoading ? (
                    <EmptySection isLoading={true} title="Loading..." height={'200px'} />
                  ) : (
                    <ProjectedRiskChart
                      assetId={assetLayer.id}
                      yAxisTitle={yAxisTitle}
                      data={projectedRiskData}
                      parameter={projectedRiskParameters.primaryParameterKey}
                      parameterUnit={parameterUnit}
                      vulnerabilityList={vulnerabilityList}
                    />
                  )}
                </Box>
              </Box>
            </Box>
          )}
          {!isLoading && projectedRiskData.length == 0 && (
            <Box css={chartSection({ theme })}>
              <EmptySection title="No Projected Risk chart available for this combination" />
            </Box>
          )}
        </>
      )}
    </Box>
  )
}

export default ProjectedRisk
