const exploringLayers = (prefer_hazard_term: boolean) => {
  const riskterm = prefer_hazard_term ? 'Hazard' : 'Risk Source'
  return {
    UNDERSTANDINGLAYERS: (
      <div className="column">
        <div className="row">
          <img src="./images/tutorial/LayerTypes.svg" alt="Layer Types" className="m-image" />
          There are three layer types: Element, {riskterm}s, and Contextual Layers.
        </div>
        <div className="row">
          To view risk, you must add at least one Element Layer and one {riskterm} Layer.
        </div>
        <div className="divider"></div>
        <div className="row">
          <img src="./images/tutorial/ElementLayer.svg" alt="Element Layers" className="s-image" />
          <div>
            <b>Element Layers</b> include taonga or locations of value. That includes communities,
            environment, places, and infrastructure.
          </div>
        </div>
        <div className="row">
          <img src="./images/tutorial/RiskLayer.svg" alt="Risk Layers" className="s-image" />
          <div>
            <b>{riskterm} Layers</b> include natural hazard scenarios such as floods, fire, and
            landslides that can put elements at risk.
          </div>
        </div>
        <div className="row">
          <img src="./images/tutorial/ContextLayer.svg" alt="Context Layers" className="s-image" />
          <div>
            <b>Contextual Layers</b> include layers that provide additional context to the map but
            do not interact with Element or {riskterm}s layers.
          </div>
        </div>
        <div className="row">
          Contextual layers include information such as soil type, vertical land movement, or
          demographics.
        </div>
      </div>
    ),
    ADDLAYERS: (
      <div className="column">
        <div>
          Within the &quot;Add Layers&quot; dialog, you can choose a selection of <b>Element</b>,{' '}
          <b>{riskterm}</b>, and <b>Contextual</b> layers to add to the map.
          <br />
          <br />
          You can search for a layer by name using the search bar, or by expanding the group your
          desired layer belongs to. Once you have found the layer you&apos;d like to to add, click
          on it to select it.
          <br />
          <br />
          Selected layers are shown in the Layers Preview panel on the right side of the screen.
          When you are ready, you can click the &quot;Done&quot; button to add the selected layers
          to the map.
        </div>
      </div>
    ),
    ADDELEMENTS: (
      <div className="column">
        <div className="row">
          <img src="./images/tutorial/ElementLayer.svg" alt="Element Layers" className="s-image" />
          <div>
            <b>Element layers</b> are sorted into groups within relevant civil domains.
          </div>
        </div>
        <div className="row">
          Depending on your sector, these may include the Built Environment, Natural Environment,
          Economic, and Human domains, among others.
        </div>
      </div>
    ),
    ADDHAZARDS: (
      <div className="column">
        <div className="row">
          <img src="./images/tutorial/RiskLayer.svg" alt="Risk Layers" className="s-image" />
          <div>
            <b>{riskterm}s</b> are grouped by their type. Within each group, you can choose a
            specific {riskterm} model you&apos;d like to add to the map as a layer.
          </div>
        </div>
        <div className="row">
          Once added as a layer, each {riskterm} model has parameters that can be used to change the
          scenario shown on the map. Elements at risk from the selected scenario will then be
          highlighted on the map.
        </div>
        <div className="divider"></div>
        <i>
          Keep in mind: Only available {riskterm} information is displayed; if a {riskterm}
          &nbsp;scenario is not shown or available for an area, it simply may not have been modelled
          and does not imply that a future event will not impact that area.
        </i>
      </div>
    ),
    ADDINFORMATION: (
      <div className="column">
        <div className="row">
          <img src="./images/tutorial/ContextLayer.svg" alt="Context Layers" className="s-image" />
          <div>
            Lastly, you can also select <b>contextual layers</b> to add to the map. These layers
            provide additional information, without affecting the displayed risk to elements
            from&nbsp;
            {riskterm}s.
          </div>
        </div>
      </div>
    ),
    HOVERINFORMATION: (
      <div className="column">
        <div className="row">
          Now you&apos;ve got layers on your map you can hover the mouse over an element to view
          information about it. This often includes its name, type, and risk statistics, but can
          vary depending on the element.
        </div>
        <div className="divider"></div>
        <img
          src="./images/tutorial/HoverOverMap.svg"
          alt="Hover Information Demo"
          className="l-image"
        />
      </div>
    ),
    LAYERSTAB: (
      <div className="column">
        <div>
          The <b>Layers</b> tab is where you can see all the layers you have added to the map. This
          includes Element, {riskterm}, and Contextual layers.
          <br />
          <br />
          You can also manipulate layers from this tab, such as reordering, hiding, or removing
          them.
        </div>
      </div>
    ),
    REORDERINGLAYERS: (
      <div className="column">
        <div className="row">
          <img
            src="./images/tutorial/LayerDrag.svg"
            alt="Drag Layer Icon Demo"
            className="m-image"
          />
          You can change the order of layers by clicking and dragging the six dots next to the layer
          name.
        </div>
        <div>
          Layers toward the top of this list are shown on top of layers toward the bottom of the
          list on the map.
        </div>
      </div>
    ),
    MANIPULATINGLAYERS: (
      <div className="column">
        <div className="row">
          <img
            src="./images/tutorial/LayerControls.svg"
            alt="Layer Controls Demo"
            className="m-image"
          />
          Each layer has three control buttons. You can use these to hide, remove, or disable hover
          interactions for a layer.
        </div>
        <div className="divider"></div>
        <div className="row">
          <img src="./images/tutorial/LayerHide.svg" alt="Hide Layers" className="s-image" />
          <div>
            <b>Hiding</b> a layer will make it invisible on the map, without removing it from the
            map or risk results. Click the same option again to show the layer.
          </div>
        </div>
        <div className="row">
          <img src="./images/tutorial/LayerRemove.svg" alt="Remove Layers" className="s-image" />
          <div>
            <b>Removing</b> a layer will completely remove it from the map and any risk results.
          </div>
        </div>
        <div className="row">
          <img
            src="./images/tutorial/LayerToggleHover.svg"
            alt="Toggle Hover On Layers"
            className="s-image"
          />
          <div>
            Lastly, <b>disabling hover interactions</b> on a layer will prevent your mouse from
            interacting with that layer, and let it interact with layers shown below it. Click the
            same option again to enable hover interactions.
          </div>
        </div>
      </div>
    ),
    MANIPULATINGLAYERS_INFORMATION: (
      <div className="column">
        <div>
          To access additional information about a layer, you can click the layer&apos;s header to
          expand it. This will show you more information about the layer, such as its description,
          source, and underlying methodology.
        </div>
        <div>
          If additional information is not available, the layer will not have the option to expand.
        </div>
      </div>
    ),
  }
}

export default exploringLayers
