import { css } from '@emotion/react'
import { Theme } from '@mui/material/styles'

export const layersPreviewContainer = css`
  height: 100%;
  flex: 1;

  display: flex;
  align-items: stretch;
  justify-content: stretch;

  overflow: auto;
  min-width: 400px;

  @media (max-width: 1080px) {
    min-width: 100%;
  }
`

export const layersPreviewSection = css`
  flex: 1;
  height: 100%;
`

export const layersPreviewTitle = css`
  width: 100%;
  font-weight: 700;
  margin-bottom: 8px;
`
export const layersPreviewBody = ({ theme }: { theme: Theme }) => css`
  width: 100%;
  height: 100%;

  display: flex;
  align-items: stretch;
  justify-content: stretch;
  flex-direction: column;

  min-height: 400px;

  gap: ${theme.spacing(2)};
  overflow: auto;

  @media (max-width: 1080px) {
    min-height: auto;
  }
`

export const accordionContentContainer = ({ theme }: { theme: Theme }) => css`
  min-height: 100%;
  overflow: auto;

  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: start;

  padding-top: ${theme.spacing(1)};
  gap: ${theme.spacing(2)};
`

export const accordionToStart = ({ theme }: { theme: Theme }) => css`
  align-items: start;
  gap: ${theme.spacing(0)};
`

export const accordionHazardsHeader = ({ theme }: { theme: Theme }) => css`
  display: flex;
  align-items: center;
  justify-content: space-between;

  flex: 1 1 auto;
  padding-right: ${theme.spacing(2)};
`

export const accordionInfoContentContainer = ({ theme }: { theme: Theme }) => css`
  min-height: ${theme.spacing(9)};

  overflow: auto;

  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: start;
`

export const accordionHazardContentContainer = ({ theme }: { theme: Theme }) => css`
  height: 100%;
  overflow: auto;
  padding: ${theme.spacing(1)};
`

export const hiddenLayerTooltip = ({ theme }: { theme: Theme }) => css`
  display: flex;
  align-items: center;
  justify-content: center;

  transition: background-color 0.2s;

  border-radius: ${theme.spacing(5)};
  padding: ${theme.spacing(1)};

  & :hover {
    background-color: ${theme.palette.grey[100] + '40'};
  }
`

export const hiddenLayerContainer = ({ theme }: { theme: Theme }) => css`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${theme.spacing(2)};
`

export const hiddenLayerLabel = ({ theme, isVisible }: { theme: Theme; isVisible: boolean }) => css`
  text-decoration: ${!isVisible && 'line-through'};
  color: ${!isVisible && theme.palette.grey[300]};
`

export const emptyAccordion = css`
  min-height: 0;
  height: 0;
`
