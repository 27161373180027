import { css } from '@emotion/react'
import { Theme } from '@mui/material/styles'
import { TOP_NAVBAR_HEIGHT } from '../../../app-constants'

interface ActionPlanContainerProps {
  theme: Theme
}

export const actionPlanContainer = ({ theme }: ActionPlanContainerProps) => css`
  width: 100%;
  height: calc(100svh - ${TOP_NAVBAR_HEIGHT}px);
  padding: ${theme.spacing(4)};
`

export const actionPLanTopMenu = ({ theme }: ActionPlanContainerProps) => css`
  display: flex;
  justify-content: space-between;
  align-items: start;
  gap: ${theme.spacing(3)};
  flex-wrap: wrap-reverse;
  margin-bottom: ${theme.spacing(2)};
`

export const actionPlanContent = ({ theme }: ActionPlanContainerProps) => css`
  width: 100%;
  height: 100%;
  background-color: ${theme.palette.white.main};
  padding: ${theme.spacing(3)};
  border-radius: ${theme.shape.borderRadius}px;
  max-width: 2000px;
  margin: 0 auto;

  display: flex;
  flex-direction: column;
`

export const actionPlanSearchInput = ({ theme }: ActionPlanContainerProps) => css`
  background-color: ${theme.palette.white.main};
  width: 100%;

  & .MuiInputBase-root,
  .MuiOutlinedInput-input {
    /*
    * Yep, this number looks wrong but it's required to 
    * keep the input box 44px height
    */
    padding: ${theme.spacing(2.99)};
    padding-left: 0;
  }
`

export const actionPlanSearchControl = ({ theme }: ActionPlanContainerProps) => css`
  width: ${theme.spacing(100)};

  @media screen and (max-width: 790px) {
    width: 100%;
  }
`

export const tableContainer = css`
  display: contents; // fix max height
`
