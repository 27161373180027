import { css } from '@emotion/react'
import { Theme } from '@mui/material/styles'

export const hazardLayersFormContainer = ({ theme }: { theme: Theme }) => css`
  display: flex;
  flex-direction: column;
  flex: 1;

  width: 100%;
  height: 100%;

  gap: ${theme.spacing(3)};
  padding-top: ${theme.spacing(1)};
`

export const hazardLayersFormContent = () => css`
  height: 100%;
  overflow: auto;
`

export const sectionTitle = css`
  font-weight: 700;
`

export const checkboxLabel = ({ theme, disabled }: { theme: Theme; disabled: boolean }) => css`
  margin-left: 4px;
  color: ${disabled ? theme.palette.text.disabled : theme.palette.text.primary};
`

export const bold700 = css`
  font-weight: 700;
`

export const scenarioItem = ({ theme, isSelected }: { theme: Theme; isSelected: boolean }) => css`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;
  padding: ${theme.spacing(1)} ${theme.spacing(2)};
  margin-top: ${theme.spacing(1)};
  border-radius: ${theme.shape.borderRadius}px;
  background-color: ${isSelected ? theme.palette.grey[100] + '30' : theme.palette.white.light};
  color: ${isSelected ? theme.palette.grey[500] : theme.palette.text.primary};
  border: 1px solid ${theme.palette.grey[100]};
  width: 100%;
  gap: ${theme.spacing(2)};
  transition: background-color 0.2s;

  &:hover {
    cursor: ${!isSelected ? 'pointer' : 'not-allowed'};
    background-color: ${theme.palette.grey[100] + '30'};
  }
`

export const accordionContainer = ({ theme }: { theme: Theme }) => css`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing(2)};
  padding: ${theme.spacing(1)};
  padding-top: 0;
`

export const accordionContainerSmallGap = ({ theme }: { theme: Theme }) => css`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing(1)};
`
