/** @jsxImportSource @emotion/react */

import { useRiskFetcher } from '@contexts/RiskFetcherContext/RiskFetcherContext'
import { Box, ToggleButton, ToggleButtonGroup, useTheme } from '@mui/material'
import { DrawArea, MapLayer } from '@redux/map/mapSlice'
import {
  chartContainer,
  chartHeader,
  chartSection,
  chartSizeProtector,
  chartsContainer,
  consqMethImage,
  disclaimerText,
  reportAssetRiskStackedSection,
  riskSummary,
} from './RiskContent.styles'
import { Typography } from '@mui/material'
import { useSelector } from 'react-redux'
import { RootState } from '@redux/store'
import { capitalizeFirstLetter } from '@src/utils/strings.utils'
import { css } from '@emotion/react'
import { EmptySection } from '../../../../components/EmptySection'
import { useMap } from '@contexts/MapContext'
import { useConsequenceMethodologyFetcher } from '../../../../data_fetchers/consequenceMethodologyFetcher'
import { Accordion } from '../../../../../../Atoms/Accordion'
import SourceOutlinedIcon from '@mui/icons-material/SourceOutlined'
import { SourceButton } from '../../../../components/SourceButton'
import UiMarkdown from '@src/components/Atoms/UiMarkdown/UiMarkdown'
import { Barchart, ChartDataItem } from '@src/components/Molecules/Charts/Barchart'
import { memo, useCallback, useMemo } from 'react'
import {
  getConsequencePalette,
  round,
  roundForDisplay,
} from '@src/components/Molecules/Charts/utils/utils'
import { Gauge } from '@src/components/Molecules/Charts/Gauge'
import ProjectedRisk from './ProjectedRisk/ProjectedRisk'
import { Histogram, HistogramDataItem } from '@src/components/Molecules/Charts/Histogram'
import { ChartTooltipItem } from '@src/components/Molecules/Charts/ChartTooltip'
import { useFeatureFlags } from '@contexts/FeatureFlagsContext'
import { RegionOption } from '@src/components/Molecules/RegionFilter'
import { FancySuspense } from '@src/components/Atoms/FancySuspense'
import { usePreferences } from '@contexts/PreferencesContext'
import {
  IdentifyingInformation,
  RiskData,
  RiskFetcherIsLoading,
  VulnBucket,
} from '@contexts/RiskFetcherContext/RiskFetcher.types'

interface RiskContentProps {
  assetLayer: MapLayer
  hazardLayer: MapLayer
  riskDataByProperty: {
    [key: string]: RiskData | null
  } | null
  isLoading: RiskFetcherIsLoading
}
interface RiskContentInnerProps {
  assetLayer: MapLayer
  hazardLayer: MapLayer
  riskData: RiskData | null
  regionMasks: RegionOption[] | null
  drawAreas: DrawArea[]
  isLoading: boolean
  availableIdentifyingInformation: {
    [key: string]: IdentifyingInformation[]
  }
  setIdentifyingInformationForAssetHazard: (
    assetType: string,
    scenario: string,
    attribute: string,
  ) => void
  relevantIdentifyingInformation: IdentifyingInformation | undefined
}

const vulnerabilityConverter = (
  vulnerability: string,
  palette: {
    [key: string]: string
  },
  isMinorMajor: boolean,
):
  | {
      label: string
      color: string
      order?: number
    }
  | undefined => {
  const trimmedVulnerability = vulnerability.replace('_vulnerability', '')
  return {
    potential: {
      label: 'Potential',
      color: palette['potential'],
      order: 2,
    },
    exposed: {
      label: 'Exposed',
      color: palette['exposed'],
      order: 1,
    },
    low: {
      label: isMinorMajor ? 'Minor' : 'Low',
      color: palette['low'],
      order: 3,
    },
    medium: {
      label: 'Moderate',
      color: palette['moderate'],
      order: 4,
    },
    high: {
      label: isMinorMajor ? 'Major' : 'High',
      color: palette['high'],
      order: 5,
    },
    unspecified: {
      label: 'Unspecified',
      color: palette['exposed'],
      order: 0,
    },
    insignificant: {
      label: 'Insignificant',
      color: palette['insignificant'],
      order: 0,
    },
  }[trimmedVulnerability]
}

export const buildHistogramData = (
  vulnBuckets: ({ exposure_bucket?: string | number; n_days?: number } & VulnBucket)[],
  palette: {
    [key: string]: string
  },
  isMinorMajor: boolean,
) => {
  const histogramData = vulnBuckets
    .map((bucket) => {
      const vulnDetails = Object.entries(bucket)
        .map(([key, value]) => ({
          ...vulnerabilityConverter(key, palette, isMinorMajor),
          value,
        }))
        .toSorted((a, b) => (b.order ?? 0) - (a.order ?? 0))
      const values: {
        [key: string]: number
      } = {}
      const colors: {
        [key: string]: string
      } = {}
      vulnDetails.forEach((curr) => {
        if (curr.label && curr.color) {
          values[curr.label] = curr.value as number
          colors[curr.label] = curr.color
        }
      })
      return {
        label: +(bucket.exposure_bucket ?? bucket.n_days ?? 0),
        value: values,
        color: colors,
      }
    })
    .sort((a, b) => +a.label - +b.label)
    .filter((a) => !isNaN(a.label))

  return histogramData
}

export const RiskContent = ({
  assetLayer,
  hazardLayer,
  riskDataByProperty,
  isLoading,
}: RiskContentProps) => {
  const theme = useTheme()
  const { term_preference } = usePreferences()
  const { consequenceMethodologyObject: consequenceMethodology, isLoading: consequenceIsLoading } =
    useConsequenceMethodologyFetcher(assetLayer, hazardLayer)
  const iAmLoading = isLoading.perHazard[assetLayer.type]?.includes(hazardLayer.assetTag)
  const {
    availableIdentifyingInformation,
    setIdentifyingInformationForAssetHazard,
    selectedIdentifyingInformation,
  } = useRiskFetcher()

  const relevantIdentifyingInformation = useMemo(() => {
    if (!selectedIdentifyingInformation[assetLayer.type]) return undefined
    return selectedIdentifyingInformation[assetLayer.type][hazardLayer.assetTag]
  }, [assetLayer.type, hazardLayer.assetTag, selectedIdentifyingInformation])

  const { regionMasks } = useMap()
  const { drawAreas } = useSelector((state: RootState) => state.map)

  let riskDataContent = null

  const riskData = riskDataByProperty
    ? riskDataByProperty[relevantIdentifyingInformation?.property ?? 'default'] ?? null
    : null

  if (riskData || iAmLoading) {
    riskDataContent = (
      <RiskContentInner
        assetLayer={assetLayer}
        hazardLayer={hazardLayer}
        riskData={riskData}
        regionMasks={regionMasks}
        drawAreas={drawAreas}
        isLoading={iAmLoading}
        availableIdentifyingInformation={availableIdentifyingInformation}
        setIdentifyingInformationForAssetHazard={setIdentifyingInformationForAssetHazard}
        relevantIdentifyingInformation={relevantIdentifyingInformation}
      />
    )
  } else if ((regionMasks && regionMasks.length > 0) || drawAreas.length > 0) {
    riskDataContent = (
      <EmptySection title="There is no known exposure for this element from this hazard scenario in the selected areas." />
    )
  } else if (!regionMasks && drawAreas.length === 0) {
    riskDataContent = (
      <EmptySection title="There is no known exposure for this element from this hazard scenario." />
    )
  }

  return (
    <>
      {riskDataContent}
      {consequenceMethodology ? (
        <Accordion
          title={term_preference.risk ? 'Risk Methodology' : 'Consequence Methodology'}
          defaultExpanded={false}
          icon={<SourceOutlinedIcon />}
          variant="outline"
          level="h3"
          body={
            <>
              {consequenceMethodology.content && (
                <UiMarkdown>{consequenceMethodology.content}</UiMarkdown>
              )}
              {consequenceMethodology.image_url && (
                <img
                  alt={term_preference.risk ? 'Risk Methodology' : 'Consequence Methodology'}
                  src={consequenceMethodology.image_url}
                  css={consqMethImage({
                    theme,
                  })}
                />
              )}
              {consequenceMethodology.source && (
                <SourceButton source={consequenceMethodology.source}>Source</SourceButton>
              )}
            </>
          }
        />
      ) : (
        <EmptySection
          isLoading={consequenceIsLoading}
          customCSS={css`
            width: 100%;
          `}
          title={
            consequenceIsLoading
              ? 'Loading...'
              : `${term_preference.risk ? 'Risk' : 'Consequence'} Methodology Unavailable`
          }
        />
      )}
    </>
  )
}

const RiskContentInner = memo(
  function RiskContentInner({
    assetLayer,
    hazardLayer,
    riskData,
    isLoading,
    availableIdentifyingInformation,
    setIdentifyingInformationForAssetHazard,
    relevantIdentifyingInformation,
  }: RiskContentInnerProps) {
    const theme = useTheme()
    const { features } = useFeatureFlags()
    const replacementCostsEnabled = features.find(
      (feature) => feature.feature == 'replacementCosts' && feature.enabled,
    )
    const { cascadingAssetNDays } = useMap()
    const useCascading = cascadingAssetNDays !== null && assetLayer.is_cascading

    // Check if we're using the new consequences legend
    const isMinorMajor = useSelector((state: RootState) =>
      state.map.legendsData.vulnerability.sections.some(
        (section) =>
          section.items &&
          section.items.filter((item) => item.label === 'Minor' || item.label === 'Major').length >
            0,
      ),
    )

    const vulnerabilityPalette = useSelector(
      (state: RootState) => state.map.legendsData['vulnerability'],
    )
    const palette = getConsequencePalette(vulnerabilityPalette) || {}
    let vulnerabilityData: ChartDataItem[] = []
    let replacementCostsData: ChartDataItem[] = []

    let exposurePercentage = 0
    let exposureTotal = 0
    let assetTotal = 0

    let histogramData: HistogramDataItem[] = []

    const assetIdentifyingInformation = useMemo(
      () => availableIdentifyingInformation[assetLayer.type],
      [assetLayer.type, availableIdentifyingInformation],
    )

    if (riskData) {
      const vulnSource: Partial<VulnBucket> = useCascading
        ? riskData.data.n_days_buckets.find(
            (bucket) => bucket.n_days === cascadingAssetNDays[assetLayer.type],
          ) ?? {}
        : riskData.data.totals
      vulnerabilityData = [
        ...Object.entries(vulnSource).flatMap(([key, value]) => {
          const vulnDetails = vulnerabilityConverter(key, palette, isMinorMajor)
          if (vulnDetails)
            return [
              {
                ...vulnDetails,
                value: value,
              },
            ]
          return []
        }),
      ]

      histogramData = buildHistogramData(
        useCascading ? riskData.data.n_days_buckets : riskData.data.exposure_buckets,
        palette,
        isMinorMajor,
      )

      if (riskData?.attribute_stats?.replacement_costs) {
        replacementCostsData = [
          ...Object.entries(riskData.attribute_stats.replacement_costs).flatMap(([key, value]) => {
            const vulnDetails = vulnerabilityConverter(key, palette, isMinorMajor)
            if (vulnDetails)
              return [
                {
                  ...vulnDetails,
                  min: value.min,
                  value: value.value,
                  max: value.max,
                },
              ]
            return []
          }),
        ]
      }

      exposurePercentage = ((vulnSource.total ?? 0) / riskData.data.total_metric) * 100
      exposureTotal = Math.round((vulnSource.total ?? 0) * 10) / 10
      assetTotal = Math.round(riskData.data.total_metric * 10) / 10
    }

    const isIsolationLayer =
      riskData?.data.bucket_size === null &&
      riskData?.data.exposure_buckets.length == 1 &&
      riskData.data.exposure_buckets[0].exposure_bucket === null

    // If any of 'Low/Minor', 'Moderate', 'High/Major' > 0 then show them all as a group
    let lowModHighIsSet =
      !isIsolationLayer &&
      vulnerabilityData.some(
        (item) =>
          ['Low', 'Minor', 'Moderate', 'High', 'Major'].includes(item.label) && item.value > 0,
      )

    vulnerabilityData = vulnerabilityData.filter(
      (item) =>
        item.value ||
        (['Low', 'Minor', 'Moderate', 'High', 'Major'].includes(item.label) && lowModHighIsSet),
    )

    // If any of 'Low/Minor', 'Moderate', 'High/Major' > 0 then show them all as a group
    lowModHighIsSet = replacementCostsData.some(
      (item) =>
        ['Low', 'Minor', 'Moderate', 'High', 'Major'].includes(item.label) && item.value > 0,
    )

    replacementCostsData = replacementCostsData.filter(
      (item) =>
        item.value ||
        (['Low', 'Minor', 'Moderate', 'High', 'Major'].includes(item.label) && lowModHighIsSet),
    )

    // Some models dont' have fragility curves so we can't show a damage state, all we can say for sure is that
    // the element is exposed. If the only stat available is 'Exposed' then show a message stating this.
    const isVulnerabilityOnlyExposed =
      vulnerabilityData.length == 1 && vulnerabilityData[0].label == 'Exposed'

    const isReplacementCostMode = relevantIdentifyingInformation?.property === 'replacement_cost'
    const xAxisTitle = `${
      assetLayer.metric_type === 'count'
        ? 'Number'
        : isReplacementCostMode
        ? 'Dollars worth'
        : capitalizeFirstLetter(assetLayer.unit?.trimStart() ?? '')
    } of ${
      useCascading
        ? 'parcels'
        : isReplacementCostMode
        ? assetLayer.display_name.toLocaleLowerCase()
        : assetLayer.display_name
    } ${isReplacementCostMode ? 'threatened' : ''}`

    const withArticle = (value: string) => {
      return /^[aeiouAEIOU]/i.test(value.trim()) ? `an ${value}` : `a ${value}`
    }

    const consequencesPossible = useCallback(
      (
        consequenceLabel: string,
        value: number,
        suffix: string | undefined,
        prefix = '',
        type?: string,
      ) => {
        const exposure = {
          Minor: 'Minor consequences',
          Low: 'Low consequences',
          Moderate: 'Moderate consequences',
          Major: 'Major consequences',
          High: 'High consequences',
          Potential: 'Potential consequences',
          Unspecified: 'Unspecified consequences',
          Insignificant: 'Insignificant consequences',
        }[consequenceLabel]
        let roundedValue = round(value, value > 1000 ? 0 : 2).toLocaleString()
        if (roundedValue === '0' && value !== 0) roundedValue = '<0.01'
        if (useCascading)
          return `${exposure} possible for  upstream connections of ${roundedValue} parcels`
        if (type === 'Replacement Costs')
          return `${prefix}${roundedValue} worth of damage from possible ${exposure?.toLocaleLowerCase()}`
        if (consequenceLabel === 'Exposed') return `${prefix}${roundedValue} ${suffix} are exposed`
        return `${exposure} possible for ${prefix}${roundedValue} ${suffix}`
      },
      [useCascading],
    )
    const damageStateTooltipFormatter = useCallback(
      (data: ChartDataItem) => {
        let unit = assetLayer.unit
        let prefix = undefined
        let type = undefined
        if (relevantIdentifyingInformation) {
          unit = undefined
          if (relevantIdentifyingInformation.property === 'replacement_cost') {
            unit = undefined
            type = 'Replacement Costs'
          }
          prefix = relevantIdentifyingInformation.prefix
        }
        return consequencesPossible(data.label, data.value, unit, prefix, type)
      },
      [assetLayer.unit, consequencesPossible, relevantIdentifyingInformation],
    )

    const hazardUnit = useMemo(() => hazardLayer.tilesets[0]?.unit, [hazardLayer])

    const exposureDistributionTooltipFormatter = useCallback(
      (data: HistogramDataItem): ChartTooltipItem[] => {
        const values = data.value as { [key: string]: number }
        const colors = data.color as { [key: string]: string }
        return Object.keys(data.value)
          .toReversed()
          .map((key) => {
            return {
              text: consequencesPossible(key, values[key], assetLayer.unit),
              color: colors[key],
            }
          })
      },
      [assetLayer.unit, consequencesPossible],
    )
    const exposureDistributionTooltipHeaderFormatter = useCallback(
      ({
        bucketRangeStart,
        bucketRangeEnd,
      }: {
        bucketRangeStart: number
        bucketRangeEnd: number
      }) => {
        if (useCascading) return `${bucketRangeStart} days after event`
        return `Exposure: ${bucketRangeStart} - ${bucketRangeEnd}${hazardUnit ? hazardUnit : ''}`
      },
      [hazardUnit, useCascading],
    )

    //Collect charts
    const charts = []
    if (!isVulnerabilityOnlyExposed) {
      charts.push(
        <Box css={chartSection({ theme })} key="damage-state">
          <Box css={chartHeader({ theme })}>
            <Typography>Damage State{useCascading ? ' of Upstream Connections' : ''}</Typography>
          </Box>
          {vulnerabilityData.length > 0 ? (
            <Box
              css={css`
                ${chartContainer()}
                min-height: ${Math.max((vulnerabilityData.length + 1) * 40, 96)}px;
              `}
              key="damage-state-chart"
            >
              <Box css={chartSizeProtector()}>
                <Barchart
                  data={vulnerabilityData}
                  xTitle={xAxisTitle}
                  tooltipFormatter={damageStateTooltipFormatter}
                />
              </Box>
            </Box>
          ) : (
            <EmptySection title="No damage state data" height="90%" />
          )}
        </Box>,
      )
    }
    charts.push(
      <Box
        css={css`
          ${chartSection({ theme })}
          max-width: 200px;
        `}
        key="exposure"
      >
        <Box css={chartHeader({ theme })}>
          <Typography paddingRight="4px">
            Percent {useCascading ? 'Impacted' : 'Exposed'}
          </Typography>
        </Box>
        {exposurePercentage ? (
          <Box
            css={css`
              ${chartContainer()}
              justify-content: center;
              padding: 16px 0 0 0;
              min-width: 80px;
              align-self: flex-start;
              box-sizing: border-box;

              > div {
                align-self: center;
              }
            `}
            key="exposure-chart"
          >
            <Gauge
              value={exposureTotal}
              total={assetTotal}
              unit={
                useCascading
                  ? 'parcels'
                  : relevantIdentifyingInformation?.property === 'replacement_cost'
                  ? ''
                  : assetLayer.unit
              }
              prefix={relevantIdentifyingInformation?.prefix}
            />
          </Box>
        ) : (
          <EmptySection title="No exposure data" height="90%" />
        )}
      </Box>,
    )

    if (
      !isVulnerabilityOnlyExposed &&
      relevantIdentifyingInformation?.property !== 'replacement_cost' &&
      histogramData.length > 0 &&
      !isIsolationLayer
    ) {
      charts.push(
        <Box css={chartSection({ theme })} key="exposure-distribution">
          <Box css={chartHeader({ theme })}>
            <Typography>{useCascading ? 'Outage' : 'Exposure'} Distribution</Typography>
          </Box>
          <Box
            css={css`
              ${chartContainer()}
              min-height: 200px;
            `}
            key="exposure-distribution-chart"
          >
            <Box css={chartSizeProtector()}>
              <Histogram
                data={histogramData}
                bucketSize={riskData?.data.bucket_size ?? 1}
                xTitle={
                  useCascading
                    ? `Days since event`
                    : `Exposure${hazardUnit ? ` (${hazardUnit})` : ''}`
                }
                tooltipFormatter={exposureDistributionTooltipFormatter}
                tooltipHeaderFormatter={exposureDistributionTooltipHeaderFormatter}
                yTitle={
                  useCascading
                    ? 'Outages'
                    : capitalizeFirstLetter(assetLayer.unit || '') + ' Exposed'
                }
              />
            </Box>
          </Box>
        </Box>,
      )
    }

    charts.push(
      <ProjectedRisk
        key="projected-risk"
        assetLayer={assetLayer}
        hazardLayer={hazardLayer}
        yAxisTitle={
          isReplacementCostMode
            ? 'Value ($)'
            : `${
                assetLayer.unit ? capitalizeFirstLetter(assetLayer.unit) : 'Length/Count'
              } Impacted`
        }
        identifyingInformationKey={relevantIdentifyingInformation?.property}
      />,
    )

    let disclaimer = ''
    if (isVulnerabilityOnlyExposed) {
      disclaimer = `The following charts are not available due to data being unavailable: Damage State, Exposure Distribution, Replacement Costs.`
    }

    return (
      <Box>
        <Box css={reportAssetRiskStackedSection()}>
          <FancySuspense isLoading={isLoading} variant="text">
            {exposureTotal >= 0 && (
              <Box css={riskSummary({ theme })}>
                {assetLayer.is_cascading ? (
                  <Typography>
                    {cascadingAssetNDays[assetLayer.type]} days after&nbsp;
                    {hazardLayer.scenario_name
                      ? withArticle(hazardLayer.display_name)
                      : 'this scenario'}
                    &nbsp;event, approximately {exposureTotal.toLocaleString()} parcels are expected
                    to be without&nbsp;
                    {assetLayer.display_name.charAt(0).toLowerCase() +
                      assetLayer.display_name
                        .slice(1)
                        .replace(/outage.*/, '')
                        .trim()}
                    .
                  </Typography>
                ) : exposureTotal == 0 ? (
                  <Typography>
                    {assetLayer.display_name} aren&apos;t exposed under this scenario.
                  </Typography>
                ) : relevantIdentifyingInformation?.property === 'replacement_cost' ? (
                  <Typography>
                    Approximately&nbsp;
                    <b>
                      {relevantIdentifyingInformation.prefix ?? ''}
                      {roundForDisplay(exposureTotal, 2)}
                    </b>{' '}
                    of{' '}
                    <b>
                      {relevantIdentifyingInformation.prefix ?? ''}
                      {roundForDisplay(assetTotal, 2)}
                    </b>{' '}
                    worth of {assetLayer.display_name.toLowerCase()}
                    &nbsp;are threatened under this scenario.
                  </Typography>
                ) : (
                  <Typography>
                    Approximately <b>{exposureTotal.toLocaleString()}</b> of{' '}
                    <b>
                      {assetTotal}
                      {assetLayer.unit}
                    </b>
                    {assetLayer.metric_type === 'count'
                      ? ''
                      : ' of ' + assetLayer.display_name.toLowerCase()}
                    &nbsp;are threatened under this scenario.
                  </Typography>
                )}
              </Box>
            )}
          </FancySuspense>
          {exposureTotal !== 0 && (
            <Box>
              {assetIdentifyingInformation &&
                assetIdentifyingInformation.length > 0 &&
                replacementCostsEnabled && (
                  <Box
                    sx={{
                      paddingLeft: '8px',
                      display: 'flex',
                      gap: '8px 12px',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                    }}
                  >
                    <Typography variant="caption" sx={{ color: '#0b2948', fontWeight: 'bold' }}>
                      Measure consequence by:
                    </Typography>
                    <ToggleButtonGroup
                      value={relevantIdentifyingInformation?.property ?? 'default'}
                      exclusive
                      size="small"
                      onChange={(_, value) =>
                        setIdentifyingInformationForAssetHazard(
                          assetLayer.type,
                          hazardLayer.assetTag,
                          value,
                        )
                      }
                    >
                      {[
                        { name: 'Quantity (default)', property: 'default' },
                        ...assetIdentifyingInformation,
                      ].map((parameter) => {
                        return (
                          <ToggleButton
                            key={parameter.property}
                            value={parameter.property}
                            css={css`
                              border-color: #0b2948b0;
                              padding: 4px 8px;
                              border-radius: 8px;
                              transition: background-color 0.1s;

                              &.Mui-selected {
                                background-color: #6694c320;
                              }

                              &.Mui-selected:hover {
                                background-color: #6694c330;
                              }

                              &:hover {
                                background-color: #6694c318;
                              }
                            `}
                          >
                            <Typography
                              sx={{
                                fontSize: '12px',
                                lineHeight: '16px',
                                color: '#0b2948',
                              }}
                            >
                              {parameter.name}
                            </Typography>
                          </ToggleButton>
                        )
                      })}
                    </ToggleButtonGroup>
                  </Box>
                )}
              <FancySuspense isLoading={isLoading} variant="charts">
                <Box css={chartsContainer({ theme })}>{charts}</Box>
              </FancySuspense>
            </Box>
          )}
        </Box>
        {disclaimer && (
          <Typography css={disclaimerText({ theme })}>
            <b>Note:</b>&nbsp;{disclaimer}
          </Typography>
        )}
      </Box>
    )
  },

  (prevProps, nextProps) => {
    return (
      prevProps.isLoading === nextProps.isLoading &&
      prevProps.relevantIdentifyingInformation === nextProps.relevantIdentifyingInformation &&
      prevProps.setIdentifyingInformationForAssetHazard ===
        nextProps.setIdentifyingInformationForAssetHazard &&
      prevProps.riskData === nextProps.riskData
    )
  },
)
