import { createContext, useCallback, useContext, useState } from 'react'

export const Features = ['strategyPage'] as const
export type Feature = (typeof Features)[number]

export interface TutorialContextProviderProps {
  children: React.ReactNode
}

export interface TutorialContextContext {
  tutorialItems: { [key: string]: unknown }
  tutorialActive: boolean
  setTutorialActive: (active: boolean) => void
  setTutorialItem: (key: string, value: unknown) => void
  openAddLayersModal: boolean
  setOpenAddLayersModal: (open: boolean) => void
  openMapToolbar: boolean
  setOpenMapToolbar: (open: boolean) => void
  tutorialToRun: string | null
  setTutorialToRun: (tutorial: string | null) => void
}

export const TutorialContext = createContext({} as TutorialContextContext)

export const TutorialContextProvider = ({ children }: TutorialContextProviderProps) => {
  const [tutorialItems, setTutorialItems] = useState<{ [key: string]: unknown }>({})
  const [tutorialActive, setTutorialActive] = useState<boolean>(false)
  const [openAddLayersModal, setOpenAddLayersModal] = useState<boolean>(false)
  const [openMapToolbar, setOpenMapToolbar] = useState<boolean>(false)
  const [tutorialToRun, setTutorialToRun] = useState<string | null>(null)

  const setTutorialItem = useCallback(
    (key: string, value: unknown) => {
      setTutorialItems((prev) => ({ ...prev, [key]: value }))
    },
    [setTutorialItems],
  )

  // TODO: Add loading state
  return (
    <TutorialContext.Provider
      value={{
        tutorialItems,
        setTutorialItem,
        tutorialActive,
        tutorialToRun,
        setTutorialToRun,
        setTutorialActive,
        openAddLayersModal,
        setOpenAddLayersModal,
        openMapToolbar,
        setOpenMapToolbar,
      }}
    >
      {children}
    </TutorialContext.Provider>
  )
}

export const useTutorialContext = () => useContext(TutorialContext)
