/** @jsxImportSource @emotion/react */
import { Visibility, VisibilityOff } from '@mui/icons-material'
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'
import { Box, IconButton, TextField, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { useState } from 'react'

import {
  helperText,
  helperTextsContainer,
  passwordContainer,
  showPasswordButton,
} from './PasswordInput.styles'

export interface PasswordProps {
  value: string
  error: boolean
  helperTexts: string[]
}

export interface PasswordInputProps extends PasswordProps {
  id: string
  label?: string
  handleChangePassword: (password: PasswordProps) => void
}

export const PasswordInput = ({
  id,
  label = 'Password',
  error,
  helperTexts,
  value,
  handleChangePassword,
}: PasswordInputProps) => {
  const theme = useTheme()
  const [showPassword, setShowPassword] = useState(false)

  const handleClickShowPassword = () => setShowPassword((show) => !show)

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
  }

  const handleCheckPassword = (password: string) => {
    handleChangePassword({ error: false, helperTexts: [], value: password })
  }

  return (
    <Box css={passwordContainer}>
      <TextField
        id={id}
        name={id}
        autoComplete="on"
        variant="outlined"
        type={showPassword ? 'text' : 'password'}
        label={label}
        value={value}
        error={error}
        onChange={(e) => handleCheckPassword(e.target.value)}
        fullWidth
      />

      <IconButton
        css={showPasswordButton({ theme })}
        aria-label="toggle password visibility"
        onClick={handleClickShowPassword}
        onMouseDown={handleMouseDownPassword}
        edge="end"
      >
        {showPassword ? <VisibilityOff /> : <Visibility />}
      </IconButton>

      <Box css={helperTextsContainer({ theme })}>
        {helperTexts.map((error) => (
          <Box key={error} css={helperText({ theme })}>
            <CancelOutlinedIcon color="error" fontSize="small" />
            <Typography variant="caption">{error}</Typography>
          </Box>
        ))}
      </Box>
    </Box>
  )
}
