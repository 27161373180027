/** @jsxImportSource @emotion/react */
import { backgroundImage, bannerBlock } from './BannerBlock.styles'
import { Box, useTheme } from '@mui/material'
import { Video } from '@src/components/Atoms/Video'
import UiMarkdown from '@src/components/Atoms/UiMarkdown/UiMarkdown'

export type BannerBlockProps = {
  type: 'banner'
  header?: string // warning
  content?: string
  videoUrl?: string
  backgroundImageUrl?: string
}

export const BannerBlock = ({
  header,
  content,
  videoUrl,
  backgroundImageUrl,
}: BannerBlockProps) => {
  const theme = useTheme()

  return (
    <Box css={bannerBlock({ theme })}>
      <Box css={backgroundImageUrl && backgroundImage({ backgroundImageUrl })} />
      <h1>{header}</h1>
      {videoUrl &&
        (videoUrl.includes('embed') ? (
          <iframe src={videoUrl} allowFullScreen />
        ) : (
          <Video videoUrl={videoUrl} />
        ))}
      {content && <UiMarkdown>{content}</UiMarkdown>}
    </Box>
  )
}
