import { css, keyframes } from '@emotion/react'
import { Theme } from '@mui/material/styles'
import { AccordionProps } from './Accordion'

export function variantColor({
  theme,
  variant,
}: {
  theme: Theme
  variant?: AccordionProps['variant']
}) {
  if (!variant) return theme.palette.primary.dark
  if (variant === 'clear') return theme.palette.primary.dark
  if (variant === 'outline') return theme.palette.primary.dark
  return theme.palette.secondary.main
}

export const accordionHeader = ({
  theme,
}: {
  theme: Theme
  variant?: AccordionProps['variant']
}) => css`
  display: flex;
  align-items: center;
  gap: ${theme.spacing(2)};
  flex-grow: 1;

  p {
    color: inherit;
    font-size: inherit;
    line-height: inherit;
    font-weight: inherit;
  }
`

export const accordionHeaderIcon = css`
  display: flex;
  align-items: center;
`

export const accordionBody = ({ theme }: { theme: Theme }) => css`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing(2)};
  background-color: transparent;
`
const fadeinandup = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px) scaleY(0);
  }
  to {
    opacity: 1;
    transform: translateY(0) scaleY(1);
  }
`

export const accordionStyle = ({
  theme,
  variant,
  level,
  animationDelay,
  priority = 0,
  hasTextContent,
  isFirstRender,
  canCollapse,
  backgroundColor,
  hasBody,
}: {
  theme: Theme
  variant?: AccordionProps['variant']
  level?: AccordionProps['level']
  animationDelay?: number
  priority?: number
  hasTextContent?: boolean
  isFirstRender?: boolean
  canCollapse?: boolean
  backgroundColor?: string
  hasBody?: boolean
}) => {
  let contentStyle = css``
  let headerStyle = css``
  let expandedHeaderStyle = css``
  let titleIconStyle = css``
  const expandedTitleIconStyle = css``
  const expandedStyle = css``
  let baseStyle = css``

  switch (variant) {
    case 'filled':
      contentStyle = css`
        border: 1px solid ${theme.palette.primary.dark}10;
        border-top: none;
      `
      headerStyle = css`
        background-color: ${theme.palette.primary.dark};
        color: ${theme.palette.white.main};
      `
      expandedHeaderStyle
      titleIconStyle
      expandedTitleIconStyle
      expandedStyle
      baseStyle = css`
        ${baseStyle}
        background-color: ${theme.palette.primary.dark}20;
      `
      break
    case 'clear':
      contentStyle
      headerStyle = css`
        color: ${theme.palette.primary.dark};
        background-color: transparent;
      `
      expandedHeaderStyle
      titleIconStyle
      expandedTitleIconStyle
      expandedStyle
      baseStyle = css`
        ${baseStyle}
        background-color: transparent;
      `
      break
    case 'outline':
      contentStyle
      headerStyle = css`
        color: ${theme.palette.primary.dark};
        border-top: 2px solid ${theme.palette.primary.dark}00;
        border-bottom: 1px solid ${theme.palette.primary.dark}00;
      `
      expandedHeaderStyle = css`
        border-bottom: 2px solid ${theme.palette.primary.dark}20;
      `
      titleIconStyle
      expandedTitleIconStyle
      expandedStyle
      baseStyle = css`
        ${baseStyle}
        border: 1px solid ${theme.palette.primary.main}80;
      `
      break
    case 'panel':
      contentStyle
      headerStyle = css`
        color: ${theme.palette.primary.dark};
        background-color: ${theme.palette.primary.main}20;
        svg {
          color: ${theme.palette.primary.dark} !important;
        }
      `
      expandedHeaderStyle
      titleIconStyle
      expandedTitleIconStyle
      expandedStyle
      baseStyle = css`
        ${baseStyle}
        background-color: ${theme.palette.primary.main}10;
      `
      break
  }

  switch (level) {
    case 'h1':
      contentStyle
      headerStyle = css`
        ${headerStyle}
        font-size: 1.3rem;
        font-weight: 500;
        line-height: 1.3;
        margin: ${theme.spacing(2)} 0;
        padding: ${theme.spacing(2)} ${theme.spacing(4)};
      `
      expandedHeaderStyle
      titleIconStyle = css`
        height: ${theme.spacing(6)};
        width: ${theme.spacing(6)};
        margin-right: ${theme.spacing(2)};
      `
      expandedTitleIconStyle
      expandedStyle
      baseStyle
      break
    case 'h2':
      contentStyle
      headerStyle = css`
        ${headerStyle}
        font-size: 1.15rem;
        font-weight: 600;
        line-height: 1.3;
        padding: ${theme.spacing(2)} ${theme.spacing(2)};
      `
      expandedHeaderStyle
      titleIconStyle
      expandedTitleIconStyle
      expandedStyle
      baseStyle
      break
    case 'h3':
      contentStyle
      headerStyle = css`
        ${headerStyle}
        font-size: 0.9rem;
        font-weight: 600;
        line-height: 1.3;
        padding: ${theme.spacing(1)} ${theme.spacing(2)};
      `
      expandedHeaderStyle
      titleIconStyle
      expandedTitleIconStyle
      expandedStyle
      baseStyle
      break
  }

  if (hasTextContent) {
    contentStyle = css`
      ${contentStyle}
      background-color: ${theme.palette.white.main};
    `
    if (variant == 'filled') {
      contentStyle = css`
        ${contentStyle}
        border: 1px solid ${theme.palette.primary.main}80;
        border-top: none;
      `
    } else if (variant == 'panel') {
      contentStyle = css`
        ${contentStyle}
        border: 1px solid ${theme.palette.primary.dark}20;
        border-top: none;
      `
    } else if (variant == 'clear') {
      contentStyle = css`
        ${contentStyle}
        border: 1px solid ${theme.palette.primary.dark}60;
        border-radius: ${theme.spacing(1)};
        margin-top: ${theme.spacing(1)};
      `
    }
  }

  if (!canCollapse) {
    baseStyle = css`
      ${baseStyle}
      .MuiAccordionDetails-root {
        height: initial;
      }
    `
  }

  if (backgroundColor) {
    contentStyle = css`
      ${contentStyle}
      background-color: ${backgroundColor};
    `
  }

  if (!hasBody) {
    contentStyle = css`
      ${contentStyle}
      display: none;
    `
    expandedHeaderStyle = css`
      ${expandedHeaderStyle}
      border-bottom: 1px solid ${theme.palette.primary.dark}00;
    `
  }

  return css`
    & > .MuiCollapse-root {
      will-change: height;

      > .MuiCollapse-wrapper
        > .MuiCollapse-wrapperInner
        > .MuiAccordion-region
        > .MuiAccordionDetails-root {
        background-color: transparent;
        border-radius: 0 0 ${theme.spacing(1)} ${theme.spacing(1)};
        overflow: visible;
        height: initial;
        ${contentStyle}
      }
    }
    & > div.MuiAccordionSummary-root {
      transition: border-bottom 0.3s ease, height 0.3s ease, padding 0.3s ease;
      ${headerStyle}
      min-height: ${theme.spacing(8)};
      ${canCollapse ? '' : 'cursor: default !important;'}

      > div.MuiAccordionSummary-expandIconWrapper {
        align-items: center;
      }

      flex-grow: 1;

      svg {
        color: ${variantColor({ theme, variant })};
      }

      > div.MuiAccordionSummary-content svg {
        ${titleIconStyle}
      }
    }
    &.Mui-expanded > div.MuiAccordionSummary-root {
      ${expandedHeaderStyle}
      .MuiAccordionSummary-content svg {
        ${expandedTitleIconStyle}
      }
    }
    &.Mui-expanded {
      ${expandedStyle}
      margin: 0;
    }
    div[aria-roledescription='sortable'] {
      width: ${theme.spacing(5)};
      height: ${theme.spacing(5)};
      display: flex;
      align-items: center;
      justify-content: center;
      &:hover {
        background-color: #0001;
      }
      svg {
        margin-right: 0 !important;
        height: ${theme.spacing(5)};
        width: ${theme.spacing(5)};
      }
    }
    border: none;
    ${baseStyle}
    flex-shrink: 0;
    transition: border-color 0.3s ease, box-shadow 0.3s ease, padding 0.3s ease, margin 0.3s ease,
      background-color 0.3s ease;
    will-change: border-color, box-shadow, padding, margin, background-color, transform;

    ${isFirstRender
      ? css`
          animation: ${fadeinandup} 0.2s ease both;
          animation-delay: ${animationDelay}s;
        `
      : ''}
    transform-origin: top;
    order: ${-priority};
  `
}
