/** @jsxImportSource @emotion/react */
import { useTheme } from '@mui/material/styles'
import { storyControls } from './StoryControls.styles'
import { Box } from '@mui/material'
import { memo, useState } from 'react'
import { StoryNavigation } from '../StoryNavigation'
import { Icon } from '@src/components/Atoms/Icon'
import { SequencedStep } from '../../Stories.utils'

export interface StoryControlsProps {
  activeStepIndex: number
  sequencedSteps: SequencedStep[]
  changeStep: (step: number) => void
}

export const StoryControls = memo(
  function StoryControlsComp({ activeStepIndex, sequencedSteps, changeStep }: StoryControlsProps) {
    const theme = useTheme()
    const [navMenuOpen, setNavMenuOpen] = useState(false)

    return (
      <Box css={storyControls(theme)}>
        <Box sx={{ position: 'relative' }}>
          <Icon
            iconName="s3://menu.svg"
            size="small"
            onClick={() => setNavMenuOpen(true)}
            colour={theme.palette.primary.main}
          />
          {navMenuOpen && (
            <StoryNavigation
              sequencedSteps={sequencedSteps}
              activeStepIndex={activeStepIndex}
              onClose={() => setNavMenuOpen(false)}
              setStep={(index: number) => {
                changeStep(index - activeStepIndex)
              }}
            />
          )}
        </Box>
        <Box>
          <Icon
            iconName="s3://uparrow.svg"
            size="small"
            onClick={() => changeStep(-1)}
            colour={theme.palette.primary.main}
          />
        </Box>
        <Box>
          <Icon
            iconName="s3://downarrow.svg"
            size="small"
            onClick={() => changeStep(1)}
            colour={theme.palette.primary.main}
          />
        </Box>
      </Box>
    )
  },
  (prevProps, nextProps) => {
    return prevProps.activeStepIndex === nextProps.activeStepIndex
  },
)
