import { css } from '@emotion/react'
import { Theme } from '@mui/material/styles'

export const bannerBlock = ({ theme }: { theme: Theme }) => css`
  padding: ${theme.spacing(12)} ${theme.spacing(20)};
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${theme.palette.common.white};
  text-align: center;
  flex-direction: column;
  position: relative;
  background-color: #0b2948;
  overflow: hidden;
  gap: ${theme.spacing(8)};

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: ${theme.spacing(8)};
    background: linear-gradient(
      0deg,
      ${theme.palette.white.main}D0 0%,
      ${theme.palette.white.main}A0 8%,
      #04497c40 8%,
      #04497c00 100%
    );
    z-index: 1;
  }

  h1 {
    font-size: 36px;
    font-weight: 700;
    text-shadow: 0 0 16px rgba(0, 0, 0, 0.1), -1px 0 0 #fff8;
  }

  iframe {
    width: 460px;
    height: 260px;
    border: none;
    border-radius: 4px;
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.1);
  }

  video {
    width: 460px;
    height: 260px;
    border: none;
    border-radius: 4px;
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.1);
  }

  > * {
    z-index: 1;
  }
`

export const backgroundImage = ({ backgroundImageUrl }: { backgroundImageUrl: string }) => css`
  background-image: url(${backgroundImageUrl});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  pointer-events: none;
`
