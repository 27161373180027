/** @jsxImportSource @emotion/react */

import { Box, IconButton, useTheme } from '@mui/material'
import {
  clickableTitle,
  closeButton,
  header,
  subtitleContainer,
  titleContainer,
} from './SideDrawerHeader.styles'
import { Icon } from '@src/components/Atoms/Icon'

export const SideDrawerHeader = ({
  title,
  subtitle,
  iconName,
  onTitleClicked,
  onclose,
}: {
  title: string
  subtitle?: string
  iconName: string
  onTitleClicked?: () => void
  onclose?: () => void
}) => {
  const theme = useTheme()
  return (
    <Box css={header({ theme })}>
      <Box css={titleContainer({ theme })}>
        <Icon iconName={iconName} colour={theme.palette.primary.main} />
        <span>
          <span css={onTitleClicked !== undefined ? clickableTitle : null} onClick={onTitleClicked}>
            {title.toUpperCase()}
          </span>
          {subtitle && (
            <span css={subtitleContainer}>
              <span css={{ padding: `0 12px` }}>❯</span>
              {subtitle}
            </span>
          )}
        </span>
      </Box>
      {onclose && (
        <IconButton css={closeButton({ theme })} onClick={() => onclose()}>
          <Icon size="large" iconName="s3://return" colour={theme.palette.primary.main} />
        </IconButton>
      )}
    </Box>
  )
}
