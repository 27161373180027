/** @jsxImportSource @emotion/react */
import { Box, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'

import {
  BubbleChart,
  BubbleChartDataset,
  CircleSizeLegend,
  defaultBubbleChartOptions,
  horizonScanExternalTooltip,
} from '@uintel/ui-component-library'
import { useEffect, useState } from 'react'

import { HorizonScanItem, scenarioOptions } from '../HorizonScan'

import {
  chartContainer,
  chartRowContainer,
  chartTitle,
  chartsContainer,
  emptyChartContainer,
  horizonScanMultipleBubbleChartContainer,
} from './HorizonScanMultipleBubbleCharts.styles'
import { MultipleBubbleChartsAxis } from './MultipleBubbleChartsAxis'
import { usePreferences } from '@contexts/PreferencesContext'

export const HorizonScanMultipleBubbleCharts = ({
  data,
}: {
  data: BubbleChartDataset<HorizonScanItem>[]
}) => {
  const theme = useTheme()
  const { term_preference } = usePreferences()

  const [currentData, setCurrentData] = useState<BubbleChartDataset<HorizonScanItem>[]>()
  const [orderlyData, setOrderlyData] = useState<BubbleChartDataset<HorizonScanItem>[]>()
  const [disorderlyData, setDisorderlyData] = useState<BubbleChartDataset<HorizonScanItem>[]>()
  const [hothouseData, setHothouseData] = useState<BubbleChartDataset<HorizonScanItem>[]>()

  const horizonScanBubbleChartProps = {
    xSuggestedMax: 5.5,
    ySuggestedMax: 5.5,
    isSmallChart: true,
    chartOptions: {
      plugins: {
        ...defaultBubbleChartOptions({
          legendTitle: 'PESTLE',
          xAxisLabel: 'Likelihood',
          yAxisLabel: term_preference.risk ? 'Risk' : 'Consequence',
        }).plugins,
        tooltip: horizonScanExternalTooltip(),
      },
    },
  }

  useEffect(() => {
    if (!data) return

    for (const scenario of scenarioOptions) {
      const hydrateCurrentData = data.map((relation) => {
        const finalData = relation.data.filter((data) => {
          if (data.climate_scenario.trim().toLowerCase() !== 'all') {
            return data.climate_scenario.trim().toLowerCase() === scenario
          }
          return true
        })

        return { ...relation, data: finalData }
      })

      switch (scenario) {
        case 'current':
          setCurrentData(hydrateCurrentData)
          break
        case 'disorderly':
          setDisorderlyData(hydrateCurrentData)
          break
        case 'hothouse':
          setHothouseData(hydrateCurrentData)
          break
        case 'orderly':
          setOrderlyData(hydrateCurrentData)
          break
        default:
          break
      }
    }
  }, [data])

  return (
    <Box css={horizonScanMultipleBubbleChartContainer({ theme })}>
      <MultipleBubbleChartsAxis />

      <Box css={chartsContainer({ theme })}>
        <Box css={chartRowContainer({ theme })}>
          {disorderlyData && (
            <Box css={chartContainer({ theme })}>
              <Typography variant="body1" css={chartTitle({ theme })}>
                Disorderly
              </Typography>
              <BubbleChart {...horizonScanBubbleChartProps} datasets={disorderlyData} />
              <CircleSizeLegend />
            </Box>
          )}
          {currentData && <Box css={[chartContainer({ theme }), emptyChartContainer]} />}
        </Box>
        <Box css={chartRowContainer({ theme })}>
          {orderlyData && (
            <Box css={chartContainer({ theme })}>
              <Typography variant="body1" css={chartTitle({ theme })}>
                Orderly
              </Typography>
              <BubbleChart {...horizonScanBubbleChartProps} datasets={orderlyData} />
              <CircleSizeLegend />
            </Box>
          )}

          {hothouseData && (
            <Box css={chartContainer({ theme })}>
              <Typography variant="body1" css={chartTitle({ theme })}>
                Hothouse
              </Typography>
              <BubbleChart {...horizonScanBubbleChartProps} datasets={hothouseData} />
              <CircleSizeLegend />
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  )
}
