/** @jsxImportSource @emotion/react */
import { LegendRow } from '@atoms/LegendRow'
import { Box } from '@mui/material'

import { LegendItem } from '../Legend'

interface LegendCategoricalProps {
  items: LegendItem[]
}

// Determines if two items ought to be separated
export const areSeparateItems = (item1: LegendItem, item2: LegendItem) => {
  const item1WithType = { type: 'fill', ...item1 }
  const item2WithType = { type: 'fill', ...item2 }

  return item1WithType.type !== item2WithType.type
}

export const LegendCategorical = ({ items }: LegendCategoricalProps) => {
  return (
    <Box>
      {items.map((item, itemIndex) => {
        return <LegendRow key={item.label + itemIndex} label={item.label} swatch={item} />
      })}
    </Box>
  )
}
