import { SCALES_WORKBOOK_ID } from '@constants'
import { read, utils } from 'xlsx'
import axios from './customAxios'

const EXCEL_FILE = `/data/scales_dashboard_contents.xlsx`

export async function getWorkbookData(workbookName: string) {
  if (import.meta.env['MODE'] === 'development') {
    return await getWorkbookDataLocally(EXCEL_FILE, workbookName)
  } else {
    return await getWorkbookDataFromAPI({
      workbookId: SCALES_WORKBOOK_ID,
      sheetName: workbookName,
    })
  }
}

export async function getWorkbookDataLocally(file: string, workbookName: string) {
  // Won't convert this to axios as I don't understand it - Kev.
  const fileToRead = await (await fetch(file)).arrayBuffer()
  const workBook = read(fileToRead)
  const data = utils.sheet_to_json(workBook.Sheets[workbookName])

  return data
}

export async function getWorkbookDataFromAPI({
  workbookId,
  sheetName,
}: {
  sheetName: string
  workbookId: string
}) {
  const apiURL = `/api/sheets/${workbookId}/${sheetName}`

  const response = await axios.get(apiURL)

  return response.data
}
