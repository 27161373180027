/** @jsxImportSource @emotion/react */
import SecurityIcon from '@mui/icons-material/Security'
import TaskOutlinedIcon from '@mui/icons-material/TaskOutlined'
import { Box, Button, Divider, useTheme, Typography } from '@mui/material'
import { Icon } from '@src/components/Atoms/Icon'
import { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { MapLayer } from '../../../../../../../redux/map/mapSlice'
import { RootState } from '../../../../../../../redux/store'
import {
  getAssetAndHazardDetailsForSelectedFeature,
  getInformationDetailsForSelectedFeature,
  removeSelectedMarkerFromMapIfExists,
} from '../../../../../MapView/RiskMapView.utilities'
import {
  assetSpecificDetailContainer,
  assetSpecificFooter,
  assetSpecificFooterMain,
  assetSpecificFooterResult,
  assetSpecificFooterResultIcon,
  assetSpecificHazardsBody,
  assetSpecificHazardsFooter,
  assetSpecificHazardsFooterMain,
  assetSpecificHazardsFooterValue,
  assetSpecificHazardsSentenceContainer,
  assetSpecificHazardsSentenceSpan,
  assetSpecificLayerDetails,
  assetSpecificMainBody,
  assetSpecificMainBodyID,
  assetSpecificMainBodyTitle,
  assetSpecificMainHTML,
  selectedHeader,
} from './SpecificLocationAccordion.styles'
import { Accordion } from '../../../../../../Atoms/Accordion'
import { HazardDialogData } from '@src/components/Molecules/MapView/AddLayersDialog'
import { HazardLayersForm } from '@src/components/Molecules/MapView/AddLayersDialog/HazardLayersForm'
import { useLayerDataFetcher } from '../../../../data_fetchers/layerDataFetcher'
import { useScenariosFetcher } from '../../../../data_fetchers/scenariosFetcher'
import { EmptySection } from '../../../../components/EmptySection'
import UiMarkdown from '@src/components/Atoms/UiMarkdown/UiMarkdown'
import { useLayers } from '@contexts/LayerContext'
import { css } from '@emotion/react'
import { Delete } from '@mui/icons-material'
import { useMap } from '@contexts/MapContext'
import pluralize from 'pluralize'
import ProjectedExposure from './ProjectedExposure/ProjectedExposure'
import { useFeatureFlags } from '@contexts/FeatureFlagsContext'
import { usePreferences } from '@contexts/PreferencesContext'
import { MapTooltipProps } from '@src/components/Molecules/MapTooltip/MapTooltip'
import { firstLetterToUpperCase } from '@src/utils/strings.utils'

export interface AssetReportProps {
  selectedAsset?: MapLayer
  feature?: mapboxgl.MapboxGeoJSONFeature
  details?: {
    asset?: MapTooltipProps['asset']
    hazards?: MapTooltipProps['hazards']
    information?: MapTooltipProps['information']
  }
}

export interface AssetSpecificDetailProps {
  assetLayers: MapLayer[]
  hazardLayers: MapLayer[]
  informationLayers: MapLayer[]
  selectedFeature: mapboxgl.MapboxGeoJSONFeature | null
  selectedAsset: MapLayer | null
}

export const SpecificLocationAccordion = memo(function SpecificLocationAccordion({
  assetLayers,
  hazardLayers,
  informationLayers,
  selectedFeature,
  selectedAsset,
}: AssetSpecificDetailProps) {
  const theme = useTheme()
  const { term_preference } = usePreferences()
  const { features } = useFeatureFlags()
  const { clientName } = useSelector((state: RootState) => state.user)
  const { prepareAndUpdateLayers } = useLayers()
  const [isLoading, setIsLoading] = useState(false)
  const [knownRisksLoading, setKnownRisksLoading] = useState(true)
  const [previouslyCalledFeatureKey, setPreviouslyCalledFeatureKey] = useState<string | null>(null)
  const [details, setDetails] = useState<AssetReportProps['details'] | null>(null)
  const { layers } = useSelector((state: RootState) => state.map)
  const [hazardDialogData, setHazardDialogData] = useState<HazardDialogData>({
    hazardDetails: [],
  })
  const scenarios = useScenariosFetcher(selectedAsset, selectedFeature, setKnownRisksLoading)
  const [filteredHazardDialogData, setFilteredHazardDialogData] = useState<HazardDialogData>({
    hazardDetails: [],
  })
  const shouldHaveRisk = useMemo(() => {
    return selectedAsset?.layerType === 'asset'
  }, [selectedAsset?.layerType])

  const filteredHazardLayers = useMemo(() => {
    return hazardLayers.filter((layer) => !layer.infoLookupTileset?.length)
  }, [hazardLayers])

  const { deselectFeature } = useMap()

  const mapStore = useSelector((state: RootState) => state.map)

  const { legendsData } = mapStore

  const showProjectedExposure =
    features.find((feature) => feature.feature == 'projectedExposure')?.enabled ?? false

  const handleAddHazardLayer = (hazardLayer: MapLayer) => {
    if (!hazardLayer) return
    hazardLayer.addedByKnownRisks = true
    const updatedLayers = [
      ...layers.filter((layer) =>
        layer.hazard_id && hazardLayer.hazard_id ? layer.hazard_id !== hazardLayer.hazard_id : true,
      ),
      hazardLayer,
    ]
    prepareAndUpdateLayers({ layers: updatedLayers })
  }

  const handleRemoveHazardLayer = (hazardLayer: MapLayer) => {
    if (!hazardLayer) return

    const updatedLayers = layers.filter((layer) => layer.assetTag !== hazardLayer.assetTag)
    prepareAndUpdateLayers({ layers: updatedLayers })
  }

  const getFilteredHazardDialogData = useCallback(() => {
    if (!hazardDialogData || !selectedAsset || !scenarios) {
      return { ...hazardDialogData }
    }
    // combine the scenarios from the hazardDialogData with the scenarios from the asset
    // return the combined list
    const FilteredDetails = hazardDialogData.hazardDetails.filter((hazard) =>
      hazard.scenarios.some((hazardScenario) =>
        scenarios.some((scenario) => hazardScenario.assetTag === scenario.hazard_scenario),
      ),
    )
    const FilteredDetailsScenarios = FilteredDetails.map((hazard) => {
      const filteredScenarios = hazard.scenarios.filter((hazardScenario) =>
        scenarios.some((scenario) => hazardScenario.assetTag === scenario.hazard_scenario),
      )
      return { ...hazard, scenarios: filteredScenarios }
    })
    setFilteredHazardDialogData({ hazardDetails: FilteredDetailsScenarios })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hazardDialogData, selectedAsset, selectedFeature?.properties?.asset_id, scenarios])

  useEffect(() => {
    getFilteredHazardDialogData()
  }, [scenarios, getFilteredHazardDialogData])

  useEffect(() => {
    const abortController = new AbortController()

    if (!selectedAsset) {
      removeSelectedMarkerFromMapIfExists()
    }

    const callGetAllData = async () => {
      if (!selectedFeature) return null

      setIsLoading(true)
      try {
        let details = null
        switch (selectedAsset?.layerType) {
          case 'hazard':
          case 'asset':
            details = await getAssetAndHazardDetailsForSelectedFeature(
              selectedFeature as mapboxgl.MapboxGeoJSONFeature,
              assetLayers,
              filteredHazardLayers,
              legendsData,
            )
            break
          case 'information':
            details = {
              information: await getInformationDetailsForSelectedFeature(
                selectedFeature,
                informationLayers,
              ),
            }
            break
          default:
            break
        }

        setDetails(details ?? null)
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error)
        setDetails(null)
      } finally {
        setIsLoading(false)
      }
    }
    if (selectedFeature?.properties?.asset_id) {
      const cacheKey =
        selectedFeature?.properties?.asset_id +
        '|' +
        filteredHazardLayers.reduce((acc, layer) => acc + layer.assetTag, '')
      if (cacheKey === previouslyCalledFeatureKey) {
        setIsLoading(false)
        return
      }
      setPreviouslyCalledFeatureKey(cacheKey)
    }

    callGetAllData()

    return () => abortController.abort()
  }, [
    assetLayers,
    selectedFeature,
    hazardLayers,
    filteredHazardLayers,
    legendsData,
    selectedAsset,
    informationLayers,
    previouslyCalledFeatureKey,
  ])

  const layersData = useLayerDataFetcher()

  useEffect(() => {
    if (layersData === null) return
    setHazardDialogData(layersData.hazardDialogData)
  }, [layersData, clientName])

  const hasHazardInfo = details?.hazards?.length
    ? details?.hazards?.some((hazard) => hazard !== undefined)
    : false

  if (isLoading)
    return (
      <EmptySection title="Loading details on selected element" isLoading={true} height={'240px'} />
    )

  const renderedDetails = {
    name: '',
    id: '',
    sentence: '',
    icon: '',
  }

  switch (selectedAsset?.layerType) {
    case 'hazard':
    case 'asset':
      renderedDetails.name = details?.asset?.name ?? ''
      renderedDetails.id = details?.asset?.id.toString() ?? ''
      renderedDetails.sentence = details?.asset?.sentence ?? ''
      renderedDetails.icon = details?.asset?.icon ?? ''
      break
    case 'information':
      renderedDetails.name = details?.information?.title ?? ''
      renderedDetails.id = details?.information?.id.toString() ?? ''
      renderedDetails.sentence = details?.information?.value ?? ''
      renderedDetails.icon = details?.information?.icon ?? ''
      break
    default:
      break
  }

  if (!details?.asset && !details?.information) return null

  return (
    <Box css={assetSpecificDetailContainer({ theme })}>
      <Accordion
        defaultExpanded
        canCollapse={false}
        variant="clear"
        level="h2"
        hasTextContent={true}
        icon={<TaskOutlinedIcon />}
        header={
          <Box css={selectedHeader}>
            <Typography>Selected Element Details</Typography>
            <Button
              onClick={(e) => {
                e.stopPropagation()
                deselectFeature()
              }}
              variant="outlined"
              size="small"
              css={css`
                align-self: flex-end;
              `}
            >
              Clear selection <Delete />
            </Button>
          </Box>
        }
        body={
          <>
            <Box css={assetSpecificMainBody({ theme })}>
              <Box css={assetSpecificMainBodyTitle({ theme })}>
                <Icon
                  iconName={firstLetterToUpperCase(renderedDetails.icon) as Icon}
                  size="large"
                />
                <Typography variant="subtitle2" fontWeight={700}>
                  {pluralize.singular(renderedDetails.name)}
                </Typography>
              </Box>
              <Box css={assetSpecificMainBodyID({ theme })}>
                <Typography>ID</Typography>
                <Typography>{renderedDetails.id}</Typography>
              </Box>
            </Box>

            <Box css={assetSpecificMainHTML({ theme })}>
              <div id="asset-sentence">
                {renderedDetails.sentence ? (
                  <UiMarkdown>{renderedDetails.sentence}</UiMarkdown>
                ) : (
                  selectedAsset?.layerType == 'information' && (
                    <Typography sx={{ fontStyle: 'italic' }}>
                      Additional information not available.
                    </Typography>
                  )
                )}
              </div>
            </Box>
            <Box css={assetSpecificLayerDetails({ theme })}>
              {showProjectedExposure && details.asset && filteredHazardLayers.length ? (
                <Accordion
                  defaultExpanded
                  variant="outline"
                  level="h3"
                  icon={<Icon iconName="Warning" size="medium" />}
                  title={`Projected ${term_preference.hazard ? 'hazards' : 'risks'}`}
                  body={
                    <>
                      <Box css={assetSpecificHazardsBody({ theme })}>
                        {filteredHazardLayers.map((hazard, index) => {
                          if (!hazard) return null

                          return (
                            <Box
                              key={hazard.display_name + index}
                              css={assetSpecificHazardsSentenceContainer({ theme })}
                            >
                              <Typography fontWeight={700}>{hazard.hazard_name}</Typography>
                              <ProjectedExposure
                                asset_id={details.asset?.id}
                                hazardLayer={hazard}
                              />
                            </Box>
                          )
                        })}
                      </Box>
                    </>
                  }
                />
              ) : null}
              {details.asset && details.hazards?.length && hasHazardInfo ? (
                <Accordion
                  defaultExpanded
                  variant="outline"
                  level="h3"
                  icon={<Icon iconName="Warning" size="medium" />}
                  title={`Element exposure by ${
                    term_preference.hazard ? 'hazards' : 'risk sources'
                  } on map`}
                  body={
                    <>
                      <Box css={assetSpecificHazardsBody({ theme })}>
                        {details.hazards.map((hazard, index) => {
                          if (!hazard) return null

                          return (
                            <Box
                              key={hazard.title + index}
                              css={assetSpecificHazardsSentenceContainer({ theme })}
                            >
                              <Box
                                component="span"
                                css={assetSpecificHazardsSentenceSpan({
                                  colour: hazard.vulnerabilityColour,
                                })}
                              />
                              <Typography fontWeight={700}>{hazard.title}</Typography>
                              <div id="hazard-sentence">
                                <UiMarkdown>{hazard.sentence ?? ''}</UiMarkdown>
                              </div>
                              <Divider light />
                              <Box css={assetSpecificHazardsFooter({ theme })}>
                                <Box css={assetSpecificHazardsFooterMain({ theme })}>
                                  <Typography>
                                    {term_preference.risk ? 'Risk' : 'Consequence'}:
                                  </Typography>
                                  <Typography id="single-vulnerability-value">
                                    {hazard.vulnerabilityLabel}
                                  </Typography>
                                </Box>
                                {hazard.exposure_value !== null && (
                                  <Box css={assetSpecificHazardsFooterValue({ theme })}>
                                    <Typography>Exposure:</Typography>
                                    <Typography id="single-exposure-value">
                                      {hazard.exposure_value} {hazard.unit ?? ''}
                                    </Typography>
                                  </Box>
                                )}
                              </Box>
                            </Box>
                          )
                        })}
                      </Box>
                      <Box css={assetSpecificFooter({ theme })}>
                        <Box css={assetSpecificFooterMain({ theme })}>
                          <Box css={assetSpecificFooterResult({ theme })}>
                            <SecurityIcon css={assetSpecificFooterResultIcon({ theme })} />
                            <Typography variant="subtitle2">
                              Element highest {term_preference.risk ? 'risk' : 'consequence'}
                            </Typography>
                          </Box>
                          <Typography id="vulnerability-label" variant="subtitle2" fontWeight={700}>
                            {details.asset?.vulnerabilityLabel}
                          </Typography>
                        </Box>
                      </Box>
                    </>
                  }
                />
              ) : null}
              {shouldHaveRisk &&
                (knownRisksLoading ? (
                  <EmptySection
                    title={`Loading known ${
                      term_preference.hazard ? 'hazards' : 'risks'
                    } on selected element`}
                    isLoading={true}
                    height={'50px'}
                  />
                ) : scenarios && scenarios.length > 0 ? (
                  details.asset &&
                  filteredHazardDialogData.hazardDetails &&
                  filteredHazardDialogData?.hazardDetails.length > 0 && (
                    <Accordion
                      defaultExpanded={false}
                      icon={<Icon iconName="Report-Outlined" size="medium" />}
                      variant="outline"
                      level="h3"
                      title={
                        term_preference.hazard
                          ? 'Known hazards to element'
                          : 'Known risks to element'
                      }
                      body={
                        <>
                          <Typography sx={{ marginY: `${theme.spacing(2)}` }}>
                            This element is exposed to the following mapped{' '}
                            {term_preference.hazard ? 'hazards' : 'risk sources'}. There may be
                            other {term_preference.hazard ? 'hazards affecting' : 'risks to'} the
                            element.
                          </Typography>
                          <HazardLayersForm
                            hazardDialogData={filteredHazardDialogData}
                            selectedHazards={layers.filter((layer) => layer.layerType === 'hazard')}
                            selectedAssets={layers.filter((layer) => layer.layerType === 'asset')}
                            onSelectHazardLayer={(scenario) => {
                              handleAddHazardLayer(scenario)
                            }}
                            onRemoveLayer={(scenario) => {
                              handleRemoveHazardLayer(scenario)
                            }}
                            isKnownRisks={true}
                          />
                        </>
                      }
                    />
                  )
                ) : (
                  <Typography variant="subtitle2" sx={{ marginLeft: theme.spacing(1) }}>
                    There are no known {term_preference.hazard ? 'hazards' : 'risks'} for this
                    element.
                  </Typography>
                ))}
            </Box>
          </>
        }
      />
    </Box>
  )
})
