import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { RootState } from '@redux/store'
import { useLayers } from '@contexts/LayerContext'
import { useMap } from '@contexts/MapContext'
import { useSelector } from 'react-redux'
import {
  IdentifyingInformation,
  RiskFetcherContextProps,
  RiskIsLoadingObject,
  RiskMultiHazard,
  RiskPerHazard,
} from './RiskFetcher.types'
import {
  fetchAssetExposureStats,
  fetchAssetMultiHazardExposureStats,
  fetchCheckIfIdentifyingInformationExists,
  fetchIdentifyingInformationAssetExposureStats,
} from './RiskFetcher.utils'
import { MapLayer } from '@uintel/ui-component-library'

const potentialIdentifyingInformation = [
  {
    name: 'Value ($)',
    property: 'replacement_cost',
    prefix: '$',
    suffix: '',
  },
]

export const RiskFetcherContext = createContext<RiskFetcherContextProps>({
  riskByAssetMultiHazard: {},
  riskByAssetPerHazard: {},
  isLoading: {
    multiHazards: [],
    perHazard: {},
  },
  availableIdentifyingInformation: {},
  setIdentifyingInformationForAssetHazard: () => {
    return
  },
  selectedIdentifyingInformation: {},
})

export function RiskFetcherProvider({ children }: { children: React.ReactNode }) {
  const { visibleAssets, elementFilters } = useLayers()
  const { regionMasks } = useMap()
  const { drawAreas, layers } = useSelector((state: RootState) => state.map)

  // === States
  const [isLoading, setIsLoading] = useState<RiskIsLoadingObject>({
    multiHazards: [],
    perHazard: {},
  })
  const [riskByAssetPerHazardCache, setRiskByAssetPerHazardCache] = useState<{
    [key: string]: RiskPerHazard
  }>({})
  const [riskByAssetMultiHazardCache, setRiskByAssetMultiHazardCache] = useState<{
    [key: string]: RiskMultiHazard
  }>({})
  const [availableIdentifyingInformation, setAvailableIdentifyingInformation] = useState<{
    [key: string]: IdentifyingInformation[]
  }>({})
  const [selectedIdentifyingInformation, setSelectedIdentifyingInformation] = useState<{
    [key: string]: {
      [key: string]: IdentifyingInformation | undefined
    }
  }>({})
  const setIdentifyingInformationForAssetHazard = useCallback(
    (assetType: string, scenario: string, attribute: string | undefined) => {
      setSelectedIdentifyingInformation((prev) => {
        const newSelectedIdentifyingInformation = {
          ...prev,
          [assetType]: {
            ...prev[assetType],
            [scenario]: potentialIdentifyingInformation.find((info) => info.property === attribute),
          },
        }
        return newSelectedIdentifyingInformation
      })
    },
    [],
  )
  const [abortControllers, setAbortControllers] = useState<{
    multiHazard: AbortController | undefined
    perHazard: AbortController | undefined
  }>({
    multiHazard: undefined,
    perHazard: undefined,
  })

  // Memoized inputs
  const elementLayers = useMemo(
    () => layers.filter((layer) => layer.layerType === 'asset'),
    [layers],
  )
  const hazardLayers = useMemo(
    () => layers.filter((layer) => layer.layerType === 'hazard'),
    [layers],
  )
  const hasElementFilters = useMemo(
    () => Object.values(elementFilters).some((filters) => Object.keys(filters).length > 0),
    [elementFilters],
  )

  // === Memoized caches
  // Multi-hazard cache
  // First, hash the element filters to track if they have changed
  const elementFiltersKey = useMemo(
    () =>
      Object.keys(elementFilters).length > 0
        ? Object.values(visibleAssets).map((x) =>
            Object.keys(x).reduce((acc, id) => {
              return (acc + +id) % 1000000007
            }, 0),
          )
        : null,
    [elementFilters, visibleAssets],
  )

  const multiHazardCacheKey = useMemo(
    () =>
      hazardLayers.map((layer) => layer.assetTag).join(',') +
      JSON.stringify({
        regionMasks,
        drawAreas,
        elementFiltersKey,
      }),
    [hazardLayers, regionMasks, drawAreas, elementFiltersKey],
  )
  const multiHazardCurrentCache = riskByAssetMultiHazardCache[multiHazardCacheKey] ?? {}

  const multiHazardMissingLayers = elementLayers.filter(
    (element) =>
      !Object.keys(multiHazardCurrentCache).includes(element.type) &&
      !isLoading.multiHazards.includes(element.type),
  )
  // Per hazard cache
  const perHazardCacheKey = useMemo(
    () =>
      JSON.stringify({
        regionMasks,
        drawAreas,
        elementFiltersKey,
      }),
    [drawAreas, elementFiltersKey, regionMasks],
  )
  const perHazardCurrentCache = riskByAssetPerHazardCache[perHazardCacheKey] ?? {}

  // Returns a list of new, needed [element, hazard, identifying_information_property] pairs that are missing from the cache
  const perHazardMissingLayerPairs = hazardLayers
    .map((hazard) => elementLayers.map((element) => [element, hazard]))
    .flat()
    .map(
      ([element, hazard]) =>
        [
          element,
          hazard,
          selectedIdentifyingInformation[element.type]?.[hazard.assetTag]?.property ?? 'default',
        ] as [MapLayer, MapLayer, string],
    )
    .filter(([element, hazard, info]: [MapLayer, MapLayer, string]) => {
      return (
        perHazardCurrentCache[element.type]?.[hazard.assetTag]?.[info] === undefined &&
        !isLoading.perHazard[element.type]?.includes(hazard.assetTag)
      )
    })

  // === Effects for updating caches
  // Multi-hazard cache
  useEffect(() => {
    if (multiHazardMissingLayers.length === 0 || hazardLayers.length <= 1) {
      return
    }
    setIsLoading((prev) => {
      return {
        ...prev,
        multiHazards: [
          ...prev.multiHazards,
          ...multiHazardMissingLayers.map((layer) => layer.type),
        ],
      }
    })

    if (abortControllers.multiHazard) {
      abortControllers.multiHazard.abort()
    }
    const abortController = new AbortController()
    setAbortControllers((prev) => {
      return {
        ...prev,
        multiHazard: abortController,
      }
    })
    fetchAssetMultiHazardExposureStats(
      multiHazardMissingLayers,
      hazardLayers,
      regionMasks ? regionMasks.map((mask) => mask.region) : [],
      drawAreas,
      abortController,
      hasElementFilters ? visibleAssets : undefined,
    )
      .then((newMultiHazards) => {
        const newMultiHazardsValues = Object.values(newMultiHazards)
        setRiskByAssetMultiHazardCache((prev) => {
          if (!prev[multiHazardCacheKey]) {
            prev[multiHazardCacheKey] = {}
          }
          for (const mhValue of newMultiHazardsValues) {
            prev[multiHazardCacheKey][mhValue.asset_type] = mhValue
          }
          // else if wasn't loaded, set to empty object
          for (const layer of multiHazardMissingLayers) {
            if (!prev[multiHazardCacheKey][layer.type]) {
              prev[multiHazardCacheKey][layer.type] = null
            }
          }
          return prev
        })
        setIsLoading((prev) => {
          return {
            ...prev,
            multiHazards: prev.multiHazards.filter(
              (asset_type) =>
                !newMultiHazardsValues.map((layer) => layer.asset_type).includes(asset_type),
            ),
          }
        })
        setAbortControllers((prev) => {
          return {
            ...prev,
            multiHazard: undefined,
          }
        })
      })
      .catch((e) => {
        if (e.code === 'ERR_CANCELED') {
          return
        }
        // eslint-disable-next-line no-console
        console.log(e)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [multiHazardMissingLayers])

  // Per-hazard cache
  useEffect(() => {
    if (perHazardMissingLayerPairs.length === 0) {
      return
    }
    // i.e. perHazard: { 'assetType': ['loadingHazardScenario', ...] }
    setIsLoading((prev) => {
      perHazardMissingLayerPairs.reduce((acc, [element, hazard]) => {
        if (!acc[element.type]) {
          acc[element.type] = []
        }
        if (!acc[element.type].includes(hazard.assetTag)) {
          acc[element.type].push(hazard.assetTag)
        }
        return acc
      }, prev.perHazard)
      return prev
    })

    if (abortControllers.perHazard) {
      abortControllers.perHazard.abort()
    }
    const abortController = new AbortController()
    setAbortControllers((prev) => {
      return {
        ...prev,
        perHazard: abortController,
      }
    })
    const fetchPromises = perHazardMissingLayerPairs.map(
      ([element, hazard, identifying_information_property]) =>
        identifying_information_property !== 'default'
          ? fetchIdentifyingInformationAssetExposureStats(
              [element],
              [hazard],
              identifying_information_property,
              regionMasks ? regionMasks.map((mask) => mask.region) : [],
              drawAreas,
              hasElementFilters ? visibleAssets : undefined,
              abortController,
            )
          : fetchAssetExposureStats(
              [element],
              [hazard],
              regionMasks ? regionMasks.map((mask) => mask.region) : [],
              drawAreas,
              hasElementFilters ? visibleAssets : undefined,
              abortController,
            ),
    )

    Promise.all(fetchPromises)
      .then((newPerHazards) => {
        const newPerHazardsValues = newPerHazards.flat()
        setRiskByAssetPerHazardCache((prev) => {
          if (!prev[perHazardCacheKey]) {
            prev[perHazardCacheKey] = {}
          }
          for (const phValue of newPerHazardsValues) {
            if (!prev[perHazardCacheKey][phValue.asset_type]) {
              prev[perHazardCacheKey][phValue.asset_type] = {}
            }
            if (!prev[perHazardCacheKey][phValue.asset_type][phValue.hazard_scenario]) {
              prev[perHazardCacheKey][phValue.asset_type][phValue.hazard_scenario] = {}
            }
            prev[perHazardCacheKey][phValue.asset_type][phValue.hazard_scenario][
              phValue.identifying_information_property ?? 'default'
            ] = phValue
          }
          // else if wasn't loaded, set to null
          for (const [
            element,
            hazard,
            identifying_information_property,
          ] of perHazardMissingLayerPairs) {
            if (
              prev[perHazardCacheKey][element.type]?.[hazard.assetTag]?.[
                identifying_information_property
              ] === undefined
            ) {
              if (!prev[perHazardCacheKey][element.type]) {
                prev[perHazardCacheKey][element.type] = {}
              }
              if (!prev[perHazardCacheKey][element.type][hazard.assetTag]) {
                prev[perHazardCacheKey][element.type][hazard.assetTag] = {}
              }
              prev[perHazardCacheKey][element.type][hazard.assetTag][
                identifying_information_property
              ] = null
            }
          }
          return prev
        })
        setIsLoading((prev) => {
          perHazardMissingLayerPairs.reduce((acc, [element, hazard]) => {
            if (acc[element.type]) {
              acc[element.type] = acc[element.type].filter((h) => h !== hazard.assetTag)
            }
            return acc
          }, prev.perHazard)
          return prev
        })
        setAbortControllers((prev) => {
          return {
            ...prev,
            perHazard: undefined,
          }
        })
      })
      .catch((e) => {
        if (e.code === 'ERR_CANCELED') {
          return
        }
        // eslint-disable-next-line no-console
        console.log(e)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [perHazardMissingLayerPairs])

  // === Effects for updating available identifying information
  useEffect(() => {
    if (elementLayers.length === 0) {
      return
    }
    const fetchPromises = potentialIdentifyingInformation.map((info) =>
      fetchCheckIfIdentifyingInformationExists(info.property, elementLayers),
    )
    Promise.all(fetchPromises).then((newAvailableIdentifyingInformation) => {
      const newAvailableIdentifyingInformationValues = newAvailableIdentifyingInformation.flat()
      const byAssetType = newAvailableIdentifyingInformationValues.reduce((acc, info) => {
        const iiObject = potentialIdentifyingInformation.find((i) => i.property === info.attribute)
        if (iiObject) {
          if (!acc[info.asset_type]) {
            acc[info.asset_type] = []
          }
          acc[info.asset_type].push(iiObject)
        }
        return acc
      }, {} as { [key: string]: IdentifyingInformation[] })
      setAvailableIdentifyingInformation(byAssetType)
    })
  }, [elementLayers])

  return (
    <RiskFetcherContext.Provider
      value={{
        riskByAssetMultiHazard: multiHazardCurrentCache,
        riskByAssetPerHazard: perHazardCurrentCache,
        isLoading,
        availableIdentifyingInformation,
        setIdentifyingInformationForAssetHazard,
        selectedIdentifyingInformation,
      }}
    >
      {children}
    </RiskFetcherContext.Provider>
  )
}

export const useRiskFetcher = () => useContext(RiskFetcherContext)
