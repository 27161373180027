import { UserState } from '@redux/user/userSlice'
import { CombinedState } from '@reduxjs/toolkit'
import { createContext, useCallback, useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { BASE_API_URL } from '../../app-constants'
import axios from '@src/utils/customAxios'

export const Features = ['strategyPage', 'summaryPage', 'sideDrawerReport'] as const
export type Feature = (typeof Features)[number]

export interface FeatureFlag {
  feature: string
  enabled: boolean
}

export interface FeatureFlagsProviderProps {
  children: React.ReactNode
}

export interface FeatureFlagsContext {
  features: FeatureFlag[]
}

export const FeatureFlags = createContext({} as FeatureFlagsContext)

export const FeatureFlagsProvider = ({ children }: FeatureFlagsProviderProps) => {
  const [isLoading, setIsLoading] = useState(true)
  const [features, setFeatures] = useState<FeatureFlag[]>([])

  const { clientName } = useSelector(
    (
      state: CombinedState<{
        user: UserState
      }>,
    ) => state.user,
  )
  const apiGetClientFeatureFlags = useCallback(async () => {
    try {
      const featureFlagsResponse = await axios.get<{ feature_flags: FeatureFlag[] }>(
        `${BASE_API_URL}/api/client/feature_flags`,
      )

      return featureFlagsResponse.data.feature_flags
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(`error: `, error)
      return null
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientName])

  useEffect(() => {
    ;(async () => {
      try {
        if (!clientName) return
        const data = await apiGetClientFeatureFlags()
        if (!data) return

        setFeatures(data)
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err)
      } finally {
        setIsLoading(false)
      }
    })()
  }, [apiGetClientFeatureFlags, clientName])

  // TODO: Add loading state
  return (
    <FeatureFlags.Provider value={{ features }}>
      {isLoading ? 'Loading...' : children}
    </FeatureFlags.Provider>
  )
}

export const useFeatureFlags = () => useContext(FeatureFlags)
