/** @jsxImportSource @emotion/react */

import LanguageIcon from '@mui/icons-material/Language'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import { Box, Card, CardContent, CardMedia, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { Button } from '@src/components/Atoms/Button'

import {
  content,
  footer,
  footerText,
  ShowOnMobile,
  MobileBodyText,
  MobileHeaderText,
  SummaryPageContainer,
  SummaryPageLeft,
  SummaryPageLeftInnerContainer,
  SummaryPageRight,
  logo,
  mainText,
  marketingButton,
  secondaryTextText,
  secondaryTextPrimary,
  socialLinks,
} from './SummaryPagePlaceHolder.styles'
import { Tooltip } from '@src/components/Atoms/Tooltip/Tooltip'

export const SummaryPagePlaceHolder = () => {
  const theme = useTheme()

  return (
    <Box css={SummaryPageContainer({ theme })}>
      <Box css={SummaryPageRight({ theme })}>
        <Card sx={{ width: '40vw' }}>
          <CardMedia
            sx={{ height: '35vh' }}
            image="./images/pictures/summary-marketing.png"
            title="Example of summary analytics screen"
          />
          <CardContent>
            <Typography variant="h2" component="div" css={secondaryTextPrimary({ theme })}>
              Summary
            </Typography>
            <Typography variant="body1">
              Understand and monitor risk and adaptation strategies for your business, district,
              region/state, or country. This module summarises the risk information, is regularly
              updated with forecasts of change, and is a hub of useful information.
            </Typography>
          </CardContent>
        </Card>
      </Box>
      <Box css={SummaryPageLeft({ theme })}>
        <Box component="header">
          <a href="https://resilience-explorer.org/" target="_blank" rel="noreferrer">
            <Box component="img" src="logos/RexLogoFullWhite.svg" css={logo} />
          </a>
        </Box>
        <Box component="main" css={content}>
          <Box css={ShowOnMobile}>
            <Box component="main" css={SummaryPageLeftInnerContainer}>
              <Typography variant="h2" css={MobileHeaderText({ theme })}>
                Summary
              </Typography>
              <Typography variant="body1" css={MobileBodyText({ theme })}>
                Understand and monitor risk and adaptation strategies for your business, district,
                region/state, or country. This module summarises the risk information, is regularly
                updated with forecasts of change, and is a hub of useful information.
              </Typography>
            </Box>
          </Box>
          <Typography variant="h2" css={mainText({ theme })}>
            Contact us to activate
          </Typography>
          <a
            href="https://resilience-explorer.org/contact-resilience-explorer/"
            target="_blank"
            rel="noreferrer"
          >
            <Button variant="outlined" color="secondary" css={marketingButton({ theme })}>
              Get in touch
            </Button>
          </a>
          <Typography variant="h6" css={secondaryTextText({ theme })}>
            Powered by
            <a href="https://urbanintelligence.co.nz" target="_blank" rel="noreferrer">
              Urban Intelligence
            </a>
          </Typography>
        </Box>

        <Box component="footer" css={footer({ theme })}>
          <Typography variant="h6" css={footerText({ theme })}>
            Find us at
          </Typography>
          <Box css={socialLinks({ theme })}>
            <Tooltip title="LinkedIn">
              <a
                href="https://www.linkedin.com/company/urban-intelligence-nz/"
                target="_blank"
                rel="noreferrer"
              >
                <LinkedInIcon />
              </a>
            </Tooltip>
            <Tooltip title="Website">
              <a href="https://resilience-explorer.org/" target="_blank" rel="noreferrer">
                <LanguageIcon />
              </a>
            </Tooltip>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
