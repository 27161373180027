import { Icon } from '@src/components/Atoms/Icon'
import { theme } from '@uintel/ui-component-library'
import Fuse from 'fuse.js'

import { getWorkbookData } from '../../../utils/workbook.utils'
import { ActionPlanWorkbookData } from './ActionPlan'

const WORKBOOK_NAME = 'action_plan'

export const fuseSearchOptions: Fuse.IFuseOptions<ActionPlanWorkbookData> = {
  threshold: 0.15,
  location: 0,
  distance: 100,
  includeMatches: true,
  includeScore: true,
  useExtendedSearch: true,

  ignoreFieldNorm: true,
  ignoreLocation: true,

  // TODO: make this data-driven
  keys: ['model_alignment', 'sector', 'action', 'year', 'kpi', 'target', 'capex', 'lead'],
}

export async function getStrategyData() {
  return (await getWorkbookData(WORKBOOK_NAME)) as ActionPlanWorkbookData[]
}

export function getDivisionIcon({
  division,
  divisionFilters = [],
}: {
  division: string
  divisionFilters?: string[]
}) {
  switch (division) {
    case 'Horticulture':
      return (
        <Icon
          iconName="Horticulture"
          colour={
            !divisionFilters.length
              ? theme.palette.grey[500]
              : divisionFilters.includes('Horticulture')
              ? theme.palette.primary.dark
              : theme.palette.grey[500]
          }
          size="medium"
        />
      )
    case 'Proteins':
      return (
        <Icon
          iconName="Proteins"
          colour={
            !divisionFilters.length
              ? theme.palette.grey[500]
              : divisionFilters.includes('Proteins')
              ? theme.palette.primary.dark
              : theme.palette.grey[500]
          }
          size="medium"
        />
      )
    case 'Corporation':
      return (
        <Icon
          iconName="Corporation"
          colour={
            !divisionFilters.length
              ? theme.palette.grey[500]
              : divisionFilters.includes('Corporation')
              ? theme.palette.primary.dark
              : theme.palette.grey[500]
          }
          size="medium"
        />
      )
    case 'Logistics':
      return (
        <Icon
          iconName="Logistics"
          colour={
            !divisionFilters.length
              ? theme.palette.grey[500]
              : divisionFilters.includes('Logistics')
              ? theme.palette.primary.dark
              : theme.palette.grey[500]
          }
          size="medium"
        />
      )

    default:
      return (
        <Icon
          iconName="Help"
          colour={
            divisionFilters.includes(division)
              ? theme.palette.primary.dark
              : theme.palette.grey[500]
          }
          size="medium"
        />
      )
  }
}

export function getAvailableDivisions(data: ActionPlanWorkbookData[]) {
  const divisionsFromWorkbook = new Set<string>()

  if (!data.length) return
  for (const row in data) {
    divisionsFromWorkbook.add(data[row].division as string)
  }

  return divisionsFromWorkbook
}

export function hydrateData(workbookData: ActionPlanWorkbookData[]) {
  return workbookData.map((row) => {
    return {
      division: row.division ?? '-',
      model_alignment: row.model_alignment ?? '-',
      sector: row.sector ?? '-',
      action: row.action ?? '-',
      goal: row.goal ?? '-',
      initiative_id: row.initiative_id ?? '-',
      initiative_description: row.initiative_description ?? '-',
      year: row.year ? row.year : 0,
      kpi: row.kpi ?? '-',
      target: row.target ?? '-',
      status: row.status ?? 0,
      capex: row.capex,
      priority: row.priority ?? 0,
      lead: row.lead ?? '-',
      more_info: row.more_info ?? '-',
    }
  })
}

export function filterDataByDivision({
  data,
  filters,
}: {
  data: ActionPlanWorkbookData[]
  filters: string[]
}) {
  return data.filter((item) => filters.includes(item.division))
}
