import { css, keyframes } from '@emotion/react'
import { Theme, darken, lighten } from '@mui/material'

const fadein = keyframes`
  from, 70% { opacity: 0.3; }
  to   { opacity: 1; }
`
export const autocompleteContainer = ({ theme }: { theme: Theme }) => css`
  position: relative;
  overflow: hidden;
  .MuiAutocomplete-tag {
    color: ${theme.palette.primary.dark};
  }

  label.MuiFormLabel-root {
    top: -2px;
  }

  .MuiAutocomplete-inputRoot {
    flex-wrap: nowrap;
  }

  .MuiInputBase-root {
    padding: 4px !important;

    .MuiChip-root {
      border-radius: 0.2rem;
      background-color: ${lighten(theme.palette.primary.main, 0.85)};
      color: ${theme.palette.primary.dark};
    }

    .MuiAutocomplete-input {
      min-width: 10px;
      height: 28px;
      transition: min-width 0.2s ease-in-out;

      ::placeholder {
        color: ${theme.palette.grey[700]};
        opacity: 1;
      }
    }
    .MuiAutocomplete-input:focus {
      min-width: 70px !important;
    }

    :hover .MuiAutocomplete-input {
      min-width: 70px !important;
    }

    .MuiAutocomplete-clearIndicator {
      background-color: #fbfbfb;
    }

    .MuiChip-deleteIcon {
      width: 0;
      transition: width 0.2s ease-in-out, color 0.1s ease-in-out;
      color: ${theme.palette.primary.main};

      :hover {
        color: ${darken(theme.palette.primary.main, 0.2)};
      }
    }
    :hover .MuiChip-deleteIcon {
      width: 16px;
    }

    .MuiOutlinedInput-notchedOutline {
      border: none;
    }
  }
`

export const groupHeader = ({ theme }: { theme: Theme }) => css`
  position: sticky;
  top: -8px;
  padding: 0.1rem 0.5rem;
  color: ${theme.palette.primary.contrastText};
  background-color: ${theme.palette.primary.main};
  font-size: 0.7rem;
  font-weight: 600;
  margin-top: -8px;
  z-index: 8;
`
export const groupItems = ({ theme }: { theme: Theme }) => css`
  padding: 0;
  margin-bottom: 1rem;

  .MuiAutocomplete-option {
    animation: ${fadein} 0.2s ease-in-out;
    transition: background-color 0.05s ease-in-out, color 0.05s ease-in-out;
  }
  .MuiAutocomplete-option[aria-selected='true'] {
    background-color: ${lighten(theme.palette.primary.main, 0.85)};
    color: ${theme.palette.primary.dark};
  }
  .MuiAutocomplete-option[aria-selected='true']:hover {
    background-color: ${lighten(theme.palette.primary.main, 0.75)};
  }
`
