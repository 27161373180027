/** @jsxImportSource @emotion/react */

import {
  setSideDrawerInfoLayerFocus,
  setSideDrawerIsOpen,
  setSideDrawerTab,
} from '@redux/sideDrawer/sideDrawerSlice'
import { useDispatch, useSelector } from 'react-redux'
import sidebarText from '../TutorialContent/Sidebar'
import mapToolsText from '../TutorialContent/MapToolsText'
import { RegionOption } from '@src/components/Molecules/RegionFilter'
import { useTutorialContext } from '@contexts/TutorialContext'
import { TutorialMenu } from '../TutorialMenu/TutorialMenu'
import { TutorialStepData } from '../TutorialRunner/index.js'
import { useMap } from '@contexts/MapContext/index.js'
import mapBasics from '../TutorialContent/MapBasics'
import exploringLayers from '../TutorialContent/ExploringLayers'
import { useMemo } from 'react'
import { MapLayerType } from '@redux/map/mapSlice'
import { useLayerDataFetcher } from '@src/components/Molecules/SideDrawerContent/data_fetchers/layerDataFetcher'
import { useLayers } from '@contexts/LayerContext'
import { Layers, Map } from '@mui/icons-material'
import { Icon } from '@src/components/Atoms/Icon'
import { useFeatureFlags } from '@contexts/FeatureFlagsContext'
import { RootState } from '@redux/store'
import { usePreferences } from '@contexts/PreferencesContext'

export const RiskPageTutorial = () => {
  const dispatch = useDispatch()

  // Fetch Tutorial Objects Here (make sure to type them well!)
  const { setOpenAddLayersModal, tutorialItems, setOpenMapToolbar } = useTutorialContext()
  const { term_preference } = usePreferences()
  const { methods_report_url } = useSelector((state: RootState) => state.user)
  const regions = tutorialItems['regions'] as RegionOption[] | null
  const { setShowAddLayersModalTab, flyTo, clientLocation } = useMap()
  const { prepareAndUpdateLayers } = useLayers()
  const layerData = useLayerDataFetcher()
  const { features } = useFeatureFlags()
  const reportPrinterEnabled = features.find(
    (feature) => feature.feature == 'sideDrawerReport' && feature.enabled,
  )

  const exampleLayers = useMemo(() => {
    if (!layerData) return []
    return [
      {
        ...Object.values(layerData.assetDialogData)[0].groups[0].assets[0],
        layerType: 'asset' as MapLayerType,
        id: 'example-asset-layer',
        visible: true,
        interactivityDisabled: false,
      },
      {
        ...layerData.hazardDialogData.hazardDetails[0].scenarios[0],
        layerType: 'hazard' as MapLayerType,
        id: 'example-hazard-layer',
        visible: true,
        interactivityDisabled: false,
      },
    ]
  }, [layerData])

  // Define Tutorial Steps Here
  // Make sure to define onBacks that undo the actions of the onNexts
  const mapBasicsSteps: TutorialStepData[] = [
    {
      title: 'Interactive Navigation Controls: Move Around',
      intro: mapBasics.MOVEAROUND,
      onStart: () => {
        if (clientLocation) flyTo(clientLocation)
      },
      element: '.tutorial-map',
    },
    {
      element: '.mapboxgl-ctrl-group',
      title: 'Interactive Navigation Controls: Zoom',
      intro: mapBasics.ZOOM,
    },
    {
      title: 'Navigating Basemaps',
      element: '.tutorial-basemap',
      intro: mapBasics.BASEMAPS,
    },
    {
      title: 'Exploring in 3D: Rotation and Perspective',
      intro: mapBasics.ROTATIONANDPERSPECTIVE,
      element: '.tutorial-map',
    },
    {
      element: '.mapboxgl-ctrl-compass',
      title: 'Reorienting with the Compass',
      intro: mapBasics.COMPASS,
    },
  ]
  //prepareAndUpdateLayers({ layers: allLayers })

  const exploringLayersContent = exploringLayers(term_preference.hazard)
  const exploringLayersSteps: TutorialStepData[] = [
    {
      title: 'Understanding Layers',
      element: '.tutorial-add-layers-button',
      intro: exploringLayersContent.UNDERSTANDINGLAYERS,
      onStart: () => {
        setOpenAddLayersModal(false)
      },
      onNext: () => {
        prepareAndUpdateLayers({ layers: [] })
        setOpenAddLayersModal(true)
      },
    },
    {
      title: 'Adding Layers',
      element: '#add-layers-dialog',
      intro: exploringLayersContent.ADDLAYERS,
      onBack: () => {
        setOpenAddLayersModal(false)
      },
    },
    {
      title: 'Adding Elements',
      intro: exploringLayersContent.ADDELEMENTS,
      element: '.tutorial-addlayers-panel',
      onNext: () => {
        setShowAddLayersModalTab('hazard')
      },
    },
    {
      title: 'Adding Risk Source Scenarios',
      element: '.tutorial-addlayers-panel',
      intro: exploringLayersContent.ADDHAZARDS,
      onBack: () => {
        setShowAddLayersModalTab('element')
      },
      onNext: () => {
        setShowAddLayersModalTab('contextual')
      },
    },
    {
      title: 'Adding Contextual Layers',
      element: '.tutorial-addlayers-panel',
      intro: exploringLayersContent.ADDINFORMATION,
      onBack: () => {
        setShowAddLayersModalTab('hazard')
      },
      onNext: () => {
        if (clientLocation) flyTo(clientLocation)
        setOpenAddLayersModal(false)
        prepareAndUpdateLayers({ layers: exampleLayers })
      },
    },
    {
      title: 'Hover Information on Elements',
      intro: exploringLayersContent.HOVERINFORMATION,
      element: '.tutorial-map',
      onBack: () => {
        setOpenAddLayersModal(true)
        prepareAndUpdateLayers({ layers: [] })
        setTimeout(() => setShowAddLayersModalTab('contextual'), 500)
      },
      onNext: () => {
        dispatch(setSideDrawerInfoLayerFocus({ layerFocus: 'layers', isOpen: true }))
      },
      onNextDelay: 500,
    },
    {
      title: 'Managing Layers',
      element: '.tutorial-layer-organizer',
      intro: exploringLayersContent.LAYERSTAB,
    },
    {
      title: 'Reordering Layers',
      element: '.tutorial-layer-organizer',
      intro: exploringLayersContent.REORDERINGLAYERS,
    },
    {
      title: 'Manipulating Layers',
      element: '.tutorial-layer-organizer',
      intro: exploringLayersContent.MANIPULATINGLAYERS,
    },
    {
      title: 'Manipulating Layers: Accessing Additional Information',
      element: '.tutorial-layer-organizer',
      intro: exploringLayersContent.MANIPULATINGLAYERS_INFORMATION,
    },
  ]

  const sidebarContent = sidebarText(term_preference.hazard)
  const sidebarSteps: TutorialStepData[] = [
    {
      title: 'Opening the Sidebar',
      element: '.tutorial-sidedrawer-tab',
      intro: sidebarContent.IDENTIFYELEMENTS,
      onNext: () => {
        dispatch(setSideDrawerTab('Risk'))
        prepareAndUpdateLayers({ layers: exampleLayers })
        dispatch(setSideDrawerInfoLayerFocus({ layerFocus: 'layers', isOpen: true }))
      },
      onNextDelay: 500,
    },
    {
      title: 'The Layers Tab - Map Layers',
      element: '.tutorial-maplayers',
      intro: sidebarContent.LAYERSTAB,
      onBack: () => {
        dispatch(setSideDrawerIsOpen(false))
        prepareAndUpdateLayers({ layers: [] })
      },
      onBackDelay: 500,
    },
    {
      title: 'The Layers Tab - Scenario Controls',
      element: '.tutorial-sidedrawer',
      intro: sidebarContent.SCENARIOCONTROLS,
      onNext: () => {
        dispatch(setSideDrawerInfoLayerFocus({ layerFocus: 'risk', isOpen: true }))
      },
    },
    {
      title: 'The Risk Summary Tab',
      element: '.tutorial-sidedrawer',
      intro: sidebarContent.RISKSUMMARYTAB,
      onBack: () => {
        dispatch(setSideDrawerInfoLayerFocus({ layerFocus: 'layers', isOpen: true }))
      },
      onNext: () => {
        // scroll to the .tutorial-risk-footer
        document.querySelector('.tutorial-risk-footer')?.scrollIntoView()
      },
      onNextDelay: 500,
    },
    {
      title: 'Risk Summary - Footer',
      element: '.tutorial-risk-footer',
      intro: sidebarContent.RISKSUMMARYFOOTER,
    },
    {
      title: 'Risk Summary - Methodology Report',
      element: '.tutorial-methodology-button',
      intro: sidebarContent.RISKSUMMARYMETHODOLOGYREPORT,
      condition: () => !!methods_report_url,
      onNext: () => {
        if (reportPrinterEnabled?.enabled) return
        dispatch(setSideDrawerInfoLayerFocus({ layerFocus: 'selection', isOpen: true }))
      },
    },
    {
      title: 'Risk Summary - Download Report',
      element: '.tutorial-summary-report-button',
      intro: sidebarContent.RISKSUMMARYREPORT,
      condition: () => !!reportPrinterEnabled?.enabled,
      onNext: () => {
        dispatch(setSideDrawerInfoLayerFocus({ layerFocus: 'selection', isOpen: true }))
      },
    },
    {
      title: 'The Selected Tab',
      element: '.tutorial-sidedrawer',
      intro: sidebarContent.SELECTEDTAB,
      onBack: () => {
        dispatch(setSideDrawerInfoLayerFocus({ layerFocus: 'risk', isOpen: true }))
      },
    },
    {
      title: 'The Selected Tab - Continued',
      element: '.tutorial-sidedrawer',
      intro: sidebarContent.SELECTEDTAB2,
    },
  ]

  const mapToolsSteps: TutorialStepData[] = [
    {
      title: 'Searching for an Address',
      intro: mapToolsText.SEARCHADDRESS,
      element: '.tutorial-geocoder',
      onStart: () => {
        setOpenMapToolbar(false)
      },
      onNext: () => {
        setOpenMapToolbar(true)
      },
    },
    {
      title: 'Map Tools',
      intro: mapToolsText.MAPTOOLS,
      element: '.tutorial-maptools',
    },
    {
      title: 'Apply Spatial Filters',
      intro: mapToolsText.APPLYREGIONFILTERS,
      element: '.tutorial-region-filter',
      condition: () => {
        return !!regions && regions.length > 1
      },
    },
    {
      title: 'Create Custom Area Filters',
      intro: mapToolsText.CREATECUSTOMFILTERS,
      element: '.tutorial-polygon-draw',
    },
    {
      title: 'Saving to PDF Format',
      intro: mapToolsText.SAVEMAPVIEW,
      element: '.tutorial-save-map',
    },
    {
      title: 'Copying the Map to PNG Format',
      intro: mapToolsText.COPYMAPVIEW,
      element: '.tutorial-copy-map',
    },
    {
      title: 'Measuring Distances and Areas',
      intro: mapToolsText.MEASURETOOL,
      element: '.tutorial-measure-tool',
    },
    {
      title: 'Full Screen Mode',
      intro: mapToolsText.FULLSCREENTOOL,
      element: '.tutorial-fullscreen-map',
    },
  ]

  return (
    <TutorialMenu
      tutorialModules={{
        'Map Basics': {
          steps: mapBasicsSteps,
          icon: <Map />,
        },
        'Add & Explore Layers': {
          steps: exploringLayersSteps,
          icon: <Layers />,
        },
        'Navigating the Sidebar': {
          steps: sidebarSteps,
          icon: <Icon iconName="s3://map-info.svg" />,
        },
        'Map Tools': {
          steps: mapToolsSteps,
          icon: <Icon iconName="Spanner" />,
        },
      }}
      title="The Risk Tutorial"
      welcomeText="Welcome to the Risk Module Tutorial in The Resilience Explorer."
      congratulationsText={
        <>
          You&apos;ve completed a tutorial for the Risk Module. We hope you found it helpful in
          mastering key functionalities of The Resilience Explorer.
          <br />
          <br />
          <b>Next Steps:</b>
          <ol>
            <li>
              <b>Try it yourself:</b> Dive deeper into other features with your own data to unlock
              insights.
            </li>
            <li>
              <b>Feedback:</b> We&apos;d love to hear your thoughts! Share your feedback to help us
              improve.
            </li>
          </ol>
          <br />
          Thank you for using The Resilience Explorer. Here&apos;s to a more resilient future!
        </>
      }
    ></TutorialMenu>
  )
}
