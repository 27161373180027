export const RoadIcon = ({ ...rest }) => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M27.5003 12.8774C27.1229 12.8774 14.1355 21.9969 8.63232 27.0283"
        stroke="currentColor"
        strokeWidth="1.63504"
        strokeLinecap="round"
      />
      <path
        d="M24.198 7.68867C23.8207 7.68867 10.8332 16.8082 5.33008 21.8396"
        stroke="currentColor"
        strokeWidth="1.63504"
        strokeLinecap="round"
        strokeDasharray="3.27 3.27"
      />
      <path
        d="M21.3679 2.97169C20.9906 2.97169 8.00315 12.0912 2.5 17.1226"
        stroke="currentColor"
        strokeWidth="1.63504"
        strokeLinecap="round"
      />
    </svg>
  )
}

export default RoadIcon
