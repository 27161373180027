export const SchoolIcon = ({ ...rest }) => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M21.5002 20.8762L15.0002 24.5325L8.50021 20.8762V17.0133L6.64307 15.9815V21.9623L15.0002 26.6632L23.3574 21.9623V15.9815L21.5002 17.0133V20.8762Z"
        fill="currentColor"
      />
      <path
        d="M15 3.19473L2 9.93547V11.545L15 18.7671L26.1429 12.5768V17.705H28V9.93547L15 3.19473ZM24.2857 11.484L22.4286 12.5157L15 16.6429L7.57143 12.5157L5.71429 11.484L4.42711 10.7689L15 5.28669L25.5729 10.7689L24.2857 11.484Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SchoolIcon
