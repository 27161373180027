export const LifeLineIcon = ({ ...rest }) => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M25.1756 5.9345C24.562 5.32089 23.8335 4.83422 23.0317 4.50228C22.23 4.17035 21.3706 3.99967 20.5028 4C19.6351 4.00033 18.7759 4.17165 17.9743 4.50418C17.1728 4.83672 16.4447 5.32394 15.8315 5.93801L15.0312 6.74813L14.2374 5.93976L14.2323 5.9346C13.619 5.32128 12.8909 4.83477 12.0895 4.50284C11.2882 4.17092 10.4293 4.00008 9.56196 4.00008C8.6946 4.00008 7.83573 4.17092 7.0344 4.50284C6.23306 4.83477 5.50495 5.32128 4.89164 5.9346L4.53508 6.29116C3.29644 7.5298 2.60059 9.20975 2.60059 10.9615C2.60059 12.7132 3.29644 14.3931 4.53508 15.6318L13.9455 25.0421L15.0082 26.1555L15.0336 26.1301L15.0611 26.1576L16.0568 25.1071L25.5322 15.6316C26.769 14.392 27.4635 12.7124 27.4635 10.9613C27.4635 9.21021 26.769 7.53063 25.5322 6.291L25.1756 5.9345ZM24.3644 14.464L15.0336 23.795L5.70258 14.464C4.77361 13.5351 4.25172 12.2751 4.25172 10.9613C4.25172 9.64755 4.77361 8.38759 5.70258 7.45861L6.05919 7.10205C6.98772 6.17353 8.24692 5.65168 9.56005 5.65119C10.8732 5.65069 12.1328 6.1716 13.062 7.09942L15.0276 9.10083L17.0025 7.10205C17.4625 6.64206 18.0086 6.27718 18.6096 6.02824C19.2106 5.77929 19.8548 5.65116 20.5053 5.65116C21.1558 5.65116 21.7999 5.77929 22.401 6.02824C23.002 6.27718 23.548 6.64206 24.008 7.10205L24.3646 7.45856C25.2922 8.3883 25.8131 9.64802 25.813 10.9613C25.813 12.2747 25.292 13.5343 24.3644 14.464Z"
        fill="currentColor"
      />
      <path
        d="M2 15.6891H10.1173L11.8344 10.0116L15.4247 20.2312L19.015 11.9041L20.42 15.6891H28"
        stroke="currentColor"
        strokeWidth="1.60864"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default LifeLineIcon
