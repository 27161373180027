/** @jsxImportSource @emotion/react */
import { Box, Typography, useTheme } from '@mui/material'
import { ReactElement, useEffect, useRef } from 'react'
import {
  accordionBody,
  accordionHeader,
  accordionHeaderIcon,
  accordionStyle,
} from './Accordion.styles'
import { UiclAccordion } from './UiclAccordion'

export interface AccordionProps {
  title?: string
  body?: ReactElement
  accordionStateKey?: string
  defaultExpanded?: boolean
  defaultExpandedOnce?: boolean
  icon?: ReactElement
  variant?: 'filled' | 'clear' | 'outline' | 'panel'
  level?: 'h1' | 'h2' | 'h3'
  animationDelay?: number
  priority?: number
  isDraggable?: boolean
  header?: ReactElement
  hasTextContent?: boolean
  backgroundColor?: string
  canCollapse?: boolean
  lazyContent?: boolean
}

export const Accordion = ({
  defaultExpanded = false,
  defaultExpandedOnce = false,
  title,
  accordionStateKey,
  body,
  variant,
  icon,
  level = 'h1',
  hasTextContent = false, // Sets background of content to white if true
  backgroundColor,
  header,
  canCollapse = true, // locks it open if false
  isDraggable,
  priority = 0,
  animationDelay,
  lazyContent = false,
}: AccordionProps) => {
  const theme = useTheme()
  const headerElement = (
    <Box css={accordionHeader({ theme, variant })}>
      {header ? (
        header
      ) : (
        <>
          {icon && <Box css={accordionHeaderIcon}>{icon}</Box>}
          <Typography fontWeight={700}>{title}</Typography>
        </>
      )}
    </Box>
  )
  const isFirstRender = useRef(true)
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false
    }
  }, [])

  return (
    <UiclAccordion
      css={accordionStyle({
        theme,
        variant,
        level,
        animationDelay,
        priority,
        hasTextContent,
        isFirstRender: isFirstRender.current,
        canCollapse,
        backgroundColor,
        hasBody: body !== undefined,
      })}
      accordionStateKey={accordionStateKey}
      defaultExpanded={defaultExpanded || !canCollapse}
      defaultExpandedOnce={defaultExpandedOnce}
      header={headerElement}
      disableExpand={!body || !canCollapse}
      isDraggable={isDraggable}
      body={<Box css={accordionBody({ theme })}>{body}</Box>}
      lazyContent={lazyContent}
    />
  )
}
