/** @jsxImportSource @emotion/react */

import { useRef, useEffect, useState, useCallback } from 'react'
import * as d3 from 'd3'
import { ChartTooltip, ChartTooltipItem } from '../ChartTooltip'

export type DataPoint = {
  x_parameter_value: number
  exposure_value: number
}

export const MakeProjectedExposureChartSvg = ({
  data,
  svgElement,
  width = 900,
  parameter,
  parameterTitle,
  updateTooltip,
  x_unit,
  y_unit,
}: {
  data: DataPoint[] | null
  svgElement: HTMLDivElement | null
  width: number
  parameter: string
  parameterTitle: string
  updateTooltip?: (args: { show: boolean; data: DataPoint }) => void
  x_unit: string | null
  y_unit: string | null
}) => {
  if (!data || data.length === 0) return
  // strong margin right because text gets cut off
  const margin = { top: 12, right: 80, bottom: 40, left: 52 }
  const height = Math.max(width / 3, 200)
  const innerWidth = width - margin.left - margin.right
  const innerHeight = height - margin.top - margin.bottom

  const shouldReverse = parameter.includes('aep')
  const useLogScale = parameter.includes('aep') || parameter.includes('ari')

  d3.select(svgElement).selectAll('svg').remove()
  const svg = d3
    .select(svgElement)
    .append('svg')
    .attr('version', '1.1')
    .attr('xmlns', 'http://www.w3.org/2000/svg')
    .attr('width', width)
    .attr('height', height)
    .attr('font-family', 'Inter')
    .attr('font-size', '8pt')
    .attr('viewBox', `0 0 ${width} ${height}`)
    .attr('color', '#8392a1')
    .append('g')
    .attr('transform', `translate(${margin.left},${margin.top})`)

  const maxXValue = d3.max(data, (d) => +d.x_parameter_value) as number
  const minXValue = d3.min(data, (d) => +d.x_parameter_value) as number

  let x = null
  if (useLogScale) {
    x = d3
      .scaleLog()
      .domain([minXValue, maxXValue])
      .range(shouldReverse ? [innerWidth, 0] : [0, innerWidth])
  } else {
    x = d3
      .scaleLinear()
      .domain([minXValue, maxXValue])
      .range(shouldReverse ? [innerWidth, 0] : [0, innerWidth])
  }

  const maxYValue = d3.max(data, (d) => +d.exposure_value) as number
  const minYValue = d3.min(data, (d) => +d.exposure_value) as number

  const y = d3
    .scaleLinear()
    .domain([minYValue < 0 ? minYValue : 0, maxYValue].reverse())
    .nice(8)
    .range([0, innerHeight])

  svg
    .append('g')
    .attr('transform', `translate(${0},${innerHeight})`)
    // will need to match ticks to parameter's values
    .call(
      d3
        .axisBottom(x)
        .tickSizeOuter(6)
        .tickValues(data.map((d) => d.x_parameter_value))
        .tickFormat((d) => {
          if (Number.isInteger(d)) {
            return `${d.toString()}${x_unit == '%' ? '%' : ''}`
          } else if (d) {
            return `${d.toLocaleString(undefined, {
              minimumFractionDigits: 1,
              maximumFractionDigits: 1,
            })}${x_unit == '%' ? '%' : ''}`
          } else {
            return `${d}${x_unit == '%' ? '%' : ''}`
          }
        })
        .tickPadding(8),
    )
    .append('text') // x label
    .attr('fill', '#8392a1')
    .attr('text-anchor', 'middle')
    .attr('x', innerWidth / 2)
    .attr('y', margin.bottom - 4)
    .text(`${parameterTitle}${x_unit == '%' ? '' : ` (${x_unit})`}`)
    .attr('font-family', 'Inter')

  svg
    .append('g')
    .call(
      d3
        .axisLeft(y)
        .tickSizeOuter(0)
        .tickSize(0)
        .ticks(Math.min(8, y.ticks().length))
        .tickFormat((t) => {
          return `${t}`
        }),
    )
    .append('text') // y label
    .attr('fill', '#8392a1')
    .attr('font-family', 'Inter')
    .attr('text-anchor', 'middle')
    .attr('font-size', '10.67px')
    .attr('transform', 'rotate(-90)')
    .attr('y', -margin.left + 10)
    .attr('x', -innerHeight / 2)
    .text(`Exposure${y_unit ? ' (' + y_unit + ')' : ''}`)

  svg.selectAll('.domain').attr('stroke', '#53687e').attr('stroke-width', 0)

  // Append lines for y-axis ticks
  y.ticks().forEach((tick) => {
    svg
      .append('line')
      .attr('x1', 0)
      .attr('x2', innerWidth)
      .attr('y1', y(tick))
      .attr('y2', y(tick))
      .attr('stroke', 'lightgrey')
      .attr('stroke-line')
  })
  if (data.length === 1) {
    svg
      .append('circle')
      .attr('cx', x(data[0].x_parameter_value as number))
      .attr('cy', y(Number(data[0].exposure_value) || 0))
      .attr('r', 3)
      .attr('fill', '#000')
  } else if (data.length > 1) {
    svg
      .append('path')
      .datum(data)
      .attr('fill', 'none')
      .attr('stroke', '#000')
      .attr('stroke-width', 1.5)
      .attr(
        'd',
        d3
          .line<DataPoint>()
          .x((d) => x(d.x_parameter_value))
          .y((d) => y(d.exposure_value)),
      )

    svg
      .selectAll('circle')
      .data(data)
      .enter()
      .append('circle')
      .attr('cx', (d) => x(d.x_parameter_value))
      .attr('cy', (d) => y(d.exposure_value))
      .attr('r', 2)
      .attr('stroke', '#000')
      .attr('stroke-width', 1.5)
      .attr('fill', 'white')
  }

  // Append invisible but hoverable vertical lines
  if (updateTooltip)
    svg
      .selectAll('.vertical-line')
      .data(data)
      .enter()
      .append('line')
      .attr('class', 'vertical-line')
      .attr('x1', (d) => x(d.x_parameter_value))
      .attr('x2', (d) => x(d.x_parameter_value))
      .attr('y1', 0)
      .attr('y2', innerHeight)
      .attr('stroke', '#00000000')
      .attr('stroke-width', 40)
      .on('mouseleave', (_, d) => {
        updateTooltip({ show: false, data: d })
      })
      .on('mouseenter', (_, d) => {
        updateTooltip({ show: true, data: d })
      })
}

export const ProjectedExposureChart = ({
  data,
  parameter,
  parameterTitle,
  x_unit,
  y_unit,
}: {
  data: DataPoint[]
  parameter: string
  parameterTitle: string
  x_unit: string | null
  y_unit: string | null
}) => {
  const svgRef = useRef<HTMLDivElement | null>(null)
  const [width, setWidth] = useState<number | null>(null)
  const [showTooltip, setShowTooltip] = useState(false)
  const [tooltipHeader, setTooltipHeader] = useState('')
  const [tooltipItems, setTooltipItems] = useState<ChartTooltipItem[]>([])

  useEffect(() => {
    if (!svgRef.current) return
    const resizeObserver = new ResizeObserver((event) => {
      // Round width to nearest 50
      const newWidth = Math.round(event[0].contentBoxSize[0].inlineSize / 50) * 50
      setWidth(newWidth)
    })

    resizeObserver.observe(svgRef.current)
  }, [])

  const updateTooltip = useCallback(
    ({ show, data }: { show: boolean; data: DataPoint }) => {
      setShowTooltip(show)
      setTooltipHeader(`${data.x_parameter_value}${x_unit} ${parameterTitle}`)
      setTooltipItems([{ color: '#000', text: `Exposure: ${data.exposure_value} (${y_unit})` }])
    },
    [parameterTitle, x_unit, y_unit],
  )

  useEffect(() => {
    if (!width) return
    MakeProjectedExposureChartSvg({
      data,
      svgElement: svgRef.current,
      width,
      parameter,
      parameterTitle,
      updateTooltip,
      x_unit,
      y_unit,
    })
  }, [data, svgRef, width, parameter, parameterTitle, updateTooltip, x_unit, y_unit])

  return (
    <ChartTooltip header={tooltipHeader} show={showTooltip} items={tooltipItems}>
      <div ref={svgRef}></div>
    </ChartTooltip>
  )
}
