import { LegendData } from '../../Legend'

export const MAX_CHART_DECIMALS = 1
export const round = (val: number, decimals = MAX_CHART_DECIMALS) => {
  return Math.round(val * 10 ** decimals) / 10 ** decimals
}

export const roundForDisplay = (val: number, decimals = MAX_CHART_DECIMALS) => {
  const roundedValue = cleanFloatingPointErrors(val)
  const absValue = Math.abs(roundedValue)
  let result = ''

  if (absValue >= 1e12) {
    result = (roundedValue / 1e12).toFixed(decimals) + 'T'
  } else if (absValue >= 1e9) {
    result = (roundedValue / 1e9).toFixed(decimals) + 'B'
  } else if (absValue >= 1e6) {
    result = (roundedValue / 1e6).toFixed(decimals) + 'M'
  } else {
    result = roundedValue.toLocaleString(undefined, {
      maximumFractionDigits: decimals,
      minimumFractionDigits: decimals,
    })
  }
  return result
}

const consequenceConverterNew: { [key: number]: string | string[] } = {
  0: 'not_exposed',
  1: 'potential',
  2: ['insignificant', 'undefined'],
  3: ['low', 'minor'],
  4: 'moderate',
  5: ['high', 'major'],
  6: 'exposed',
}

const consequenceConverterNewNoPotential: { [key: number]: string | string[] } = {
  0: 'not_exposed',
  1: ['insignificant', 'undefined'],
  2: ['low', 'minor'],
  3: 'moderate',
  4: ['high', 'major'],
  5: 'exposed',
}

const consequenceConverterOld: { [key: number]: string | string[] } = {
  0: 'not_exposed',
  1: 'exposed',
  2: 'potential',
  3: ['low', 'minor'],
  4: 'moderate',
  5: ['high', 'major'],
  6: 'unspecified',
}

// @TODO with analysts, swap to this being completely data driven, no consequenceConverter
export const getConsequencePalette = (original_palette: LegendData) => {
  const colorList = original_palette.sections[0].items
  const paletteObj = colorList?.map((item) => item.color)

  if (!paletteObj) return undefined
  if (!colorList || !Object.keys(colorList).length) return undefined

  const hasInsignificant = colorList.some((item) => item.label === 'Insignificant')
  const hasPotential = colorList.some((item) => item.label === 'Potential')

  let consequenceConverter = null
  if (hasInsignificant) consequenceConverter = consequenceConverterNew
  if (hasInsignificant && !hasPotential) consequenceConverter = consequenceConverterNewNoPotential
  if (!consequenceConverter) consequenceConverter = consequenceConverterOld

  const output: { [key: string]: string } = {}
  for (let i = 0; i < paletteObj.length; i++) {
    if (paletteObj[i] !== undefined || paletteObj[i] !== '') {
      if (typeof consequenceConverter[i] === 'string') {
        output[consequenceConverter[i] as string] = paletteObj[i] ?? 'grey'
      } else {
        for (const key of consequenceConverter[i] as string[]) {
          output[key] = paletteObj[i] ?? 'grey'
        }
      }
    }
  }

  return output
}

/**
 * Appends custom options to the existing options object. Deeply, recursively merges the two objects.
 *
 * @param options - The original options object.
 * @param customOptions - The custom options to be appended.
 * @returns The modified options object with the custom options appended.
 */
export const appendOptions = (
  options: { [key: string]: unknown },
  customOptions: { [key: string]: unknown },
): { [key: string]: unknown } => {
  const out: { [key: string]: unknown } = {}
  for (const key in options) {
    if (options[key] !== undefined) {
      if (typeof options[key] === 'object' && typeof customOptions[key] === 'object') {
        out[key] = appendOptions(options[key] as { [key: string]: unknown }, {})
      } else {
        out[key] = options[key]
      }
    }
  }
  for (const key in customOptions) {
    if (customOptions[key] !== undefined) {
      if (typeof customOptions[key] === 'object' && typeof options[key] === 'object') {
        out[key] = appendOptions(
          options[key] as { [key: string]: unknown },
          customOptions[key] as { [key: string]: unknown },
        )
      } else {
        out[key] = customOptions[key]
      }
    }
  }
  return out
}

export const cleanFloatingPointErrors = (value: number) => {
  return Math.round(value * 10000) / 10000
}
