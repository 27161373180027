import { css } from '@emotion/react'
import { Theme } from '@mui/material/styles'

export const HeaderContainer = ({ theme }: { theme: Theme }) => css`
  background-color: #0b2a48;
  color: ${theme.palette.white.main};
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  overflow: hidden;
  flex-shrink: 0;

  h1 {
    padding: ${theme.spacing(2)} ${theme.spacing(5)};
    margin: 0;
    font-size: 20px;
    font-weight: 500;
  }
`

export const versionMenu = (theme: Theme, menuVisible: boolean) => css`
  width: ${menuVisible ? theme.spacing(64) : 0};
  flex-shrink: 0;
  max-height: 100%;
  overflow-y: auto;
  border-left: 1px solid #ddd;
  background-color: #0b2a4808;
  transition: width 0.3s;

  > div {
    cursor: pointer;
    background-color: #0b2a4808;
    padding: ${theme.spacing(3)} ${theme.spacing(3)};
    width: ${theme.spacing(64)};
    border-bottom: 1px solid #0b2a4820;
    transition: background-color 0.1s;
    color: #424e5a;

    &:hover {
      background-color: #0b2a4810;
    }

    &.selected {
      background-color: #0b2a4820;
      cursor: default;
    }
  }
`

export const footer = (theme: Theme) => css`
  padding: ${theme.spacing(2)} ${theme.spacing(5)};
  display: flex;
  background-color: #0b2a48;

  img {
    height: 24px;
  }
`

export const content = (theme: Theme) => css`
  overflow-y: auto;
  padding: ${theme.spacing(4)} ${theme.spacing(5)};
  color: #424e5a;
`

export const contentParent = css`
  overflow-y: auto;
  display: flex;
  flex-direction: column;
`

export const versionsButton = (theme: Theme, menuVisible: boolean) => css`
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: ${theme.spacing(1)};
  color: #fff;
  padding: 0 ${theme.spacing(4)};
  border-top-right-radius: 4px;
  transition: background-color 0.1s, min-width 0.3s;
  min-width: ${menuVisible ? theme.spacing(64) : 0};
  user-select: none;

  &:hover {
    background-color: #fff1;
  }

  span {
    padding-top: 2px;
  }
`
