export const CO2CloudIcon = ({ ...rest }) => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M9.6897 19.9988C9.6897 19.9988 9.68529 19.9988 8.04974 19.9988C-0.395966 20.1477 0.0425505 6.25126 9.10606 6.25126C10.5668 1.61912 19.6895 1.80996 21.5295 7.64091C30.7382 8.10412 29.4227 19.7828 21.9519 19.7828C21.4303 19.7828 18.0214 19.7828 18.0214 19.7828"
        stroke="currentColor"
        strokeWidth="1.77164"
        strokeLinejoin="round"
      />
      <path
        d="M13.4771 19.0126V26.5095M13.4771 26.5095L16.5641 23.8635M13.4771 26.5095L10.3901 23.8635"
        stroke="currentColor"
        strokeWidth="1.92888"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.7897 16.4034V15.6145L22.2964 14.3681C22.3963 14.2853 22.4818 14.2077 22.5528 14.1354C22.6251 14.0618 22.6803 13.9862 22.7184 13.9086C22.7579 13.831 22.7776 13.7449 22.7776 13.6503C22.7776 13.5464 22.7552 13.4577 22.7105 13.384C22.6672 13.3104 22.6067 13.2539 22.5291 13.2144C22.4515 13.1737 22.3621 13.1533 22.2609 13.1533C22.1597 13.1533 22.0703 13.1737 21.9927 13.2144C21.9164 13.2552 21.8573 13.315 21.8152 13.3939C21.7731 13.4728 21.7521 13.5688 21.7521 13.6818H20.7108C20.7108 13.3978 20.7746 13.1533 20.9021 12.9482C21.0296 12.7431 21.2097 12.5853 21.4425 12.4749C21.6752 12.3644 21.948 12.3092 22.2609 12.3092C22.5843 12.3092 22.8644 12.3611 23.101 12.465C23.339 12.5676 23.5224 12.7122 23.6513 12.8989C23.7814 13.0856 23.8465 13.3045 23.8465 13.5556C23.8465 13.7107 23.8143 13.8652 23.7499 14.0191C23.6854 14.1716 23.5697 14.3405 23.4028 14.5259C23.2358 14.7113 22.9985 14.9322 22.6908 15.1885L22.3122 15.5041V15.5278H23.8899V16.4034H20.7897Z"
        fill="currentColor"
      />
      <path
        d="M12.0061 11.9678H10.0851C10.0715 11.8088 10.0352 11.6647 9.97616 11.5352C9.91939 11.4058 9.83992 11.2946 9.73775 11.2015C9.63784 11.1061 9.51637 11.0334 9.37332 10.9835C9.23027 10.9313 9.06793 10.9052 8.88628 10.9052C8.5684 10.9052 8.29934 10.9824 8.07909 11.1368C7.86112 11.2912 7.69537 11.5125 7.58184 11.8009C7.47058 12.0893 7.41495 12.4355 7.41495 12.8397C7.41495 13.2666 7.47171 13.6242 7.58524 13.9125C7.70104 14.1986 7.86793 14.4143 8.0859 14.5596C8.30388 14.7027 8.56613 14.7742 8.87266 14.7742C9.04749 14.7742 9.20416 14.7526 9.34267 14.7095C9.48117 14.6641 9.60151 14.5994 9.70369 14.5154C9.80586 14.4314 9.88874 14.3303 9.95232 14.2123C10.0182 14.0919 10.0624 13.9568 10.0851 13.807L12.0061 13.8206C11.9833 14.1158 11.9005 14.4166 11.7574 14.7231C11.6144 15.0274 11.4112 15.3089 11.1478 15.5678C10.8867 15.8244 10.5631 16.031 10.1771 16.1877C9.79111 16.3443 9.34267 16.4227 8.83179 16.4227C8.19149 16.4227 7.61703 16.2853 7.10842 16.0105C6.60208 15.7358 6.20133 15.3316 5.90615 14.7981C5.61325 14.2645 5.4668 13.6117 5.4668 12.8397C5.4668 12.0632 5.61665 11.4092 5.91637 10.8779C6.21609 10.3443 6.62025 9.9413 7.12886 9.66883C7.63747 9.39409 8.20511 9.25672 8.83179 9.25672C9.27228 9.25672 9.67758 9.31689 10.0477 9.43723C10.4178 9.55757 10.7425 9.73354 11.0218 9.96514C11.301 10.1945 11.5258 10.4772 11.6961 10.8132C11.8664 11.1492 11.9697 11.5341 12.0061 11.9678Z"
        fill="currentColor"
      />
      <path
        d="M19.6224 12.8397C19.6224 13.6162 19.4714 14.2713 19.1694 14.8049C18.8674 15.3362 18.4599 15.7392 17.9467 16.014C17.4336 16.2864 16.8614 16.4227 16.2302 16.4227C15.5944 16.4227 15.02 16.2853 14.5068 16.0105C13.9959 15.7335 13.5895 15.3294 13.2875 14.7981C12.9878 14.2645 12.8379 13.6117 12.8379 12.8397C12.8379 12.0632 12.9878 11.4092 13.2875 10.8779C13.5895 10.3443 13.9959 9.9413 14.5068 9.66883C15.02 9.39409 15.5944 9.25672 16.2302 9.25672C16.8614 9.25672 17.4336 9.39409 17.9467 9.66883C18.4599 9.9413 18.8674 10.3443 19.1694 10.8779C19.4714 11.4092 19.6224 12.0632 19.6224 12.8397ZM17.6743 12.8397C17.6743 12.4219 17.6186 12.07 17.5074 11.7839C17.3984 11.4955 17.236 11.2775 17.0203 11.1299C16.8069 10.9801 16.5435 10.9052 16.2302 10.9052C15.9168 10.9052 15.6523 10.9801 15.4366 11.1299C15.2232 11.2775 15.0608 11.4955 14.9496 11.7839C14.8406 12.07 14.7861 12.4219 14.7861 12.8397C14.7861 13.2575 14.8406 13.6105 14.9496 13.8989C15.0608 14.185 15.2232 14.403 15.4366 14.5528C15.6523 14.7004 15.9168 14.7742 16.2302 14.7742C16.5435 14.7742 16.8069 14.7004 17.0203 14.5528C17.236 14.403 17.3984 14.185 17.5074 13.8989C17.6186 13.6105 17.6743 13.2575 17.6743 12.8397Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default CO2CloudIcon
