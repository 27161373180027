import { css } from '@emotion/react'

import { IconSize } from './Icon'

type IconContainerProps = {
  colour: string
  size: IconSize
  fillColour?: string
}

const getIconSize = (size: IconSize) => {
  switch (size) {
    case 'small':
      return '16px'
    case 'medium':
      return '20px'
    case 'large':
      return '24px'
    case 'fit':
      return '100%'
    default:
      '24px'
  }
}

export const iconContainer = ({ colour, size, fillColour }: IconContainerProps) => css`
  width: ${getIconSize(size)};
  height: ${getIconSize(size)};
  color: ${colour};
  fill: ${fillColour && colour};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
`
