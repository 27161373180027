export const HorizonScanIcon = ({ ...rest }) => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path d="M27.5 14.074H2.5V15.9259H27.5V14.074Z" fill="currentColor" />
      <path d="M27.4999 25.6487H25.6479V27.5006H27.4999V25.6487Z" fill="currentColor" />
      <path d="M19.8404 25.6487H17.9043V27.5006H19.8404V25.6487Z" fill="currentColor" />
      <path d="M12.0963 25.6487H10.1602V27.5006H12.0963V25.6487Z" fill="currentColor" />
      <path d="M23.7125 25.6487H21.7764V27.5006H23.7125V25.6487Z" fill="currentColor" />
      <path d="M8.22373 25.6487H6.2876V27.5006H8.22373V25.6487Z" fill="currentColor" />
      <path d="M4.35193 25.6487H2.5V27.5006H4.35193V25.6487Z" fill="currentColor" />
      <path d="M4.35193 17.9036H2.5V19.8396H4.35193V17.9036Z" fill="currentColor" />
      <path d="M4.35193 21.775H2.5V23.7111H4.35193V21.775Z" fill="currentColor" />
      <path d="M4.35193 10.1586H2.5V12.0947H4.35193V10.1586Z" fill="currentColor" />
      <path d="M4.35193 6.28732H2.5V8.2234H4.35193V6.28732Z" fill="currentColor" />
      <path d="M4.35193 2.49948H2.5V4.35141H4.35193V2.49948Z" fill="currentColor" />
      <path d="M19.8404 2.49948H17.9043V4.35141H19.8404V2.49948Z" fill="currentColor" />
      <path d="M12.0968 2.49948H10.1606V4.35141H12.0968V2.49948Z" fill="currentColor" />
      <path d="M23.7125 2.49948H21.7764V4.35141H23.7125V2.49948Z" fill="currentColor" />
      <path d="M15.9679 2.49948H14.0317V4.35141H15.9679V2.49948Z" fill="currentColor" />
      <path d="M8.22373 2.49948H6.2876V4.35141H8.22373V2.49948Z" fill="currentColor" />
      <path d="M27.4999 2.49948H25.6479V4.35141H27.4999V2.49948Z" fill="currentColor" />
      <path d="M27.4999 21.775H25.6479V23.7111H27.4999V21.775Z" fill="currentColor" />
      <path d="M27.4999 17.9034H25.6479V19.8394H27.4999V17.9034Z" fill="currentColor" />
      <path d="M27.4999 6.28712H25.6479V8.2232H27.4999V6.28712Z" fill="currentColor" />
      <path d="M27.4999 10.1586H25.6479V12.0947H27.4999V10.1586Z" fill="currentColor" />
      <path d="M15.9679 17.9036H14.0317V19.8396H15.9679V17.9036Z" fill="currentColor" />
      <path d="M15.9679 10.1586H14.0317V12.0947H15.9679V10.1586Z" fill="currentColor" />
      <path d="M15.9679 6.28712H14.0317V8.2232H15.9679V6.28712Z" fill="currentColor" />
      <path d="M15.9679 21.775H14.0317V23.7111H15.9679V21.775Z" fill="currentColor" />
      <path d="M15.9679 25.6487H14.0317V27.5006H15.9679V25.6487Z" fill="currentColor" />
    </svg>
  )
}

export default HorizonScanIcon
