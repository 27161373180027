/** @jsxImportSource @emotion/react */
import { useTheme } from '@mui/material/styles'
import { sideImageBlock } from './SideImageBlock.styles'
import { ButtonInBlockProps } from '../DataDrivenBlocks'
import { Box } from '@mui/material'
import UiMarkdown from '@src/components/Atoms/UiMarkdown/UiMarkdown'

export type SideImageBlockProps = {
  type: 'side-image' | 'side-image-first' | 'side-image-filled' | 'side-image-filled-first'
  header?: string
  imageUrl?: string // warning
  content?: string // warning
  buttons?: ButtonInBlockProps[]
}

export const SideImageBlock = ({
  type,
  header,
  imageUrl,
  content,
  buttons,
}: SideImageBlockProps) => {
  const theme = useTheme()

  const contentBox = (
    <Box
      sx={{
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'column',
        gap: '16px',
        paddingRight: theme.spacing(16),
      }}
    >
      <h1>{header}</h1>
      {content && (
        <p>
          <UiMarkdown>{content}</UiMarkdown>
        </p>
      )}
      <Box sx={{ display: 'flex', flexDirection: 'row', gap: '8px' }}>
        {buttons &&
          buttons.map((button, index) => (
            <button key={index} onClick={() => window.open(button.url)}>
              {button.text}
            </button>
          ))}
      </Box>
    </Box>
  )

  const imageBox = <img src={imageUrl} />

  return (
    <Box css={sideImageBlock({ theme, type })}>
      <Box>
        {type.includes('first') ? (
          <>
            {imageBox}
            {contentBox}
          </>
        ) : (
          <>
            {contentBox}
            {imageBox}
          </>
        )}
      </Box>
    </Box>
  )
}
