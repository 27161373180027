import { css } from '@emotion/react'
import { Theme } from '@mui/material'

function getToolColor(tool: string) {
  switch (tool) {
    case 'measuring':
      return '#dd6800'
    case 'selection':
      return '#0d2b4a'
    default:
      return '#ED872D'
  }
}

export const mapToolsPortalOverlay = css`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 50px;
  z-index: 100;
  pointer-events: none;
  display: flex;
  align-items: flex-end;
  justify-content: center;
`

export const mapTool = (theme: Theme, tool: string) => css`
  border-radius: 4px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2), 0 0 0 3px ${getToolColor(tool)}20;
  background: white;
  z-index: 100;
  pointer-events: all;
  color: ${theme.palette.text.primary};
  display: flex;
  flex-direction: column;
  overflow: hidden;

  background-color: ${getToolColor(tool)};
`

export const mapToolContent = (theme: Theme) => css`
  padding: ${theme.spacing(2)} ${theme.spacing(4)} ${theme.spacing(2)};
  display: flex;
  gap: ${theme.spacing(3)};
  flex-grow: 1;
  justify-content: center;
  background-color: white;
  border-radius: ${theme.spacing(0.5)};
  margin: ${theme.spacing(0.5)};

  div {
    justify-content: center;
    display: flex;
    flex-direction: column;
  }

  div:first-of-type {
    text-align: right;
  }

  div:last-of-type p {
    font-weight: 600;
  }
`

export const mapToolClearButton = css`
  position: absolute;
  right: 0;
  top: 0;
`

export const mapToolHeaderTitle = css`
  font-weight: 500;
  color: white;
`

export const mapToolHint = (theme: Theme, tool: string) => css`
  color: ${getToolColor(tool)};
  margin-top: ${theme.spacing(-1)};
  margin-bottom: ${theme.spacing(0.5)};
  max-width: 240px;
  line-height: 1em;
  font-style: italic;
  color: white;
  opacity: 0.8;
`

export const mapToolHeader = css`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 4px;
  padding: 6px 12px;
  width: 100%;
  position: relative;
  padding-right: 40px;
`

export const MapToolsContainer = css`
  pointer-events: none;
  gap: 10px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
`

export const tutorialMaptoolsLocator = css`
  position: absolute;
  top: 0;
  right: 0;
  width: 30px;
  height: 30px;
  z-index: 100;
  pointer-events: none;
  user-select: none;
`
