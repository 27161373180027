import { css } from '@emotion/react'
import { Theme } from '@mui/material/styles'

export const hazardScenarioDetailsModalContainer = () => css`
  min-width: 600px;
  min-height: 500px;
`

export const hazardScenarioDetailsModalContent = ({ theme }: { theme: Theme }) => css`
  max-height: 80svh;
  max-width: 800px;
  overflow-y: auto;
  padding: ${theme.spacing(2)} ${theme.spacing(2)};
  margin: 1px 2px 8px 2px;
`

export const hazardScenarioDetailsModalHeader = ({ theme }: { theme: Theme }) => css`
  color: ${theme.palette.secondary.main};
  background-color: ${theme.palette.primary.main};
  padding: ${theme.spacing(1)} ${theme.spacing(3)};
  border-radius: ${theme.spacing(1)} ${theme.spacing(1)} 0 0;
  box-shadow: inset 0px -4px 0px -2px #0003;

  svg {
    color: ${theme.palette.secondary.main};
  }
`

export const hazardScenarioDetailsModalHeaderInner = () => css`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const hazardScenarioDetailsModalHeaderText = ({ theme }: { theme: Theme }) => css`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: ${theme.spacing(2)};
  align-items: center;
`
