import { css } from '@emotion/react'
import { Theme } from '@mui/material/styles'

export const sideImageBlock = ({ theme, type }: { theme: Theme; type: string }) => css`
  display: flex;
  padding: 0 ${theme.spacing(16)};
  justify-content: center;
  padding: ${type.includes('filled') ? theme.spacing(8) : 0} ${theme.spacing(16)};
  background-color: ${type.includes('filled') ? '#0b294820' : 'transparent'};

  > div {
    display: flex;
    width: 1000px;
    max-width: 1000px;
    justify-content: flex-start;
    align-items: center;
    gap: ${theme.spacing(16)};
    flex-grow: 1;
  }

  img {
    max-height: 240px;
    max-width: 50%;
    border-radius: 4px;
  }

  h1 {
    color: #0b2948;
    font-size: 20px;
    line-height: 1;
    margin-top: ${theme.spacing(1)};
  }

  p {
    margin-left: ${theme.spacing(2)};
    font-size: 15px;
  }
`
