/** @jsxImportSource @emotion/react */
import { Box, useTheme } from '@mui/material'

import { toggleButton } from './VerticalThumbTab.styles'
import { Icon } from '@src/components/Atoms/Icon'

interface ToggleDrawerButtonProps {
  arrowPosition: 'left' | 'right' | 'up' | 'down'
  disabled?: boolean
  onClick: () => void
  initialIcon?: string | null
  expanded?: boolean
  label?: string
}

export const ToggleArrowButton = ({
  arrowPosition,
  onClick,
  disabled = false,
  initialIcon = null,
  expanded = false,
  label,
  ...rest
}: ToggleDrawerButtonProps) => {
  const theme = useTheme()

  return (
    <Box
      component="button"
      css={toggleButton({ theme, disabled, expanded, initialIcon })}
      onClick={onClick}
      {...rest}
    >
      <Box className="button-background">
        {initialIcon !== null && (
          <Icon
            size="medium"
            iconName={initialIcon}
            colour={
              disabled
                ? theme.palette.grey[100]
                : expanded
                ? theme.palette.primary.main
                : theme.palette.white.main
            }
          />
        )}
        {label && <Box className="label">{label}</Box>}
      </Box>
      <div className="tutorial-sidedrawer-tab" />
    </Box>
  )
}
