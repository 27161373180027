import { css } from '@emotion/react'
import { Theme } from '@mui/material/styles'

export const tsncsModal = ({ theme }: { theme: Theme }) => css`
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;

  padding: 0;

  gap: 0;
  background-color: ${theme.palette.primary.main};
  border-radius: ${theme.spacing(1)};

  box-shadow: ${theme.shadows[10]};

  width: 1100px;

  max-width: 95vw;
  height: 95svh;
`

export const tsncsCopy = ({ theme }: { theme: Theme }) => css`
  padding: ${theme.spacing(2)} ${theme.spacing(12)};
  text-align: justify;
  ol {
    padding-left: 40px;
  }
  li {
    margin-top: ${theme.spacing(1)};
  }

  > p:first-of-type {
    border-bottom: 1px solid ${theme.palette.primary.main};
    padding-bottom: ${theme.spacing(4)};
    margin-bottom: ${theme.spacing(6)};
    font-weight: bold;
    text-align: left;
    color: ${theme.palette.primary.main};
  }
`

export const checkboxLabel = ({ theme }: { theme: Theme }) => css`
  margin: ${theme.spacing(2)};
`

export const tsncsContainer = ({ theme }: { theme: Theme }) => css`
  background-color: ${theme.palette.background.paper};
  display: flex;
  flex: 1;
  position: relative;
`
export const tsncsInnerContainer = ({ theme }: { theme: Theme }) => css`
  padding: 0 ${theme.spacing(2)};
  padding-bottom: ${theme.spacing(8)};
  overflow-y: auto;
  position: absolute;
  top: ${theme.spacing(2)};
  bottom: 0;
  left: ${theme.spacing(1)};
  right: ${theme.spacing(1)};
`

export const headerContainer = () => css``

export const header = ({ theme }: { theme: Theme }) => css`
  margin: ${theme.spacing(2)};
  color: ${theme.palette.secondary.main};
  font-weight: 500;
  font-size: 1.2rem;
  padding: ${theme.spacing(1.5)} ${theme.spacing(3)};
`
export const footer = ({ theme }: { theme: Theme }) => css`
  display: flex;
  gap: ${theme.spacing(2)};
  background-color: ${theme.palette.background.paper};
  padding: ${theme.spacing(2)} ${theme.spacing(2)};
  padding-left: ${theme.spacing(4)};
  border-top: 1px solid ${theme.palette.primary.main}10;
  align-items: center;
  flex-shrink: 0;
  border-radius: 0 0 ${theme.spacing(1)} ${theme.spacing(1)};
  height: ${theme.spacing(16)};
`

export const acceptanceControls = css`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`

export const continueButton = ({ theme }: { theme: Theme }) => css`
  height: ${theme.spacing(10)};
  margin: ${theme.spacing(2)};
`

export const logo = ({ theme }: { theme: Theme }) => css`
  border-radius: ${theme.spacing(2)};
  max-width: ${theme.spacing(44)};
  max-height: 100%;
`

export const uiLogo = ({ theme }: { theme: Theme }) => css`
  background-color: ${theme.palette.white.main};
  height: ${theme.spacing(14)};
  max-width: ${theme.spacing(44)};
  max-height: 100%;
`
