/** @jsxImportSource @emotion/react */

import { RiskPageTutorial } from './TutorialConfigs'
import { memo } from 'react'

import 'intro.js/introjs.css'
import './TutorialRunner/TutorialRunner.css'

// TutorialToRun = the name of the file the tutorial is defined in, in ./Tutorials
export type TutorialProps = {
  tutorialToRun: string
  showTutorial: boolean
}
export const Tutorial = memo(({ tutorialToRun, showTutorial }: TutorialProps) => {
  if (!showTutorial) return null

  if (tutorialToRun === 'RiskPageTutorial') return <RiskPageTutorial />

  return null
})
Tutorial.displayName = 'TutorialButton'
