/** @jsxImportSource @emotion/react */
import { Box, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { useCallback, useMemo } from 'react'

import { MapLayer, MapLayerType } from '@redux/map/mapSlice'
import { RootState } from '@redux/store'
import { ModalLayer } from '../../../../../LayersControl'
import { DialogLayersPreviewProps } from '../../../../../MapView/AddLayersDialog/LayersPreview'
import {
  accordionContentContainer,
  emptyAccordion,
  layersOrganizerContainer,
} from './LayersOrganizer.styles'
import { useLayers } from '@contexts/LayerContext'
import { useSelector } from 'react-redux'
import { LayerAccordionList } from './LayerAccordionList'
import { Accordion } from '@src/components/Atoms/Accordion'
import { SortableList } from '@src/components/Atoms/Sortable'
import { usePreferences } from '@contexts/PreferencesContext'
import { useLayerListDetailsFetcher } from '@src/components/Molecules/SideDrawerContent/data_fetchers/layerDetailsFetcher'

export interface LayersOrganizerProps {
  isDialogPreview?: boolean
  dialogProps?: DialogLayersPreviewProps
}

export const LayersOrganizer = ({ dialogProps, isDialogPreview = false }: LayersOrganizerProps) => {
  const theme = useTheme()
  const { term_preference } = usePreferences()
  const { prepareAndUpdateLayers } = useLayers()

  const { layers: storeLayers } = useSelector((state: RootState) => state.map)

  const modalLayersToMapLayers = useMemo(() => {
    const modalLayers: ModalLayer[] | undefined = dialogProps?.stagedLayers
    if (!modalLayers) return undefined
    const res = modalLayers.map((modalLayer) => modalLayer.layers).flat()
    return res
  }, [dialogProps?.stagedLayers])

  const { layerDetailsList } = useLayerListDetailsFetcher(storeLayers, modalLayersToMapLayers)

  /**
   * @TODO there is potentially performance savings by creating a layer ordering exclusive function
   * But at the moment this is used to update layers in a variety of ways
   */
  const onUpdateLayersOrder = (newLayers: ModalLayer[]) => {
    const updatedFlatLayers = newLayers.map((layer) => layer.layers).flat()
    // if layers or updatedFlatLayers aren't empty, update the layers
    if (
      storeLayers.length ||
      updatedFlatLayers.length ||
      newLayers.length != updatedFlatLayers.length
    ) {
      if (isDialogPreview) {
        dialogProps?.handleUpdateLayers(updatedFlatLayers)
      } else {
        prepareAndUpdateLayers({ layers: updatedFlatLayers })
      }
    }
  }

  let layers: ModalLayer[] = useMemo(
    () => dialogProps?.stagedLayers ?? [],
    [dialogProps?.stagedLayers],
  )

  if (!isDialogPreview) {
    layers = storeLayers.reduce<ModalLayer[]>((acc, layer) => {
      const layerType = layer.layerType
      const layerGroup = acc.find((group) => group.id === layerType)

      if (layerGroup) {
        layerGroup.layers.push(layer)
      } else {
        acc.push({
          id: layerType,
          layers: [layer],
        })
      }

      return acc
    }, [])
  }

  const hazardLayers = useMemo(
    () => layers.filter((layer) => layer.id === 'hazard')[0]?.layers,
    [layers],
  )
  const assetLayers = useMemo(
    () => layers.filter((layer) => layer.id === 'asset')[0]?.layers,
    [layers],
  )
  const informationLayers = useMemo(
    () => layers.filter((layer) => layer.id === 'information')[0]?.layers,
    [layers],
  )

  const handleDeleteLayer = useCallback(
    ({ selectedLayer }: { selectedLayer: MapLayer }) => {
      const updatedLayers = layers.map((layer) => {
        if (layer.id === selectedLayer.layerType) {
          return {
            ...layer,
            layers: layer.layers.filter(
              (layer) => layer.display_name !== selectedLayer.display_name,
            ),
          }
        }
        return layer
      })

      onUpdateLayersOrder(updatedLayers)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [layers],
  )

  const handleUpdateLayer = useCallback(
    ({ parentLayerId, newLayers }: { parentLayerId: MapLayerType; newLayers: MapLayer[] }) => {
      const updatedLayers = layers.map((layer) => {
        if (layer.id === parentLayerId) {
          return {
            ...layer,
            layers: newLayers,
          }
        }
        return layer
      })

      onUpdateLayersOrder(updatedLayers)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [layers],
  )

  const getItems = useMemo(
    () => [
      {
        id: 'hazard',
        header: <Typography>{term_preference.hazard ? 'Hazards' : 'Risk Sources'}</Typography>,
        body: (
          <>
            {!hazardLayers?.length ? (
              <Box css={emptyAccordion}></Box>
            ) : (
              <Box css={accordionContentContainer({ theme })}>
                <LayerAccordionList
                  layers={hazardLayers}
                  handleUpdateLayer={handleUpdateLayer}
                  layerDetailsList={layerDetailsList}
                  dialogProps={dialogProps}
                  isDialogPreview={isDialogPreview}
                  handleDeleteLayer={handleDeleteLayer}
                />
              </Box>
            )}
          </>
        ),
      },
      {
        id: 'asset',
        header: <Typography>Elements</Typography>,
        body: (
          <>
            {!assetLayers?.length ? (
              <Box css={emptyAccordion}></Box>
            ) : (
              <Box css={accordionContentContainer({ theme })}>
                <LayerAccordionList
                  layers={assetLayers}
                  handleUpdateLayer={handleUpdateLayer}
                  layerDetailsList={layerDetailsList}
                  dialogProps={dialogProps}
                  isDialogPreview={isDialogPreview}
                  handleDeleteLayer={handleDeleteLayer}
                />
              </Box>
            )}
          </>
        ),
      },
      {
        id: 'information',
        header: <Typography>Contextual</Typography>,
        body: (
          <>
            {!informationLayers?.length ? (
              <Box css={emptyAccordion}></Box>
            ) : (
              <Box css={accordionContentContainer({ theme })}>
                <LayerAccordionList
                  layers={informationLayers}
                  handleUpdateLayer={handleUpdateLayer}
                  layerDetailsList={layerDetailsList}
                  dialogProps={dialogProps}
                  isDialogPreview={isDialogPreview}
                  handleDeleteLayer={handleDeleteLayer}
                />
              </Box>
            )}
          </>
        ),
      },
    ],
    [
      term_preference.hazard,
      hazardLayers,
      theme,
      handleUpdateLayer,
      dialogProps,
      isDialogPreview,
      handleDeleteLayer,
      assetLayers,
      informationLayers,
      layerDetailsList,
    ],
  )

  return (
    <>
      <Box css={layersOrganizerContainer({ theme })}>
        <SortableList
          items={layers}
          onChange={onUpdateLayersOrder}
          renderItem={(layer: ModalLayer, _index: number, isDragging: boolean | undefined) => {
            const selected = getItems.find((item) => item.id === layer.id)

            return (
              <SortableList.Item id={layer.id}>
                <Accordion
                  isDraggable
                  key={layer.id}
                  defaultExpanded={!isDragging}
                  variant="filled"
                  level="h3"
                  lazyContent
                  header={selected ? selected.header : <></>}
                  body={selected ? selected.body : <></>}
                />
              </SortableList.Item>
            )
          }}
        />
      </Box>
    </>
  )
}
