/** @jsxImportSource @emotion/react */
import { Box } from '@mui/material'
import { mapToolsPortalOverlay } from './MapToolsPortal.styles'

export interface MapToolsPortalProps {
  children: React.ReactNode
}

export const MapToolsPortal = ({ children }: MapToolsPortalProps) => {
  return <Box css={mapToolsPortalOverlay}>{children}</Box>
}
