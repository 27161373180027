export const WarningIcon = ({ ...rest }) => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path d="M15.8332 10.8334H14.1665V20H15.8332V10.8334Z" fill="currentColor" />
      <path d="M15.8332 21.6666H14.1665V23.3333H15.8332V21.6666Z" fill="currentColor" />
      <path
        d="M16.3693 3.33335C16.1045 2.82146 15.5763 2.5 15 2.5C14.4237 2.5 13.8955 2.82146 13.6307 3.33335L2.738 24.3927C2.58161 24.6951 2.5 25.0305 2.5 25.3709C2.5 26.5468 3.45325 27.5 4.62915 27.5H25.3709C26.5468 27.5 27.5 26.5468 27.5 25.3709C27.5 25.0305 27.4184 24.6951 27.262 24.3927L16.3693 3.33335ZM25.8334 25.3708C25.8334 25.6263 25.6263 25.8334 25.3708 25.8334H4.62919C4.37374 25.8334 4.16667 25.6263 4.16667 25.3708C4.16667 25.2969 4.1844 25.224 4.21837 25.1584L15 4.31386L25.7817 25.1584C25.8156 25.224 25.8334 25.2969 25.8334 25.3708Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default WarningIcon
