/** @jsxImportSource @emotion/react */
import { useMap } from '@contexts/MapContext'
import { RootState } from '@redux/store'
import { useSnackbars } from '@uintel/ui-component-library'
import { MoreMenu, MoreMenuButton, MoreMenuOpenState } from '@molecules/MapView/MoreMenu/MoreMenu'
import { RefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Filter,
  Fullscreen,
  FullscreenExit,
  HighlightAlt,
  Print,
  Straighten,
} from '@mui/icons-material'
import { updateMapStateProperty } from '@redux/map/mapSlice'
import MeasuringTool, { Measurement, MeasuringToolOutput } from './MeasuringTool'
import SelectionTool, { SelectionArea, SelectionToolOutput } from './SelectionTool'
import { createPortal } from 'react-dom'
import { MapToolsPortal } from './MapToolsPortal'
import { Box } from '@mui/material'
import { MapToolsContainer, tutorialMaptoolsLocator } from './MapToolsPortal.styles'
import { RegionFilter } from '@src/components/Molecules/RegionFilter'
import { generateVulnerabilityPalette } from '@src/components/Molecules/MapView/RiskMapView.utilities'
import { useTutorialContext } from '@contexts/TutorialContext'

export const MapTools = ({ mapOverlayRef }: { mapOverlayRef: RefObject<HTMLDivElement> }) => {
  const {
    clientName,
    clientDisplayName,
    logos,
    theme: userTheme,
  } = useSelector((state: RootState) => state.user)
  const { layers, legendsData, isFullScreen, drawAreas } = useSelector(
    (state: RootState) => state.map,
  )
  const { tab } = useSelector((state: RootState) => state.sideDrawer)
  const vulnerabilityPalette = generateVulnerabilityPalette(legendsData, 'vulnerability')
  const dispatch = useDispatch()
  const { map, updateDrawAreas } = useMap()
  const { displaySnackbar } = useSnackbars()
  const { openMapToolbar, tutorialActive } = useTutorialContext()

  const [isSelecting, setIsSelecting] = useState(false)
  const [hasSelection, setHasSelection] = useState(false)
  const [isMeasuring, setIsMeasuring] = useState(false)
  const [measurement, setMeasurement] = useState<Measurement>({
    distance: '0 m',
    area: null,
    perimeter: null,
  })
  const [selectionArea, setSelectionArea] = useState<SelectionArea>({
    area: null,
    perimeter: null,
  })

  const deleteSelection = useCallback(() => {
    if (!map) return
    SelectionTool.deleteSelectionArea(map)
  }, [map])

  const handleExportToPdf = useCallback(async () => {
    if (!map) return

    displaySnackbar({ message: 'Exporting to PDF', type: 'info' })

    try {
      const { exportMapToPdf } = await import('./ExportMap/mapExporter')
      await exportMapToPdf(
        map,
        clientDisplayName,
        layers,
        legendsData,
        logos,
        userTheme.primary.main,
      )
      displaySnackbar({ message: 'PDF exported', type: 'success' })
    } catch (error) {
      displaySnackbar({ message: 'Error exporting to PDF', type: 'error' })
    }
  }, [clientDisplayName, displaySnackbar, layers, legendsData, logos, map, userTheme.primary.main])

  const handleExportToClipboard = useCallback(async () => {
    if (!map) return
    const { exportMapToClipboard } = await import('./ExportMap/mapExporter')
    await exportMapToClipboard(map, vulnerabilityPalette)
    displaySnackbar({ message: 'Copied to clipboard', type: 'success' })
  }, [displaySnackbar, map, vulnerabilityPalette])

  const buttons: MoreMenuButton[] = useMemo(() => {
    if (tab == 'Stories') {
      return [
        {
          title: `${clientName ? 'Print map to PDF' : 'Login required'}`,
          handleClick: handleExportToPdf,
          icon: <Print className="tutorial-save-map" />,
          disabled: !clientName,
        },
        {
          title: 'Copy map as image to clipboard',
          handleClick: handleExportToClipboard,
          icon: <Filter className="tutorial-copy-map" />,
        },
      ]
    }
    return [
      {
        title: 'Select elements',
        handleClick: () => {
          if (hasSelection) deleteSelection()
          setIsSelecting(true)
          setIsMeasuring(false)
        },
        icon: <HighlightAlt className="tutorial-polygon-draw" />,
        disabled: !(clientName && !isSelecting),
      },
      {
        title: `${clientName ? 'Print map to PDF' : 'Login required'}`,
        handleClick: handleExportToPdf,
        icon: <Print className="tutorial-save-map" />,
        disabled: !clientName,
      },
      {
        title: 'Copy map as image to clipboard',
        handleClick: handleExportToClipboard,
        icon: <Filter className="tutorial-copy-map" />,
      },
      {
        title: 'Measure distances and area',
        handleClick: () => {
          setIsMeasuring(true)
          setIsSelecting(false)
        },
        icon: <Straighten className="tutorial-measure-tool" />,
        disabled: isSelecting,
      },
      {
        title: isFullScreen ? 'Exit full screen' : 'Full screen',
        handleClick: () => {
          dispatch(updateMapStateProperty({ isFullScreen: !isFullScreen }))

          if (!isFullScreen) {
            document.dispatchEvent(new CustomEvent('EnteringFullScreen'))
          }
          // Toggle fullscreen
          if (document.fullscreenElement) {
            document.exitFullscreen()
          } else {
            document.documentElement.requestFullscreen()
          }
        },
        icon: isFullScreen ? (
          <FullscreenExit className="tutorial-fullscreen-map" />
        ) : (
          <Fullscreen className="tutorial-fullscreen-map" />
        ),
      },
    ]
  }, [
    clientName,
    isSelecting,
    handleExportToPdf,
    handleExportToClipboard,
    isFullScreen,
    hasSelection,
    deleteSelection,
    dispatch,
    tab,
  ])
  const regionsEnabled = useMemo(() => tab !== 'Stories', [tab])

  // Watch for fullscreenchange - this does not trigger if user pressed F11
  useEffect(() => {
    function onFullscreenChange() {
      dispatch(updateMapStateProperty({ isFullScreen: Boolean(document.fullscreenElement) }))
    }

    document.addEventListener('fullscreenchange', onFullscreenChange)

    return () => document.removeEventListener('fullscreenchange', onFullscreenChange)
  }, [dispatch])

  // Close toolbar when entering fullscreen
  const moreMenuOpenRef = useRef<MoreMenuOpenState>(null)
  useEffect(() => {
    function onEnterFullscreen() {
      moreMenuOpenRef.current?.setOpen(false)
    }

    document.addEventListener('EnteringFullScreen', onEnterFullscreen)

    return () => document.removeEventListener('EnteringFullScreen', onEnterFullscreen)
  }, [])

  // Let tutorial control toolbar
  useEffect(() => {
    if (!tutorialActive) return
    if (openMapToolbar) moreMenuOpenRef.current?.setOpen(true)
  }, [openMapToolbar, tutorialActive])

  // If user leaves page while drawing for example isDrawing is reset to false but
  // canInteractWithLayers redux property remains false when it should be true
  // this useEffect picks up on this and also reacts to isDrawing and isMeasuring state changes above.
  useEffect(() => {
    dispatch(updateMapStateProperty({ canInteractWithLayers: !(isSelecting || isMeasuring) }))
    if (!map) return

    // So that the mouse pointer gets set correctly first
    // deactivate all tools and then active the selected one
    MeasuringTool.deactivateMeasuringTool(map)
    SelectionTool.deactivateSelectionTool(map)
    if (isMeasuring) MeasuringTool.activateMeasuringTool(map, setMeasurement)
    if (isSelecting)
      SelectionTool.activateSelectionTool(
        map,
        updateDrawAreas,
        setIsSelecting,
        setHasSelection,
        displaySnackbar,
        setSelectionArea,
      )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSelecting, isMeasuring])

  useEffect(() => {
    // This is a bit of a hack.
    // If they navigate away from the Risk Map with an active polygon tool and return
    // there's no way to properly recover the state of the SelectionTool due to it being
    // an awful mix of plain old js and react.
    // When there are any draw areas and the hasSelection flag is not set this means the
    // SelectionTool is in the wrong state so clear all the areas and remove all the
    // SelectionTool layers and source.
    if (map && !hasSelection && drawAreas.length > 0) {
      if (map.getLayer('selectionToolLineLayer')) map.removeLayer('selectionToolLineLayer')
      if (map.getLayer('selectionToolAreaLayer')) map.removeLayer('selectionToolAreaLayer')
      if (map.getSource('selectionToolSource')) map.removeSource('selectionToolSource')

      updateDrawAreas({ drawAreas: [] })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drawAreas, map])
  return (
    <>
      <MoreMenu
        buttons={buttons}
        size="large"
        moreButtonIcon="Spanner"
        showOutline
        defaultOpen
        ref={moreMenuOpenRef}
      >
        {regionsEnabled ? <RegionFilter /> : undefined}
      </MoreMenu>
      <Box css={tutorialMaptoolsLocator} className="tutorial-maptools" />
      {(isMeasuring || isSelecting || hasSelection) &&
        mapOverlayRef.current &&
        createPortal(
          <MapToolsPortal>
            <Box css={MapToolsContainer}>
              {(isSelecting || hasSelection) && (
                <SelectionToolOutput
                  handleClose={() => deleteSelection()}
                  selectionArea={selectionArea}
                  isSelecting={isSelecting}
                />
              )}
              {isMeasuring && (
                <MeasuringToolOutput
                  handleClose={() => setIsMeasuring(false)}
                  measurement={measurement}
                />
              )}
            </Box>
          </MapToolsPortal>,
          mapOverlayRef.current,
        )}
    </>
  )
}

export function formatDistance(metres: number): string {
  if (metres < 1000) return `${metres.toFixed(0)} m`

  const km = metres / 1000

  return `${km.toLocaleString(undefined, { maximumFractionDigits: 1 })} km`
}

export function formatArea(squareMetres: number): string {
  const OneMillionHecatares = 1e10
  if (squareMetres < OneMillionHecatares) {
    const hectares = squareMetres / 10000
    return `${hectares.toLocaleString(undefined, { maximumFractionDigits: 1 })} ha`
  }

  const sqkm = squareMetres / 1e6
  return `${sqkm.toLocaleString(undefined, { maximumFractionDigits: 1 })} km²`
}
