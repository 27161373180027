import { css } from '@emotion/react'
import { Theme } from '@mui/material/styles'

export const layerInfo = ({ theme }: { theme: Theme }) => css`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing(2)};
  height: 100%;

  > div {
    flex-grow: 1;
  }
`

export const layerDetailsHeader = ({ theme }: { theme: Theme }) => css`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  color: ${theme.palette.primary.dark};
  padding: ${theme.spacing(1)} 0;
`
