export const DrawRulerIcon = ({ ...rest }) => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M15.9449 20.359V7C15.9449 6.44772 16.3926 6 16.9449 6L24 6.00001C24.5523 6.00001 25 6.44772 25 7.00001V22.9487C25 23.501 24.5523 23.9487 24 23.9487H16.9449C16.3926 23.9487 15.9449 23.5011 15.9449 22.9488C15.9449 22.2645 15.9449 21.3117 15.9449 20.359ZM15.9449 20.359H19.5346M15.9449 9.58976H20.4321M15.9449 16.7244L20.4321 16.7692M15.9449 13.1795H18.6372M10.3038 10.4872H5M7.65641 6.00002C6.19359 6.00002 5 6.0846 5 7.53845V20.2782L7.65641 23.9487L10.3038 20.2782V7.53845C10.3038 6.0846 9.11026 6.00002 7.65641 6.00002Z"
        stroke="currentColor"
        strokeLinecap="round"
      />
    </svg>
  )
}

export default DrawRulerIcon
