import { Content } from 'pdfmake/interfaces'
import { AssetDetails, parseHtml } from '../../Report.utils'
import { MakeProjectedExposureChartSvg } from '@src/components/Molecules/Charts/ProjectedExposureChart/ProjectedExposureChart'
import {
  ProjectedExposureChartState,
  ProjectedExposureChartStateByHazardId,
} from '@redux/projectedExposureChartSlice'

export const GenerateSelectedElementDetailsContent = (
  assetDetails: AssetDetails | null | undefined,
): Content => {
  if (!assetDetails || !assetDetails.asset) return []

  return [
    { text: 'Selected element details', style: 'h2' },
    {
      table: {
        body: [
          [
            { text: `Element classification: ${assetDetails.asset.name}` },
            { text: `ID: ${assetDetails.asset.id}`, alignment: 'right' },
          ],
        ],
        widths: ['*', 'auto'],
      },
      layout: 'noBorders',
      marginTop: 8,
      marginBottom: 8,
    },
    parseHtml(assetDetails.asset.sentence),
  ]
}

export const GenerateSelectedElementExposureBySelectedRisks = (
  assetDetails: AssetDetails | null | undefined,
  prefer_risk_term: boolean,
  projectedExposureChartState: ProjectedExposureChartStateByHazardId,
): Content => {
  if (!assetDetails || !assetDetails.hazards) return []

  const toReturn: Content = [
    ...assetDetails.hazards.map((hazard): Content => {
      const projectedExposureChartDivElement = document.createElement('div')
      const hazardProjectedExposureChartState: ProjectedExposureChartState | null =
        hazard.hazard_id !== undefined ? projectedExposureChartState[hazard.hazard_id] : null
      const showProjectedExposureChart =
        hazardProjectedExposureChartState &&
        hazardProjectedExposureChartState.data &&
        hazardProjectedExposureChartState.data.length > 0
      if (showProjectedExposureChart)
        MakeProjectedExposureChartSvg({
          ...hazardProjectedExposureChartState,
          svgElement: projectedExposureChartDivElement,
          width: 450,
        })
      return {
        stack: [
          {
            margin: [0, 10, 0, 0],
            table: {
              body: [
                [
                  {
                    text: '',
                    fillColor: hazard.vulnerabilityColour,
                    rowSpan: 2,
                  },
                  {
                    stack: [
                      { text: hazard.title, bold: true },
                      { text: parseHtml(hazard.sentence) },
                    ],
                    colSpan: 2,
                    margin: [10, 0, 0, 0],
                  },
                  '',
                ],
                [
                  '',
                  {
                    text: [
                      { text: prefer_risk_term ? 'Vulnerability: ' : 'Consequence: ' },
                      { text: hazard.vulnerabilityLabel, bold: true },
                    ],
                    margin: [10, 0, 0, 0],
                  },
                  hazard.exposure_value == null
                    ? []
                    : {
                        text: [
                          { text: 'Exposure: ' },
                          { text: `${hazard.exposure_value} ${hazard.unit}`, bold: true },
                        ],
                      },
                ],
              ],
              widths: ['auto', '*', '*'],
            },
            layout: 'noBorders',
            unbreakable: true,
          },
          showProjectedExposureChart
            ? {
                stack: [
                  { text: 'Projected Risk', style: 'h2', marginTop: 20 },
                  {
                    svg: projectedExposureChartDivElement.innerHTML,
                    marginTop: 20,
                    alignment: 'center',
                  },
                ],
                unbreakable: true,
              }
            : [],
        ],
        unbreakable: true,
      }
    }),
  ]
  return toReturn
}
