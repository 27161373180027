/** @jsxImportSource @emotion/react */

import { Box, Dialog, useTheme } from '@mui/material'
import releaseNotesMd from '../../../../../release-notes.md?raw'
import { useState, useEffect } from 'react'
import {
  content,
  contentParent,
  footer,
  HeaderContainer,
  versionMenu,
  versionsButton,
} from './ReleaseNotesModal.styles'
import UiMarkdown from '@src/components/Atoms/UiMarkdown/UiMarkdown'
import { css } from '@emotion/react'
import { ChevronLeft, ChevronRight } from '@mui/icons-material'

export const ReleaseNotesModal = ({
  open,
  handleClose,
}: {
  open: boolean
  handleClose: (arg0: boolean) => void
}) => {
  const theme = useTheme()
  const [postMarkdown, setPostMarkdown] = useState<{ title: string; content: string }[]>([])
  const [selectedPost, setSelectedPost] = useState<number>(0)
  const [menuVisible, setMenuVisible] = useState<boolean>(false)

  useEffect(() => {
    const splitMarkdown = releaseNotesMd.split(/\n# /).map((item) => {
      return {
        title: item.split('\n')[0].replace('# ', ''),
        content: item.split('\n').slice(1).join('\n').trim(),
      }
    })
    setPostMarkdown(splitMarkdown)
  }, [])

  return (
    <Dialog open={open} onClose={() => handleClose(false)}>
      <Box
        css={css`
          display: flex;
          flex-direction: column;
          overflow-y: auto;
        `}
      >
        <Box css={HeaderContainer({ theme })}>
          <h1>Release Notes</h1>
          <Box
            css={versionsButton(theme, menuVisible)}
            onClick={() => setMenuVisible(!menuVisible)}
          >
            {menuVisible ? <ChevronRight /> : <ChevronLeft />}
            <span>Previous Versions</span>
          </Box>
        </Box>
        <Box
          css={css`
            display: flex;
            overflow-y: auto;
            position: relative;
            height: 1000px;
          `}
        >
          {postMarkdown.length > 0 && (
            <>
              <Box css={contentParent}>
                <Box css={content(theme)}>
                  <h2>{postMarkdown[selectedPost].title}</h2>
                  <UiMarkdown>{postMarkdown[selectedPost].content}</UiMarkdown>
                </Box>
              </Box>
              <Box css={versionMenu(theme, menuVisible)}>
                {postMarkdown.map((item, index) => (
                  <Box
                    onClick={() => setSelectedPost(index)}
                    className={selectedPost == index ? 'selected' : ''}
                    key={index}
                  >
                    {item.title}
                  </Box>
                ))}
              </Box>
            </>
          )}
        </Box>
        <Box css={footer(theme)}>
          <img src="/logos/UILogoFullWhite.svg" alt="uintel logo" />
        </Box>
      </Box>
    </Dialog>
  )
}
