/** @jsxImportSource @emotion/react */

import { Box, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'

import { ModalLayer } from '@molecules/LayersControl'
import { LayersOrganizer } from '@src/components/Molecules/SideDrawerContent/tabs/InformativePanel/LayersTab/LayersOrganizer'
import { MapLayer } from '@redux/map/mapSlice'
import { Section } from '@uintel/ui-component-library'
import {
  layersPreviewBody,
  layersPreviewContainer,
  layersPreviewSection,
  layersPreviewTitle,
} from './LayersPreview.styles'
import { useSelector } from 'react-redux'
import { RootState } from '@redux/store'

export interface DialogLayersPreviewProps {
  stagedLayers: ModalLayer[]
  handleUpdateLayers: (newLayers: MapLayer[]) => void
  handleToggleLayerVisibility: (layer: MapLayer) => void
}

export interface LayersPreviewProps {
  dialogProps: DialogLayersPreviewProps
  isDialogPreview?: boolean
}

export const LayersPreview = ({ dialogProps, isDialogPreview = false }: LayersPreviewProps) => {
  const theme = useTheme()
  const { legendsData } = useSelector((state: RootState) => state.map)

  return (
    <Box css={layersPreviewContainer}>
      <Section css={layersPreviewSection}>
        <Typography variant="body1" align="center" css={layersPreviewTitle}>
          Layers Preview
        </Typography>
        <Box css={layersPreviewBody({ theme })}>
          {Object.keys(legendsData).length ? (
            <LayersOrganizer dialogProps={dialogProps} isDialogPreview={isDialogPreview} />
          ) : (
            ''
          )}
        </Box>
      </Section>
    </Box>
  )
}
