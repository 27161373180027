import { MapLayer } from '@redux/map/mapSlice'
import { BASE_API_URL } from '@src/app-constants'
import axios from '@src/utils/customAxios'
import { Icon } from '@src/components/Atoms/Icon'
import { useEffect, useState } from 'react'
import { useLayerDetails } from '@contexts/LayerDetailsContext/LayerDetailsContext'

export interface LayerDetailsReport {
  layer_id: string
  title: string
  icon: Icon
  sources?: {
    title: string
    url: string
  }[]
  source?: string
  additional_information?: {
    title: string
    url: string
  }[]
  sections: {
    title: string
    description: string
  }[]
  images?: {
    url: string
    alt: string
  }[]
  confidence?: {
    title: string
    description: string
    colour: string
  }
  coveredArea?: {
    title: string
    description: string
    regions: string[]
  }
  details?: {
    label: string
    value: string
  }[]
  summary?: string
  startsOpen?: boolean
  accordions?: {
    title: string
    description: string
    startsOpen?: boolean
  }[]
}

export interface LayerDetailsProps {
  layer: string
  displayErrorImage?: boolean
  defaultExpanded?: boolean
  displayErrorMessage?: boolean
}

const fetchAPILayerDetails = async (abortController: AbortController, layerAssetTag: string) => {
  const url = `${BASE_API_URL}/api/layer/details/${layerAssetTag}`

  try {
    const response = await axios.get(url, {
      signal: abortController.signal,
    })

    if (response.status !== 200) return null

    return response.data
  } catch (error) {
    return null
  }
}

export const useLayerListDetailsFetcher = (layers: MapLayer[], stagedLayers?: MapLayer[]) => {
  const [isLoading, setIsLoading] = useState(false)

  const { layerDetailsList, setLayerDetailsList, layerDetailsListCache, setLayerDetailsListCache } =
    useLayerDetails()

  useEffect(() => {
    const abortController = new AbortController()

    const fetchData = async (layersDefined: MapLayer[]) => {
      setIsLoading(true)

      const data = await Promise.all(
        layersDefined.map(async (layer) => {
          const layer_id =
            layer.layerType === 'hazard'
              ? layer.assetTag
              : layer.layerType === 'asset'
              ? layer.type
              : layer.layerType === 'information'
              ? layer.id
              : ''

          // if layer_id in cache, return cache
          if (layerDetailsListCache[layer_id]) {
            return layerDetailsListCache[layer_id]
          }
          const layerDetails = await fetchAPILayerDetails(abortController, layer_id)
          return layerDetails
        }),
      )
      // using data, set the cache to all keys not found in the current cache
      const newCache = data.reduce<{ [key: string]: LayerDetailsReport[][] | null }>(
        (acc, layerDetails, index) => {
          const layer = layersDefined[index]
          const layer_id =
            layer.layerType === 'hazard'
              ? layer.assetTag
              : layer.layerType === 'asset'
              ? layer.type
              : layer.layerType === 'information'
              ? layer.id
              : ''

          if (!layerDetailsListCache[layer_id]) {
            acc[layer_id] = layerDetails
          }
          return acc
        },
        {},
      )
      if (Object.keys(newCache).length > 0)
        setLayerDetailsListCache((prev) => ({ ...prev, ...newCache }))

      setLayerDetailsList(data)
      setIsLoading(false)
    }

    if (layers.length > 0 || (stagedLayers && stagedLayers.length > 0)) {
      if (stagedLayers && stagedLayers.length > 0) {
        fetchData([...layers, ...stagedLayers])
      } else {
        fetchData(layers)
      }
    }

    return () => abortController.abort()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [layers, stagedLayers])

  return { isLoading, layerDetailsList }
}
