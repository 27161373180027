/** @jsxImportSource @emotion/react */

import { useFeatureFlags } from '@contexts/FeatureFlagsContext'
import { HazardControls } from './HazardControls'
import {
  layerDataHazardIdTransformer,
  useLayerDataFetcher,
} from '../../../data_fetchers/layerDataFetcher'

import { MapLayer } from '@redux/map/mapSlice'
import { useCallback, useMemo, useState } from 'react'
import { RootState } from '@redux/store'
import { useSelector } from 'react-redux'
import { HazardDetail } from '@src/components/Molecules/MapView/AddLayersDialog'
import { LayersOrganizer } from '@src/components/Molecules/SideDrawerContent/tabs/InformativePanel/LayersTab/LayersOrganizer'
import { Accordion } from '../../../../../Atoms/Accordion'
import { Box, Button, Popover, Typography, useTheme } from '@mui/material'
import { useMap } from '@contexts/MapContext'
import { css } from '@emotion/react'
import { EmptySideDrawer } from '../../EmptyTab'
import { layersTabParent, mapLayerHeader } from './LayersTab.styles'
import { SideDrawerDivider } from '../../../components/SideDrawerDivider'
import { SideDrawerFooter } from '../../../components/SideDrawerFooter'
import { HazardGroup } from '../InformativePanel'
import { useLayers } from '@contexts/LayerContext'

export const LayersTab = () => {
  const theme = useTheme()
  const { features } = useFeatureFlags()
  const { setShowAddLayersModalTab, setHazardOptionState } = useMap()
  const { prepareAndUpdateLayers } = useLayers()
  const { layers } = useSelector((state: RootState) => state.map)
  const layersData = useLayerDataFetcher()
  const transformedLayerData = layerDataHazardIdTransformer(layersData)

  const { clientName } = useSelector((state: RootState) => state.user)

  const [deleteAllLayersButtonAnchor, setDeleteAllLayersButtonAnchor] =
    useState<HTMLButtonElement | null>(null)

  const hazardLayers = layers.filter((layer) => layer.layerType === 'hazard')

  const showHazardControls = !!features.find(
    (feature) => feature.feature === 'hazardParameters' && feature.enabled,
  )

  /**
   * Get the dialog layer data that matches the hazardMapLayers
   */
  const filteredLayersData = useMemo(() => {
    const hazardMapLayers = layers.filter((layer: MapLayer) => layer.layerType === 'hazard')
    const res = layersData?.hazardDialogData.hazardDetails.filter((hazardDetail: HazardDetail) => {
      // filter based on title
      return hazardMapLayers.some((layer: MapLayer) => {
        if (layer.hazard_id) {
          return hazardDetail.scenarios.some((scenario) => {
            return layer.hazard_id === scenario.hazard_id
          })
        }
        return layer.hazard_title === hazardDetail.title
      })
    })
    return res
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [layersData, layers, clientName])

  function groupHazardsByType(hazardLayers: MapLayer[]): HazardGroup[] {
    const hazardLayersByType: HazardGroup[] = []
    hazardLayers.forEach((readonly_layer) => {
      const layer = { ...readonly_layer }
      const hazardType = layer.hazard_title ?? layer.display_name.split('(')[0].trim()
      let hazardScenario = layer.display_name
      if (layer.scenario_name) {
        hazardScenario = layer.scenario_name
      } else if (hazardScenario.includes('(')) {
        hazardScenario = hazardScenario.split('(')[1].split(')')[0].trim()
        layer.scenario_name = hazardScenario
      }
      const hazardGroup = hazardLayersByType.find((group) => group.hazard_type === hazardType)
      if (hazardGroup) {
        hazardGroup.scenarios.push({ scenario: hazardScenario, layer })
      } else {
        hazardLayersByType.push({
          hazard_type: hazardType,
          scenarios: [{ scenario: hazardScenario, layer }],
        })
      }
    })
    return hazardLayersByType
  }

  const handleConfirmClearAllLayers = () => {
    prepareAndUpdateLayers({ layers: [] })
    setDeleteAllLayersButtonAnchor(null)
    setHazardOptionState({})
  }

  const hasHazardControls = filteredLayersData?.some((hazardDetail) => hazardDetail.form.length > 0)
  const hasAtLeastOneUsableControl = useCallback(
    (hazardLayers: MapLayer[]) => {
      // if there is no transformed data (no hazard_id), do the same operations but with filteredLayersData instead
      if (!transformedLayerData) {
        return filteredLayersData?.some((hazardDetail) => {
          const paramObj: { [key: string]: (string | number)[] } = {}
          for (const scenario of hazardDetail.scenarios) {
            for (const paramKey of Object.keys(scenario.parameters)) {
              if (paramObj[paramKey]) {
                paramObj[paramKey].push(scenario.parameters[paramKey])
              } else {
                paramObj[paramKey] = [scenario.parameters[paramKey]]
              }
            }
          }
          return Object.keys(paramObj).some((paramKey) => {
            return paramObj[paramKey].length > 1
          })
        })
      } else {
        const hazardLayersGrouped = groupHazardsByType(hazardLayers)
        const filteredTransformedLayerData = Object.keys(transformedLayerData).filter((key) => {
          const foundHazardLayer = hazardLayersGrouped.find((hazardLayer) => {
            return hazardLayer.scenarios.some((scenario) => {
              return `${scenario.layer.hazard_id}` === `${key}`
            })
          })
          return !!foundHazardLayer
        })
        return filteredTransformedLayerData.some((hazardId) => {
          if (
            !hazardLayersGrouped.some((layer) =>
              layer.scenarios.some((scenario) => {
                return scenario.layer.hazard_id == hazardId
              }),
            )
          ) {
            return false
          }
          return Object.keys(transformedLayerData[hazardId].availableParameterValues).some(
            (parameterKey) => {
              return (
                transformedLayerData[hazardId].availableParameterValues[parameterKey].length > 1
              )
            },
          )
        })
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [hazardLayers, clientName, transformedLayerData, filteredLayersData],
  )

  return layers.length > 0 ? (
    <>
      <Box css={layersTabParent} className="tutorial-layer-organizer">
        {showHazardControls &&
          hazardLayers.length > 0 &&
          hasAtLeastOneUsableControl(hazardLayers) &&
          hasHazardControls && (
            <>
              <Box
                css={css`
                  margin: 8px;
                `}
              >
                <Accordion
                  title="Scenario Controls"
                  variant="panel"
                  level="h3"
                  body={
                    <HazardControls
                      layersData={layersData}
                      scenarioByHazardId={transformedLayerData}
                      hazardIds={layers
                        .filter((l) => l.layerType == 'hazard')
                        .map((l) => l.hazard_id ?? l.display_name)
                        .toSorted()}
                    />
                  }
                  defaultExpanded
                />
              </Box>
              <SideDrawerDivider />
            </>
          )}
        <Box className="tutorial-maplayers">
          <Accordion
            header={
              <Box css={mapLayerHeader}>
                <Typography>Map Layers</Typography>
                <Box sx={{ alignSelf: 'flex-end' }}>
                  <Button
                    onClick={(e) => {
                      e.stopPropagation()
                      setDeleteAllLayersButtonAnchor(e.currentTarget)
                    }}
                    id="clear-all-layers-button"
                    variant="text"
                    size="small"
                    css={css`
                      align-self: flex-end;
                      margin-right: 8px;
                      color: ${theme.palette.warning.contrastText};
                    `}
                  >
                    Clear All Layers
                  </Button>
                  <Popover
                    id={deleteAllLayersButtonAnchor ? 'clear-all-layers-button' : undefined}
                    open={deleteAllLayersButtonAnchor?.id === 'clear-all-layers-button'}
                    anchorEl={deleteAllLayersButtonAnchor}
                    onClose={() => setDeleteAllLayersButtonAnchor(null)}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <Button
                      size="small"
                      variant="text"
                      color="error"
                      onClick={(e) => {
                        e.stopPropagation()
                        handleConfirmClearAllLayers()
                      }}
                    >
                      Clear
                    </Button>
                  </Popover>
                  <Button
                    onClick={(e) => {
                      e.stopPropagation()
                      setShowAddLayersModalTab('elements')
                    }}
                    variant="outlined"
                    size="small"
                    css={css`
                      align-self: flex-end;
                    `}
                  >
                    Add Layers +
                  </Button>
                </Box>
              </Box>
            }
            variant="clear"
            level="h1"
            body={<LayersOrganizer />}
            defaultExpanded
          />
        </Box>
      </Box>
      <SideDrawerFooter />
    </>
  ) : (
    <EmptySideDrawer
      text="No layers are visible on the map. Add layers to gain insights"
      type="element"
      buttonText="Add Layers"
    />
  )
}
