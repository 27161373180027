import { css } from '@emotion/react'
import { darken, lighten, Theme } from '@mui/material/styles'

export const SubmitFeedbackModalContainer = ({ theme }: { theme: Theme }) => css`
  border-radius: ${theme.shape.borderRadius}px;
  box-shadow: ${theme.shadows[5]};
`

// class for centering a modal
// also for changing the child elements styling
export const SubmitFeedbackModalContentContainer = ({ theme }: { theme: Theme }) => css`
  max-height: 80vh;
  max-width: 800px;
  min-width: 600px;

  width: 100%;
  overflow-y: auto;
  border-radius: ${theme.shape.borderRadius}px;
  margin: auto;
`

export const HeaderContainer = ({ theme }: { theme: Theme }) => css`
  background-color: #0b2a48;
  color: ${theme.palette.white.main};
  width: 100%;
  padding: ${theme.spacing(6)};
  padding-left: ${theme.spacing(7)};
  padding-top: 0;
`

export const HeaderImgContainer = ({ theme }: { theme: Theme }) => css`
  width: 100%;
  height: 100%;
  max-height: 125px;
  padding: ${theme.spacing(6)};
  background-color: #0b2a48;
  object-fit: contain;
`

export const formWrapper = ({ theme }: { theme: Theme }) => css`
  display: flex;
  flex-direction: column;
  padding-top: ${theme.spacing(2)};
  gap: ${theme.spacing(4)};
  width: 100%;
`

export const uiButtonOutlined = (theme: Theme) => css`
  border-color: #0b2a48;
  color: ${theme.palette.text.primary};
  &:hover {
    border-color: ${lighten('#0b2a48', 0.1)};
    background-color: ${darken('#fff', 0.05)};
  }
`

export const uiButton = (theme: Theme) => css`
  background-color: #0b2a48;
  color: ${theme.palette.white.main};
  &:hover {
    background-color: ${lighten('#0b2a48', 0.1)};
  }
`

export const hiddenInput = css`
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  whitespace: nowrap;
  width: 1;
`
