/** @jsxImportSource @emotion/react */
import { Box, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'

import {
  multipleBubbleAxisContainer,
  xAxis,
  xAxisArrowBottom,
  xAxisArrowTop,
  xAxisEndLabel,
  xAxisLabel,
  xAxisLine,
  xAxisStartLabel,
  yAxis,
  yAxisArrowBottom,
  yAxisArrowTop,
  yAxisEndLabel,
  yAxisLabel,
  yAxisLine,
  yAxisStartLabel,
} from './MultipleBubbleChartsAxis.styles'

export const MultipleBubbleChartsAxis = () => {
  const theme = useTheme()

  return (
    <Box css={multipleBubbleAxisContainer}>
      <Box css={xAxis}>
        <Typography css={xAxisStartLabel({ theme })}>Low</Typography>
        <Typography css={xAxisLabel({ theme })}>Physical Risk</Typography>
        <Typography css={xAxisEndLabel({ theme })}>High</Typography>
        <Box css={xAxisLine({ theme })} />
        <Box css={xAxisArrowTop({ theme })} />
        <Box css={xAxisArrowBottom({ theme })} />
      </Box>
      <Box css={yAxis}>
        <Typography css={yAxisStartLabel({ theme })}>High</Typography>
        <Typography css={yAxisLabel({ theme })}>Transition Risk</Typography>
        <Typography css={yAxisEndLabel({ theme })}>Low</Typography>
        <Box css={yAxisLine({ theme })} />
        <Box css={yAxisArrowTop({ theme })} />
        <Box css={yAxisArrowBottom({ theme })} />
      </Box>
    </Box>
  )
}
