import { css } from '@emotion/react'
import { Theme } from '@mui/material/styles'

export const storyControls = (theme: Theme) => css`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: flex-start;
  justify-content: space-between;
  width: ${theme.spacing(10)};
  padding: ${theme.spacing(1)};
  position: absolute;
  top: ${theme.spacing(2)};
  left: ${theme.spacing(2)};
  gap: ${theme.spacing(1)};
  user-select: none;

  & > * {
    cursor: pointer;
    padding: ${theme.spacing(2)};
    box-sizing: initial;
    border-radius: 50%;
    transition: background-color 0.3s, box-shadow 0.3s;

    &:hover {
      background-color: ${theme.palette.primary.main}20;
    }
  }
`
