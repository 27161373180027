import { darken, Theme } from '@mui/material'
import { css } from '@emotion/react'

export const informationColumn = ({ theme }: { theme: Theme }) => css`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing(2)};
  margin: ${theme.spacing(2)} 0;
`

export const reportPrintContainer = ({ theme }: { theme: Theme }) => css`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 0;
  background: ${theme.palette.white.main};
  box-shadow: 0 1px 4px 0 ${theme.palette.primary.dark}80;
  z-index: 1;
  height: 40px;
  flex-shrink: 0;
`

export const scrollingRiskBody = ({ theme }: { theme: Theme }) => css`
  flex-grow: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;

  &::-webkit-scrollbar {
    width: 12px !important;
  }

  &::-webkit-scrollbar-track {
    background-color: ${theme.palette.primary.main}00 !important;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${theme.palette.primary.main}40 !important;
    border-radius: 0px !important;
  }
  &::-webkit-scrollbar-thumb:hover {
    background-color: ${theme.palette.primary.main}80 !important;
  }
`

export const riskSummaryFooter = css`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex-grow: 1;
  justify-content: flex-end;
`

export const riskSummaryFooterInner = css`
  padding: 16px 8px 4px 8px;
  position: relative;

  .tutorial-risk-footer {
    position: absolute;
    inset: 8px;
    pointer-events: none;
    user-select: none;
  }
`

export const riskSummaryFilterBanner = ({ theme }: { theme: Theme }) => css`
  padding: ${theme.spacing(2)};
  padding-left: ${theme.spacing(4)};
  background-color: ${theme.palette.primary.main}40;
  color: ${darken(theme.palette.primary.contrastText, 0.9)};
  font-style: italic;
`
