export const SnowFlakeIcon = ({ ...rest }) => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path d="M15.0391 15.9781V4" stroke="currentColor" strokeWidth="1.4" />
      <path d="M15.2632 9.17831L19.4259 5.20305" stroke="currentColor" strokeWidth="1.4" />
      <path d="M14.8135 9.17829L10.6508 5.30765" stroke="currentColor" strokeWidth="1.4" />
      <path d="M15.2076 15.0389L24.8047 9.8868" stroke="currentColor" strokeWidth="1.4" />
      <path d="M20.0945 12.6574L25.8782 14.0218" stroke="currentColor" strokeWidth="1.4" />
      <path d="M19.8691 12.2949L21.3928 7.00751" stroke="currentColor" strokeWidth="1.4" />
      <path d="M14.7914 15.0395L5.19434 9.88731" stroke="currentColor" strokeWidth="1.4" />
      <path d="M10.1297 12.295L8.50859 6.95531" stroke="currentColor" strokeWidth="1.4" />
      <path d="M9.90479 12.6575L4.21846 14.0742" stroke="currentColor" strokeWidth="1.4" />
      <path d="M14.9609 15.6957L14.9609 26" stroke="currentColor" strokeWidth="1.4" />
      <path d="M14.7358 20.8217L10.5732 24.797" stroke="currentColor" strokeWidth="1.4" />
      <path d="M15.186 20.8217L19.3487 24.6923" stroke="currentColor" strokeWidth="1.4" />
      <path d="M14.7909 14.9612L5.19385 20.1133" stroke="currentColor" strokeWidth="1.4" />
      <path d="M9.90408 17.3427L4.12032 15.9783" stroke="currentColor" strokeWidth="1.4" />
      <path d="M10.1294 17.7052L8.60575 22.9926" stroke="currentColor" strokeWidth="1.4" />
      <path d="M15.2071 14.9606L24.8042 20.1127" stroke="currentColor" strokeWidth="1.4" />
      <path d="M19.8689 17.705L21.4899 23.0447" stroke="currentColor" strokeWidth="1.4" />
      <path d="M20.0933 17.3425L25.7796 15.9258" stroke="currentColor" strokeWidth="1.4" />
    </svg>
  )
}

export default SnowFlakeIcon
