import { defaultDropAnimationSideEffects, DragOverlay, type DropAnimation } from '@dnd-kit/core'
import type { PropsWithChildren } from 'react'
import { createPortal } from 'react-dom'

const dropAnimationConfig: DropAnimation = {
  sideEffects: defaultDropAnimationSideEffects({
    styles: {
      active: {
        opacity: '0.4',
      },
    },
  }),
}

type Props = object

export function SortableOverlay({ children }: PropsWithChildren<Props>) {
  const rootElement = document.getElementById('root')

  if (!rootElement) return <></>
  return (
    <>
      {createPortal(
        <DragOverlay dropAnimation={dropAnimationConfig} style={{ zIndex: 99999 }}>
          {children}
        </DragOverlay>,
        rootElement,
      )}
    </>
  )
}
