import { css } from '@emotion/react'
import { Theme } from '@mui/material/styles'

export const contentBlock = ({
  theme,
  filled,
  onlyHeader,
}: {
  theme: Theme
  filled: boolean
  onlyHeader: boolean
}) => css`
  background-color: ${filled ? '#0b294820' : theme.palette.background.default};
  display: flex;
  padding: ${filled ? theme.spacing(8) : 0} ${theme.spacing(16)};
  ${onlyHeader ? `margin: ${theme.spacing(0)} 0 ${theme.spacing(-8)};` : ''}
  justify-content: center;

  > div {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 1000px;
    max-width: 1000px;
    gap: ${theme.spacing(4)};
  }

  h1 {
    color: #0b2948;
    font-size: 20px;
    line-height: 1;
    margin-bottom: ${theme.spacing(1)};
  }

  p {
    margin-left: ${theme.spacing(2)};
    font-size: 15px;
  }
`
