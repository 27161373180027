import { css } from '@emotion/react'
import { Theme } from '@mui/material/styles'

interface AdminPageContainerProps {
  theme: Theme
}

export const AdminPageContainer = ({ theme }: AdminPageContainerProps) => css`
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: center;

  width: 100%;
  height: 100svh;
  min-height: 100%;

  overflow: auto;

  margin: 0 auto;
  background-color: ${theme.palette.primary.main}18;
`

export const AdminPageContentContainer = ({ theme }: AdminPageContainerProps) => css`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  width: 95%;
  max-width: 1200px;

  padding: ${theme.spacing(3)};
`

export const formWrapper = css`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
`
