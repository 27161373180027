/** @jsxImportSource @emotion/react */
import { Box, Divider, Typography, useTheme } from '@mui/material'
import { LayerDetailsReport } from '../../../../data_fetchers/layerDetailsFetcher'
import {
  accordionsContainer,
  layerDetailConfidence,
  layerDetailDetails,
  layerDetailImage,
  layerDetailImagesContainer,
  layerDetailSources,
  layerDetailsAccordionContent,
  layerDetailsCoveredArea,
  layerDetailsCoveredAreaContent,
  layerDetailsCoveredAreaDivider,
  layerDetailsCoveredAreaRegions,
} from './LayerInfoAccordions.styles'
import React from 'react'
import { SourceButton } from '../../../../components/SourceButton'
import UiMarkdown from '@src/components/Atoms/UiMarkdown/UiMarkdown'
import { UiclAccordion } from '@src/components/Atoms/Accordion/UiclAccordion'

interface LayerInfoContentProps {
  layerDetail: LayerDetailsReport
}

export const LayerInfoContent = ({ layerDetail }: LayerInfoContentProps) => {
  const theme = useTheme()

  if (!layerDetail.additional_information || !layerDetail.source || layerDetail.sources) {
    layerDetail.additional_information = layerDetail.sources?.filter(
      (source) => source.title !== 'Source',
    )
    layerDetail.source = layerDetail.sources?.filter((source) => source.title === 'Source')[0]?.url
  }

  return (
    <Box css={layerDetailsAccordionContent({ theme })}>
      {layerDetail.summary ? (
        <Box>
          <Typography fontWeight={700}>Summary</Typography>
          <UiMarkdown>
            {layerDetail.summary.trim().startsWith('Summary<br>')
              ? layerDetail.summary.trim().substring(11)
              : layerDetail.summary ?? ''}
          </UiMarkdown>
        </Box>
      ) : null}

      {layerDetail.sections.map((section, index) => (
        <React.Fragment key={index + section.title}>
          <Box>
            <Typography fontWeight={700}>{section.title ?? ''}</Typography>
            <UiMarkdown>{section.description ?? ''}</UiMarkdown>
          </Box>
          {layerDetail.sections.length - 1 !== index ? <Divider light /> : null}
        </React.Fragment>
      ))}

      {layerDetail.confidence ? (
        <Box
          css={layerDetailConfidence({
            theme,
            colour: layerDetail.confidence.colour,
          })}
        >
          <Box>
            <Typography>Data suitability: </Typography>
            <Typography fontWeight={700}>{layerDetail.confidence.title ?? ''}</Typography>
            <UiMarkdown>{layerDetail.confidence.description ?? ''}</UiMarkdown>
          </Box>
        </Box>
      ) : null}

      {layerDetail.accordions ? (
        <Box css={accordionsContainer({ theme })}>
          {layerDetail.accordions.map((accordion, index) => (
            <UiclAccordion
              lazyContent
              key={index + accordion.title}
              defaultExpanded={accordion.startsOpen ?? true}
              header={<Typography fontWeight={700}>{accordion.title ?? ''}</Typography>}
              body={<UiMarkdown>{accordion.description ?? ''}</UiMarkdown>}
            />
          ))}
        </Box>
      ) : null}

      {layerDetail.images ? (
        <Box css={layerDetailImagesContainer({ theme })}>
          {layerDetail.images.map((image, index) => (
            <Box key={index + image.url} title={image.alt} css={layerDetailImage({ theme })}>
              <img src={image.url} alt={image.alt} />
            </Box>
          ))}
        </Box>
      ) : null}

      {layerDetail.coveredArea || layerDetail.details ? (
        <Box>
          <Box css={layerDetailsCoveredArea({ theme })}>
            {layerDetail.coveredArea ? (
              <Box>
                <Typography fontWeight={700}>{layerDetail.coveredArea.title ?? ''}</Typography>
                <UiMarkdown>{layerDetail.coveredArea.description ?? ''}</UiMarkdown>
                <Box css={layerDetailsCoveredAreaContent({ theme })}>
                  {layerDetail.coveredArea.regions.map((region, index) => (
                    <Box key={index + region} css={layerDetailsCoveredAreaRegions({ theme })}>
                      <Typography>{region ?? ''}</Typography>
                    </Box>
                  ))}
                </Box>
              </Box>
            ) : null}

            {layerDetail.details && layerDetail.coveredArea ? (
              <Divider
                light
                orientation="vertical"
                flexItem
                css={layerDetailsCoveredAreaDivider({ theme })}
              />
            ) : null}

            {layerDetail.details ? (
              <Box css={layerDetailDetails({ theme })}>
                {layerDetail.details.map((detail, index) => (
                  <Box key={index + detail.label}>
                    <Typography fontWeight={700}>{detail.label ?? ''}</Typography>
                    <UiMarkdown>{detail.value ?? ''}</UiMarkdown>
                  </Box>
                ))}
              </Box>
            ) : null}
          </Box>
        </Box>
      ) : null}

      {layerDetail.source ? (
        <Box css={layerDetailSources({ theme })} marginBottom={theme.spacing(-2)}>
          <SourceButton source={layerDetail.source} key={layerDetail.source}>
            Source
          </SourceButton>
        </Box>
      ) : null}
      {layerDetail.additional_information ? (
        <Box css={layerDetailSources({ theme })}>
          {layerDetail.additional_information.map((source, index) => (
            <SourceButton source={source.url} key={index + source.url}>
              {source.title}
            </SourceButton>
          ))}
        </Box>
      ) : null}
    </Box>
  )
}
