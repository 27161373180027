import { css } from '@emotion/react'
import { Tooltip, TooltipProps, tooltipClasses } from '@mui/material'
import { Theme, styled } from '@mui/material/styles'

export const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({})

export const chartTooltip = ({ theme }: { theme: Theme }) => css`
  .${tooltipClasses.tooltip} {
    background-color: ${theme.palette.white.main};
    color: #0b2948;
    box-shadow: ${theme.shadows[1]}, inset 0 1px 0 0 ${theme.palette.divider}10;
    font-weight: 400;
    border-radius: ${theme.spacing(1)};
    padding: 0px;
    overflow: hidden;
  }

  .header {
    padding: ${theme.spacing(1, 2)};
    font-weight: 700;
    border-bottom: 1px solid #0b2948cc;
  }
`

export const tooltipContent = ({ theme }: { theme: Theme }) => css`
  font-size: 12px;
  display: flex;
  align-items: stretch;
  border-radius: ${theme.spacing(1)};

  .info {
    box-shadow: inset 0 1px 0 0 ${theme.palette.divider}10;
    padding: ${theme.spacing(1, 2)};
    line-height: 1.3;
    border-radius: ${theme.spacing(0, 1, 1, 0)};
  }
`

export const decoColor = (color: string) => css`
  background-color: ${color};
  padding: 4px;
  box-shadow: inset 0 1px 0 0 #0001;
`
