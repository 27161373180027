import axios from '@src/utils/customAxios'
import { Content } from 'pdfmake/interfaces'
import { convertFigmaPatternsToImages } from '../../Report.utils'
import { PUBLIC_ASSETS_BASE_URL } from '@src/app-constants'

export const GenerateBackPageContent = async (): Promise<Content[]> => {
  const svgBackpageResponse = await axios.get(
    `${PUBLIC_ASSETS_BASE_URL}/reports-ui/cover_pages/backpage.svg`,
    { withCredentials: false },
  )

  const svgBackpage = convertFigmaPatternsToImages(svgBackpageResponse.data)

  return [
    {
      svg: svgBackpage,
      absolutePosition: { x: 0, y: 0 },
      pageBreak: 'before',
    },
  ]
}
