import { css } from '@emotion/react'
import { Theme } from '@mui/material'

type SidebarButtonContainerProps = {
  theme: Theme
  active: boolean
  disabled: boolean
  hidden?: boolean
  displayLabel?: boolean
}

type SidebarButtonLabelProps = SidebarButtonContainerProps

export const sidebarButtonContainer = ({
  theme,
  active,
  disabled,
  hidden,
}: SidebarButtonContainerProps) => css`
  display: flex;
  justify-content: center;
  align-items: ${hidden ? 'flex-end' : 'center'};
  overflow: hidden;

  padding: ${hidden ? 0 : theme.spacing(2)} ${theme.spacing(2)};
  border-radius: ${theme.spacing(1)};
  background-color: ${active ? theme.palette.secondary.main : theme.palette.primary.main};
  background-color: ${hidden || (disabled && 'transparent')};
  transform: ${hidden ? 'translateY(4px)' : 'translateX(0)'};
  max-height: ${hidden ? 0 : ''};
  opacity: ${hidden ? 0 : 1};

  border: none;

  transition: background-color 0.2s, transform 0.4s, opacity 0.2s;

  &:hover {
    background-color: ${!hidden && !disabled && !active && theme.palette.primary.dark};
    cursor: ${hidden || disabled ? '' : 'pointer'};

    transform: ${!hidden && !disabled && !active && `translateX(4px)`};
  }

  > svg {
    width: ${theme.spacing(7)};
  }

  position: relative;
`

export const sidebarButtonLabel = ({
  theme,
  active,
  displayLabel,
  disabled,
}: SidebarButtonLabelProps) => css`
  color: ${active ? theme.palette.primary.main : theme.palette.primary.contrastText};
  color: ${disabled && theme.palette.primary.dark};
  white-space: nowrap;
  flex-grow: 1;
  text-align: left;
  width: 0;
  opacity: ${displayLabel ? 1 : '0 !important'};
  transition: opacity 0.1s, transform 0.2s;
  transform: ${displayLabel ? 'translateX(8px)' : 'translateX(-20px)'};
`
