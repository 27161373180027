/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import {
  Box,
  FormControlLabel,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography,
} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { useCallback, useEffect, useState } from 'react'

import { PasswordInput } from '@src/components/Molecules/PasswordInput'
import { Icon } from '@src/components/Atoms/Icon'
import { Checkbox, useSnackbars } from '@uintel/ui-component-library'
import { Button } from '@src/components/Atoms/Button'
import { BASE_API_URL, validEmailRegex } from '../../../../../app-constants'
import { LoadingButton, PasswordProps, ValidationInputProps } from '../SignInModal'
import {
  codeAndPasswordContainer,
  codeAndPasswordContent,
  codeAndPasswordModalContainer,
  emptyBox,
  finalStep,
  formControlGap,
  formWrapper,
  loginContainer,
  resetPasswordButton,
  signInBodyContent,
  signInFooter,
  stepTitle,
  stepper,
} from '../SignInModal.styles'
import { checkIfPasswordHasValidFormat } from '../SignInModal.utils'
import axios from '@src/utils/customAxios'
import { AxiosError } from 'axios'

interface ForgotPasswordProps {
  validationCode: ValidationInputProps
  email: ValidationInputProps
  password: PasswordProps
  confirmPassword: PasswordProps
  forgotPassword: boolean
  isLoading: boolean
  handleChangePassword: (password: PasswordProps) => void
  handleChangeConfirmPassword: (confirmPassword: PasswordProps) => void
  handleChangeForgotPassword: (forgotPassword: boolean) => void
  handleChangeEmail: (email: ValidationInputProps) => void
  handleChangeCode: (code: ValidationInputProps) => void
  handleSetLoading: (loading: boolean) => void
  handleClose: () => void
}

export const ForgotPassword = ({
  email,
  validationCode,
  password,
  confirmPassword,
  forgotPassword,
  isLoading,
  handleChangePassword,
  handleChangeConfirmPassword,
  handleChangeForgotPassword,
  handleChangeEmail,
  handleChangeCode,
  handleSetLoading,
  handleClose,
}: ForgotPasswordProps) => {
  const theme = useTheme()
  const { displaySnackbar } = useSnackbars()
  const [activeStep, setActiveStep] = useState(0)

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const handleCheckPasswords = useCallback(() => {
    const passwordErrors = checkIfPasswordHasValidFormat(password.value)

    if (passwordErrors.length) {
      handleChangePassword({ ...password, error: true, helperTexts: passwordErrors })
      handleSetLoading(false)
      return false
    }

    if (password.value !== confirmPassword.value) {
      handleChangeConfirmPassword({
        ...confirmPassword,
        error: true,
        helperTexts: ['Passwords do not match'],
      })
      handleSetLoading(false)
      return false
    }

    return true
  }, [
    confirmPassword,
    handleChangeConfirmPassword,
    handleChangePassword,
    handleSetLoading,
    password,
  ])

  const handleVerifyCode = useCallback(() => {
    if (validationCode.value.match(/\D/g)) {
      handleChangeCode({ ...validationCode, error: true, helperText: 'Code must be only numbers' })
      handleSetLoading(false)
      return false
    }

    if (validationCode.value.length !== 6) {
      handleChangeCode({ ...validationCode, error: true, helperText: 'Code must be 6 digits long' })
      handleSetLoading(false)
      return false
    }

    return true
  }, [handleChangeCode, handleSetLoading, validationCode])

  const apiForgotPassword = useCallback(async () => {
    try {
      const forgotPasswordResponse = await axios.post(`${BASE_API_URL}/api/user/forgot_password`, {
        email: email.value,
      })

      const success = forgotPasswordResponse.status === 200
      return success
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error)
      displaySnackbar({ message: 'Something went wrong.', type: 'error' })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displaySnackbar, email.value])

  const apiConfirmForgotPassword = useCallback(async (): Promise<string> => {
    try {
      const confirmForgotPasswordResponse = await axios.post(
        `${BASE_API_URL}/api/user/confirm_forgot_password`,
        {
          email: email.value,
          confirmation_code: validationCode.value,
          password: confirmPassword.value,
        },
      )

      if (confirmForgotPasswordResponse.status === 200) return 'success'
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status === 401 && error.response?.data === 'Confirmation code mismatch')
          return 'Confirmation code mismatch'

        if (error.response?.status === 401 && error.response?.data === 'Confirmation code expired')
          return 'Confirmation code expired'

        if (error.response?.status === 429 && error.response?.data === 'Limit exceeded')
          return 'Limit exceeded'
      }
      displaySnackbar({ message: 'Something went wrong.', type: 'error' })
    }

    return ''
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmPassword.value, displaySnackbar, email.value, validationCode.value])

  const handleForgotPassword = useCallback(async () => {
    handleSetLoading(true)

    const isEmailValid = validEmailRegex.test(email.value)

    if (!isEmailValid) {
      handleSetLoading(false)
      handleChangeEmail({ ...email, error: true, helperText: 'Invalid email' })
      return
    }

    const isForgotPasswordRequestSuccessful = await apiForgotPassword()

    if (!isForgotPasswordRequestSuccessful) {
      handleSetLoading(false)
      displaySnackbar({
        message: 'Something went wrong. Please check if the e-mail is valid',
        type: 'error',
      })
      return
    }

    displaySnackbar({
      message: 'If email exists you will receive a confirmation code.',
      type: 'info',
    })

    handleSetLoading(false)
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }, [apiForgotPassword, displaySnackbar, email, handleChangeEmail, handleSetLoading])

  const handleResetPassword = useCallback(async () => {
    handleSetLoading(true)

    const isValidCode = handleVerifyCode()
    const isValidPassword = handleCheckPasswords()

    if (!isValidCode || !isValidPassword) return handleSetLoading(false)

    const confirmPasswordResponse = await apiConfirmForgotPassword()

    handleSetLoading(false)

    if (confirmPasswordResponse == 'Confirmation code mismatch') {
      displaySnackbar({
        message: 'Confirmation code does not match, please try again.',
        type: 'error',
      })
      return
    }

    if (confirmPasswordResponse == 'Confirmation code expired') {
      displaySnackbar({
        message: 'Confirmation code has expired, please try again.',
        type: 'error',
      })
      handleClose()
      return
    }

    if (confirmPasswordResponse == 'Limit exceeded') {
      displaySnackbar({
        message: 'Too many attempts, please wait and try again',
        type: 'error',
      })
      return
    }

    if (confirmPasswordResponse !== 'success') {
      displaySnackbar({
        message: 'An error occured',
        type: 'error',
      })
      return
    }

    displaySnackbar({ message: 'Password reset successfully.', type: 'success' })
    handleClose()
  }, [
    apiConfirmForgotPassword,
    displaySnackbar,
    handleCheckPasswords,
    handleClose,
    handleSetLoading,
    handleVerifyCode,
  ])

  // add event listener to send code by email when user press enter
  useEffect(() => {
    const handleEnter = (event: KeyboardEvent) => {
      if (event.key !== 'Enter') return

      if (activeStep === 0) return handleForgotPassword()

      if (activeStep === 1) return handleResetPassword()
    }

    document.addEventListener('keydown', handleEnter)
    return () => document.removeEventListener('keydown', handleEnter)
  }, [activeStep, handleForgotPassword, handleResetPassword])

  const steps = [
    {
      title: 'Send code to email',
      content: (
        <form autoComplete="on" css={formWrapper}>
          <Box css={loginContainer({ theme })}>
            <TextField
              id="forgot-password-email"
              name="password"
              autoComplete="on"
              variant="outlined"
              type="email"
              label="E-mail"
              value={email.value}
              error={email.error}
              helperText={email.helperText}
              onChange={(e) =>
                handleChangeEmail({ error: false, helperText: '', value: e.target.value })
              }
              fullWidth
            />
            <FormControlLabel
              css={formControlGap({ theme })}
              control={
                <Checkbox
                  size="small"
                  checked={forgotPassword}
                  onChange={() => handleChangeForgotPassword(!forgotPassword)}
                />
              }
              label="Forgot password"
            />
          </Box>
        </form>
      ),
    },
    {
      title: 'Code and password',
      content: (
        <Box css={codeAndPasswordContainer({ theme })}>
          <Box css={codeAndPasswordContent({ theme })}>
            <Typography align="center">Add the confirmation code sent by email</Typography>
            <TextField
              id="forgot-confirmation-code"
              name="confirmation_code"
              variant="outlined"
              type="text"
              label="Code"
              value={validationCode.value}
              error={validationCode.error}
              helperText={validationCode.helperText}
              onChange={(e) =>
                handleChangeCode({ error: false, helperText: '', value: e.target.value })
              }
              fullWidth
            />
          </Box>
          <Box
            css={css`
              width: 100%;
              display: flex;
              flex-direction: column;
            `}
          >
            <PasswordInput
              id="forgot-signup-password"
              error={password.error}
              helperTexts={password.helperTexts}
              value={password.value}
              handleChangePassword={handleChangePassword}
            />
            <PasswordInput
              id="forgot-confirm-password"
              label="Confirm password"
              error={confirmPassword.error}
              helperTexts={confirmPassword.helperTexts}
              value={confirmPassword.value}
              handleChangePassword={handleChangeConfirmPassword}
            />
          </Box>
        </Box>
      ),
    },
  ]

  return (
    <Box>
      <Box css={codeAndPasswordModalContainer({ theme })}>
        <Stepper activeStep={activeStep} css={stepper({ theme })}>
          {steps.map((step) => {
            const stepProps: { completed?: boolean } = {}

            return (
              <Step key={step.title} {...stepProps}>
                <StepLabel>{step.title}</StepLabel>
              </Step>
            )
          })}
        </Stepper>

        {activeStep === steps.length ? (
          <>
            <Box css={finalStep({ theme })}>
              <Icon iconName="VerifiedUserIcon" size="large" colour={theme.palette.green.main} />
              <Typography css={stepTitle({ theme })} variant="subtitle2">
                Password reset successfully.
              </Typography>
            </Box>
          </>
        ) : (
          <>
            <Box css={signInBodyContent({ theme })}>{steps[activeStep].content}</Box>
            <Box css={signInFooter}>
              {activeStep !== 0 && (
                <Button variant="outlined" onClick={handleBack}>
                  Return
                </Button>
              )}
              <Box css={emptyBox} />

              {activeStep === steps.length - 1 ? (
                <Button onClick={handleResetPassword} css={resetPasswordButton}>
                  {isLoading ? (
                    <LoadingButton />
                  ) : (
                    <Typography variant="button">Reset password</Typography>
                  )}
                </Button>
              ) : (
                <Button onClick={handleForgotPassword} fullWidth>
                  {isLoading ? (
                    <LoadingButton />
                  ) : (
                    <Typography variant="button">Send code by e-mail</Typography>
                  )}
                </Button>
              )}
            </Box>
          </>
        )}
      </Box>
    </Box>
  )
}
