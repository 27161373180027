export const AssetAreaIcon = ({ ...rest }) => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M24.488 14.488L15.512 5.51205C15.3512 5.34855 15.1592 5.21898 14.9474 5.13103C14.7356 5.04308 14.5083 4.99854 14.279 5.00004H6.73459C6.27455 5.00004 5.83335 5.18279 5.50805 5.50809C5.18275 5.83338 5 6.27458 5 6.73462V23.2655C5 23.7255 5.18275 24.1667 5.50805 24.492C5.83335 24.8173 6.27455 25 6.73459 25H23.2654C23.7255 25 24.1667 24.8173 24.492 24.492C24.8172 24.1667 25 23.7255 25 23.2655V15.721C24.9984 15.2586 24.8144 14.8156 24.488 14.488ZM20.8203 20.0575C20.8203 20.2598 20.7399 20.4538 20.5969 20.5969C20.4538 20.7399 20.2598 20.8203 20.0575 20.8203H9.94253C9.74022 20.8203 9.5462 20.7399 9.40315 20.5969C9.2601 20.4538 9.17973 20.2598 9.17973 20.0575V9.94257C9.17973 9.74026 9.2601 9.54624 9.40315 9.40318C9.5462 9.26013 9.74022 9.17976 9.94253 9.17976H12.9101C13.1122 9.18302 13.3058 9.26122 13.4535 9.3992L20.559 16.5047C20.697 16.6524 20.7752 16.846 20.7785 17.0481L20.8203 20.0575Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default AssetAreaIcon
