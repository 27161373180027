/** @jsxImportSource @emotion/react */
import { useTheme } from '@mui/material/styles'
import { storyNavigation } from './StoryNavigation.styles'
import { SequencedStep } from '../../Stories.utils'
import { Box } from '@mui/material'
import { Icon } from '@src/components/Atoms/Icon'
import { ArrowForwardRounded } from '@mui/icons-material'

export interface StoryNavigationProps {
  sequencedSteps: SequencedStep[]
  activeStepIndex: number
  onClose: () => void
  setStep: (index: number) => void
}

export const StoryNavigation = ({
  sequencedSteps,
  activeStepIndex,
  onClose,
  setStep,
}: StoryNavigationProps) => {
  const theme = useTheme()

  const steps = []
  for (let i = 0; i < sequencedSteps.length; i++) {
    const step = sequencedSteps[i]
    if (sequencedSteps[i].chapterTitle) {
      steps.push(
        <Box key={'chapter-' + i} className="chapter">
          {step.chapterTitle}
        </Box>,
      )
    }
    steps.push(
      <Box
        key={i}
        className={`step ${i === activeStepIndex ? 'active' : ''}`}
        onClick={() => {
          setStep(i)
          onClose()
        }}
      >
        <Box>
          <ArrowForwardRounded />
        </Box>
        {step.stepTitle}
      </Box>,
    )
  }

  return (
    <Box css={storyNavigation({ theme })}>
      <Box className="title" onClick={onClose}>
        <Icon iconName="s3://menu.svg" size="small" colour="#FFF" />
        Story Navigation
      </Box>
      <Box className="menu">{steps}</Box>
    </Box>
  )
}
