/** @jsxImportSource @emotion/react */

import { useEffect, useMemo, useState } from 'react'
import { Box, Typography } from '@mui/material'
import { useMap } from '@contexts/MapContext'
import { useSelector } from 'react-redux'
import { cascadingLayersSelector, MapLayer } from '@redux/map/mapSlice'
import { RootState } from '@redux/store'
import { Accordion } from '../../../../Atoms/Accordion'
import { useDebounce } from '@src/hooks/useDebounce'
import { Slider } from '@src/components/Molecules/Slider'

export const CascadingNDaysSelector = ({
  layer,
  inAccordion,
}: {
  layer: MapLayer
  inAccordion?: boolean
}) => {
  const [availableValues, setAvailableValues] = useState<number[]>([0])
  const [sliderValue, setSliderValue] = useState<number>(0)
  const cascadingLayers = useSelector(cascadingLayersSelector)
  const { layers } = useSelector((state: RootState) => state.map)
  const { cascadingAssetNDays, setCascadingAssetNDays } = useMap()

  const debouncedSliderValue = useDebounce(sliderValue, 50)

  const hazardLayers = useMemo(
    () => layers.filter((layer) => layer.layerType === 'hazard'),
    [layers],
  )

  useEffect(() => {
    if (cascadingLayers && cascadingLayers.length) {
      // just use the first of things
      const values = layer.n_days?.[hazardLayers[0]?.assetTag] ?? []
      setAvailableValues(values)
      setSliderValue(cascadingAssetNDays[layer.type] ?? 0)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cascadingLayers])

  useEffect(() => {
    // if this value is different from the cascadingAssetNDays, update the value as it may be updated elsewhere
    // component is generic across app
    if (
      sliderValue !== cascadingAssetNDays[layer.type] &&
      cascadingAssetNDays[layer.type] !== undefined &&
      cascadingAssetNDays[layer.type] !== null
    ) {
      setSliderValue(cascadingAssetNDays[layer.type] as number)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cascadingAssetNDays[layer.type]])

  useEffect(() => {
    // do additional check to see if value needs to change or not via the days_after_event field
    if (cascadingLayers && cascadingLayers.length) {
      setCascadingAssetNDays(layer.type, sliderValue)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSliderValue, cascadingLayers])

  const sliderText =
    availableValues.length > 1
      ? 'Cascading Impact of Days After Event'
      : 'No Adjustable Timeframe Available'

  const sliderContent = (
    <Box
      sx={{
        display: 'flex',
        justifyItems: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        width: '100%',
      }}
    >
      <Box sx={{ marginTop: '8px', marginBottom: availableValues.length > 1 ? '' : '8px' }}>
        <Typography>{sliderText}</Typography>
      </Box>
      {availableValues.length > 1 && (
        <Box sx={{ width: '75%', margin: '16px' }}>
          <Slider
            value={sliderValue}
            handleValueChange={(value) => setSliderValue(value)}
            marks={availableValues.map((value) => ({
              label: `${value} Days`,
              value: +value,
            }))}
            defaultValue={+availableValues[0]}
            valueLabelDisplay="on"
            minimalLabels
          />
        </Box>
      )}
    </Box>
  )

  // if there is no associated hazardLayer for this cascading layer, just return null
  if (!cascadingLayers || availableValues.length < 2) {
    return null
  }

  return inAccordion ? (
    <Accordion
      title="Cascading Timeframe Selector"
      defaultExpanded={true}
      body={sliderContent}
      level="h3"
      variant="outline"
      hasTextContent
    />
  ) : (
    <>{sliderContent}</>
  )
}
