/** @jsxImportSource @emotion/react */
import { Feature, FeatureCollection, Point, Position } from 'geojson'
import { MapLayerMouseEvent } from 'mapbox-gl'
import * as turf from '@turf/turf'
import { Box, IconButton, useTheme, Typography } from '@mui/material'
import { Clear } from '@mui/icons-material'
import mapboxgl from 'mapbox-gl'
import {
  mapTool,
  mapToolClearButton,
  mapToolContent,
  mapToolHeader,
  mapToolHeaderTitle,
  mapToolHint,
} from './MapToolsPortal.styles'
import { formatArea, formatDistance } from './MapTools'

export type Measurement = {
  distance: string
  area: string | null
  perimeter: string | null
}

export default class MeasuringTool {
  static activateMeasuringTool = (
    map: mapboxgl.Map,
    setMeasurement: React.Dispatch<React.SetStateAction<Measurement>>,
  ) => {
    setTheMeasurement = setMeasurement
    map.getCanvas().style.cursor = 'crosshair'

    if (!map.getSource('measureToolSource')) {
      map.addSource('measureToolSource', {
        type: 'geojson',
        data: featureCollection,
      })

      map.addLayer({
        id: 'measureToolLineLayer',
        type: 'line',
        source: 'measureToolSource',
        layout: {
          'line-cap': 'round',
          'line-join': 'round',
        },
        paint: {
          'line-color': '#ED872D',
          'line-width': 2.5,
        },
        filter: ['in', '$type', 'LineString'],
      })

      map.addLayer({
        id: 'measureToolPointsLayer',
        type: 'circle',
        source: 'measureToolSource',
        paint: {
          'circle-radius': 5,
          'circle-color': '#ED872D',
        },
        filter: ['in', '$type', 'Point'],
      })

      map.addLayer({
        id: 'measureToolAreaLayer',
        type: 'fill',
        source: 'measureToolSource',
        paint: {
          'fill-color': '#ED872D',
          'fill-opacity': 0.1,
        },
        filter: ['in', '$type', 'Polygon'],
      })
    }

    // Bring the layers to the top
    // Lines and areas will always sit below circles :-(
    // This is a mapbox limitation:
    //     Layers draped over globe and terrain, such as fill , line , background , hillshade , and raster , are rendered first. These layers are rendered underneath symbols, regardless of whether they are placed in the middle or top slots or without a designated slot.
    //     https://docs.mapbox.com/mapbox-gl-js/api/map/#map#movelayer
    map.moveLayer('measureToolAreaLayer')
    map.moveLayer('measureToolPointsLayer')
    map.moveLayer('measureToolLineLayer')

    map.on('click', onMouseClick)
    map.on('mouseenter', 'measureToolPointsLayer', onMouseEnterPoint)
    map.on('mouseleave', 'measureToolPointsLayer', onMouseLeavePoint)
    map.on('mousedown', 'measureToolPointsLayer', onMouseDown)
  }

  static deactivateMeasuringTool = (map: mapboxgl.Map) => {
    if (setTheMeasurement) setTheMeasurement({ distance: '0 m', area: null, perimeter: null })
    if (map.getLayer('measureToolPointsLayer')) map.removeLayer('measureToolPointsLayer')
    if (map.getLayer('measureToolLineLayer')) map.removeLayer('measureToolLineLayer')
    if (map.getLayer('measureToolAreaLayer')) map.removeLayer('measureToolAreaLayer')
    if (map.getSource('measureToolSource')) map.removeSource('measureToolSource')
    featureCollection.features = []
    pointFeatures = []
    map.off('click', onMouseClick)
    map.off('mouseenter', 'measureToolPointsLayer', onMouseEnterPoint)
    map.off('mouseleave', 'measureToolPointsLayer', onMouseLeavePoint)
    map.off('mousedown', 'measureToolPointsLayer', onMouseDown)
    map.off('mousemove', onDragPoint)
    map.off('mouseup', onUp)
    map.getCanvas().style.cursor = 'default'
  }
}

const featureCollection: FeatureCollection = {
  type: 'FeatureCollection',
  features: [],
}

let pointFeatures: Feature[] = []

let setTheMeasurement: React.Dispatch<React.SetStateAction<Measurement>>

const onMouseEnterPoint = (event: MapLayerMouseEvent) => {
  const map = event.target
  map.getCanvas().style.cursor = 'pointer'

  if (dragging) return
  const point = event.features ? (event.features[0].geometry as Point) : null
  if (!point) return
  const pointPosition = point.coordinates
  popup.setLngLat({ lng: pointPosition[0], lat: pointPosition[1] })
  popup.setText('Drag to change, click to remove')
  popup.addTo(map)
}

const onMouseLeavePoint = (event: MapLayerMouseEvent) => {
  const map = event.target
  map.getCanvas().style.cursor = 'crosshair'
  popup.remove()
}

const onMouseClick = (event: MapLayerMouseEvent) => {
  const map = event.target
  const clickedFeatures = map.queryRenderedFeatures(event.point, {
    layers: ['measureToolPointsLayer'],
  })

  if (clickedFeatures.length) {
    const id = clickedFeatures[0].properties?.id
    pointFeatures = pointFeatures.filter((point) => point.properties?.id !== id)
    if (pointFeatures.length == 1) pointFeatures = []
  } else {
    const pointFeatre: Feature = {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [event.lngLat.lng, event.lngLat.lat],
      },
      properties: {
        id: String(new Date().getTime()),
      },
    }

    pointFeatures.push(pointFeatre)
  }

  updateFeatureCollection(map)

  const source = map.getSource('measureToolSource') as mapboxgl.GeoJSONSource
  source.setData(featureCollection)
}

let draggedPoint: Point
let dragging = false

const onMouseDown = (event: MapLayerMouseEvent) => {
  event.preventDefault()
  const map = event.target

  const clickedFeatures = map.queryRenderedFeatures(event.point, {
    layers: ['measureToolPointsLayer'],
  })
  const clickedId = clickedFeatures[0].properties?.id
  const clickedFeature = pointFeatures.find((feature) => feature.properties?.id == clickedId)

  if (!clickedFeature) return
  draggedPoint = clickedFeature.geometry as Point

  popup.remove()
  dragging = true
  map.on('mousemove', onDragPoint)
  map.once('mouseup', onUp)
}

const onDragPoint = (event: MapLayerMouseEvent) => {
  const map = event.target
  const coords = event.lngLat

  draggedPoint.coordinates = [coords.lng, coords.lat]
  updateFeatureCollection(map)
  const source = map.getSource('measureToolSource') as mapboxgl.GeoJSONSource
  source.setData(featureCollection)
}

const onUp = (event: MapLayerMouseEvent) => {
  const map = event.target
  map.off('mousemove', onDragPoint)
  dragging = false
}

const updateFeatureCollection = (map: mapboxgl.Map) => {
  const measurement: Measurement = { distance: '0 m', area: null, perimeter: null }

  if (pointFeatures.length <= 1) {
    featureCollection.features = pointFeatures
  } else if (pointFeatures.length > 1) {
    const coords: Position[] = pointFeatures.map(
      (pointFeature) => (pointFeature.geometry as Point).coordinates,
    )

    const lineStringFeature: Feature = {
      type: 'Feature',
      properties: {},
      geometry: {
        type: 'LineString',
        coordinates: coords,
      },
    }

    featureCollection.features = [...pointFeatures, lineStringFeature]

    const distance = turf.length(lineStringFeature, { units: 'meters' })
    measurement.distance = formatDistance(distance)

    if (coords.length >= 3) {
      const polygonFeature: Feature = {
        type: 'Feature',
        properties: {},
        geometry: {
          type: 'Polygon',
          coordinates: [[...coords, coords[0]]],
        },
      }
      featureCollection.features.push(polygonFeature)

      const polygon = turf.polygon([[...coords, coords[0]]])
      const doesNotSelfIntersect = turf.kinks(polygon).features.length == 0
      if (doesNotSelfIntersect) {
        map.setLayoutProperty('measureToolAreaLayer', 'visibility', 'visible')
        const area = turf.area(polygon)
        const perimeter = turf.length(polygon, { units: 'meters' })
        measurement.area = formatArea(area)
        measurement.perimeter = formatDistance(perimeter)
      } else {
        map.setLayoutProperty('measureToolAreaLayer', 'visibility', 'none')
        measurement.area = null
        measurement.perimeter = null
      }
    } else {
      measurement.area = null
      measurement.perimeter = null
    }
  }
  setTheMeasurement(measurement)
}

const popup = new mapboxgl.Popup({
  closeButton: false,
  maxWidth: 'none',
  anchor: 'top-right',
  offset: 10,
  className: 'measureTooltip',
})

interface MeasuringToolPortalProps {
  measurement: Measurement
  handleClose: () => void
}

export const MeasuringToolOutput = ({ measurement, handleClose }: MeasuringToolPortalProps) => {
  const theme = useTheme()
  return (
    <Box css={mapTool(theme, 'measuring')}>
      <Box css={mapToolHeader}>
        <Typography css={mapToolHeaderTitle}>Measure distance</Typography>
        <Box css={mapToolHint(theme, 'measuring')}>
          {measurement.distance == '0 m' ? (
            <Typography variant="caption">
              Click on the map to trace a path you want to measure
            </Typography>
          ) : (
            <Typography variant="caption">Click on the map to add to your path</Typography>
          )}
        </Box>
        <Box css={mapToolClearButton}>
          <IconButton onClick={handleClose} size="small" color="secondary">
            <Clear />
          </IconButton>
        </Box>
      </Box>
      <Box css={mapToolContent(theme)}>
        <div>
          <Typography>Distance:</Typography>
          {measurement.area != null && <Typography>Area:</Typography>}
          {measurement.perimeter != null && <Typography>Perimeter:</Typography>}
        </div>
        <div>
          <Typography>{measurement.distance}</Typography>
          {measurement.area != null && <Typography>{measurement.area}</Typography>}
          {measurement.perimeter != null && <Typography>{measurement.perimeter}</Typography>}
        </div>
      </Box>
    </Box>
  )
}
