import { css } from '@emotion/react'
import { Theme } from '@mui/material'

export const videoStyle = ({ theme }: { theme: Theme }) => css`
  border-radius: ${theme.spacing(2)};
  margin-top: ${theme.spacing(2)};
  margin-bottom: ${theme.spacing(2)};
  border: 1px solid ${theme.palette.primary.main}20;

  &::-webkit-media-controls-panel {
    background: linear-gradient(
      0deg,
      ${theme.palette.primary.main} 0%,
      ${theme.palette.primary.main}C0 20%,
      ${theme.palette.primary.main}00 40%
    );
  }
  &::-webkit-media-controls-time-remaining-display,
  &::-webkit-media-controls-current-time-display {
    display: none;
  }
`
