export const TooltipIcon = ({ ...rest }) => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M9.51651 26.837C9.48488 26.8372 9.45388 26.8281 9.4273 26.811C9.40071 26.7939 9.37968 26.7694 9.36678 26.7405L7.26724 22.1821L4.4257 24.5478C4.39505 24.5711 4.35772 24.5839 4.31923 24.5844C4.29633 24.5905 4.27225 24.5905 4.24935 24.5844C4.22022 24.5719 4.19548 24.5509 4.17829 24.5242C4.16111 24.4976 4.15226 24.4664 4.15286 24.4347V12.789C4.15316 12.7567 4.16272 12.7251 4.18041 12.6979C4.1981 12.6708 4.22317 12.6493 4.25268 12.636C4.27475 12.6329 4.29716 12.6329 4.31923 12.636C4.34022 12.6359 4.36102 12.64 4.38043 12.648C4.39984 12.656 4.41749 12.6678 4.43236 12.6826L13.0069 20.7314C13.0301 20.7543 13.0462 20.7835 13.0533 20.8153C13.0604 20.8472 13.0581 20.8804 13.0468 20.911C13.0358 20.942 13.0156 20.9689 12.9888 20.988C12.962 21.007 12.9299 21.0173 12.8971 21.0175L9.13719 21.134L11.2833 25.9353C11.2924 25.9557 11.2971 25.9778 11.2971 26.0002C11.2971 26.0225 11.2924 26.0446 11.2833 26.0651C11.2661 26.1055 11.2339 26.1377 11.1935 26.1549L9.5764 26.8204C9.55789 26.8302 9.53745 26.8359 9.51651 26.837Z"
        fill="currentColor"
      />
      <path
        d="M4.31942 12.779L12.8906 20.8511L8.89782 20.9775L11.1305 26.0018L9.5167 26.6673L7.32732 21.9158L4.33273 24.418V12.7724M4.33273 12.4396C4.28678 12.4403 4.24146 12.4505 4.19964 12.4696C4.14064 12.4953 4.09038 12.5376 4.05494 12.5914C4.01951 12.6451 4.00042 12.708 4 12.7724V24.418C3.99984 24.4816 4.01794 24.544 4.05215 24.5977C4.08635 24.6513 4.13523 24.6941 4.19299 24.7208C4.23685 24.7408 4.28453 24.751 4.33273 24.7507C4.40628 24.7469 4.4765 24.7189 4.53237 24.6709L7.19423 22.4449L9.19062 26.807C9.21735 26.8648 9.26008 26.9136 9.31375 26.9479C9.36742 26.9821 9.42977 27.0002 9.49341 27C9.53667 27.0005 9.57962 26.9926 9.61985 26.9767L11.2369 26.3112C11.2778 26.2938 11.3148 26.2683 11.3456 26.2363C11.3765 26.2043 11.4006 26.1664 11.4166 26.1249C11.434 26.0839 11.4429 26.0397 11.4429 25.9951C11.4429 25.9506 11.434 25.9064 11.4166 25.8654L9.39026 21.2936L12.9006 21.1838C12.9665 21.1823 13.0304 21.1613 13.0843 21.1234C13.1382 21.0854 13.1797 21.0324 13.2034 20.9709C13.2272 20.909 13.232 20.8415 13.2172 20.7768C13.2024 20.7122 13.1686 20.6535 13.1202 20.6082L4.54901 12.5395C4.48733 12.48 4.40509 12.4466 4.31942 12.4463L4.33273 12.4396Z"
        fill="currentColor"
      />
      <path
        d="M23.9007 3H10.1688C9.23528 3 8.47852 3.75676 8.47852 4.69028V13.175C8.47852 14.1085 9.23528 14.8652 10.1688 14.8652H23.9007C24.8342 14.8652 25.5909 14.1085 25.5909 13.175V4.69028C25.5909 3.75676 24.8342 3 23.9007 3Z"
        fill="currentColor"
      />
      <path
        d="M22.3867 5.67188H11.6794C11.4754 5.67188 11.3101 5.83723 11.3101 6.04121V7.58176C11.3101 7.78573 11.4754 7.95109 11.6794 7.95109H22.3867C22.5907 7.95109 22.756 7.78573 22.756 7.58176V6.04121C22.756 5.83723 22.5907 5.67188 22.3867 5.67188Z"
        fill="#FBFBFB" // TODO: can be an issue
      />
      <path
        d="M18.6901 9.92747H11.6794C11.4754 9.92747 11.3101 10.0928 11.3101 10.2968V11.8374C11.3101 12.0413 11.4754 12.2067 11.6794 12.2067H18.6901C18.894 12.2067 19.0594 12.0413 19.0594 11.8374V10.2968C19.0594 10.0928 18.894 9.92747 18.6901 9.92747Z"
        fill="#FBFBFB" // TODO: can be an issue
      />
    </svg>
  )
}

export default TooltipIcon
