/** @jsxImportSource @emotion/react */
import { Box, Typography } from '@mui/material'

import { Swatch, SwatchItem } from '@uintel/ui-component-library'
import { legendRowMainClass } from './LegendRow.styles'

export type LegendRowProps = {
  label: string
  swatch: SwatchItem
  isTop?: boolean
  isBottom?: boolean
}

export const LegendRow = ({ label, swatch, isTop = false, isBottom = false }: LegendRowProps) => {
  // Make sure swatch has a size
  const theSwatch: SwatchItem = { size: 'small', ...swatch }
  return (
    <Box css={legendRowMainClass()}>
      <Swatch item={theSwatch} isTop={isTop} isBottom={isBottom} />
      <Typography variant="body2">{label}</Typography>
    </Box>
  )
}
