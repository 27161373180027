import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export const AuthRoles = ['admin', 'member'] as const
export type AuthRole = (typeof AuthRoles)[number]

export interface AuthUser {
  name?: string
  role: AuthRole
}

export interface AuthSliceState {
  authSlice: {
    user: AuthUser | null
  }
}

const initialState: AuthSliceState = {
  authSlice: { user: { role: 'member' } },
}

export const authSlice = createSlice({
  name: 'authSlice',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<AuthUser>) => {
      state.authSlice.user = action.payload
    },
  },
})

export const { setUser } = authSlice.actions
export default authSlice.reducer
