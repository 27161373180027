export const ChartLineUpIcon = ({ ...rest }) => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M21.2494 5.78796V7.66681H24.6542L17.8128 15.716L14.0628 11.4886L6.20126 20.0359C5.89393 20.37 5.88932 20.8825 6.19058 21.2221C6.53772 21.6135 7.14674 21.6199 7.50203 21.2359L14.0628 14.1456L17.8128 18.373L25.8328 8.99539V12.8336H27.4995V5.78796H21.2494Z"
        fill="currentColor"
      />
      <path
        d="M4.16667 3.33333C4.16667 2.8731 3.79357 2.5 3.33333 2.5C2.8731 2.5 2.5 2.8731 2.5 3.33333V27.5H26.5606C27.0794 27.5 27.5 27.0794 27.5 26.5606C27.5 26.0418 27.0794 25.6212 26.5606 25.6212H4.16667V3.33333Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default ChartLineUpIcon
