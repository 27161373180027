import { css, keyframes } from '@emotion/react'
import { Theme } from '@mui/material/styles'

interface SideDrawerContainerProps {
  theme: Theme
  isOpen: boolean
}

const fadein = keyframes`
  from { opacity: 0; top: 10px }
  to   { opacity: 1; top: 0px }
`

export const sideDrawerContainer = css`
  display: flex;

  width: 100%;
  height: 100%;
`

export const sideDrawer = ({ theme, isOpen }: SideDrawerContainerProps) => css`
  position: relative;

  height: 100%;

  max-width: 90vw;
  min-width: ${isOpen ? '60px' : undefined};

  padding-right: 0;
  padding-bottom: 0px;

  background-color: ${theme.palette.secondary.main};
  box-shadow: ${theme.shadows[5]};
  transition: all 0.4s, width 0s;

  z-index: 11;

  & .right-handle {
    &:hover::after {
      background-color: ${theme.palette.primary.main};
      box-shadow: ${theme.shadows[5]};

      cursor: ns-resize;
    }

    &::after {
      content: '';
      display: flex;
      height: 100%;
      width: 1px;

      margin-left: 5px;
      background-color: ${theme.palette.grey[100]};

      transition: background-color 0.2s, box-shadow 0.2s;
    }
  }

  & .pointer-events-none {
    pointer-events: none;
  }
`

export const sideDrawerContent = () => css`
  position: relative;
  opacity: 0;
  top: 10px;
  animation: ${fadein} 3s ease-in-out forwards;
`

export const toggleButton = ({ theme }: { theme: Theme }) => css`
  position: absolute;
  right: 0;
  top: ${theme.spacing(22)};

  transform: translateX(11px);
`
export const sideDrawerContentContainer = ({ theme }: { theme: Theme }) => css`
  width: 100%;
  height: 100%;
  padding: ${theme.spacing(0)};
  padding-left: ${theme.spacing(0)};
`
export const sideDrawerContentContainerParent = ({ theme }: { theme: Theme }) => css`
  position: absolute;
  top: ${theme.spacing(0)};
  left: 0;
  right: ${theme.spacing(0)};
  bottom: ${theme.spacing(0)};
`

export const sideDrawerContentParent = () => css`
  display: flex;
  flex-direction: column;
  height: 100%;
`

export const sideDrawerContentChildren = ({ theme }: { theme: Theme }) => css`
  height: 100%;
  overflow-y: scroll;
  padding: ${theme.spacing(3)} ${theme.spacing(0)} 0 ${theme.spacing(3)};
  margin-right: ${theme.spacing(2)};

  &::-webkit-scrollbar {
    width: ${theme.spacing(2)} !important;
  }

  &::-webkit-scrollbar-track {
    border-radius: 0 !important;
    background-color: transparent !important;
  }
  &::-webkit-scrollbar-track:hover {
    background-color: #f0f0f0 !important;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 2px !important;
    background-color: #e0e0e0 !important;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #c0c0c0 !important;
    border: 1px solid #b8b8b8 !important;
  }
`

export const sideDrawerContentScrollBox = ({ theme }: { theme: Theme }) => css`
  min-height: 100%;
  width: 100%;
  padding-right: ${theme.spacing(2)};
  padding-bottom: ${theme.spacing(20)};
  align-items: stretch;
  display: flex;
  flex-direction: column;
`
