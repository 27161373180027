export const MaraeIcon = ({ ...rest }) => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path d="M1 19.713L15.0214 8L29 19.542" stroke="currentColor" strokeWidth="3" />
      <path d="M15.0215 5L14.9774 22.9809" stroke="currentColor" strokeWidth="2" />
      <path d="M24 22L6 22" stroke="currentColor" strokeWidth="0.723298" />
      <path d="M6 12V23" stroke="currentColor" strokeWidth="1.84843" />
      <path d="M24 12V23" stroke="currentColor" strokeWidth="1.84843" />
    </svg>
  )
}

export default MaraeIcon
