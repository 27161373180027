/** @jsxImportSource @emotion/react */
import { Button as MuiButton, ButtonProps as MuiButtonProps } from '@mui/material'
import { useTheme } from '@mui/material/styles'

import { buttonContainer } from './Button.styles'

export type ButtonProps = MuiButtonProps

export const Button = ({ ...rest }: ButtonProps) => {
  const theme = useTheme()

  return (
    <MuiButton
      {...rest}
      variant={rest.variant ?? 'contained'}
      data-testid="btn"
      css={buttonContainer({ theme, rest })}
    >
      {rest.children}
    </MuiButton>
  )
}
