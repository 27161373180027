/** @jsxImportSource @emotion/react */
import { Expression, Map, CircleLayer } from 'mapbox-gl'
import { byAssetIdIn } from '../RiskMapView.utilities'
import { MapLayerStyle } from '@contexts/MapContext'

export interface PointLayerProps {
  map: Map
  layerStyle: MapLayerStyle
  id: string
  beforeId: string
  isVisible: boolean
  sourceLayer: string
  colorExpression: Expression
  fillOpacityExpression: Expression
  assetIds?: { [asset_id: number]: boolean }
  sortKeyExpression?: Expression
  hasFilters?: boolean
  shouldUpdate?: boolean
}

export const addPointLayer = ({
  map,
  layerStyle,
  id,
  beforeId,
  isVisible,
  sourceLayer,
  colorExpression,
  fillOpacityExpression,
  assetIds,
  sortKeyExpression,
  hasFilters = false,
  shouldUpdate = false,
}: PointLayerProps) => {
  const pointLayer: CircleLayer = {
    id,
    type: 'circle',
    source: id,
    'source-layer': sourceLayer,
    layout: {
      visibility: isVisible ? 'visible' : 'none',
      'circle-sort-key': sortKeyExpression || 0,
    },
    paint: {
      'circle-opacity': fillOpacityExpression,
      'circle-radius': ['interpolate', ['linear'], ['zoom'], 1, 4, 15, 4, 16, 8],
      ...layerStyle,
    },
  }
  if (shouldUpdate) {
    if (pointLayer.paint) {
      for (const paintProp of Object.entries(pointLayer.paint)) {
        map.setPaintProperty(id, paintProp[0], paintProp[1])
      }
    }
    if (pointLayer.layout) {
      for (const layoutProp of Object.entries(pointLayer.layout)) {
        map.setLayoutProperty(id, layoutProp[0], layoutProp[1])
      }
    }
    if (!hasFilters && !assetIds) map.setFilter(id, null)
  } else {
    map.addSource(id, { type: 'vector', url: `mapbox://${id}` })
    map.addLayer(pointLayer, beforeId)
  }
  if (hasFilters && assetIds) {
    const visibilityExpression: Expression = ['boolean', byAssetIdIn(assetIds)]
    map.setFilter(id, visibilityExpression)
  }
  if (colorExpression) {
    map.setPaintProperty(id, 'circle-color', colorExpression)
  }
}
