import { css } from '@emotion/react'

export const mapMarkerContainer = css`
  position: relative;
  width: 42px;
  height: 60px;
  cursor: pointer;

  transform: translate(10%, -35%);
`

export const mapMarkerChildren = css`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, -102%);

  width: 26px;
  height: 26px;

  z-index: 99999;

  display: flex;
  justify-content: center;
  align-items: center;
`
