import { css } from '@emotion/react'

export const layersTabParent = css`
  display: flex;
  flex-direction: column;
`

export const mapLayerHeader = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
  padding-right: 16px;
`
