/** @jsxImportSource @emotion/react */

import LanguageIcon from '@mui/icons-material/Language'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import { Box, Card, CardActions, CardContent, CardMedia, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { Button } from '@src/components/Atoms/Button'

import {
  content,
  footer,
  footerText,
  ShowOnMobile,
  MobileBodyText,
  MobileHeaderText,
  AccessPageContainer,
  AccessPageLeft,
  AccessPageLeftInnerContainer,
  AccessPageRight,
  logo,
  mainText,
  marketingButton,
  secondaryTextText,
  secondaryTextPrimary,
  socialLinks,
} from './AccessPage.styles'
import { Tooltip } from '@src/components/Atoms/Tooltip/Tooltip'

export const AccessPage = () => {
  const theme = useTheme()

  return (
    <Box css={AccessPageContainer({ theme })}>
      <Box css={AccessPageRight({ theme })}>
        <Card sx={{ width: '40vw' }}>
          <CardMedia
            sx={{ height: '35vh' }}
            image="./images/pictures/accessibility-marketing.png"
            title="Example of the accessibility functionality"
          />
          <CardContent>
            <Typography variant="h2" component="div" css={secondaryTextPrimary({ theme })}>
              Access
            </Typography>
            <Typography variant="body1">
              Coming Soon. We’re integrating our national accessibility map and providing more
              information and improving the user experience. Check out the existing site below.
            </Typography>
          </CardContent>
          <CardActions sx={{ padding: theme.spacing(4) }}>
            <a href="https://projects.urbanintelligence.co.nz/access-nz/">
              <Button size="small">Access NZ</Button>
            </a>
          </CardActions>
        </Card>
      </Box>
      <Box css={AccessPageLeft({ theme })}>
        <Box component="header">
          <a href="https://resilience-explorer.org/" target="_blank" rel="noreferrer">
            <Box component="img" src="logos/RexLogoFullWhite.svg" css={logo} />
          </a>
        </Box>
        <Box component="main" css={content}>
          <Box css={ShowOnMobile}>
            <Box component="main" css={AccessPageLeftInnerContainer}>
              <Typography variant="h2" css={MobileHeaderText({ theme })}>
                Access
              </Typography>
              <Typography variant="body1" css={MobileBodyText({ theme })}>
                Coming Soon. We’re integrating our national accessibility map and providing more
                information and improving the user experience. Check out the existing site{' '}
                <a href="https://projects.urbanintelligence.co.nz/access-nz/">
                  https://projects.urbanintelligence.co.nz/access-nz/
                </a>
              </Typography>
            </Box>
          </Box>
          <Typography variant="h2" css={mainText({ theme })}>
            Contact us to activate
          </Typography>
          <a
            href="https://resilience-explorer.org/contact-resilience-explorer/"
            target="_blank"
            rel="noreferrer"
          >
            <Button variant="outlined" color="secondary" css={marketingButton({ theme })}>
              Get in touch
            </Button>
          </a>
          <Typography variant="h6" css={secondaryTextText({ theme })}>
            Powered by
            <a href="https://urbanintelligence.co.nz" target="_blank" rel="noreferrer">
              Urban Intelligence
            </a>
          </Typography>
        </Box>

        <Box component="footer" css={footer({ theme })}>
          <Typography variant="h6" css={footerText({ theme })}>
            Find us at
          </Typography>
          <Box css={socialLinks({ theme })}>
            <Tooltip title="LinkedIn">
              <a
                href="https://www.linkedin.com/company/urban-intelligence-nz/"
                target="_blank"
                rel="noreferrer"
              >
                <LinkedInIcon />
              </a>
            </Tooltip>
            <Tooltip title="Website">
              <a href="https://resilience-explorer.org/" target="_blank" rel="noreferrer">
                <LanguageIcon />
              </a>
            </Tooltip>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
