/** @jsxImportSource @emotion/react */
import { useTheme } from '@mui/material/styles'
import { AnimatePresence, motion } from 'framer-motion'
import { Resizable } from 're-resizable'
import { useCallback, useEffect, useState } from 'react'

import {
  sideDrawer,
  sideDrawerButton,
  sideDrawerContent,
  toggleButtonParent,
} from './SideDrawer.styles'
import { ToggleArrowButton } from '@src/components/Atoms/VerticalThumbTab'

const noCursorClassName = 'pointer-events-none'

export interface SideDrawerProps {
  isOpen: boolean
  handleToggle: React.Dispatch<React.SetStateAction<boolean>>
  children: JSX.Element | React.ReactNode
  initialWidth?: number
  tabIcons?: string[]
  tabs?: string[]
  tab?: string
  setTab?: (tabIcon: string) => void
  handleWidthChange?: (width: number) => void
}

export const SideDrawer = ({
  isOpen,
  handleToggle,
  initialWidth = 500,
  children,
  tabs = [],
  tabIcons = [],
  tab,
  setTab,
  handleWidthChange,
}: SideDrawerProps) => {
  const theme = useTheme()
  const [width, setWidth] = useState(initialWidth)

  // Because both tab & tabIcons are in the same order, we can use the index of a tab to get its icon
  const getTabIcon = useCallback(
    (tab: string) => {
      if (!tabIcons || !tabs) return
      return tabIcons[tabs.indexOf(tab)]
    },
    [tabs, tabIcons],
  )

  // Handles the different states of the buttons, i.e. if it's active, open, etc
  const onButtonClick = useCallback(
    (clicked_tab: string) => {
      if (tab === clicked_tab || !isOpen) {
        handleToggle(!isOpen)
      }
      setTab?.(clicked_tab)
    },
    [tab, isOpen, setTab, handleToggle],
  )

  useEffect(() => {
    if (isOpen && tabs && tabs.length > 0 && tab === undefined) {
      setTab?.(tabs[0])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, tabs])

  // Handles when isOpen is changed
  useEffect(() => {
    handleWidthChange?.(width)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width])

  useEffect(() => {
    if (tabs && tab !== undefined) {
      if (!tabs.includes(tab)) {
        if (tabs.length > 0) {
          setTab?.(tabs[0])
        } else {
          handleToggle(false)
        }
      } else {
        setTab?.(tab)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab, tabs])

  // if escape key is pressed, close the layers menu
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        if (isOpen) {
          handleToggle(false)
          event.stopPropagation()
          event.stopImmediatePropagation()
        }
      }
    }
    document.addEventListener('keydown', handleKeyDown)
    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])

  return (
    <Resizable
      css={sideDrawer({ theme, isOpen })}
      minHeight="100%"
      maxWidth={isOpen ? '70vw' : '20px'}
      onResizeStop={(_e, _direction, _ref, d) => {
        setWidth((currentWidth) => currentWidth + d.width)
      }}
      defaultSize={{
        width,
        height: '100%',
      }}
      enable={{
        top: false,
        right: isOpen,
        bottom: false,
        left: false,
        topRight: false,
        bottomRight: false,
        bottomLeft: false,
        topLeft: false,
      }}
      handleClasses={{
        right: isOpen ? 'right-handle' : noCursorClassName,
      }}
    >
      {tabs && tabs.length > 0 ? (
        tabs.map((this_tab, index) => (
          <div css={toggleButtonParent({ theme })} key={this_tab}>
            <ToggleArrowButton
              css={sideDrawerButton({ theme, index })}
              arrowPosition="left"
              onClick={() => onButtonClick(this_tab)}
              initialIcon={this_tab ? getTabIcon(this_tab) : null}
              expanded={isOpen && this_tab === tab}
              label={this_tab}
            />
          </div>
        ))
      ) : (
        <div css={toggleButtonParent({ theme })}>
          <ToggleArrowButton
            css={sideDrawerButton({ theme, index: 0 })}
            arrowPosition={!isOpen ? 'right' : 'left'}
            onClick={() => handleToggle(!isOpen)}
          />
        </div>
      )}
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            css={sideDrawerContent({ theme })}
          >
            {children}
          </motion.div>
        )}
      </AnimatePresence>
    </Resizable>
  )
}
