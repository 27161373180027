export const PaperPageIcon = ({ ...rest }) => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M18.5201 4H5V26.6415H25V10.4799L18.5201 4ZM23.4906 11.105V11.1698H17.8302V5.50943H17.895L23.4906 11.105ZM6.50943 25.1321V5.50943H16.3208V12.6792H23.4906V25.1321H6.50943Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default PaperPageIcon
