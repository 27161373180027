/** @jsxImportSource @emotion/react */

import { Box, useTheme, Typography } from '@mui/material'
import { Icon } from '@src/components/Atoms/Icon'
import { Story, addCloudfrontUrl } from '../../Stories.utils'
import {
  contentContainer,
  footerContainer,
  headerContainer,
  imageContainer,
  imageOverlay,
  playButton,
  storyMenuItemContainer,
  storyMenuItemParent,
  storyMenuItemRow,
  textContainer,
} from './StoryMenuItem.styles'
import { ImageWithFilters } from '@src/components/Atoms/ImageWithFilters'
import { css } from '@emotion/react'

export const StoryMenuItem = ({ story, onClick }: { story: Story; onClick: () => void }) => {
  const theme = useTheme()

  const totalSteps = story.chapters.reduce((acc, chapter) => acc + chapter.steps.length, 1)

  return (
    <Box css={storyMenuItemParent(theme)} onClick={onClick}>
      <Box css={storyMenuItemRow(theme)}>
        <Box css={storyMenuItemContainer()}>
          <Box css={headerContainer(theme)}>
            <Typography variant="h6">{story.title}</Typography>
          </Box>
          <Box css={contentContainer(theme)}>
            <Box css={textContainer(theme)}>
              <Typography>{story.description}</Typography>
            </Box>
          </Box>
          <Box css={footerContainer(theme)}>
            {story.chapters.length} Chapters, {totalSteps} Steps
          </Box>
        </Box>
      </Box>
      <Box className="play-button" css={playButton(theme)}>
        <Typography>Start</Typography>
        <Icon iconName="s3://playbutton.svg" colour={theme.palette.secondary.main} size="small" />
      </Box>
      <Box css={imageContainer}>
        <Box
          css={[
            imageOverlay(theme),
            css`
              left: -90%;
              opacity: 0.5;
            `,
          ]}
          className="image-overlay"
        />
        <Box css={imageOverlay(theme)} className="inner-image-overlay" />
        <ImageWithFilters
          src={addCloudfrontUrl(story.coverImageUrl) ?? ''}
          alt="Story cover"
          color={theme.palette.primary.main}
          hueStrength={0.4}
          desaturateStrength={0.6}
          lightenStrength={0.6}
          customCss={css`
            height: 100%;

            img {
              object-fit: cover;
              width: 100%;
              height: 100%;
            }
          `}
        />
      </Box>
    </Box>
  )
}
