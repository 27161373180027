export const PinIcon = ({ ...rest }) => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M14.9991 8.37738C14.3228 8.37738 13.6617 8.57793 13.0993 8.95367C12.537 9.32942 12.0987 9.86347 11.8399 10.4883C11.5811 11.1131 11.5134 11.8007 11.6453 12.464C11.7772 13.1273 12.1029 13.7366 12.5811 14.2149C13.0594 14.6931 13.6687 15.0188 14.332 15.1507C14.9953 15.2827 15.6829 15.215 16.3077 14.9561C16.9326 14.6973 17.4666 14.259 17.8424 13.6967C18.2181 13.1344 18.4186 12.4732 18.4186 11.7969C18.4176 10.8903 18.057 10.0211 17.4159 9.38008C16.7749 8.73901 15.9057 8.37841 14.9991 8.37738ZM14.9991 13.5067C14.661 13.5067 14.3304 13.4064 14.0492 13.2185C13.7681 13.0307 13.5489 12.7636 13.4195 12.4512C13.2901 12.1388 13.2562 11.795 13.3222 11.4633C13.3882 11.1317 13.551 10.827 13.7901 10.5879C14.0292 10.3488 14.3339 10.186 14.6656 10.12C14.9972 10.054 15.341 10.0879 15.6534 10.2173C15.9658 10.3467 16.2329 10.5658 16.4207 10.847C16.6086 11.1282 16.7089 11.4587 16.7089 11.7969C16.7084 12.2502 16.5281 12.6848 16.2075 13.0053C15.887 13.3258 15.4524 13.5061 14.9991 13.5067Z"
        fill="currentColor"
      />
      <path
        d="M21.5703 5.22094C19.9456 3.59668 17.7769 2.63181 15.4827 2.51254C13.1884 2.39327 10.9313 3.12806 9.14701 4.57509C7.36267 6.02212 6.1776 8.07881 5.8205 10.3482C5.4634 12.6176 5.95959 14.9389 7.2133 16.8639L13.6734 26.7813C13.8171 27.0019 14.0137 27.1833 14.2452 27.3088C14.4768 27.4343 14.736 27.5 14.9994 27.5C15.2628 27.5 15.522 27.4343 15.7535 27.3088C15.9851 27.1833 16.1816 27.0019 16.3254 26.7813L22.7857 16.8639C23.949 15.0782 24.4631 12.9468 24.2418 10.827C24.0205 8.70725 23.0773 6.72798 21.5703 5.22094ZM21.3531 15.9307L14.9994 25.6843L8.64566 15.9307C6.7008 12.9451 7.11793 8.94956 9.63748 6.4299C10.3416 5.72575 11.1775 5.16718 12.0975 4.7861C13.0175 4.40501 14.0036 4.20887 14.9994 4.20887C15.9952 4.20887 16.9813 4.40501 17.9013 4.7861C18.8213 5.16718 19.6572 5.72575 20.3613 6.4299C22.8809 8.94956 23.298 12.9451 21.3531 15.9307Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default PinIcon
