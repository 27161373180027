/** @jsxImportSource @emotion/react */
import { Box } from '@mui/material'
import { useTheme } from '@mui/material/styles'

import { SideBarBrandLogo } from '../Sidebar'
import { logoContainer, sidebarFullLogo, sidebarSimpleLogo } from './SidebarLogo.styles'

interface SidebarLogoProps {
  logo: SideBarBrandLogo
  fullWidth: number
  simpleWidth: number
  isOpen: boolean
}

export const SidebarLogo = ({ logo, isOpen, fullWidth, simpleWidth }: SidebarLogoProps) => {
  const theme = useTheme()

  return (
    <Box
      css={logoContainer({ theme, logoBackgroundColour: logo.logoBackgroundColour })}
      onClick={logo.onClick}
      title={logo.title}
    >
      <Box css={sidebarFullLogo(isOpen, fullWidth)} component="img" src={logo.fullLogo} />
      <Box css={sidebarSimpleLogo(!isOpen, simpleWidth)} component="img" src={logo.reducedLogo} />
    </Box>
  )
}
