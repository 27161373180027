import { DrawArea, updateMapStateProperty } from '@redux/map/mapSlice'
import { AnyAction, Dispatch } from '@reduxjs/toolkit'

export interface UpdateDrawAreasProps {
  drawAreas: DrawArea[]
  dispatch: Dispatch<AnyAction>
}

export function handleUpdateDrawAreas({ drawAreas, dispatch }: UpdateDrawAreasProps) {
  dispatch(updateMapStateProperty({ drawAreas }))
}
