import { sentryCreateBrowserRouter } from '@modules/Sentry'
import { RouteObject, redirect } from 'react-router-dom'

import { ProtectedRoute } from '@atoms/ProtectedRoute'
import { RiskExplorerUpdate } from '../components/Pages/RiskExplorerUpdate/RiskExplorerUpdate'
import { App, loader as AppLoader } from './App'
import { ErrorPage } from './ErrorPage'
import { LandingPage } from './LandingPage'
import { RiskPage } from './RiskPage'
import { LivePage } from './LivePage'
import { SummaryPage } from './SummaryPage'
import { AdminPage, ProtectedRouteLoader } from './AdminPage'
import { StrategyPage } from './StrategyPage'
import { ActionPlan } from './StrategyPage/ActionPlan'
import { HorizonScan } from './StrategyPage/HorizonScan'
import { Review } from './StrategyPage/Review'
import { Strategy } from './StrategyPage/Strategy'
import { AccessPage } from './AccessPage'
import { AdaptationPage } from './AdaptationPage'
import { LandusePage } from './LandusePage'
import { OnboardingPage } from './OnboardingPage'

const routes: RouteObject[] = [
  {
    path: '',
    element: <LandingPage />,
  },
  {
    path: 'home',
    element: <LandingPage />,
  },
  {
    path: 'risk',
    element: <RiskPage />,
  },
  {
    path: 'map',
    loader: () => redirect('/risk'),
  },
  {
    path: 'live',
    element: <LivePage />,
  },
  {
    path: 'summary',
    element: <SummaryPage />,
  },
  {
    path: 'access',
    element: <AccessPage />,
  },
  {
    path: 'adaptation',
    element: <AdaptationPage />,
  },
  {
    path: 'landuse',
    element: <LandusePage />,
  },
  {
    path: 'onboarding',
    element: <OnboardingPage />,
  },
  {
    path: 'strategy',
    element: (
      <ProtectedRoute feature="strategyPage">
        <StrategyPage />
      </ProtectedRoute>
    ),
    children: [
      { path: 'review', element: <Review /> },
      { path: 'horizon-scan', element: <HorizonScan /> },
      { path: 'strategy', element: <Strategy /> },
      { path: 'action-plan', element: <ActionPlan /> },
    ],
  },
  {
    path: 'manage-users',
    element: <AdminPage />,
    loader: ProtectedRouteLoader,
  },
]

export const router = sentryCreateBrowserRouter([
  {
    path: '/',
    loader: AppLoader, // TODO: extract component to its own file
    element: <App />,
    errorElement: <ErrorPage />,
    children: routes,
  },
  {
    path: '/explorer/*',
    element: <RiskExplorerUpdate />,
    errorElement: <ErrorPage />,
    loader: () => redirect('/'),
  },
  {
    path: '*',
    element: <ErrorPage />,
  },
])
