import { css } from '@emotion/react'
import { Theme } from '@mui/material/styles'

export const horizonScanLoadingContainer = ({ theme }: { theme: Theme }) => css`
  padding: ${theme.spacing(4)};

  width: 100%;
  height: 100%;

  display: flex;
  flex: 1 1 auto;
  gap: ${theme.spacing(3)};

  overflow: auto;
`

export const horizonScanLoadingRight = css`
  display: flex;
  width: 100%;
  gap: 8px;
`

export const horizonScanLoadingLeft = css`
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: relative;
`
