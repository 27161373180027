import { Theme } from '@mui/material'
import { css } from '@emotion/react'

export const storyContainer = (theme: Theme) => css`
  padding: ${theme.spacing(4)} ${theme.spacing(4)} ${theme.spacing(4)} ${theme.spacing(2)};
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: center;
  gap: ${theme.spacing(2)};
  overflow-y: scroll;
  position: relative;

  &::-webkit-scrollbar {
    width: 12px !important;
  }

  &::-webkit-scrollbar-track {
    background-color: ${theme.palette.primary.main}00 !important;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${theme.palette.primary.main}40 !important;
    border-radius: 0px !important;
  }
`

export const storyScrollingContainer = () => css`
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 0.25s ease;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  gap: 16px;
`

export const storyFlexContainer = (theme: Theme) => css`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  gap: ${theme.spacing(8)};
  padding-bottom: 80vh;
`

export const pageContent = (theme: Theme) => css`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  padding-left: ${theme.spacing(12)};
  position: relative;
  height: 100%;
  transition: background-color 0.25s ease;
  background-color: ${theme.palette.primary.main}20;

  > * {
    transition: filter 0.25s ease;
  }
`
