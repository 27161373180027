/** @jsxImportSource @emotion/react */

import { Box, useTheme } from '@mui/material'
import { body, scrollingBody } from './SideDrawerBody.styles'

export const SideDrawerBody = ({ children }: { children: React.ReactNode }) => {
  const theme = useTheme()
  return (
    <Box css={body({ theme })}>
      <Box css={scrollingBody}>{children}</Box>
    </Box>
  )
}
