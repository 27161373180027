import { css } from '@emotion/react'
import { Theme } from '@mui/material'

export const continuousLegendContainer = ({ theme }: { theme: Theme }) => css`
  display: flex;
  align-items: center;
  justify-content: start;

  gap: ${theme.spacing(2)};

  margin-top: ${theme.spacing(2)};
  padding: ${theme.spacing(1)} ${theme.spacing(3)};

  background-color: ${theme.palette.white.main};

  border-radius: ${theme.spacing(1)};
  border: 1px solid ${theme.palette.grey[100]};
`
