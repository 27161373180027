import { css } from '@emotion/react'

export const sliderIndicatorContainer = css`
  width: 16px;

  display: flex;
  align-items: center;
  justify-content: center;

  position: relative;
`

export const rotateClass = (degree: number) => css`
  -webkit-transform: rotate(${degree}deg);
  -moz-transform: rotate(${degree}deg);
  -ms-transform: rotate(${degree}deg);
  -o-transform: rotate(${degree}deg);
  transform: rotate(${degree}deg);
`

export const indicatorContainer = css`
  position: absolute;
  inset: 0;
`

export const sliderIndicatorArrow = css`
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%) translateY(-120%);
`
export const labelContainer = css`
  font-weight: 500;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`
