/** @jsxImportSource @emotion/react */
import Markdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import remarkGfm from 'remark-gfm'
import remarkToc from 'remark-toc'
import { MarkdownStyle, markdownContainer } from './UiMarkdown.styles'
import { forwardRef, memo, useEffect, useState } from 'react'
import { SerializedStyles } from '@emotion/react'
import { useSelector } from 'react-redux'
import { RootState } from '@redux/store'
import { PUBLIC_ASSETS_BASE_URL } from '@src/app-constants'
import { FancySuspense } from '../FancySuspense'

interface UiMarkdownProps {
  css?: SerializedStyles
  children: string
  className?: string
}

const UiMarkdown = forwardRef<HTMLDivElement, UiMarkdownProps>(({ children, className }, ref) => {
  const [parsedMD, setParsedMd] = useState<string | null>(null)
  const { clientDisplayName, logos } = useSelector((state: RootState) => state.user)

  useEffect(() => {
    const replaceClientName = (md: string): string => {
      md = md.replaceAll('{clientName}', clientDisplayName)
      return md
    }

    const replaceLogos = (md: string): string => {
      md = md.replaceAll('{uiLogo}', '![uiLogo](/logos/UILogoFull.svg)')

      // Clients can have mulitple logos
      if (md.indexOf('{clientLogo}') >= 0) {
        let logosMd = ''
        logosMd += `![clientLogo](/logos/${logos.full})\n`
        md = md.replaceAll('{clientLogo}', logosMd)
      }

      return md
    }

    const parseMD = async (md: string): Promise<string> => {
      // Some icons maybe unnecessarily prefixed with s3://
      md = md.replaceAll(/:s3:\/\/([a-z_]+):/g, ':$1:')

      // Replace :icon_name: with image to S3 icon folder
      md = md.replaceAll(
        /:([a-z_]+):/gi,
        `<img class="inline-img" src="${PUBLIC_ASSETS_BASE_URL}/icons/$1.svg" />`,
      )

      // replace {token} with replacement
      const replacements: ((md: string) => string | Promise<string>)[] = [
        (md) => md.replaceAll('{datetime}', new Date().toLocaleString()),
        (md) => replaceLogos(md),
        (md) => replaceClientName(md),
      ]

      for (const replacement of replacements) {
        md = await replacement(md)
      }
      return md
    }

    const parseChildren = async () => {
      setParsedMd(await parseMD(children))
    }
    parseChildren()
  }, [children, clientDisplayName, logos])

  return (
    <FancySuspense isLoading={!parsedMD} variant="text">
      <div css={markdownContainer} ref={ref}>
        <Markdown
          css={MarkdownStyle}
          className={className}
          urlTransform={markdownUrlTransform}
          rehypePlugins={[rehypeRaw]}
          remarkPlugins={[remarkToc, remarkGfm]}
        >
          {parsedMD}
        </Markdown>
      </div>
    </FancySuspense>
  )
})

UiMarkdown.displayName = 'UiMarkdown'

export default memo(UiMarkdown, (prev, next) => prev.children === next.children)

// Add 'data' protocol to allow dynamic images such as the map by replacing markdownUrlTransform:
function markdownUrlTransform(value: string): string | null | undefined {
  // Same as:
  // <https://github.com/micromark/micromark/blob/929275e/packages/micromark-util-sanitize-uri/dev/index.js#L34>
  // But without the `encode` part.
  const colon = value.indexOf(':')
  const questionMark = value.indexOf('?')
  const numberSign = value.indexOf('#')
  const slash = value.indexOf('/')
  const safeProtocol = /^(https?|ircs?|mailto|xmpp|data)$/i

  if (
    // If there is no protocol, it’s relative.
    colon < 0 ||
    // If the first colon is after a `?`, `#`, or `/`, it’s not a protocol.
    (slash > -1 && colon > slash) ||
    (questionMark > -1 && colon > questionMark) ||
    (numberSign > -1 && colon > numberSign) ||
    // It is a protocol, it should be allowed.
    safeProtocol.test(value.slice(0, colon))
  ) {
    return value
  }

  return ''
}
