/* eslint-disable @typescript-eslint/ban-ts-comment */
import { getWorkbookData } from '@utils/workbook.utils'
import { HorizonScanItem, ScenarioOption, ScenarioType } from './HorizonScan'

export interface SelectScenarioOption {
  label: string
  value: ScenarioOption | 'all'
}

export type AllScenarioOptions = SelectScenarioOption | CurrentScenarioOption | null

export interface CurrentScenarioOption {
  label: string
  value: string
}

export interface SelectTimeframeOption {
  label: string
  value: 'current' | '2025-2050'
}

export interface SelectRiskCategoryOption {
  label: string
  value: 'physical' | 'transition' | 'all'
}

export interface SelectClimateRelatedOption {
  label: string
  value: 'yes' | 'no' | 'all'
}

export const selectScenarioOptions: SelectScenarioOption[] = [
  { label: 'All', value: 'all' },
  { label: 'Hothouse', value: 'hothouse' },
  { label: 'Orderly', value: 'orderly' },
  { label: 'Disorderly', value: 'disorderly' },
]

export const selectRiskCategoryOptions: SelectRiskCategoryOption[] = [
  { label: 'All', value: 'all' },
  { label: 'Physical', value: 'physical' },
  { label: 'Transition', value: 'transition' },
]

export const selectClimateRelatedOptions: SelectClimateRelatedOption[] = [
  { label: 'All', value: 'all' },
  { label: 'Yes', value: 'yes' },
  { label: 'No', value: 'no' },
]

export const timeframeOptions: SelectTimeframeOption[] = [
  { label: 'Current', value: 'current' },
  { label: '2025-2050', value: '2025-2050' },
]

export interface ExcelHorizonScanColumns {
  division: string
  climate_scenario: ScenarioOption
  pestle: string
  threat_opportunity: string
  change: string
  opportunity_risk: string
  control: string
  climate_related: string
  likelihood: number
  consequence: number
  certainty: string
  score: number
  rank: number
  risk_category: string
  timeframe: string
}

export function getNodeDiameter(certainty: string) {
  switch (certainty) {
    case 'Low':
      return 14
    case 'Moderate':
      return 8
    case 'High':
      return 4
    default:
      return 0
  }
}

export function getOverlaps(data: HorizonScanItem[]) {
  return data.map((item) => {
    const overlaps: HorizonScanItem[] = data
      .filter((obj) => obj.x === item.x && obj.y === item.y && item.change !== obj.change)
      .map((overlapItem) => {
        return {
          change: overlapItem.change,
          x: Number(overlapItem.x),
          y: Number(overlapItem.y),
          r: Number(overlapItem.r),
          division: overlapItem.division,
          climate_scenario: overlapItem.climate_scenario,
          pestle: overlapItem.pestle,
          control: overlapItem.control,
          threat_opportunity: overlapItem.threat_opportunity,
          opportunity_risk: overlapItem.opportunity_risk,
          climate_related: overlapItem.climate_related,
          certainty: overlapItem.certainty,
          score: overlapItem.score,
          rank: overlapItem.rank,
          risk_category: overlapItem.risk_category,
          timeframe: overlapItem.timeframe,
          overlaps: null,
        }
      })

    return {
      ...item,
      overlaps,
    }
  })
}

export function getTopTenThreatsAndOpportunitiesFromArray({
  items,
  scenarioType,
}: {
  items: HorizonScanItem[]
  scenarioType: ScenarioType
}) {
  return items
    .filter((item) => item.threat_opportunity.trim().toLowerCase() === scenarioType)
    .filter((value, index, self) => index === self.findIndex((t) => t.change === value.change))
    .sort((a, b) => a.rank - b.rank)
    .slice(0, 10)
}

const WORKBOOK_NAME = 'horizon_scan'

export async function getRiskSummaryData() {
  const workbookData = (await getWorkbookData(WORKBOOK_NAME)) as ExcelHorizonScanColumns[]

  return workbookData.map((item) => {
    return {
      change: item.change ?? '',
      x: Number(item.likelihood) ?? 0,
      y: Number(item.consequence) ?? 0,
      r: getNodeDiameter(item.certainty) ?? '',
      division: item.division ?? '',
      climate_scenario: item.climate_scenario ?? 'current',
      pestle: item.pestle ?? '',
      control: item.control ?? '',
      threat_opportunity: item.threat_opportunity ?? '',
      opportunity_risk: item.opportunity_risk ?? '',
      climate_related: item.climate_related ?? '',
      certainty: item.certainty ?? '',
      score: item.score ?? 0,
      rank: item.rank ?? 0,
      risk_category: item.risk_category ?? '',
      timeframe: item.timeframe ?? '',
      overlaps: [],
    }
  })
}

interface Conditions {
  [key: string]: string
}

export async function filterByConditions<T>(array: T[], conditions: Conditions[]): Promise<T[]> {
  return new Promise((resolve) => {
    const results = array.filter((item: T) => {
      return conditions.every((condition: Conditions) => {
        return Object.keys(condition).every((key: string) => {
          if (condition[key].trim().toLowerCase() === 'all') {
            return true
          }
          // @ts-ignore
          if (typeof item[key] === 'number') return condition[key] === item[key]

          // @ts-ignore
          if (typeof item[key] === 'string') {
            // @ts-ignore
            if (item[key].trim().toLowerCase() === 'all') {
              return true
            }
            // @ts-ignore
            return condition[key].trim().toLowerCase() === item[key].trim().toLowerCase()
          }
        })
      })
    })

    resolve(results)
  })
}

export function filterData({
  data,
  scenarioType,
  scenarioOption,
  riskCategory,
}: {
  data: HorizonScanItem[]
  scenarioOption: AllScenarioOptions
  riskCategory: SelectRiskCategoryOption | null
  scenarioType: string | null
}) {
  let filteredData: HorizonScanItem[] = data

  if (scenarioType) {
    filteredData = filteredData.filter((item) => {
      return item.threat_opportunity.trim().toLowerCase() === scenarioType && item.x && item.y
    })
  }

  if (scenarioOption && scenarioOption.value !== 'all') {
    filteredData = filteredData.filter((item) => {
      return item.climate_scenario.trim().toLowerCase() === scenarioOption?.value
    })
  }

  if (riskCategory && riskCategory.value !== 'all') {
    filteredData = filteredData.filter((item) => {
      return item.risk_category.trim().toLowerCase() === riskCategory.value
    })
  }

  return filteredData
}

export async function getTopTenThreatsAndOpportunities({
  data,
  conditions,
  timeframe,
}: {
  data: HorizonScanItem[]
  conditions: Conditions[]
  timeframe: SelectTimeframeOption
}) {
  let filteredData = await filterByConditions(data, conditions)

  if (timeframe.value !== 'current') {
    filteredData = filteredData.filter(
      (item) => item.climate_scenario.trim().toLowerCase() !== 'current',
    )
  }

  const topTenThreatsData = getTopTenThreatsAndOpportunitiesFromArray({
    items: filteredData,
    scenarioType: 'threat',
  })

  const topTenOpportunitiesData = getTopTenThreatsAndOpportunitiesFromArray({
    items: filteredData,
    scenarioType: 'opportunity',
  })

  return {
    topTenOpportunitiesData,
    topTenThreatsData,
  }
}
