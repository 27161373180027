import { BASE_API_URL } from '@src/app-constants'
import axios from '@src/utils/customAxios'
import { useEffect, useState } from 'react'
import { Story } from '../tabs/StoriesTab/Stories.utils'
import { useSelector } from 'react-redux'
import { RootState } from '@redux/store'

export const useStoriesFetcher = (): Story[] | null => {
  const [stories, setStories] = useState(null)

  const { clientName } = useSelector((state: RootState) => state.user)

  useEffect(() => {
    axios
      .get(`${BASE_API_URL}/api/risk/stories/`)
      .then((response) => {
        setStories(response.data)
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error('Error fetching stories:', error)
      })
  }, [clientName])

  return stories
}
