import { BASE_API_URL } from '@src/app-constants'
import axios from '@src/utils/customAxios'

export type FullRiskSummaryDataType = {
  [key: string]: {
    [key: string]: {
      [key: string]: number
    } & { metricUnit: string }
  }
}
export const fetchFullClientRiskSummary = async (
  hazardLayersTypes: string[],
  abortController: AbortController,
): Promise<FullRiskSummaryDataType | null> => {
  if (hazardLayersTypes.length === 0) return {}
  try {
    const url = `${BASE_API_URL}/api/asset/rsm/vulnerabilities/${hazardLayersTypes.join(',')}`

    const response = await axios.get<FullRiskSummaryDataType>(url, {
      signal: abortController.signal,
    })

    return response.data
  } catch (error) {
    return null
  }
}
