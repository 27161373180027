/** @jsxImportSource @emotion/react */

import { StoryMenuItem } from './StoryMenuItem'
import { Story } from '../Stories.utils'
import { SideDrawerBody } from '../../../components/SideDrawerBody'

export const StoryMenu = ({
  stories,
  setStory,
}: {
  stories: Story[]
  setStory: (story: Story) => void
}) => {
  return (
    <SideDrawerBody>
      {stories.map((story, index) => (
        <StoryMenuItem key={index} story={story} onClick={() => setStory(story)} />
      ))}
    </SideDrawerBody>
  )
}
