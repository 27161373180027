/** @jsxImportSource @emotion/react */
import { useTheme } from '@mui/material/styles'
import { Box, Button, Tooltip, Typography } from '@mui/material'
import {
  RiskPagePlaceholderContainer,
  RiskPagePlaceholderRight,
  secondaryTextPrimary,
  RiskPagePlaceholderLeft,
  logo,
  content,
  ShowOnMobile,
  RiskPagePlaceholderLeftInnerContainer,
  MobileHeaderText,
  MobileBodyText,
  mainText,
  marketingButton,
  secondaryTextText,
  footer,
  footerText,
  socialLinks,
  backgroundGreySquare,
  rightForeground,
} from './RiskPagePlaceholder.styles'
import LanguageIcon from '@mui/icons-material/Language'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import { useSelector } from 'react-redux'
import { RootState } from '@redux/store'
import { PUBLIC_ASSETS_BASE_URL } from '@src/app-constants'
import { useNavigate } from 'react-router-dom'

export const RiskPagePlaceholder = () => {
  const theme = useTheme()
  const navigate = useNavigate()
  const { clientDisplayName } = useSelector((state: RootState) => state.user)

  return (
    <Box css={RiskPagePlaceholderContainer({ theme })}>
      <Box css={RiskPagePlaceholderRight({ theme })}>
        <Box css={rightForeground({ theme })}>
          <Box css={backgroundGreySquare()} />
          <img
            src={`${PUBLIC_ASSETS_BASE_URL}/images/3DBuildingsRexDemo.png`}
            alt="Example of the accessibility functionality"
          />
          <Typography variant="h3" css={secondaryTextPrimary({ theme })}>
            Your Risk module is being created
          </Typography>
          <Typography variant="body1">
            {clientDisplayName} will soon be able to explore the potential risks to infrastructure
            and communities from natural hazards and climate change. This interactive tool
            facilitates impact assessment, scenario planning, and stakeholder engagement to enhance
            our resilience against multiple hazards.
          </Typography>
          <br />
          <Typography variant="body1">
            For information on the set-up process, data requirements and project contacts please
            visit the&nbsp;
            <a onClick={() => navigate('/onboarding')}>onboarding tab</a>.
          </Typography>
        </Box>
      </Box>
      <Box css={RiskPagePlaceholderLeft({ theme })}>
        <Box component="header">
          <a href="https://resilience-explorer.org/" target="_blank" rel="noreferrer">
            <Box component="img" src="logos/RexLogoFullWhite.svg" css={logo} />
          </a>
        </Box>
        <Box component="main" css={content}>
          <Box css={ShowOnMobile}>
            <Box component="main" css={RiskPagePlaceholderLeftInnerContainer}>
              <Typography variant="h3" css={MobileHeaderText({ theme })}>
                Your Risk module is being created
              </Typography>
              <Typography variant="body1" css={MobileBodyText({ theme })}>
                {clientDisplayName} will soon be able to explore the potential risks to
                infrastructure and communities from natural hazards and climate change. This
                interactive tool facilitates impact assessment, scenario planning, and stakeholder
                engagement to enhance our resilience against multiple hazards.
              </Typography>
              <br />
              <Typography variant="body1" css={MobileBodyText({ theme })}>
                For information on the set-up process, data requirements and project contacts please
                visit the&nbsp;
                <a onClick={() => navigate('/onboarding')}>onboarding tab</a>.
              </Typography>
            </Box>
          </Box>
          <Typography variant="h2" css={mainText({ theme })}>
            Questions about your dashboard?
          </Typography>
          <a
            href="https://resilience-explorer.org/contact-resilience-explorer/"
            target="_blank"
            rel="noreferrer"
          >
            <Button variant="outlined" color="secondary" css={marketingButton({ theme })}>
              Get in touch
            </Button>
          </a>
          <Typography variant="h6" css={secondaryTextText({ theme })}>
            Powered by
            <a href="https://urbanintelligence.co.nz" target="_blank" rel="noreferrer">
              Urban Intelligence
            </a>
          </Typography>
        </Box>

        <Box component="footer" css={footer({ theme })}>
          <Typography variant="h6" css={footerText({ theme })}>
            Find us at
          </Typography>
          <Box css={socialLinks({ theme })}>
            <Tooltip title="LinkedIn">
              <a
                href="https://www.linkedin.com/company/urban-intelligence-nz/"
                target="_blank"
                rel="noreferrer"
              >
                <LinkedInIcon />
              </a>
            </Tooltip>
            <Tooltip title="Website">
              <a href="https://resilience-explorer.org/" target="_blank" rel="noreferrer">
                <LanguageIcon />
              </a>
            </Tooltip>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
