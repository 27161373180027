/** @jsxImportSource @emotion/react */
import { Box, Chip, useTheme, Typography } from '@mui/material'
import { continuousLegendContainer } from './LegendContinuous.styles'

export type LegendContinuousStop = {
  color: string
  value: number
  label?: string
}

export const LegendContinuous = () => {
  const theme = useTheme()

  return (
    <Box css={continuousLegendContainer({ theme })}>
      <Typography fontWeight={500}>Continuous Legend</Typography>
      <Chip label="Coming soon" variant="outlined" />
    </Box>
  )
}
