/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { Box, Divider, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { useCallback, useEffect } from 'react'

import { PasswordInput } from '@src/components/Molecules/PasswordInput'
import { useSnackbars } from '@uintel/ui-component-library'
import { Button } from '@src/components/Atoms/Button'
import { BASE_API_URL } from '../../../../../app-constants'
import { LoadingButton, PasswordProps, ValidationInputProps } from '../SignInModal'
import {
  codeAndPasswordContainer,
  codeAndPasswordContent,
  codeAndPasswordModalContainer,
  emptyBox,
  resetPasswordButton,
  signInBodyContent,
  signInFooter,
  stepperDivider,
} from '../SignInModal.styles'
import { checkIfPasswordHasValidFormat } from '../SignInModal.utils'
import axios from '@src/utils/customAxios'

interface ChangePasswordProps {
  validationCode: ValidationInputProps
  email: ValidationInputProps
  newPassword: PasswordProps
  password: PasswordProps
  confirmPassword: PasswordProps
  isLoading: boolean
  userUUID: string | null
  authChallengeSessionId: string | null
  handleChangeNewPassword: (newPassword: PasswordProps) => void
  handleChangeConfirmPassword: (confirmPassword: PasswordProps) => void
  handleChangeEmail: (email: ValidationInputProps) => void
  handleChangeCode: (code: ValidationInputProps) => void
  handleSetLoading: (loading: boolean) => void
  handlePasswordChanged: () => void
}

export const ChangePassword = ({
  email,
  password,
  newPassword,
  confirmPassword,
  isLoading,
  userUUID,
  handleChangeNewPassword,
  authChallengeSessionId,
  handleChangeConfirmPassword,
  handleSetLoading,
  handlePasswordChanged,
}: ChangePasswordProps) => {
  const theme = useTheme()
  const { displaySnackbar } = useSnackbars()

  const handleCheckPasswords = useCallback(() => {
    const passwordErrors = checkIfPasswordHasValidFormat(newPassword.value)

    if (passwordErrors.length) {
      handleChangeNewPassword({ ...newPassword, error: true, helperTexts: passwordErrors })
      handleSetLoading(false)
      return false
    }

    if (newPassword.value !== confirmPassword.value) {
      handleChangeConfirmPassword({
        ...confirmPassword,
        error: true,
        helperTexts: ['Passwords do not match'],
      })
      handleSetLoading(false)
      return false
    }

    if (password.value === confirmPassword.value) {
      handleChangeConfirmPassword({
        ...confirmPassword,
        error: true,
        helperTexts: ['Password cannot be identical to your last password.'],
      })
      handleSetLoading(false)
      return false
    }

    return true
  }, [
    confirmPassword,
    handleChangeConfirmPassword,
    handleChangeNewPassword,
    handleSetLoading,
    newPassword,
    password.value,
  ])

  const apiChangePassword = useCallback(async () => {
    try {
      const changePasswordResponse = await axios.post(
        `${BASE_API_URL}/api/user/respond_to_auth_challenge`,
        {
          user_uuid: userUUID,
          email: email.value,
          auth_challenge_session: authChallengeSessionId,
          new_password: newPassword.value,
        },
      )

      const success = changePasswordResponse.status === 200
      return success
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error)
      displaySnackbar({ message: 'Something went wrong.', type: 'error' })
    }
  }, [authChallengeSessionId, displaySnackbar, email.value, newPassword.value, userUUID])

  const handleSubmitChangePassword = useCallback(async () => {
    handleSetLoading(true)

    const isValidPassword = handleCheckPasswords()

    if (!isValidPassword) return handleSetLoading(false)

    const isConfirmChangePasswordRequestSuccessful = await apiChangePassword()

    if (!isConfirmChangePasswordRequestSuccessful) {
      handleSetLoading(false)
      displaySnackbar({
        message: 'Something went wrong. Please check if your new password is valid',
        type: 'error',
      })
      return
    }

    handleSetLoading(false)
    displaySnackbar({ message: 'Password changed successfully.', type: 'success' })
    handlePasswordChanged()
  }, [
    apiChangePassword,
    displaySnackbar,
    handleCheckPasswords,
    handlePasswordChanged,
    handleSetLoading,
  ])

  // add event listener to send code by email when user press enter
  useEffect(() => {
    const handleEnter = (event: KeyboardEvent) => {
      if (event.key !== 'Enter') return

      return handleSubmitChangePassword()
    }

    document.addEventListener('keydown', handleEnter)
    return () => document.removeEventListener('keydown', handleEnter)
  }, [handleSubmitChangePassword])

  return (
    <Box>
      <Typography align="center" color="#0b2948" margin="0 0.5rem">
        Welcome <b>{email.value}</b>!<br /> Your login was successful, but you must change your
        password.
      </Typography>
      <Divider light css={stepperDivider({ theme })} />
      <Box css={codeAndPasswordModalContainer({ theme })}>
        <>
          <Box
            css={css`
              ${signInBodyContent({ theme })}
              padding-top: 0px;
            `}
          >
            <Box css={codeAndPasswordContainer({ theme })}>
              <Box css={codeAndPasswordContent({ theme })}>
                <Typography align="center">To continue, please choose a new password.</Typography>
              </Box>
              <Box
                css={css`
                  width: 100%;
                  display: flex;
                  flex-direction: column;
                `}
              >
                <PasswordInput
                  id="change-signup-password"
                  error={newPassword.error}
                  helperTexts={newPassword.helperTexts}
                  value={newPassword.value}
                  handleChangePassword={handleChangeNewPassword}
                />
                <PasswordInput
                  id="change-confirm-password"
                  label="Confirm password"
                  error={confirmPassword.error}
                  helperTexts={confirmPassword.helperTexts}
                  value={confirmPassword.value}
                  handleChangePassword={handleChangeConfirmPassword}
                />
              </Box>
            </Box>
          </Box>
          <Box css={signInFooter}>
            <Box css={emptyBox} />
            <Button onClick={handleSubmitChangePassword} css={resetPasswordButton}>
              {isLoading ? (
                <LoadingButton />
              ) : (
                <Typography variant="button">Change password</Typography>
              )}
            </Button>
          </Box>
        </>
      </Box>
    </Box>
  )
}
