import { combineReducers, configureStore } from '@reduxjs/toolkit'

import authReducer from './auth/authSlice'
import { mapReducer } from './map/mapSlice'
import { sideDrawerReducer } from './sideDrawer/sideDrawerSlice'
import { userReducer } from './user/userSlice'
import { projectedRiskChartStateReducer } from './projectedRiskChartSlice'
import { projectedExposureChartStateReducer } from './projectedExposureChartSlice'

const store = configureStore({
  reducer: {
    map: mapReducer,
    sideDrawer: sideDrawerReducer,
    user: userReducer,
    auth: authReducer,
    projectedRiskChartState: projectedRiskChartStateReducer,
    projectedExposureChartState: projectedExposureChartStateReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
})

const rootReducer = combineReducers({
  map: mapReducer,
  auth: authReducer,
  user: userReducer,
  sideDrawer: sideDrawerReducer,
  projectedRiskChartState: projectedRiskChartStateReducer,
  projectedExposureChartState: projectedExposureChartStateReducer,
})

export type RootState = ReturnType<typeof rootReducer>

export default store
