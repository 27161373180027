/** @jsxImportSource @emotion/react */
import { Map, Expression } from 'mapbox-gl'
import { createContext, useContext, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { handleChangeMapStyle } from './utils/style'

import {
  BASE_API_URL,
  NEW_ZEALAND_LAT,
  NEW_ZEALAND_LGT,
  NEW_ZEALAND_ZOOM_LEVEL,
} from '@src/app-constants'
import {
  MapLayer,
  MapLocation,
  updateMapStateProperty,
  updateSelectedFeature,
} from '@redux/map/mapSlice'
import {
  AddRegionMasksLayersProps,
  ChangeMapStyleProps,
  ClearMapProps,
  FitToBoundsProps,
  RemoveLayerByIdProps,
  RemoveRegionMasksLayersProps,
  SetLayerVisibilityProps,
  ToggleLayerVisibilityProps,
  UpdateDrawAreasProps,
  UpdateLayersProps,
  UpdateVisibleAssetsProps,
} from './MapContext.types'
import { handleUpdateVisibleAssets } from './utils/assets'
import { handleUpdateDrawAreas } from './utils/drawAreas'
import {
  handleAddRegionMasksLayers,
  handleClearMap,
  handleRemoveLayerById,
  handleRemoveRegionMasksLayers,
  handleSetLayerVisibility,
  handleToggleLayerVisibility,
  handleToggleLayerInteractivity,
  handleUpdateLayers,
  loadRegionGeometry,
} from './utils/layers'
import {
  FitBoundsProps,
  FlyTo,
  handleFitBounds,
  handleFlyTo,
  handleFlyToFeature,
} from './utils/location'
import { RegionOption } from '@src/components/Molecules/RegionFilter'
import axios from '@src/utils/customAxios'
import {
  SideDrawerLayerTabs as SideDrawerLayerTab,
  setSideDrawerInfoLayerFocus,
} from '@redux/sideDrawer/sideDrawerSlice'
import { RootState } from '@redux/store'
import { BaseMapLabel } from '@src/components/Pages/Map/BaseMapButton'
import { HazardOptionStateByHazard } from '@src/components/Molecules/SideDrawerContent/tabs/InformativePanel/LayersTab/HazardControls'
import { removeSelectedMarkerFromMapIfExists } from '@src/components/Molecules/MapView/RiskMapView.utilities'

export interface MapContext {
  map: Map | null
  initialLocation: MapLocation
  regionMasks: RegionOption[] | null
  cascadingAssetNDays: { [assetId: string]: number | null }
  mapLayerStyles: MapLayerStyles
  showAddLayersModalTab: string
  basemap: BaseMapLabel
  hazardOptionState: HazardOptionStateByHazard
  prevHazardOptionState: HazardOptionStateByHazard | null
  setHazardOptionState: React.Dispatch<React.SetStateAction<HazardOptionStateByHazard>>
  clientLocation: MapLocation | null
  setPrevHazardOptionState: React.Dispatch<React.SetStateAction<HazardOptionStateByHazard | null>>
  handleChangeMapInitialLocation: (location: MapLocation) => void
  handleSetClientLocation: (location: MapLocation) => void
  addRegionMasksLayers: (props: AddRegionMasksLayersProps) => void
  setRegionMasks: (regions: RegionOption[] | null) => void
  clearMap: (props: ClearMapProps) => void
  changeMapStyle: (props: ChangeMapStyleProps) => void
  flyTo: (props: FlyTo) => void
  flyToFeature: (feature: GeoJSON.Feature, flyToOptions?: Partial<FlyTo>) => void
  fitToBounds: (props: FitToBoundsProps) => void
  removeLayerById: (props: RemoveLayerByIdProps) => void
  removeRegionMasksLayers: (props: RemoveRegionMasksLayersProps) => void
  setLayerVisibility: (props: SetLayerVisibilityProps) => void
  setMapInstance: (map: Map | null) => void
  setCascadingAssetNDays: (assetId: string, nDays: number | null) => void
  toggleLayerVisibility: (props: ToggleLayerVisibilityProps) => void
  toggleLayerInteractivity: (props: ToggleLayerVisibilityProps) => void
  updateLayers: (props: UpdateLayersProps) => void
  updateDrawAreas: (props: UpdateDrawAreasProps) => void
  updateVisibleAssets: (props: UpdateVisibleAssetsProps) => void
  setMapPadding: (padding: { top: number; right: number; bottom: number; left: number }) => void
  setShowAddLayersModalTab: (type: string) => void
  selectFeature: (
    feature: mapboxgl.MapboxGeoJSONFeature,
    layer: MapLayer,
    layerCategory: SideDrawerLayerTab,
  ) => boolean
  setBasemap: (basemap: BaseMapLabel) => void
  deselectFeature: () => void
}

export const MapContext = createContext({} as MapContext)

export interface MapProviderProps {
  children: React.ReactNode
}

export const default_location: MapLocation = {
  latitude: NEW_ZEALAND_LAT,
  longitude: NEW_ZEALAND_LGT,
  zoom: NEW_ZEALAND_ZOOM_LEVEL,
}

export interface MapLayerStyle {
  [property: string]: Expression
}

export interface MapLayerStyles {
  [id: string]: MapLayerStyle
}

export function MapProvider({ children }: MapProviderProps) {
  const [map, setMap] = useState<Map | null>(null)
  const dispatch = useDispatch()
  const [initialLocation, setInitialLocation] = useState(default_location)
  const [clientLocation, setClientLocation] = useState<MapLocation | null>(null)
  const [regionMasks, setRegionMasks] = useState<RegionOption[] | null>(null)
  const [cascadingAssetNDays, setCascadingAssetNDaysState] = useState<{
    [assetId: string]: number | null
  }>({})
  const [mapLayerStyles, setMapLayerStyles] = useState<MapLayerStyles>({})
  const [hazardOptionState, setHazardOptionState] = useState({} as HazardOptionStateByHazard)
  const [prevHazardOptionState, setPrevHazardOptionState] =
    useState<HazardOptionStateByHazard | null>(null)
  const [showAddLayersModalTab, setShowAddLayersModalTab] = useState('')
  const [basemap, setBasemap] = useState<BaseMapLabel>('Street')

  const setCascadingAssetNDays = (assetId: string, nDays: number | null) => {
    setCascadingAssetNDaysState((prev) => ({
      ...prev,
      [assetId]: nDays,
    }))
  }

  const mapPaddingRef = useRef<{
    top: number
    right: number
    bottom: number
    left: number
  }>({ top: 0, right: 0, bottom: 0, left: 0 })
  const { tab } = useSelector((state: RootState) => state.sideDrawer)

  const setMapPadding = (padding: {
    top?: number
    right?: number
    bottom?: number
    left?: number
  }) => {
    mapPaddingRef.current = {
      ...mapPaddingRef.current,
      ...padding,
    }
    flyTo({
      latitude: map?.getCenter().lat || default_location.latitude,
      longitude: map?.getCenter().lng || default_location.longitude,
      zoom: map?.getZoom() || default_location.zoom,
      usePadding: true,
      duration: 500,
    })
  }

  const getMapPadding = (usePadding: boolean | undefined) =>
    usePadding ? mapPaddingRef.current : { top: 0, right: 0, bottom: 0, left: 0 }

  const fetchMapLayerStyles = async (): Promise<MapLayerStyles> => {
    return axios
      .get<MapLayerStyles>(`${BASE_API_URL}/api/layer/styles`)
      .then((response) => response.data)
      .catch(() => {
        return {}
      })
  }

  const setMapInstance = (map: Map | null) => {
    setMap(map)
  }

  const handleChangeMapInitialLocation = (location: MapLocation) => {
    setInitialLocation(location)
  }

  const handleSetClientLocation = (location: MapLocation) => {
    setClientLocation(location)
  }

  const setLayerVisibility = ({
    showLayers,
    hideLayers,
    allLayers,
    map,
  }: SetLayerVisibilityProps) => {
    if (!map) return
    handleSetLayerVisibility({
      showLayers,
      hideLayers,
      map,
      allLayers,
      dispatch,
    })
  }

  const updateVisibleAssets = ({ visibleAssets, map }: UpdateVisibleAssetsProps) => {
    if (!map) return

    handleUpdateVisibleAssets({ visibleAssets, dispatch })
  }

  const removeLayerById = ({ layerId, map }: RemoveLayerByIdProps) => {
    if (!map) return

    handleRemoveLayerById({ layerId, map, dispatch })
    if (hazardOptionState[layerId]) {
      setHazardOptionState((prev) => {
        const newState = { ...prev }
        delete newState[layerId]
        return newState
      })
      if (prevHazardOptionState && prevHazardOptionState[layerId]) {
        delete prevHazardOptionState[layerId]
      }
    }
  }

  const addRegionMasksLayers = ({ regionMasks, map }: AddRegionMasksLayersProps) => {
    if (!map) return

    loadRegionGeometry(regionMasks).then((regionGeometry) => {
      handleAddRegionMasksLayers({ map, regionGeometry })
      setRegionMasks(regionMasks)
    })
  }

  const removeRegionMasksLayers = ({ map }: RemoveRegionMasksLayersProps) => {
    if (!map) return

    handleRemoveRegionMasksLayers({ map })
    setRegionMasks(null)
  }

  const fitToBounds = ({ boundingBox, map, usePadding }: FitBoundsProps) => {
    if (!map) return

    handleFitBounds({ boundingBox, map, padding: getMapPadding(usePadding) })
  }

  const clearMap = ({ map }: ClearMapProps) => {
    if (!map) return

    handleClearMap({ map })
    if (regionMasks?.length) setRegionMasks(null)
    dispatch(updateMapStateProperty({ layers: [], layerData: null }))
    if (Object.keys(hazardOptionState).length) {
      setHazardOptionState({})
      setPrevHazardOptionState(null)
    }
  }

  const flyTo = (flyTo: FlyTo) => {
    if (!map) return

    handleFlyTo({
      flyTo: { padding: getMapPadding(flyTo.usePadding), ...flyTo },
      map,
    })
  }

  const flyToFeature = (feature: GeoJSON.Feature, flyToOptions?: Partial<FlyTo>) => {
    if (!map) return

    handleFlyToFeature({
      map,
      feature,
      flyToOptions: {
        padding: getMapPadding(flyToOptions?.usePadding),
        ...flyToOptions,
      },
    })
  }

  const deselectFeature = () => {
    dispatch(
      updateSelectedFeature({
        selectedFeature: null,
        selectedLayer: null,
      }),
    )
    dispatch(updateMapStateProperty({ selectedMarker: null }))
    removeSelectedMarkerFromMapIfExists()
  }

  const selectFeature = (
    feature: mapboxgl.MapboxGeoJSONFeature,
    layer: MapLayer,
    layerCategory: SideDrawerLayerTab,
  ) => {
    if (!map || tab === 'Stories') return false
    dispatch(
      setSideDrawerInfoLayerFocus({
        isOpen: true,
        layerFocus: layerCategory,
      }),
    )
    dispatch(
      updateSelectedFeature({
        selectedFeature: feature,
        selectedLayer: layer,
      }),
    )

    return true
  }

  const updateLayers = ({ layers }: UpdateLayersProps) => {
    handleUpdateLayers({ layers, dispatch })
    const layerIds = layers.map((layer) => `${layer.hazard_id}`).filter((id) => id)
    const idsToRemove = Object.keys(hazardOptionState).filter((id) => !layerIds.includes(id))
    if (idsToRemove.length) {
      setHazardOptionState((prev) => {
        const newState = { ...prev }
        idsToRemove.forEach((id) => {
          delete newState[id]
        })
        return newState
      })
      if (prevHazardOptionState) {
        idsToRemove.forEach((id) => {
          delete prevHazardOptionState[id]
        })
      }
    }
  }
  const changeMapStyle = ({ style }: ChangeMapStyleProps) => {
    handleChangeMapStyle({ style, dispatch })
  }

  const updateDrawAreas = ({ drawAreas }: UpdateDrawAreasProps) => {
    handleUpdateDrawAreas({ drawAreas, dispatch })
  }

  const toggleLayerVisibility = ({ layer, layers }: ToggleLayerVisibilityProps) => {
    handleToggleLayerVisibility({ layer, layers, dispatch })
  }

  const toggleLayerInteractivity = ({ layer, layers }: ToggleLayerVisibilityProps) => {
    handleToggleLayerInteractivity({ layer, layers, dispatch })
  }

  // when the map is ready and the client location is set, fly to the client location
  useEffect(() => {
    if (clientLocation && map) {
      setTimeout(() => {
        flyTo(clientLocation)
      }, 1)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientLocation])

  useEffect(() => {
    // get styles once
    fetchMapLayerStyles().then((styles) => {
      setMapLayerStyles(styles)
    })
  }, [])

  return (
    <MapContext.Provider
      value={{
        map,
        flyTo,
        clearMap,
        setMapInstance,
        fitToBounds,
        updateDrawAreas,
        updateLayers,
        changeMapStyle,
        clientLocation,
        mapLayerStyles,
        prevHazardOptionState,
        setPrevHazardOptionState,
        hazardOptionState,
        removeLayerById,
        setLayerVisibility,
        toggleLayerVisibility,
        toggleLayerInteractivity,
        addRegionMasksLayers,
        removeRegionMasksLayers,
        regionMasks,
        setRegionMasks,
        cascadingAssetNDays,
        setCascadingAssetNDays,
        updateVisibleAssets,
        initialLocation,
        handleChangeMapInitialLocation,
        handleSetClientLocation,
        setMapPadding,
        flyToFeature,
        showAddLayersModalTab,
        setHazardOptionState,
        setShowAddLayersModalTab,
        selectFeature,
        basemap,
        setBasemap,
        deselectFeature,
      }}
    >
      {children}
    </MapContext.Provider>
  )
}

export const useMap = () => useContext(MapContext)
