import { css, keyframes } from '@emotion/react'

export const MarkdownStyle = css`
  ul {
    padding-left: 2em;
    padding-bottom: 0.6em;
    padding-top: 0.2em;
  }

  .inline-img {
    display: inline;
    padding: 0;
    vertical-align: baseline;
    height: 1em;
  }

  img[alt='clientLogo'] {
    background-color: black;
    width: 200px;
  }
`

const fadein = keyframes`
  from {
    opacity: 0;
    transform: translateX(10px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`

export const markdownContainer = css`
  animation: ${fadein} 0.5s ease-in-out both;
`
