export const Logistics = ({ ...rest }) => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M17.4 6.36842H15V4.68421C15 3.74947 14.288 3 13.4 3H8.6C7.712 3 7 3.74947 7 4.68421V6.36842H4.6C3.712 6.36842 3 7.11789 3 8.05263V17.3158C3 18.2505 3.712 19 4.6 19H17.4C18.288 19 19 18.2505 19 17.3158V8.05263C19 7.11789 18.288 6.36842 17.4 6.36842ZM8.6 4.68421H13.4V6.36842H8.6V4.68421ZM17.4 17.3158H4.6V15.6316H17.4V17.3158ZM17.4 13.1053H4.6V8.05263H7V9.73684H8.6V8.05263H13.4V9.73684H15V8.05263H17.4V13.1053Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default Logistics
