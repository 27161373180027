export const ArrowLeftRightIcon = ({ ...rest }) => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M20.6637 5.2806L24.2914 8.90828H9.97168V10.5845H24.2914L20.6637 14.2122L21.8489 15.3974L27.5 9.74639L21.8489 4.09541L20.6637 5.2806Z"
        fill="currentColor"
      />
      <path
        d="M9.33626 15.7876L8.15102 14.6024L2.5 20.2534L8.15102 25.9045L9.33626 24.7193L5.70858 21.0915H20.0283V19.4153H5.70858L9.33626 15.7876Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default ArrowLeftRightIcon
