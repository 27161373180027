export const LineIcon = ({ ...rest }) => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g id="Layer_2" data-name="Layer 2">
        <rect x="0.59" y="0.57" width="4" height="19.14" rx="1.38" fill="currentColor" />
        <path
          d="M9.13,9.27h.46A1.38,1.38,0,0,1,11,10.65v7.69a1.38,1.38,0,0,1-1.38,1.38H8.35A1.38,1.38,0,0,1,7,18.35V11.43A2.16,2.16,0,0,1,9.13,9.27Z"
          fill="currentColor"
        />
        <path
          d="M12.69-.86h1.07A1.46,1.46,0,0,1,15.23.6V13.54A1.46,1.46,0,0,1,13.77,15h-.6a1.93,1.93,0,0,1-1.93-1.93V.6A1.46,1.46,0,0,1,12.69-.86Z"
          transform="translate(8.87 -7.29) rotate(45)"
          fill="currentColor"
        />
      </g>
    </svg>
  )
}

export default LineIcon
