/** @jsxImportSource @emotion/react */
import { Box, Skeleton } from '@mui/material'
import { useTheme } from '@mui/material/styles'

import {
  horizonScanLoadingContainer,
  horizonScanLoadingLeft,
  horizonScanLoadingRight,
} from './HorizonScanLoading.styles'

export const HorizonScanLoading = () => {
  const theme = useTheme()

  return (
    <Box css={horizonScanLoadingContainer({ theme })}>
      <Box css={horizonScanLoadingLeft}>
        <Skeleton variant="rounded" width="498px" height="100%" animation="wave" />
        <Skeleton variant="rounded" width="498px" height="100%" animation="wave" />
      </Box>
      <Box css={horizonScanLoadingRight}>
        <Skeleton variant="rounded" width="100%" height="100%" animation="wave" />
      </Box>
    </Box>
  )
}
