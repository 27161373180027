export const PeopleIcon = ({ ...rest }) => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M19.4102 7.00001C21.3502 7.00001 22.9102 8.57001 22.9102 10.5C22.9102 12.39 21.4102 13.93 19.5402 14C19.4538 13.99 19.3666 13.99 19.2802 14M21.3402 23C22.0602 22.85 22.7402 22.56 23.3002 22.13C24.8602 20.96 24.8602 19.03 23.3002 17.86C22.7502 17.44 22.0802 17.16 21.3702 17M12.1602 13.87C12.0602 13.86 11.9402 13.86 11.8302 13.87C10.6824 13.831 9.59486 13.3468 8.79784 12.52C8.00083 11.6931 7.55696 10.5885 7.56021 9.44001C7.56021 6.99001 9.54022 5.00001 12.0002 5.00001C13.1765 4.97879 14.3129 5.4257 15.1597 6.24242C16.0064 7.05914 16.494 8.17877 16.5152 9.35501C16.5364 10.5312 16.0895 11.6677 15.2728 12.5145C14.4561 13.3612 13.3364 13.8488 12.1602 13.87ZM7.16021 17.56C4.74021 19.18 4.74021 21.82 7.16021 23.43C9.91021 25.27 14.4202 25.27 17.1702 23.43C19.5902 21.81 19.5902 19.17 17.1702 17.56C14.4302 15.73 9.92021 15.73 7.16021 17.56Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default PeopleIcon
