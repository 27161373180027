/** @jsxImportSource @emotion/react */
import { useTheme } from '@mui/material/styles'
import { sideDrawerFooter } from './SideDrawerFooter.styles'
import { Box } from '@mui/material'

export const SideDrawerFooter = () => {
  const theme = useTheme()

  return <Box css={sideDrawerFooter({ theme })}></Box>
}
