export const WindIcon = ({ ...rest }) => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M5 18H21.5C23.43 18 25 19.57 25 21.5C25 23.43 23.43 25 21.5 25C19.57 25 18 23.43 18 21.5V21M5 15H21.5C23.42 15 25 13.43 25 11.5C25 9.58 23.42 8 21.5 8C20.5726 8.00264 19.6838 8.37223 19.028 9.02804C18.3722 9.68385 18.0026 10.5726 18 11.5V12"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5 12H12.31C12.842 12 13.3621 11.8422 13.8045 11.5466C14.2469 11.2511 14.5916 10.8309 14.7952 10.3394C14.9988 9.84788 15.0521 9.30701 14.9483 8.7852C14.8445 8.26339 14.5883 7.78408 14.2121 7.40788C13.8359 7.03168 13.3566 6.77548 12.8348 6.67168C12.313 6.56789 11.7721 6.62116 11.2806 6.82476C10.789 7.02836 10.3689 7.37314 10.0733 7.81551C9.77777 8.25788 9.62 8.77796 9.62 9.31V9.69"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default WindIcon
