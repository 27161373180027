import { Map } from 'mapbox-gl'

export function createHiddenDivAndAddToPage(mapWidth: number, mapHeight: number) {
  const hidden = document.createElement('div')
  hidden.style.overflow = 'hidden'
  hidden.style.position = 'fixed'
  hidden.style.width = `${mapWidth}px`
  hidden.style.height = `${mapHeight}px`
  document.body.appendChild(hidden)

  return hidden
}

export function deleteDiv(div: HTMLDivElement) {
  div.parentNode?.removeChild(div)
}

export const getMapImageAsBlob = async (map: Map): Promise<Blob> =>
  new Promise((resolve, reject) => {
    map.getCanvas().toBlob(async (imageBlob) => {
      if (imageBlob) resolve(imageBlob)
      else reject()
    })
  })
