/** @jsxImportSource @emotion/react */
import { useTheme } from '@mui/material/styles'
import { contentBlock } from './ContentBlock.styles'
import { ButtonInBlockProps } from '../DataDrivenBlocks'
import { Box } from '@mui/material'
import UiMarkdown from '@src/components/Atoms/UiMarkdown/UiMarkdown'

export type ContentBlockProps = {
  type: 'content' | 'content-filled'
  header?: string
  content?: string // warning
  buttons?: ButtonInBlockProps[]
}

export const ContentBlock = ({ type, header, content, buttons }: ContentBlockProps) => {
  const theme = useTheme()

  return (
    <Box
      css={contentBlock({
        theme,
        filled: type == 'content-filled',
        onlyHeader: !!header && !content && !buttons,
      })}
    >
      <Box>
        <h1>{header}</h1>
        {content && <UiMarkdown>{content}</UiMarkdown>}
        {buttons && (
          <Box sx={{ display: 'flex', flexDirection: 'row', gap: '8px' }}>
            {buttons.map((button, index) => (
              <button key={index} onClick={() => window.open(button.url)}>
                {button.text}
              </button>
            ))}
          </Box>
        )}
      </Box>
    </Box>
  )
}
