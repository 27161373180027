/** @jsxImportSource @emotion/react */

import { Typography } from '@mui/material'

export type LegendKeyValues = {
  [key: string]: {
    color: string
    label?: string
  }
}

export type LegendKeyValueProps = {
  keys: string[]
  keyValues: LegendKeyValues
}

export const LegendKeyValue = ({ keyValues, keys }: LegendKeyValueProps) => {
  return (
    <Typography>
      A kevValue legend where the color of {keys[0]} = {keyValues[keys[0]].color}
    </Typography>
  )
}
