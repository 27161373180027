/** @jsxImportSource @emotion/react */
import AccordionArrowDownIcon from '@assets/icons/AccordionArrowDownIcon'
import AccordionArrowUpIcon from '@assets/icons/AccordionArrowUpIcon'
import ArrowLeftRightIcon from '@assets/icons/ArrowLeftRightIcon'
import AssetAreaIcon from '@assets/icons/AssetAreaIcon'
import BuildingIcon from '@assets/icons/BuildingIcon'
import BulbIcon from '@assets/icons/BulbIcon'
import ChartLineUpIcon from '@assets/icons/ChartLineUpIcon'
import CloudWithLightningBoltIcon from '@assets/icons/CloudWithLightningBoltIcon'
import CO2CloudIcon from '@assets/icons/CO2CloudIcon'
import CopyIcon from '@assets/icons/CopyIcon'
import CorporationIcon from '@assets/icons/Corporation'
import DragIcon from '@assets/icons/DragIcon'
import DrawCancelIcon from '@assets/icons/DrawCancelIcon'
import DrawPolyIcon from '@assets/icons/DrawPolyIcon'
import DrawRectangleIcon from '@assets/icons/DrawRectangleIcon'
import DrawRulerIcon from '@assets/icons/DrawRulerIcon'
import EarthOceaniaIcon from '@assets/icons/EarthOceaniaIcon'
import HomeIcon from '@assets/icons/HomeIcon'
import HorizonScanIcon from '@assets/icons/HorizonScanIcon'
import HorticultureIcon from '@assets/icons/Horticulture'
import InfoIcon from '@assets/icons/InfoIcon'
import LandIcon from '@assets/icons/LandIcon'
import LayersIcon from '@assets/icons/LayersIcon'
import LeafIcon from '@assets/icons/LeafIcon'
import LifeLineIcon from '@assets/icons/LifeLineIcon'
import LineIcon from '@assets/icons/LineIcon'
import LogisticsIcon from '@assets/icons/Logistics'
import MaraeIcon from '@assets/icons/MaraeIcon'
import MonitorIcon from '@assets/icons/MonitorIcon'
import NewZealandIcon from '@assets/icons/NewZealandIcon'
import PackhouseIcon from '@assets/icons/PackhouseIcon'
import PaperPageIcon from '@assets/icons/PaperPageIcon'
import PeopleIcon from '@assets/icons/PeopleIcon'
import PetrolIcon from '@assets/icons/PetrolIcon'
import PillIcon from '@assets/icons/PillIcon'
import PinIcon from '@assets/icons/PinIcon'
import ProteinsIcon from '@assets/icons/Proteins'
import RoadIcon from '@assets/icons/RoadIcon'
import SchoolIcon from '@assets/icons/SchoolIcon'
import SettingsIcon from '@assets/icons/SettingsIcon'
import SnowFlakeIcon from '@assets/icons/SnowFlakeIcon'
import TooltipIcon from '@assets/icons/TooltipIcon'
import TreeIcon from '@assets/icons/TreeIcon'
import WarningIcon from '@assets/icons/WarningIcon'
import WindIcon from '@assets/icons/WindIcon'
import BuildOutlinedIcon from '@mui/icons-material/BuildOutlined'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import CloseIcon from '@mui/icons-material/Close'
import DeleteIcon from '@mui/icons-material/Delete'
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk'
import EmojiTransportationIcon from '@mui/icons-material/EmojiTransportation'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import KeyIcon from '@mui/icons-material/Key'
import LiveHelpIcon from '@mui/icons-material/LiveHelp'
import LoginIcon from '@mui/icons-material/Login'
import LogoutIcon from '@mui/icons-material/Logout'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import NotificationsIcon from '@mui/icons-material/Notifications'
import NotInterestedIcon from '@mui/icons-material/NotInterested'
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera'
import PodcastsIcon from '@mui/icons-material/Podcasts'
import RefreshIcon from '@mui/icons-material/Refresh'
import ReportIcon from '@mui/icons-material/Report'
import ReportOutlinedIcon from '@mui/icons-material/ReportOutlined'
import SearchIcon from '@mui/icons-material/Search'
import SpeakerNotesIcon from '@mui/icons-material/SpeakerNotes'
import SpeakerNotesOffIcon from '@mui/icons-material/SpeakerNotesOff'
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import { iconContainer } from './Icon.styles'
import { IconEmbedded } from '@uintel/ui-component-library'
import { memo } from 'react'

const PUBLIC_ASSETS_BASE_URL = import.meta.env.VITE_PUBLIC_ASSETS_BASE_URL
  ? import.meta.env.VITE_PUBLIC_ASSETS_BASE_URL
  : 'https://d2ufl17mx4sema.cloudfront.net/public'

export const iconGlyphs = [
  'AccordionArrowDown',
  'AccordionArrowUp',
  'ArrowLeftRight',
  'AssetArea',
  'Building',
  'Bulb',
  'Camera',
  'ChartLineUp',
  'ChevronDown',
  'ChevronLeft',
  'ChevronRight',
  'ChevronUp',
  'Close',
  'CloudWithLightningBolt',
  'CO2Cloud',
  'Copy',
  'Corporation',
  'Delete',
  'DirectionsWalk',
  'Drag',
  'DrawCancel',
  'DrawPoly',
  'DrawRectangle',
  'DrawRuler',
  'EarthOceania',
  'EyeHidden',
  'EyeVisible',
  'EmojiTransportation',
  'Help',
  'Home',
  'HorizonScan',
  'Horticulture',
  'Info',
  'Key',
  'Land',
  'Layers',
  'Leaf',
  'LifeLine',
  'Line',
  'Logistics',
  'Marae',
  'Monitor',
  'More',
  'NotificationsIcon',
  'NotInterested',
  'NewZealand',
  'Packhouse',
  'PaperPage',
  'People',
  'Petrol',
  'Pill',
  'Pin',
  'Proteins',
  'Podcasts',
  'Refresh',
  'ReportIcon',
  'ReportOutlinedIcon',
  'Road',
  'School',
  'Search',
  'Settings',
  'SignIn',
  'SignOut',
  'SnowFlake',
  'Spanner',
  'SpeakerNotes',
  'SpeakerNotesOff',
  'Tooltip',
  'Tree',
  'VerifiedUserIcon',
  'Warning',
  'Wind',
] as const
export type Icon = (typeof iconGlyphs)[number] | string

export type IconSize = 'small' | 'medium' | 'large' | 'fit' /* 16 | 20 | 24 | 100% */

export interface IconProps {
  iconName?: Icon
  colour?: string
  fillColour?: string
  size?: IconSize
  onClick?: () => void
  src?: string
  alt?: string
  className?: string
}

export const IconComp = ({
  iconName,
  size = 'medium',
  colour = '#5C5C5C',
  fillColour = undefined,
  src,
  alt = 'Icon',
  onClick,
  className,
}: IconProps) => {
  const iconProps = {
    css: iconContainer({ colour, size, fillColour }),
    onClick,
    className,
  }

  if (iconName && looksLikeUrl(iconName)) {
    return <IconEmbedded colour={colour} src={generateS3Url(iconName)} alt={alt} {...iconProps} />
  }

  if (src) {
    return <IconEmbedded colour={colour} src={src} alt={alt} {...iconProps} />
  }

  switch (iconName) {
    case 'AccordionArrowDown':
      return <AccordionArrowDownIcon {...iconProps} />
    case 'AccordionArrowUp':
      return <AccordionArrowUpIcon {...iconProps} />
    case 'ArrowLeftRight':
      return <ArrowLeftRightIcon {...iconProps} />
    case 'AssetArea':
      return <AssetAreaIcon {...iconProps} />
    case 'Building':
      return <BuildingIcon {...iconProps} />
    case 'Bulb':
      return <BulbIcon {...iconProps} />
    case 'Camera':
      return <PhotoCameraIcon {...iconProps} />
    case 'ChartLineUp':
      return <ChartLineUpIcon {...iconProps} />
    case 'Close':
      return <CloseIcon {...iconProps} />
    case 'CloudWithLightningBolt':
      return <CloudWithLightningBoltIcon {...iconProps} />
    case 'ChevronDown':
      return <ExpandMoreIcon {...iconProps} />
    case 'ChevronUp':
      return <ExpandLessIcon {...iconProps} />
    case 'ChevronLeft':
      return <ChevronLeftIcon {...iconProps} />
    case 'ChevronRight':
      return <ChevronRightIcon {...iconProps} />
    case 'CO2Cloud':
      return <CO2CloudIcon {...iconProps} />
    case 'Copy':
      return <CopyIcon {...iconProps} />
    case 'Corporation':
      return <CorporationIcon {...iconProps} />
    case 'Delete':
      return <DeleteIcon {...iconProps} />
    case 'DirectionsWalk':
      return <DirectionsWalkIcon {...iconProps} />
    case 'Drag':
      return <DragIcon {...iconProps} />
    case 'DrawCancel':
      return <DrawCancelIcon {...iconProps} />
    case 'DrawPoly':
      return <DrawPolyIcon {...iconProps} />
    case 'DrawRectangle':
      return <DrawRectangleIcon {...iconProps} />
    case 'DrawRuler':
      return <DrawRulerIcon {...iconProps} />
    case 'EarthOceania':
      return <EarthOceaniaIcon {...iconProps} />
    case 'EyeHidden':
      return <VisibilityOffIcon {...iconProps} />
    case 'EyeVisible':
      return <VisibilityIcon {...iconProps} />
    case 'EmojiTransportation':
      return <EmojiTransportationIcon {...iconProps} />
    case 'Help':
      return <LiveHelpIcon {...iconProps} />
    case 'Home':
      return <HomeIcon {...iconProps} />
    case 'HorizonScan':
      return <HorizonScanIcon {...iconProps} />
    case 'Horticulture':
      return <HorticultureIcon {...iconProps} />
    case 'Info':
      return <InfoIcon {...iconProps} />
    case 'Key':
      return <KeyIcon {...iconProps} />
    case 'Land':
      return <LandIcon {...iconProps} />
    case 'Layers':
      return <LayersIcon {...iconProps} />
    case 'Leaf':
      return <LeafIcon {...iconProps} />
    case 'LifeLine':
      return <LifeLineIcon {...iconProps} />
    case 'Line':
      return <LineIcon {...iconProps} />
    case 'Logistics':
      return <LogisticsIcon {...iconProps} />
    case 'Marae':
      return <MaraeIcon {...iconProps} />
    case 'Monitor':
      return <MonitorIcon {...iconProps} />
    case 'More':
      return <MoreHorizIcon {...iconProps} />
    case 'NotificationsIcon':
      return <NotificationsIcon {...iconProps} />
    case 'NotInterested':
      return <NotInterestedIcon {...iconProps} />
    case 'NewZealand':
      return <NewZealandIcon {...iconProps} />
    case 'Packhouse':
      return <PackhouseIcon {...iconProps} />
    case 'PaperPage':
      return <PaperPageIcon {...iconProps} />
    case 'People':
      return <PeopleIcon {...iconProps} />
    case 'Petrol':
      return <PetrolIcon {...iconProps} />
    case 'Pill':
      return <PillIcon {...iconProps} />
    case 'Pin':
      return <PinIcon {...iconProps} />
    case 'Proteins':
      return <ProteinsIcon {...iconProps} />
    case 'Podcasts':
      return <PodcastsIcon {...iconProps} />
    case 'Refresh':
      return <RefreshIcon {...iconProps} />
    case 'Report':
      return <ReportIcon {...iconProps} />
    case 'Report-Outlined':
      return <ReportOutlinedIcon {...iconProps} />
    case 'Road':
      return <RoadIcon {...iconProps} />
    case 'School':
      return <SchoolIcon {...iconProps} />
    case 'Search':
      return <SearchIcon {...iconProps} />
    case 'Settings':
      return <SettingsIcon {...iconProps} />
    case 'SignIn':
      return <LoginIcon {...iconProps} />
    case 'SignOut':
      return <LogoutIcon {...iconProps} />
    case 'SnowFlake':
      return <SnowFlakeIcon {...iconProps} />
    case 'Spanner':
      return <BuildOutlinedIcon {...iconProps} />
    case 'SpeakerNotes':
      return <SpeakerNotesIcon {...iconProps} />
    case 'SpeakerNotesOff':
      return <SpeakerNotesOffIcon {...iconProps} />
    case 'Tooltip':
      return <TooltipIcon {...iconProps} />
    case 'Tree':
      return <TreeIcon {...iconProps} />
    case 'VerifiedUserIcon':
      return <VerifiedUserIcon {...iconProps} />
    case 'Warning':
      return <WarningIcon {...iconProps} />
    case 'Wind':
      return <WindIcon {...iconProps} />

    default:
      return <HomeIcon {...iconProps} />
  }
}

export const Icon = memo(IconComp, (prevProps, nextProps) => {
  return (
    prevProps.iconName === nextProps.iconName &&
    prevProps.size === nextProps.size &&
    prevProps.colour === nextProps.colour &&
    prevProps.fillColour === nextProps.fillColour &&
    prevProps.src === nextProps.src &&
    prevProps.alt === nextProps.alt &&
    prevProps.className === nextProps.className &&
    prevProps.onClick === nextProps.onClick
  )
})

function generateS3Url(src: string) {
  const lowerCaseSrc = src.toLocaleLowerCase()

  if (lowerCaseSrc.startsWith('s3://')) {
    const iconIdS3 = lowerCaseSrc.replace('s3://', '')
    // if icon doesn't end with a dot and an extension, add .svg
    if (!iconIdS3.includes('.')) {
      return `${PUBLIC_ASSETS_BASE_URL}/icons/${iconIdS3}.svg`
    }
    return `${PUBLIC_ASSETS_BASE_URL}/icons/${iconIdS3}`
  }

  return src
}

function looksLikeUrl(iconId: string) {
  // Could start with 'http://' or 's3://'
  return iconId.indexOf('://') >= 0
}
