/** @jsxImportSource @emotion/react */

import LanguageIcon from '@mui/icons-material/Language'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import { Box, Card, CardContent, CardMedia, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { Button } from '@src/components/Atoms/Button'

import {
  content,
  footer,
  footerText,
  ShowOnMobile,
  MobileBodyText,
  MobileHeaderText,
  AdaptationPageContainer,
  AdaptationPageLeft,
  AdaptationPageLeftInnerContainer,
  AdaptationPageRight,
  unorderedListBox,
  logo,
  mainText,
  marketingButton,
  secondaryTextText,
  secondaryTextPrimary,
  socialLinks,
} from './AdaptationPage.styles'
import { Tooltip } from '@src/components/Atoms/Tooltip/Tooltip'

export const AdaptationPage = () => {
  const theme = useTheme()

  return (
    <Box css={AdaptationPageContainer({ theme })}>
      <Box css={AdaptationPageRight({ theme })}>
        <Card sx={{ width: '40vw' }}>
          <CardMedia
            sx={{ height: '35vh' }}
            image="./images/pictures/adaptation-marketing.png"
            title="Example of adaptation functionality"
          />
          <CardContent>
            <Typography variant="h2" component="div" css={secondaryTextPrimary({ theme })}>
              Adaptation
            </Typography>
            <Typography variant="body1">
              Develop adaptive plans and test how interventions change direct and indirect risks
              with our adaptation module.
              <br />
              <br />
              In real time:
            </Typography>
            <Box css={unorderedListBox({ theme })}>
              <ul>
                <li>
                  Evaluate how an action would change direct and indirect risk from multiple hazards
                </li>
                <li>Compare interventions against multi-criteria</li>
                <li>Generate Dynamic Adaptive Policy Pathways (DAPP) maps</li>
                <li>Engage with communities on possible actions</li>
              </ul>
            </Box>
          </CardContent>
        </Card>
      </Box>
      <Box css={AdaptationPageLeft({ theme })}>
        <Box component="header">
          <a href="https://resilience-explorer.org/" target="_blank" rel="noreferrer">
            <Box component="img" src="logos/RexLogoFullWhite.svg" css={logo} />
          </a>
        </Box>
        <Box component="main" css={content}>
          <Box css={ShowOnMobile}>
            <Box component="main" css={AdaptationPageLeftInnerContainer}>
              <Typography variant="h2" css={MobileHeaderText({ theme })}>
                Adaptation
              </Typography>
              <Typography variant="body1" css={MobileBodyText({ theme })}>
                Coming Soon. We’re integrating our national accessibility map and providing more
              </Typography>
            </Box>
          </Box>
          <Typography variant="h2" css={mainText({ theme })}>
            Contact us to activate
          </Typography>
          <a
            href="https://resilience-explorer.org/contact-resilience-explorer/"
            target="_blank"
            rel="noreferrer"
          >
            <Button variant="outlined" color="secondary" css={marketingButton({ theme })}>
              Get in touch
            </Button>
          </a>
          <Typography variant="h6" css={secondaryTextText({ theme })}>
            Powered by
            <a href="https://urbanintelligence.co.nz" target="_blank" rel="noreferrer">
              Urban Intelligence
            </a>
          </Typography>
        </Box>

        <Box component="footer" css={footer({ theme })}>
          <Typography variant="h6" css={footerText({ theme })}>
            Find us at
          </Typography>
          <Box css={socialLinks({ theme })}>
            <Tooltip title="LinkedIn">
              <a
                href="https://www.linkedin.com/company/urban-intelligence-nz/"
                target="_blank"
                rel="noreferrer"
              >
                <LinkedInIcon />
              </a>
            </Tooltip>
            <Tooltip title="Website">
              <a href="https://resilience-explorer.org/" target="_blank" rel="noreferrer">
                <LanguageIcon />
              </a>
            </Tooltip>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
