/** @jsxImportSource @emotion/react */
import { useTheme } from '@mui/material/styles'
import { layerDetailsHeader, layerInfo } from './LayerInfo.styles'
import { LayerDetailsReport } from '@src/components/Molecules/SideDrawerContent/data_fetchers/layerDetailsFetcher'
import { Box } from '@mui/material'
import { Icon } from '@src/components/Atoms/Icon'
import { LayerInfoContent } from '../LayerInfoContent'

export interface LayerInfoProps {
  layerDetails: LayerDetailsReport[] | null
}

export const LayerInfo = ({ layerDetails }: LayerInfoProps) => {
  const theme = useTheme()

  return (
    <Box css={layerInfo({ theme })}>
      {layerDetails?.map((layerDetail, index) => (
        <Box key={index}>
          <Box css={layerDetailsHeader({ theme })}>
            <Icon iconName={layerDetail.icon} size="medium" colour="primary" />
            <h4>{layerDetail.title}</h4>
          </Box>
          <LayerInfoContent layerDetail={layerDetail} />
        </Box>
      ))}
    </Box>
  )
}
