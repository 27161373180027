import { css } from '@emotion/react'
import { Theme } from '@mui/material/styles'

export const groupTableDetailRowContainer = ({ theme }: { theme: Theme }) => css`
  display: flex;
  align-items: center;
  justify-content: space-between;

  gap: ${theme.spacing(6)};

  width: 100%;

  min-width: fit-content;

  padding: ${theme.spacing(3)} ${theme.spacing(5)};

  border: 1px solid ${theme.palette.grey[100]};
  border-radius: ${theme.shape.borderRadius}px;

  background-color: ${theme.palette.white.main};

  transition: background-color 0.2s;

  &:hover {
    cursor: pointer;
    background-color: ${theme.palette.white.light};
  }
`

export const extendRow = css`
  flex: 1 1 auto;
`

export const detailRowText = ({ width }: { width: number }) => css`
  width: ${width}px;
  flex-shrink: 0;
`

export const nonWrapText = css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
