/** @jsxImportSource @emotion/react */
import { useTheme } from '@mui/material'
import {
  placeholder,
  textPlaceholderContainer,
  placeholderWave,
  barChartPlaceholderContainer,
  horizontalBarChartPlaceholderContainer,
  guagePlaceholder,
  guagePlaceholderContainer,
  chartsPlaceholderContainer,
  placeholderAxii,
} from './FancySuspense.styles'
import { Suspense } from 'react'

export interface FancySuspenseProps {
  variant?: 'box' | 'text' | 'barChart' | 'horizontalBarChart' | 'guage' | 'charts'
  isLoading?: boolean
  children: React.ReactNode
}

export const FancySuspense = ({ variant, isLoading = false, children }: FancySuspenseProps) => {
  const theme = useTheme()
  let fallback = null
  switch (variant) {
    case 'box':
      fallback = <div css={placeholder({ width: '100%', theme })} />
      break
    case 'text':
      fallback = (
        <div css={textPlaceholderContainer({ theme })}>
          <div css={placeholder({ width: '100%', theme })}>
            <div css={placeholderWave()} />
          </div>
          <div css={placeholder({ width: '100%', theme })}>
            <div css={placeholderWave()} />
          </div>
          <div css={placeholder({ width: '100%', theme })}>
            <div css={placeholderWave()} />
          </div>
          <div css={placeholder({ width: '100%', theme })}>
            <div css={placeholderWave()} />
          </div>
          <div css={placeholder({ width: '60%', theme })}>
            <div css={placeholderWave()} />
          </div>
          <div css={placeholder({ width: '60%', theme })}>
            <div css={placeholderWave()} />
          </div>
        </div>
      )
      break
    case 'horizontalBarChart':
      fallback = (
        <div css={horizontalBarChartPlaceholderContainer({ theme })}>
          <div css={placeholder({ width: '33%', height: '30%', theme })}>
            <div css={placeholderWave()} />
          </div>
          <div css={placeholder({ width: '66%', height: '30%', theme })}>
            <div css={placeholderWave()} />
          </div>
          <div css={placeholder({ width: '100%', height: '30%', theme })}>
            <div css={placeholderWave()} />
          </div>
        </div>
      )
      break
    case 'barChart':
      fallback = (
        <div css={barChartPlaceholderContainer({ theme })}>
          <div css={placeholder({ height: '5%', width: '15%', theme })}>
            <div css={placeholderWave()} />
          </div>
          <div css={placeholder({ height: '10%', width: '15%', theme })}>
            <div css={placeholderWave()} />
          </div>
          <div css={placeholder({ height: '20%', width: '15%', theme })}>
            <div css={placeholderWave()} />
          </div>
          <div css={placeholder({ height: '40%', width: '15%', theme })}>
            <div css={placeholderWave()} />
          </div>
          <div css={placeholder({ height: '66%', width: '15%', theme })}>
            <div css={placeholderWave()} />
          </div>
          <div css={placeholder({ height: '100%', width: '15%', theme })}>
            <div css={placeholderWave()} />
          </div>
        </div>
      )
      break
    case 'guage':
      fallback = (
        <div css={guagePlaceholderContainer()}>
          <div css={guagePlaceholder({ theme })}>
            <div css={placeholderWave()} />
          </div>
        </div>
      )
      break
    case 'charts':
      fallback = (
        <div css={chartsPlaceholderContainer({ theme })}>
          <div css={placeholder({ height: '100%', width: '100%', theme })}>
            <div css={placeholderWave()} />
            <div css={placeholderAxii({ theme })} />
          </div>
          <div css={placeholder({ height: '100%', width: '100%', theme })}>
            <div css={placeholderWave()} />
            <div css={placeholderAxii({ theme })} />
          </div>
          <div css={placeholder({ height: '100%', width: '100%', gridColumn: '2', theme })}>
            <div css={placeholderWave()} />
            <div css={placeholderAxii({ theme })} />
          </div>
        </div>
      )
      break
  }

  if (isLoading) return fallback

  return <Suspense fallback={fallback}>{children}</Suspense>
}
