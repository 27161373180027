/** @jsxImportSource @emotion/react */
import {
  Autocomplete,
  Box,
  CircularProgress,
  FormControlLabel,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography,
} from '@mui/material'
import { useTheme } from '@mui/material/styles'

import { PasswordInput } from '@src/components/Molecules/PasswordInput'
import { Checkbox } from '@uintel/ui-component-library'
import { Button } from '@src/components/Atoms/Button'
import { LoadingButton, PasswordProps, ValidationInputProps } from '../SignInModal'
import {
  emptyBox,
  finalStep,
  forgotPasswordContainer,
  formWrapper,
  loginContainer,
  organizationContainer,
  passwordConfirmationContainer,
  signInBodyContent,
  signInButton,
  signInFooter,
  signInSpinner,
  stepTitle,
  stepper,
} from '../SignInModal.styles'
import { Client } from '@redux/user/userSlice'

export interface InputWithValidation {
  value: string
  error: boolean
  helperText: string
}

export interface LoginTabProps {
  email: ValidationInputProps
  password: PasswordProps
  activeStep: number
  isLoading: boolean
  organization: Client | null
  userPools: Client[]
  forgotPassword: boolean
  handleChangeStep: (step: number) => void
  applyClient: (client: Client | null) => void
  handleChangeActiveStep: (step: number) => void
  handleLoginSubmit: () => void
  handleLoginWithClientConfig: () => void
  handleChangeEmail: (email: ValidationInputProps) => void
  handleChangePassword: (password: PasswordProps) => void
  handleChangeForgotPassword: (forgotPassword: boolean) => void
  handleLogout: () => void
}

export const LoginTab = ({
  email,
  password,
  handleChangeEmail,
  handleChangePassword,
  activeStep,
  organization,
  userPools,
  isLoading,
  handleChangeStep,
  applyClient,
  handleChangeActiveStep,
  handleLoginSubmit,
  handleLoginWithClientConfig,
  forgotPassword,
  handleChangeForgotPassword,
  handleLogout,
}: LoginTabProps) => {
  const theme = useTheme()

  const handleReset = () => {
    handleChangeStep(0)
  }

  const handleBack = () => {
    handleLogout()
    handleChangeActiveStep(activeStep - 1)
  }

  const steps = [
    {
      title: 'Email and Password',
      content: (
        <form autoComplete="on" css={formWrapper}>
          <Box css={loginContainer({ theme })}>
            <TextField
              autoFocus
              id="login-email"
              name="email"
              variant="outlined"
              autoComplete="on"
              type="email"
              label="E-mail"
              value={email.value}
              error={email.error}
              helperText={email.helperText}
              onChange={(e) =>
                handleChangeEmail({ error: false, helperText: '', value: e.target.value })
              }
              fullWidth
            />
            <Box css={passwordConfirmationContainer}>
              <PasswordInput
                id="login-password"
                error={password.error}
                helperTexts={password.helperTexts}
                value={password.value}
                handleChangePassword={handleChangePassword}
              />

              <FormControlLabel
                id="forgotPassword"
                name="forgotPassword"
                css={forgotPasswordContainer({ theme })}
                control={
                  <Checkbox
                    size="small"
                    checked={forgotPassword}
                    onChange={() => handleChangeForgotPassword(!forgotPassword)}
                  />
                }
                label="Forgot password"
              />
            </Box>
          </Box>
        </form>
      ),
    },
    {
      title: 'Organization',
      content: (
        <Box css={organizationContainer({ theme })}>
          <Autocomplete
            id="organization"
            fullWidth
            value={organization ?? userPools[0]}
            onChange={(_event, newValue) => applyClient(newValue)}
            options={userPools}
            disabled={userPools.length === 0}
            getOptionLabel={(option) => option.display_name}
            renderInput={(params) => <TextField {...params} label="Organization" />}
          />
        </Box>
      ),
    },
  ]

  return (
    <>
      <Stepper activeStep={activeStep} css={stepper({ theme })}>
        {steps.map((step) => {
          const stepProps: { completed?: boolean } = {}

          return (
            <Step key={step.title} {...stepProps}>
              <StepLabel>{step.title}</StepLabel>
            </Step>
          )
        })}
      </Stepper>

      {activeStep === steps.length ? (
        <>
          <Typography css={stepTitle({ theme })}>Remove this step and go back to map</Typography>
          <Box css={finalStep({ theme })}>
            <Box css={emptyBox} />

            <Button onClick={handleReset}>
              {isLoading ? (
                <CircularProgress size={28} css={signInSpinner({ theme })} />
              ) : (
                <Typography variant="button">Reset</Typography>
              )}
            </Button>
          </Box>
        </>
      ) : (
        <>
          <Box css={signInBodyContent({ theme })}>{steps[activeStep].content}</Box>
          <Box css={signInFooter}>
            {activeStep !== 0 && (
              <Button variant="outlined" onClick={handleBack}>
                Return
              </Button>
            )}
            <Box css={emptyBox} />

            {activeStep === steps.length - 1 ? (
              <Button
                onClick={handleLoginWithClientConfig}
                css={signInButton}
                data-testid="login-submit"
              >
                {isLoading ? <LoadingButton /> : <Typography variant="button">Done</Typography>}
              </Button>
            ) : (
              <Button onClick={handleLoginSubmit} fullWidth data-testid="login-submit">
                {isLoading ? <LoadingButton /> : <Typography variant="button">Login</Typography>}
              </Button>
            )}
          </Box>
        </>
      )}
    </>
  )
}
