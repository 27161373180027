import { Content } from 'pdfmake/interfaces'
import axios from '@src/utils/customAxios'
import { BASE_API_URL } from '@src/app-constants'
import { ScenariosByAsset } from '@src/components/Molecules/SideDrawerContent/data_fetchers/scenariosFetcher'
import { englishList } from '@src/utils/strings.utils'
import { DialogData } from '@src/components/Molecules/MapView/AddLayersDialog'
import { AssetDetails } from '../../Report.utils'

export const GenerateKnownRisksToSelectedElement = async (
  assetDetails: AssetDetails | null | undefined,
): Promise<Content> => {
  if (!assetDetails || !assetDetails.asset) return { stack: [] }

  const layerDialogData: DialogData = (await axios.get(`${BASE_API_URL}/api/layer`)).data
  const hazardDetails = layerDialogData.hazardDialogData.hazardDetails

  const scenarios: ScenariosByAsset[] = (
    await axios.get(
      `${BASE_API_URL}/api/hazard/scenarios/${assetDetails.asset.type}/${assetDetails.asset.id}`,
    )
  ).data

  const riskTypeByScenarioId: { [scenarioId: string]: string } = {}
  const riskCounts: { [riskId: string]: { title: string; count: number } } = {}

  hazardDetails.forEach((hazardDetail) =>
    hazardDetail.scenarios.forEach((scenario) => {
      riskTypeByScenarioId[scenario.assetTag] = hazardDetail.type
      if (!Object.hasOwn(riskCounts, hazardDetail.type))
        riskCounts[hazardDetail.type] = { title: hazardDetail.title, count: 0 }
    }),
  )

  scenarios.forEach((scenario) => {
    if (riskTypeByScenarioId[scenario.hazard_scenario])
      riskCounts[riskTypeByScenarioId[scenario.hazard_scenario]].count++
  })

  const exposedRisks = Object.values(riskCounts).filter((riskCount) => riskCount.count > 0)

  const knownScenarioDescriptions: string[] = exposedRisks.map(
    (exposedRisk) =>
      `${exposedRisk.count} ${exposedRisk.title} scenario${exposedRisk.count > 1 ? 's' : ''}`,
  )

  return {
    text:
      knownScenarioDescriptions.length > 0
        ? `This element is exposed to the following number of modelled events: ${englishList(
            knownScenarioDescriptions,
          )}.`
        : `This element is not exposed to any modelled events.`,
    marginTop: 10,
    unbreakable: true,
  }
}
