/** @jsxImportSource @emotion/react */
import { MapLayer } from '@redux/map/mapSlice'
import { Box } from '@mui/material'
import { layerDetailsContainer } from './LayerInfoAccordions.styles'
import { LayerDetailsReport } from '../../../../data_fetchers/layerDetailsFetcher'
import { EmptySection } from '../../../../components/EmptySection'
import { ElementFilterForm } from '@src/components/Molecules/LayersControl/ElementFilterForm/ElementFilterForm'
import { useFeatureFlags } from '@contexts/FeatureFlagsContext'
import { LayerInfo } from './LayerInfo/LayerInfo'

interface InfoAccordionsProps {
  layer: MapLayer
  layerType: string
  defaultExpanded?: boolean
  layerDetails: LayerDetailsReport[] | null
}

export const LayerInfoAccordions = ({ layer, layerDetails }: InfoAccordionsProps) => {
  const { features } = useFeatureFlags()
  const attributeFiltersFeature = features.find(
    (feature) => feature.feature == 'attributeFilters' && feature.enabled,
  )
  if (!layerDetails && !layer.filterable_information)
    return (
      <EmptySection
        title={`Currently there's no additional information for ${layer.display_name}`}
      />
    )

  return (
    <Box css={layerDetailsContainer()}>
      {attributeFiltersFeature && <ElementFilterForm layer={layer} />}
      <LayerInfo layerDetails={layerDetails} />
    </Box>
  )
}
