/** @jsxImportSource @emotion/react */

import LinkedInIcon from '@mui/icons-material/LinkedIn'
import { Box, Divider, Typography } from '@mui/material'
import { css, useTheme } from '@mui/material/styles'
import { Button } from '@src/components/Atoms/Button'

import {
  content,
  faqItemContainer,
  footer,
  footerText,
  landingPageContainer,
  landingPageHeader,
  landingPageLeft,
  landingPageLeftCenterBox,
  landingPageLeftScroll,
  landingPageRight,
  landingPageRightBottom,
  landingPageRightScroll,
  logo,
  mainText,
  marketingButton,
  mediaContainer,
  smallLogo,
  socialLinks,
  welcomeText,
} from './LandingPage.styles'
import { useSelector } from 'react-redux'
import { RootState } from '@redux/store'
import UiMarkdown from '@src/components/Atoms/UiMarkdown/UiMarkdown'
import { PUBLIC_ASSETS_BASE_URL } from '@src/app-constants'
import { Video } from '@src/components/Atoms/Video'
import { ImageWithFilters } from '@src/components/Atoms/ImageWithFilters'
import { Tooltip } from '@src/components/Atoms/Tooltip/Tooltip'

export const LandingPage = () => {
  const theme = useTheme()

  const { clientName, clientDisplayName, clientWelcomeSidebarContent } = useSelector(
    (state: RootState) => state.user,
  )

  if (clientName && Object.keys(clientWelcomeSidebarContent).length > 0) {
    return (
      <Box css={landingPageContainer({ theme })}>
        <Box css={landingPageLeftScroll({ theme })}>
          <Box css={landingPageLeft({ theme })}>
            <Box css={landingPageLeftCenterBox()}>
              <Box component="header" css={landingPageHeader()}>
                <Typography variant="h2" css={welcomeText({ theme })}>
                  Welcome to the
                </Typography>
                <Typography variant="h2" css={mainText({ theme })}>
                  {clientWelcomeSidebarContent.title?.replace('Welcome to the', '') ??
                    clientDisplayName + ' Resilience Explorer'}
                </Typography>
              </Box>
              <Divider css={{ borderColor: '#FFF1' }} />
              {clientWelcomeSidebarContent.subtitle && (
                <Box component="main" css={content}>
                  <UiMarkdown>{clientWelcomeSidebarContent.subtitle as string}</UiMarkdown>
                </Box>
              )}
            </Box>

            <Box css={mediaContainer()}>
              {clientName == 'christchurch' ? (
                <Video videoUrl={`${PUBLIC_ASSETS_BASE_URL}/videos/christchurch/CHAP45pc.mp4`} />
              ) : (
                <Box
                  component="img"
                  src={'/images/illustrations/hero.svg'}
                  alt="Woman looking for hazards"
                />
              )}
            </Box>

            <Box component="footer" css={footer({ theme })}>
              <Box css={socialLinks({ theme })}>
                <Tooltip title="LinkedIn">
                  <a
                    href="https://www.linkedin.com/company/urban-intelligence-nz/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <LinkedInIcon />
                  </a>
                </Tooltip>
              </Box>
              <a href="https://resilience-explorer.org/" target="_blank" rel="noreferrer">
                <Box component="img" src="logos/RexLogo.svg" css={smallLogo} />
              </a>
            </Box>
          </Box>
        </Box>
        <Box css={landingPageRightScroll({ theme })}>
          <Box css={landingPageRight({ theme, withFAQ: !!clientWelcomeSidebarContent.faq })}>
            {clientWelcomeSidebarContent.faq && (
              <>
                <Divider
                  css={{
                    borderColor: theme.palette.primary.main,
                    opacity: 0.2,
                    margin: `0 ${theme.spacing(4)}`,
                  }}
                />
                <Box css={landingPageRightBottom({ theme })}>
                  <Typography variant="h3">FAQ</Typography>
                  {clientWelcomeSidebarContent.faq.map((faqItem, i) => (
                    <Box key={i} data-value={i} css={faqItemContainer({ theme })}>
                      <Typography variant="h6">{faqItem.q}</Typography>
                      <Typography variant="body2">
                        <UiMarkdown>{faqItem.a}</UiMarkdown>
                      </Typography>
                    </Box>
                  ))}
                </Box>
              </>
            )}
          </Box>
        </Box>
      </Box>
    )
  } else {
    return (
      <Box css={landingPageContainer({ theme })}>
        <Box css={landingPageLeftScroll({ theme })}>
          <Box css={landingPageLeft({ theme })}>
            <Box component="header">
              <a href="https://resilience-explorer.org/" target="_blank" rel="noreferrer">
                <Box component="img" src="logos/RexLogo.svg" css={logo} />
              </a>
            </Box>
            <Box component="main" css={content}>
              {!clientName ? (
                <>
                  <Typography variant="h2" css={mainText({ theme, align: 'left' })}>
                    A local-national scale resilience planning dashboard for New Zealand
                  </Typography>
                  <a href="https://resilience-explorer.org" target="_blank" rel="noreferrer">
                    <Button variant="outlined" color="secondary" css={marketingButton({ theme })}>
                      Explore what we offer
                    </Button>
                  </a>
                </>
              ) : (
                <Box component="header">
                  <Typography variant="h2" css={welcomeText({ theme, align: 'left' })}>
                    Welcome to the
                  </Typography>
                  <Typography variant="h2" css={mainText({ theme, align: 'left' })}>
                    {clientWelcomeSidebarContent.title?.replace('Welcome to the', '') ??
                      clientDisplayName + ' Resilience Explorer'}
                  </Typography>
                  <Divider
                    css={css`
                      padding-bottom: 16px;
                      border-bottom: 1px solid #fff2;
                      margin-bottom: 40px;
                    `}
                  />
                </Box>
              )}
            </Box>

            <Box component="footer" css={footer({ theme })}>
              <Box css={socialLinks({ theme })}>
                <Typography variant="h6" css={footerText({ theme })}>
                  Find us at
                </Typography>
                <Tooltip title="LinkedIn">
                  <a
                    href="https://www.linkedin.com/company/urban-intelligence-nz/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <LinkedInIcon />
                  </a>
                </Tooltip>
              </Box>
              <Box component="header">
                <a href="https://www.urbanintelligence.co.nz/" target="_blank" rel="noreferrer">
                  <Box component="img" src="logos/UILogoFullWhite.svg" css={smallLogo} />
                </a>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box css={landingPageRightScroll({ theme })}>
          <Box css={landingPageRight({ theme })}>
            <ImageWithFilters
              src="/images/illustrations/hero.svg"
              alt="Woman looking for hazards"
              color={theme.palette.primary.main}
              hueStrength={1}
              lightenStrength={0.5}
              desaturateStrength={0.4}
            />
          </Box>
        </Box>
      </Box>
    )
  }
}
