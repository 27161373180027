export const HomeIcon = ({ ...rest }) => {
  return (
    <svg {...rest} width="30" height="30" viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M26.4233 12.1691L15.3414 2.47634C14.9811 2.16123 14.5067 1.9907 14.0173 2.00039C13.5279 2.01008 13.0613 2.19924 12.7152 2.52833L2.52305 12.2181L2 12.7153V27H11.8214V18.0871H17.1786V27H27V12.6735L26.4233 12.1691ZM14.0557 3.69772C14.0714 3.69772 14.0617 3.70095 14.055 3.70727C14.048 3.70095 14.04 3.69772 14.0557 3.69772ZM25.2143 25.3023H18.9643V18.0871C18.9643 17.6369 18.7761 17.2051 18.4413 16.8867C18.1064 16.5683 17.6522 16.3895 17.1786 16.3895H11.8214C11.3478 16.3895 10.8936 16.5683 10.5587 16.8867C10.2239 17.2051 10.0357 17.6369 10.0357 18.0871V25.3023H3.78571V13.4185L14.0557 3.72875C14.0564 3.72812 14.0565 3.72753 14.057 3.7269L25.2143 13.4185V25.3023Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default HomeIcon
