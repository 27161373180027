import { css } from '@emotion/react'

export const imageWithFilters = () => css`
  position: relative;
  display: inline-block;
  overflow: hidden;
  background-color: white;

  img {
    display: block;
    width: 100%;
    height: auto;
    position: relative;
  }
`

export const hueFilter = ({ color, hueStrength }: { color: string; hueStrength: number }) => css`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${color};
  mix-blend-mode: hue;
  opacity: ${hueStrength};
`

export const lightenFilter = ({
  color,
  lightenStrength,
}: {
  color: string
  lightenStrength: number
}) => css`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${color};
  mix-blend-mode: lighten;
  opacity: ${lightenStrength};
`

export const background = (color: string) => css`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 0;
  height: 100%;
  background-color: ${color};
`

export const desaturateFilter = ({ desaturateStrength }: { desaturateStrength: number }) => css`
  filter: grayscale(${desaturateStrength * 100}%);
`
