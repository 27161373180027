import { SnackbarProvider } from '@uintel/ui-component-library'
import React from 'react'
import { CookiesProvider } from 'react-cookie'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { RouterProvider } from 'react-router-dom'

import { initSentry } from '@modules/Sentry'
import { FeatureFlagsProvider } from '@contexts/FeatureFlagsContext'
import { MapProvider } from '@contexts/MapContext'
import { LayerProvider } from '@contexts/LayerContext'
import store from '@redux/store'
import { router } from '@routes/router'
import { TutorialContextProvider } from '@contexts/TutorialContext'
import { AccordionStateProvider } from '@contexts/AccordionStateContext/AccordionStateContext'
import { PreferencesProvider } from '@contexts/PreferencesContext'
import { RiskFetcherProvider } from '@contexts/RiskFetcherContext'
import { LayerDetailsProvider } from '@contexts/LayerDetailsContext'

// We only want to initialize Sentry in production
if (import.meta.env.PROD) initSentry()

const AppRoot = () => {
  return (
    <React.StrictMode>
      <Provider store={store}>
        <AccordionStateProvider>
          <CookiesProvider>
            <PreferencesProvider>
              <FeatureFlagsProvider>
                <TutorialContextProvider>
                  <SnackbarProvider>
                    <MapProvider>
                      <LayerProvider>
                        <RiskFetcherProvider>
                          <LayerDetailsProvider>
                            <RouterProvider router={router} />
                          </LayerDetailsProvider>
                        </RiskFetcherProvider>
                      </LayerProvider>
                    </MapProvider>
                  </SnackbarProvider>
                </TutorialContextProvider>
              </FeatureFlagsProvider>
            </PreferencesProvider>
          </CookiesProvider>
        </AccordionStateProvider>
      </Provider>
    </React.StrictMode>
  )
}

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(<AppRoot />)
