import { css } from '@emotion/react'
import { Theme } from '@mui/material/styles'

export const passwordContainer = css`
  position: relative;
  width: 100%;
`

export const showPasswordButton = ({ theme }: { theme: Theme }) => css`
  position: absolute;
  right: ${theme.spacing(4)};
  color: ${theme.palette.grey[500]};

  &:focus-visible {
    outline: 2px solid ${theme.palette.primary.main};
  }
`
export const helperTextsContainer = ({ theme }: { theme: Theme }) => css`
  margin: ${theme.spacing(2)} 0;
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing(1)};
`

export const helperText = ({ theme }: { theme: Theme }) => css`
  display: flex;
  align-items: center;
  justify-content: start;
  gap: ${theme.spacing(1)};
`
