export const CloudWithLightningBoltIcon = ({ ...rest }) => {
  return (
    <svg
      width="30"
      height="31"
      viewBox="0 0 30 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M10.1993 18.4739C10.1993 18.4739 8.84627 18.4739 6.95153 18.4739C-0.966788 18.1053 0.691212 6.97368 8.13576 8.05281C10.7409 0.237026 19.741 0.237031 21.8726 6.63177C31.5831 7.10545 28.7409 18.4739 23.0568 18.237C22.4525 18.237 16.8271 18.237 16.8271 18.237M16.8271 18.237L19.6576 12.9277L12.3353 13L9.01852 21.5H12.3353L9.81679 30L20.5 20H16L16.8271 18.237Z"
        stroke="currentColor"
        strokeWidth="1.8"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default CloudWithLightningBoltIcon
