import { css } from '@emotion/react'
import { Theme } from '@mui/material'

export const mapOverlayContainer = () => css`
  flex: 1 1 auto;
  position: relative;

  .tutorial-map {
    position: absolute;
    inset: 8px;
    pointer-events: none;
    user-select: none;
  }
`

export const mapOverlay = css`
  position: absolute;
  z-index: 100;

  display: flex;
  gap: 12px;
`

export const mapTopLeftContainer = ({ theme }: { theme: Theme }) => css`
  ${mapOverlay}

  top: ${theme.spacing(2)};
  left: ${theme.spacing(3)};
  flex-wrap: wrap;
`

export const mapBottomRightContainer = ({ theme }: { theme: Theme }) => css`
  ${mapOverlay}
  pointer-events: none;
  flex-direction: row-reverse;
  bottom: ${theme.spacing(1)};
  right: ${theme.spacing(1)};
`

export const mapBottomLeftContainer = css`
  ${mapOverlay}

  bottom: 0;
  left: 0;
`

export const mapTopRightContainer = css`
  ${mapOverlay}

  top: 8px;
  right: 8px;
`
