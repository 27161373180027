import { css } from '@emotion/react'
import { Theme } from '@mui/material/styles'

interface LogoContainerProps {
  theme: Theme
  logoBackgroundColour: string
}
export const logoContainer = ({ logoBackgroundColour }: LogoContainerProps) => css`
  width: 100%;
  position: relative;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;

  background-color: ${logoBackgroundColour};

  &:hover {
    cursor: pointer;
  }
`

export const sidebarFullLogo = (shown: boolean, width: number) => css`
  width: ${width - 32}px;
  object-fit: contain;
  margin: 16px;
  opacity: ${shown ? 1 : '0 !important'};
  transition: opacity 0.1s, transform 0.2s;
  transform: ${shown ? 'translateX(0px)' : 'translateX(-60px)'};
  max-height: 70px;
  min-height: 24px;
`

export const sidebarSimpleLogo = (shown: boolean, width: number) => css`
  width: ${width - 24}px;
  object-fit: contain;
  margin: 12px 12px;
  opacity: ${shown ? 1 : '0 !important'};
  position: absolute;
  top: 0;
  left: 0;
  transition: opacity 0.1s, transform 0.2s;
  transform: ${shown ? 'translateX(0px)' : 'translateX(60px)'};
  max-height: 70px;
  min-height: 24px;
`
