export const DragIcon = ({ ...rest }) => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <rect x="9" y="5" width="5" height="5" rx="0.8125" fill="currentColor" />
      <rect x="9" y="12.5" width="5" height="5" rx="0.8125" fill="currentColor" />
      <rect x="9" y="20" width="5" height="5" rx="0.8125" fill="currentColor" />
      <rect x="16.5" y="5" width="5" height="5" rx="0.8125" fill="currentColor" />
      <rect x="16.5" y="12.5" width="5" height="5" rx="0.8125" fill="currentColor" />
      <rect x="16.5" y="20" width="5" height="5" rx="0.8125" fill="currentColor" />
    </svg>
  )
}

export default DragIcon
