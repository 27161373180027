/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from '@emotion/react'
import { Box, Button, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { motion } from 'framer-motion'
import { useMemo, useState } from 'react'

import {
  appVersionLabel,
  feedbackLink,
  hintPointerContainer,
  hintPointerIcon,
  hintPointerInner,
  hintPointerOuter,
  logosContainer,
  sidebarButtonContainer,
  sidebarButtonsContainer,
  sidebarContainer,
  sidebarFooter,
} from './Sidebar.styles'
import { SidebarButton } from './SidebarButton'
import { SidebarLogo } from './SidebarLogo/SidebarLogo'
import { Icon } from '@src/components/Atoms/Icon'

const sidebarVariants = {
  open: {
    width: 260,
  },
  hovered: {
    width: 80,
  },
  closed: {
    width: 68,
  },
}

export interface SideBarBrandLogo {
  title: string
  reducedLogo: string
  fullLogo: string
  logoBackgroundColour: string
  onClick: () => void
}

export interface SidebarMenuButton {
  icon: Icon
  label: string
  onClick: (() => void | null) | ((e: React.MouseEvent<HTMLButtonElement>) => void)
  displayLabel?: boolean
  id?: string
  active?: boolean
  disabled?: boolean
  hidden?: boolean
  pointerHint?: string
  customClass?: {
    iconClass?: SerializedStyles
    labelClass?: SerializedStyles
    buttonClass?: SerializedStyles
  }
  testId?: string
}

export interface SidebarProps {
  logos: SideBarBrandLogo[]
  middleMenu: SidebarMenuButton[]
  bottomMenu?: SidebarMenuButton[]
  appVersion?: string
  feedback_url?: string
  setShowFeedbackModal: (show: boolean) => void
}

export const Sidebar = ({
  middleMenu,
  bottomMenu = [],
  logos,
  appVersion = '',
  feedback_url = '',
  setShowFeedbackModal,
}: SidebarProps) => {
  const theme = useTheme()
  const [isOpen, setIsOpen] = useState(false)
  const [isHovered, setIsHovered] = useState(false)
  const sidebarState = useMemo(
    () => (isOpen ? 'open' : isHovered ? 'hovered' : 'closed'),
    [isOpen, isHovered],
  )

  return (
    <motion.div
      animate={sidebarState}
      variants={sidebarVariants}
      transition={{ duration: 0.2 }}
      css={sidebarContainer({ theme })}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => {
        setIsOpen(false)
        setIsHovered(false)
      }}
    >
      <Box
        css={logosContainer({ width: sidebarVariants[sidebarState].width })}
        onMouseEnter={() => setIsOpen(true)}
      >
        {logos.map((logo) => (
          <SidebarLogo
            key={logo.title}
            logo={logo}
            isOpen={isOpen}
            fullWidth={sidebarVariants['open'].width}
            simpleWidth={sidebarVariants['closed'].width}
          />
        ))}
      </Box>

      <Box css={sidebarButtonsContainer(theme)} onMouseEnter={() => setIsOpen(true)}>
        {middleMenu.map((button) => (
          <Box key={button.icon} css={sidebarButtonContainer}>
            <SidebarButton
              id={button.id}
              icon={button.icon}
              label={button.label}
              active={button.active}
              onClick={button.onClick}
              disabled={button.disabled}
              hidden={button.hidden}
              customClass={button.customClass}
              displayLabel={isOpen}
            />
            {button.pointerHint && (
              <Box css={hintPointerOuter}>
                <Box css={hintPointerContainer(theme)}>
                  <Icon
                    iconName="s3://playbutton.svg"
                    colour={theme.palette.primary.main}
                    size="large"
                    css={hintPointerIcon}
                  />
                  <Box css={hintPointerInner(theme)}>{button.pointerHint}</Box>
                </Box>
              </Box>
            )}
          </Box>
        ))}
      </Box>

      <Box css={sidebarButtonsContainer(theme)} onMouseEnter={() => setIsOpen(true)}>
        {bottomMenu.map((button) => (
          <SidebarButton
            id={button.id}
            key={button.icon}
            icon={button.icon}
            label={button.label}
            active={button.active}
            onClick={button.onClick}
            disabled={button.disabled}
            hidden={button.hidden}
            customClass={button.customClass}
            displayLabel={isOpen}
            testId={button.testId}
          />
        ))}
        <Box css={sidebarFooter()}>
          {feedback_url && (
            <Button
              variant="text"
              css={css`
                text-decoration: none;
              `}
              sx={{
                transition: 'all 0.2s',
                padding: '0',
                marginLeft: isOpen ? '0' : '-10px',
              }}
              onClick={() => setShowFeedbackModal(true)}
            >
              <Typography css={feedbackLink(theme)}>{isOpen ? 'Submit' : ''} Feedback</Typography>
            </Button>
          )}
          {!!appVersion.length && (isOpen || !feedback_url) && (
            <Typography css={appVersionLabel(theme)}>v {appVersion}</Typography>
          )}
        </Box>
      </Box>
    </motion.div>
  )
}
