import { css } from '@emotion/react'
import { Theme } from '@mui/material/styles'

export const dataDrivenBlocks = ({ theme }: { theme: Theme }) => css`
  display: flex;
  flex-direction: column;
  background-color: ${theme.palette.background.default};
  gap: ${theme.spacing(16)};

  button {
    border: 1px solid #0b2948;
    background-color: ${theme.palette.white.main};
    border-radius: 4px;
    color: #0b2948;
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    padding: ${theme.spacing(2)} ${theme.spacing(4)};
  }

  img {
    object-fit: contain;
  }
`

export const iconFooterBox = ({ theme }: { theme: Theme }) => css`
  display: flex;
  justify-content: center;
  flex-grow: 1;
  margin-top: -${theme.spacing(4)};
  padding-bottom: ${theme.spacing(5)};
  background: linear-gradient(180deg, #0b294800 80%, #0b294810 80%, #0b294810 90%, #0b294820 90%);

  img {
    height: 40px;
    width: 40px;
    opacity: 0.15;
  }
`
