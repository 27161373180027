import { css } from '@emotion/react'
import { Theme } from '@mui/material/styles'

export const mapLegends = ({ theme }: { theme: Theme }) => css`
  display: flex;
  margin: 0 ${theme.spacing(2)};
  padding: ${theme.spacing(1)};
  flex-direction: column-reverse;
  max-height: 80vh;
  flex-wrap: wrap-reverse;
  max-width: 80%;
  width: fit-content;
  height: fit-content;
  gap: ${theme.spacing(2)};
  pointer-events: none;
`

export const legendMenu = ({ theme }: { theme: Theme }) => css`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: ${theme.palette.primary.main};
  color: white;
  font-size: 0.7rem;
  font-weight: 500;
  padding: ${theme.spacing(0.5, 1)};
  border-radius: ${theme.spacing(1)};
  box-shadow: ${theme.shadows[1]};
  height: fit-content;
  cursor: pointer;
  user-select: none;
  flex-grow: 0;
  transition: background-color 0.3s ease-in-out;
  height: 20px;
  width: 120px;
  pointer-events: all;

  svg {
    height: ${theme.spacing(3)};
    width: ${theme.spacing(3)};
  }

  &:hover {
    background-color: ${theme.palette.primary.dark};
    box-shadow: ${theme.shadows[2]};
  }
`
