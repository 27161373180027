/** @jsxImportSource @emotion/react */
import { Box, useTheme } from '@mui/material'
import { ReactElement } from 'react'
import { StyledTooltip, chartTooltip, decoColor, tooltipContent } from './ChartTooltip.styles'

export type ChartTooltipItem = {
  text: string
  color: string
}

export interface ChartTooltipProps {
  header?: string
  items: ChartTooltipItem[]
  show?: boolean
  children: ReactElement
}

export const ChartTooltip = ({ header, items, show, children }: ChartTooltipProps) => {
  const theme = useTheme()
  return (
    <StyledTooltip
      followCursor
      title={
        <>
          {header && <Box className="header">{header}</Box>}
          {items.map(({ text, color }, i) => (
            <Box key={i} css={tooltipContent({ theme })}>
              <Box css={decoColor(color)} />
              <Box className="info">{text}</Box>
            </Box>
          ))}
        </>
      }
      open={show}
      placement="top-start"
      css={chartTooltip({ theme })}
    >
      {children}
    </StyledTooltip>
  )
}
