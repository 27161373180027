/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import UiMarkdown from '@src/components/Atoms/UiMarkdown/UiMarkdown'

export const RiskExplorerUpdate = () => {
  const staticHTML = `
  <style>
  * {
      padding: 0;
      margin: 0;
      box-sizing: border-box;
      color: #f1f1f1;
      font-family: sans-serif;
  }

  
  #body{                
      background-color: #0b2948;       
      display: flex;

      width: 100%;
      height: 100%;
  }

  header {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: .5rem;
  }

  section {                
      color: #0b2948;       
      background-color: rgb(243, 243, 243);

      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
  }

  main {       
      flex: 1;
      
      display: flex;
      justify-content: center;
      align-items: center; 
      flex-direction: column;

      gap: 2rem;

      padding: 4rem;
  }


  #main-text {
      margin: 3rem 0;        
  }

  h1 {
      font-size: 54px;
      text-align: center;
  }

  h2 {
      font-weight: 200;
      text-align: center;
  }

  p {        
      font-size: large;
      line-height: 175%;   
      letter-spacing: .07rem;
      text-align: center;
  }

  button {
      background-color: transparent;
      text-decoration: none;
      color: white;
      border-radius: 4px;
      border: 1px solid white;
      
      padding: .85rem 2rem;
      font-size: 1rem;
      font-weight: 700;
      letter-spacing: .1em;
      
      transition: all .250s ease-in-out;

      display: flex;
      justify-content: center;
      align-items: center;
      gap: .5rem;
  }

  button:hover {
      cursor: pointer;
      background-color: white;
      color: #0b2948;
  }

  button:hover > p {        
      color: #0b2948;
  }

  button > svg {                        
      transition: all .250s ease-in-out;
  }

  button:hover > svg {                        
      transform: translateX(5px);
  }

  button:hover > svg > path {        
      color: #0b2948;        
  }

  a {
      text-decoration: none;      
  }

  #hero {        
      max-width: 80%;
      width: 560px;

      max-height: 80%;
  }



  i {
      border-bottom: 1px solid #fff;        
      padding-bottom: 2px;
  }

  /* Media Query for smaller screens */
  @media only screen and (max-width: 1000px) {
      #body {
          flex-direction: column-reverse;
      }

      section {
          max-height: 36%;
      }

      main {
          padding: .5rem;
          gap: .5rem;
      }

      h1 {
          font-size: xx-large;
          text-align: center;
      }

      div {
          display: flex;
          flex-direction: column;  
          gap: 1rem;
      }
      
  }
</style>
<div id="body">
  <main>
      <header>
          <h1>Resilience Explorer </h1>
          <h2>Page Update</h2>
      </header>          
      <div id="main-text">
          <p>Hi there! We've updated the Resilience Explorer page to a new location.</p>
          <p>Please update your bookmarks or saved links to <i>resilience-explorer.com</i></p>
      </div>
      
      <a href="http://resilience-explorer.com">
        <button>
          <p>Go to resilience-explorer.com</p>
            <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M6.25 16.2499H21.0812L16.54 21.6999C16.0975 22.2299 16.17 23.0186 16.7 23.4599C17.2312 23.9024 18.0188 23.8299 18.4613 23.2999L24.7113 15.7999C24.76 15.7411 24.7838 15.6724 24.82 15.6074C24.85 15.5549 24.8862 15.5099 24.9088 15.4524C24.965 15.3086 24.9987 15.1574 24.9987 15.0049C24.9987 15.0036 25 15.0011 25 14.9999C25 14.9986 24.9987 14.9961 24.9987 14.9949C24.9987 14.8424 24.965 14.6911 24.9088 14.5474C24.8862 14.4899 24.85 14.4449 24.82 14.3924C24.7838 14.3274 24.76 14.2586 24.7113 14.1999L18.4613 6.69988C18.2125 6.40363 17.8575 6.24988 17.5 6.24988C17.2175 6.24988 16.9337 6.34488 16.7 6.53988C16.17 6.98113 16.0975 7.76988 16.54 8.29988L21.0812 13.7499H6.25C5.56 13.7499 5 14.3099 5 14.9999C5 15.6899 5.56 16.2499 6.25 16.2499Z" fill="currentColor"/>
            </svg>                
      </button>
      </a>
  </main>
  <section>
      <svg id="hero" width="850" height="521" viewBox="0 0 850 521" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_1_2)">
          <path d="M542.337 273.358C545.793 274.318 549.481 273.977 552.703 272.4C555.924 270.822 558.455 268.118 559.816 264.799C561.176 261.48 561.272 257.777 560.085 254.392C558.899 251.007 556.511 248.175 553.376 246.433L522.275 211.149L495.97 216.832L532.968 253.071C532.058 254.997 531.582 257.1 531.573 259.23C531.564 261.361 532.023 263.467 532.916 265.402C533.809 267.336 535.116 269.051 536.743 270.425C538.371 271.8 540.28 272.801 542.337 273.358Z" fill="#A0616A"/>
          <path d="M440.016 147.04L517.531 244.34L541.159 233.492L450.049 91.647L440.016 147.04Z" fill="#165494"/>
          <path d="M792.413 483.321C797.271 473.897 794.632 462.007 788.585 453.297C782.538 444.587 773.609 438.381 764.876 432.368L752.733 424.006L758.533 453.118C760.491 462.961 762.904 473.59 770.591 480.042C773.609 482.517 777.214 484.172 781.058 484.85C784.902 485.527 788.855 485.202 792.538 483.908" fill="#0B2948"/>
          <path d="M791.496 519.153C791.433 519.376 791.285 519.565 791.084 519.679C790.883 519.793 790.645 519.823 790.422 519.763C790.202 519.696 790.017 519.547 789.903 519.347C789.79 519.148 789.757 518.912 789.812 518.689C790.489 513.759 791.16 508.826 791.477 503.859C792.005 495.646 791.517 487.217 788.477 479.485C785.724 472.532 780.835 466.633 774.514 462.638C773.566 462.04 774.442 460.531 775.395 461.131C779.048 463.433 782.272 466.354 784.922 469.764C789.914 476.2 792.365 484.049 793.131 492.085C793.993 501.134 792.729 510.201 791.5 519.154L791.496 519.153Z" fill="#165494"/>
          <path d="M667.903 463.862C706.161 487.405 693.408 518.796 693.408 518.796L667.903 463.862Z" fill="#0B2948"/>
          <path d="M743.092 496.043C712.982 495.851 709.092 518.225 709.092 518.225L743.092 496.043Z" fill="#0B2948"/>
          <path d="M257.333 520.652C262.028 520.652 265.835 516.845 265.835 512.15C265.835 507.454 262.028 503.648 257.333 503.648C252.637 503.648 248.831 507.454 248.831 512.15C248.831 516.845 252.637 520.652 257.333 520.652Z" fill="#0B2948"/>
          <path d="M263.516 340.953C263.516 340.953 283.612 279.893 285.157 275.642C286.703 271.391 302.934 278.348 307.185 279.507C311.436 280.666 310.277 284.917 310.277 284.917L288.635 347.522L295.205 350.614L315.3 287.622C326.894 275.642 318.392 274.87 318.392 274.87C318.392 274.87 293.659 266.368 284.384 265.595C275.11 264.822 278.588 276.029 278.588 276.029L256.174 335.542L263.516 340.953Z" fill="#0B2948"/>
          <path d="M311.822 355.637L311.818 355.634C308.967 351.545 304.918 348.441 300.229 346.749C300.063 346.687 299.869 346.618 299.649 346.54C289.002 342.819 214.823 322.789 214.823 322.789C214.823 322.789 200.138 317.379 193.569 332.064C186.999 346.749 147.968 468.481 147.968 468.481C147.968 468.481 224.307 509.306 246.562 513.75C247.937 514.081 249.354 514.193 250.763 514.082C254.546 513.558 258.27 512.668 261.881 511.427C265.876 510.125 269.676 508.288 273.177 505.967L316.46 376.506C316.46 376.506 322.643 362.207 311.822 355.637Z" fill="#0B2948"/>
          <path d="M311.822 355.638L311.818 355.634C308.967 351.545 304.918 348.441 300.229 346.749C300.063 346.687 299.869 346.618 299.649 346.541C303.15 352.677 304.48 362.207 304.48 362.207C304.48 362.207 261.97 488.576 258.106 500.556C255.161 509.688 249.221 512.791 246.563 513.75C247.937 514.081 249.354 514.193 250.763 514.082C254.546 513.558 258.27 512.668 261.881 511.427C262.938 510.216 263.873 508.905 264.675 507.512C264.675 507.512 307.958 378.052 311.822 371.868C315.687 365.685 311.822 355.638 311.822 355.638Z" fill="#165494"/>
          <path d="M231.248 520.845C235.943 520.845 239.749 517.038 239.749 512.343C239.749 507.648 235.943 503.841 231.248 503.841C226.552 503.841 222.746 507.648 222.746 512.343C222.746 517.038 226.552 520.845 231.248 520.845Z" fill="#0B2948"/>
          <path d="M148.74 485.098C153.436 485.098 157.242 481.292 157.242 476.596C157.242 471.901 153.436 468.094 148.74 468.094C144.045 468.094 140.239 471.901 140.239 476.596C140.239 481.292 144.045 485.098 148.74 485.098Z" fill="#0B2948"/>
          <path d="M298.599 280.818C295.18 281.9 291.482 281.691 288.206 280.229C284.93 278.767 282.305 276.155 280.827 272.887C279.349 269.618 279.121 265.921 280.186 262.496C281.252 259.071 283.537 256.156 286.608 254.303L316.432 217.934H334L307.24 260.21C308.217 262.103 308.768 264.187 308.853 266.316C308.938 268.444 308.555 270.566 307.731 272.531C306.907 274.496 305.662 276.256 304.085 277.688C302.507 279.119 300.635 280.188 298.599 280.818Z" fill="#A0616A"/>
          <path d="M501.866 440.846L520.34 440.556L518.118 503.522H501.869L501.866 440.846Z" fill="#A0616A"/>
          <path d="M497.724 498.217L529.725 498.216H529.726C535.135 498.216 540.322 500.365 544.146 504.189C547.971 508.014 550.12 513.2 550.12 518.609V519.272L497.725 519.274L497.724 498.217Z" fill="#0B2948"/>
          <path d="M379.549 424.146L399.73 437.102L359.362 485.667L345.688 476.888L379.549 424.146Z" fill="#A0616A"/>
          <path d="M345.066 470.184L371.996 487.473L371.997 487.474C376.548 490.396 379.752 495.006 380.904 500.291C382.055 505.575 381.061 511.101 378.139 515.653L377.781 516.21L333.691 487.904L345.066 470.184Z" fill="#0B2948"/>
          <path d="M372.359 231.875C372.359 231.875 417.199 82.9477 431.609 80.9352C446.019 78.9227 469.237 119.148 469.237 119.148C499.34 157.556 530.381 281.969 530.381 281.969C530.381 281.969 478.635 285.207 440.808 305.333C402.981 325.458 358.34 304.314 358.34 304.314L372.359 231.875Z" fill="#165494"/>
          <path d="M454.356 114.935C468.356 128.935 455.356 143.935 455.356 143.935L471.356 179.935L408.356 196.935L386.34 98.5565L401.34 82.5565C431.34 81.5565 431.356 80.9347 431.356 80.9347C436.613 86.0786 454.356 114.935 454.356 114.935Z" fill="#EBEBEB"/>
          <path d="M406.5 127.5L322.356 250.935L298.356 240.935L377.5 96.5L406.5 127.5Z" fill="#165494"/>
          <path d="M440.328 67.9738H377.749C376.462 67.9723 375.228 67.4603 374.317 66.5501C373.407 65.6399 372.895 64.4058 372.894 63.1185V36.1448C372.891 31.3966 373.824 26.6944 375.64 22.3069C377.455 17.9195 380.117 13.9326 383.474 10.5743C386.83 7.21591 390.816 4.5518 395.202 2.73416C399.589 0.916526 404.29 -0.0190125 409.039 -0.0190125C413.787 -0.0190125 418.488 0.916526 422.875 2.73416C427.261 4.5518 431.247 7.21591 434.604 10.5743C437.96 13.9326 440.622 17.9195 442.437 22.3069C444.253 26.6944 445.186 31.3966 445.183 36.1448V63.1185C445.182 64.4058 444.67 65.6399 443.76 66.5501C442.849 67.4603 441.615 67.9724 440.328 67.9738Z" fill="#0B2948"/>
          <path d="M439.719 53.5568C446.739 40.7148 442.019 24.6132 429.177 17.5931C416.335 10.573 400.233 15.2927 393.213 28.1347C386.193 40.9768 390.913 57.0783 403.755 64.0984C416.597 71.1185 432.698 66.3989 439.719 53.5568Z" fill="#A0616A"/>
          <path d="M453.734 38.8422H415.469L415.076 33.3484L413.114 38.8422H407.222L406.445 27.9536L402.556 38.8422H391.155V38.3027C391.164 30.7222 394.179 23.4547 399.539 18.0945C404.899 12.7342 412.167 9.7191 419.747 9.71054H425.142C432.723 9.7191 439.99 12.7342 445.35 18.0944C450.711 23.4547 453.726 30.7222 453.734 38.3027V38.8422Z" fill="#0B2948"/>
          <path d="M415.156 73.009C414.868 73.0087 414.58 72.9834 414.296 72.9332L386.277 67.9894V21.681H417.121L416.357 22.5713C405.733 34.9623 413.737 55.0546 419.454 65.9284C419.875 66.7248 420.063 67.624 419.996 68.5225C419.929 69.421 419.609 70.2823 419.074 71.0071C418.626 71.6269 418.037 72.1315 417.356 72.4795C416.674 72.8275 415.92 73.009 415.156 73.009Z" fill="#0B2948"/>
          <path d="M0 519.845C0 519.58 0.105356 519.325 0.292892 519.138C0.480429 518.95 0.734784 518.845 1 518.845H849C849.265 518.845 849.52 518.95 849.707 519.138C849.895 519.325 850 519.58 850 519.845C850 520.11 849.895 520.365 849.707 520.552C849.52 520.74 849.265 520.845 849 520.845H1C0.734784 520.845 0.480429 520.74 0.292892 520.552C0.105356 520.365 0 520.11 0 519.845Z" fill="#165494"/>
          <path opacity="0.1" d="M372.359 231.875C372.359 231.875 417.199 82.9478 431.609 80.9352C446.019 78.9227 463.34 119.556 463.34 119.556C478.34 181.556 530.381 281.969 530.381 281.969C530.381 281.969 478.635 285.207 440.808 305.333C402.981 325.458 358.34 304.314 358.34 304.314L372.359 231.875Z" fill="#EBEBEB"/>
          <path d="M409.147 187.317V262.152L400.34 370.556L356.34 452.556L377.34 467.556L437.34 389.556L461.071 312.107L498.34 483.556L526.34 481.556L506.894 243.845L469.993 176.935L409.147 187.317Z" fill="#0B2948"/>
          <path d="M383.455 88.6209C378.247 92.4512 374.594 98.0303 373.166 104.335C371.737 110.64 372.629 117.249 375.678 122.95C382.895 136.728 390.659 158.07 386.656 181.5C379.411 223.902 356.189 256.118 343.254 266.464C338.75 270.065 338.593 275.31 339.254 279.076C341.101 289.597 351.302 301.535 358.357 303.929C358.832 304.086 359.33 304.164 359.83 304.16C371.292 304.164 399.486 267.659 431.885 196.349C452.063 151.939 437.418 116.33 421.58 94.2777C419.501 91.3923 416.87 88.9481 413.84 87.0867C410.81 85.2252 407.44 83.9836 403.926 83.4336C400.413 82.8836 396.825 83.0363 393.371 83.8827C389.916 84.7291 386.664 86.2525 383.803 88.3646L383.455 88.6209Z" fill="#165494"/>
          </g>
          <defs>
          <clipPath id="clip0_1_2">
          <rect width="850" height="520.845" fill="white"/>
          </clipPath>
          </defs>
      </svg>
  </section>
</div>
</html>
  `

  return (
    <UiMarkdown
      css={css`
        width: 100vw;
        height: 100svh;
        margin: 0;
      `}
    >
      {staticHTML ?? ''}
    </UiMarkdown>
  )
}
