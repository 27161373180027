/** @jsxImportSource @emotion/react */
import { Box, FormControlLabel, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'

import { Checkbox, Modal } from '@uintel/ui-component-library'
import { Button } from '@src/components/Atoms/Button'
import {
  acceptanceControls,
  checkboxLabel,
  continueButton,
  footer,
  header,
  headerContainer,
  logo,
  tsncsContainer,
  tsncsCopy,
  tsncsInnerContainer,
  tsncsModal,
  uiLogo,
} from './TsnCsModal.styles'
import { useState } from 'react'
import UiMarkdown from '@src/components/Atoms/UiMarkdown/UiMarkdown'
import { useSelector } from 'react-redux'
import { RootState } from '@redux/store'

export interface TsnCsModalProps {
  content: string
  handleAcceptTsnCs: () => void
  showClientLogo?: boolean
}
/**
 * A modal for terms and conditions acceptance
 */
export const TsnCsModal = ({
  handleAcceptTsnCs,
  content,
  showClientLogo = false,
}: TsnCsModalProps) => {
  const theme = useTheme()
  const [accepted, setAccepted] = useState(false)
  const [open, setOpen] = useState(true)
  const { logos } = useSelector((state: RootState) => state.user)

  const handleContinue = () => {
    setOpen(false)
    handleAcceptTsnCs()
  }

  return (
    <Modal open={open}>
      <Box css={tsncsModal({ theme })}>
        <Box css={headerContainer()}>
          <Typography variant="h4" css={header({ theme })}>
            Terms and Conditions
          </Typography>
        </Box>
        <Box css={tsncsContainer({ theme })}>
          <Box css={tsncsInnerContainer({ theme })}>
            <UiMarkdown css={tsncsCopy({ theme })}>{content}</UiMarkdown>
          </Box>
        </Box>
        <Box css={footer({ theme })}>
          {showClientLogo && (
            <Box key={logos.full} component="img" src={logos.full} css={logo({ theme })} />
          )}

          <Box component="img" src="/logos/UILogoFull.svg" css={uiLogo({ theme })} />

          <Box css={acceptanceControls}>
            <FormControlLabel
              control={
                <Checkbox
                  onChange={(_e, checked) => {
                    setAccepted(checked)
                  }}
                  size="small"
                />
              }
              label={
                <Typography css={checkboxLabel({ theme })}>
                  I agree to the above Terms and Conditions
                </Typography>
              }
            />
            <Button
              disabled={!accepted}
              onClick={handleContinue}
              css={continueButton({ theme })}
              data-testid="tsncs-continue-button"
            >
              Continue
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}
