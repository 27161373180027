import * as d3 from 'd3'
import { useCallback, useEffect, useRef, useState } from 'react'
import { ChartTooltip } from './ChartTooltip'
import { round } from './utils/utils'
import { AxisCalculator } from './utils/AxisCalculator'

export interface ChartDataItem {
  label: string
  min?: number
  value: number
  max?: number
  color: string
}

export const MakeBarChartSvg = (
  element: HTMLElement,
  data: ChartDataItem[],
  xTitle = '',
  yTitle = '',
  fullWidth = 300,
  updateTooltip?: (args: { show: boolean; data: ChartDataItem }) => void,
) => {
  // set the dimensions and margins of the graph
  const fullHeight = Math.max((data.length + 1) * 40, 96)
  const margin = { top: 0, right: 55, bottom: 35, left: 60 },
    width = fullWidth - margin.left - margin.right,
    height = fullHeight - margin.top - margin.bottom

  const xCalculator = new AxisCalculator(
    data.flatMap((item) => [item.max ?? 0, item.min ?? 0, item.value]),
  )

  d3.select(element).selectAll('svg').remove()
  const svg = d3
    .select(element)
    .append('svg')
    .attr('version', '1.1')
    .attr('xmlns', 'http://www.w3.org/2000/svg')
    .attr('width', fullWidth)
    .attr('height', fullHeight)
    .attr('font-family', 'Inter')
    .attr('font-size', '8pt')
    .attr('viewBox', `0 0 ${fullWidth} ${fullHeight}`)
    .attr('color', '#8392a1')
    .append('g')
    .attr('transform', `translate(${margin.left},${margin.top})`)

  const yAxisScale = d3
    .scaleBand()
    .range([height, 0])
    .domain(data.map((d) => d.label).reverse())
    .padding(0.2)

  const xAxisScale = d3.scaleLinear().domain(xCalculator.domain).range([0, width])

  // Axises
  svg.append('g').call(d3.axisLeft(yAxisScale).tickSize(0))
  svg
    .append('g')
    .attr('transform', `translate(0, ${height})`)
    .call(
      d3
        .axisBottom(xAxisScale)
        .ticks(xCalculator.tickCount)
        .tickSize(2)
        .tickSizeOuter(0)
        .tickFormat((t) => xCalculator.roundValue(t.valueOf())),
    )
    .selectAll('.tick line')
    .attr('y2', -height)
    .attr('y1', -1)
    .attr('stroke', '#e0e4e8')
    .attr('stroke-width', 1)
    .filter((_, i) => i === 0)
    .remove()
  svg.selectAll('.domain').attr('stroke', '#53687e').attr('stroke-width', 0)

  // Bars
  svg
    .selectAll('mybar')
    .data(data)
    .join('rect')
    .attr('x', 0.5)
    .attr('y', (d) => yAxisScale(d.label) ?? 0)
    .attr('height', yAxisScale.bandwidth())
    .attr('width', (d) => xAxisScale(d.value))
    .attr('fill', (d) => d.color)
    .attr('rx', yAxisScale.bandwidth() / 8)
    .attr('ry', yAxisScale.bandwidth() / 8)

  svg
    .selectAll('mybar')
    .data(data.filter((item) => hasErrorBar(item)))
    .enter()
    .append('path')
    .attr('d', (d) => drawErrorBar(d3.path(), d).toString())
    .attr('stroke', '#000')
    .attr('stroke-width', '2')

  // value label
  svg
    .selectAll('mybar')
    .data(data.filter((item) => !hasErrorBar(item)))
    .enter()
    .append('text')
    .attr('font-size', '8pt')
    .attr('fill', '#0b2948')
    .attr('font-weight', 'bold')
    .text((d) => xCalculator.roundValueForDisplay(d.value))
    .attr('x', (d) => xAxisScale(d.value) + 8)
    .attr('y', (d) => (yAxisScale(d.label) ?? 0) + yAxisScale.bandwidth() / 2)
    .attr('dy', '0.35em')
    .attr('text-anchor', 'start')

  // Add X axis title:
  if (xTitle)
    svg
      .append('text')
      .attr('fill', '#8392a1')
      .attr('text-anchor', 'middle')
      .attr('x', width / 2)
      .attr('y', height + margin.top + 30)
      .text(xTitle)

  // Y axis title:
  if (yTitle)
    svg
      .append('text')
      .attr('fill', '#8392a1')
      .attr('text-anchor', 'middle')
      .attr('transform', 'rotate(-90)')
      .attr('y', -margin.left + 20)
      .attr('x', -height / 2)
      .text(yTitle)

  svg
    .selectAll('hitbox')
    .data(data)
    .join('rect')
    .attr('x', 0)
    .attr('y', (d) => (yAxisScale(d.label) ?? 0) - yAxisScale.bandwidth() * 0.125)
    .attr('width', width)
    .attr('height', yAxisScale.bandwidth() * 1.25)
    .attr('fill', 'transparent')
    .on('mouseover', (_e, data) => {
      if (updateTooltip) updateTooltip({ show: true, data })
    })
    .on('mouseout', (_e, data) => {
      if (updateTooltip) updateTooltip({ show: false, data })
    })

  function hasErrorBar(item: ChartDataItem): boolean {
    // Has error bars if min and max are set
    // and if min and max are not equal to the value
    const hasMax = item.max !== undefined
    const hasMin = item.min !== undefined

    return hasMax && hasMin && !(item.min == item.value && item.max == item.value)
  }

  function drawErrorBar(path: d3.Path, d: ChartDataItem): d3.Path {
    const y = (yAxisScale(d.label) ?? 0) + yAxisScale.bandwidth() / 2
    const height = yAxisScale.bandwidth() / 3
    const min = d.min ?? d.value // Will never be d.value, it's here to stop whinging about d.min possibly being undefined
    const max = d.max ?? d.value

    const minX = xAxisScale(min)
    const maxX = xAxisScale(max)
    path.moveTo(minX, y - height / 2)
    path.lineTo(minX, y + height / 2)

    path.moveTo(minX, y)
    path.lineTo(maxX, y)

    path.moveTo(maxX, y - height / 2)
    path.lineTo(maxX, y + height / 2)

    return path
  }
}

interface BarchartProps {
  data: ChartDataItem[]
  xTitle?: string
  yTitle?: string
  tooltipFormatter?: ({ value, label, color }: ChartDataItem) => string
}

export const Barchart = ({ data, xTitle, yTitle, tooltipFormatter }: BarchartProps) => {
  const ref = useRef<HTMLDivElement>(null)
  const [width, setWidth] = useState<number | null>(null)
  const [showTooltip, setShowTooltip] = useState(false)
  const [tooltipText, setTooltipText] = useState('')
  const [tooltipColor, setTooltipColor] = useState('')

  const updateTooltip = useCallback(
    ({ show, data }: { show: boolean; data: ChartDataItem }) => {
      setShowTooltip(show)
      if (tooltipFormatter) {
        setTooltipText(tooltipFormatter(data))
      } else if (data) {
        data.value = round(data.value)
        setTooltipText(`${data.label}: ${data.value}`)
      }
      if (data.color) setTooltipColor(data.color)
    },
    [tooltipFormatter],
  )

  useEffect(() => {
    if (!ref.current) return
    const resizeObserver = new ResizeObserver((event) => {
      // Round width to nearest 50
      const newWidth = Math.round(event[0].contentBoxSize[0].inlineSize / 50) * 50
      setWidth(newWidth)
    })

    resizeObserver.observe(ref.current)
  }, [])

  useEffect(() => {
    if (!ref.current || width == null) return
    MakeBarChartSvg(ref.current, data, xTitle, yTitle, width, updateTooltip)
  }, [data, xTitle, yTitle, width, updateTooltip])

  return (
    <ChartTooltip show={showTooltip} items={[{ text: tooltipText, color: tooltipColor }]}>
      <div ref={ref}></div>
    </ChartTooltip>
  )
}
