export const LandIcon = ({ ...rest }) => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M12.1281 12.0537L17.416 17.3415L10.9091 23.8484L2.06066 15L15 2.06066L18.0302 5.09091L12.1281 10.993L11.5978 11.5233L12.1281 12.0537Z"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <rect
        x="15.5782"
        y="11.301"
        width="5.99151"
        height="11.3565"
        transform="rotate(-45 15.5782 11.301)"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <rect
        x="12.8824"
        y="25.6327"
        width="8.9346"
        height="3.12833"
        transform="rotate(-45 12.8824 25.6327)"
        stroke="currentColor"
        strokeWidth="1.5"
      />
    </svg>
  )
}

export default LandIcon
