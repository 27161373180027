/** @jsxImportSource @emotion/react */

import { Box, useTheme, Typography } from '@mui/material'
import { noResultsContainer } from './EmptySection.styles'
import { SerializedStyles } from '@emotion/react'

export type EmptySectionProps = {
  title: string
  content?: string
  isLoading?: boolean
  height?: string
  customCSS?: SerializedStyles
}

export const EmptySection = ({
  title,
  content,
  isLoading = false,
  height = '40px',
  customCSS,
}: EmptySectionProps) => {
  const theme = useTheme()
  return (
    <Box css={[noResultsContainer({ theme, isLoading }), customCSS]} minHeight={height}>
      <Typography variant="body2" fontWeight={700}>
        {title}
      </Typography>
      {content && <Typography variant="body2">{content}</Typography>}
    </Box>
  )
}
