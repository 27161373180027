export const PillIcon = ({ ...rest }) => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g clipPath="url(#clip0_748_48327)">
        <path
          d="M24.6711 5.33125C23.5787 4.23882 22.097 3.62511 20.5521 3.62511C19.0071 3.62511 17.5255 4.23882 16.433 5.33125L5.33145 16.4317C4.26186 17.5288 3.66758 19.0032 3.67736 20.5354C3.68715 22.0676 4.3002 23.5342 5.38371 24.6176C6.46723 25.7009 7.93395 26.3138 9.46614 26.3234C10.9983 26.333 12.4726 25.7385 13.5696 24.6687L24.6722 13.5683C25.7628 12.475 26.3752 10.9938 26.375 9.44953C26.3748 7.9053 25.762 6.42422 24.6711 5.33125ZM12.3325 23.4317C11.5683 24.1961 10.5318 24.6255 9.4509 24.6257C8.37003 24.6258 7.33339 24.1965 6.56903 23.4323C5.80466 22.668 5.37519 21.6315 5.37509 20.5506C5.37499 19.4697 5.80426 18.4331 6.56848 17.6687L11.5002 12.737L17.2643 18.5L12.3325 23.4317ZM23.4341 12.3312L18.5002 17.263L12.7383 11.5L17.6711 6.56828C18.4384 5.81875 19.4702 5.40192 20.5428 5.40819C21.6153 5.41446 22.6422 5.84332 23.4006 6.60175C24.1591 7.36019 24.5879 8.38705 24.5942 9.45962C24.6005 10.5322 24.1836 11.564 23.4341 12.3312ZM21.7464 10.0059C21.8278 10.0872 21.8923 10.1837 21.9364 10.2899C21.9804 10.3961 22.0031 10.51 22.0031 10.625C22.0031 10.74 21.9804 10.8538 21.9364 10.9601C21.8923 11.0663 21.8278 11.1628 21.7464 11.2441L19.1214 13.8691C19.0401 13.9503 18.9437 14.0147 18.8375 14.0586C18.7313 14.1026 18.6175 14.1251 18.5025 14.1251C18.3876 14.125 18.2738 14.1024 18.1677 14.0583C18.0615 14.0143 17.9651 13.9498 17.8839 13.8685C17.8026 13.7872 17.7382 13.6907 17.6943 13.5845C17.6504 13.4783 17.6278 13.3645 17.6278 13.2496C17.6279 13.1347 17.6506 13.0209 17.6946 12.9148C17.7386 12.8086 17.8031 12.7122 17.8844 12.6309L20.5094 10.0059C20.6735 9.84196 20.896 9.74986 21.1279 9.74986C21.3599 9.74986 21.5824 9.84196 21.7464 10.0059Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_748_48327">
          <rect width="28" height="28" fill="white" transform="translate(1 1)" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default PillIcon
