// Import necessary libraries and types
import { Steps } from 'intro.js-react'
import { useEffect, useState } from 'react'
import { CircularProgress } from '@mui/material'
import ReactDOMServer from 'react-dom/server'

export type TutorialType = React.ReactNode
export type TutorialStepData = {
  element?: string | HTMLElement | Element
  intro: string | React.ReactNode
  position?: string
  title?: string
  moduleTitle?: string
  tooltipClass?: string
  highlightClass?: string
  onBack?: () => void
  onNext?: () => void
  onStart?: () => void
  onBackDelay?: number
  onNextDelay?: number
  condition?: () => boolean
}

// Define the props for the Tutorial component
interface TutorialProps {
  enabled: boolean
  tutorialSteps: TutorialStepData[]
  handleComplete?: () => void
  handleExit?: () => void
  moduleTitle?: string
}

// Define the Tutorial component
export const TutorialRunner = ({
  enabled,
  handleComplete,
  handleExit,
  tutorialSteps,
  moduleTitle,
}: TutorialProps) => {
  // Define a state variable for the last step index
  const [lastStepIndex, setLastStepIndex] = useState<number | null>(null)

  // Define a state variable for the steps reference
  const [stepsRef, setStepsRef] = useState<Steps | null>(null)

  const [exitHandled, setExitHandled] = useState<boolean>(false)

  const [internalTutorialSteps, setInternalTutorialSteps] = useState<TutorialStepData[]>([])

  const startLoadingNext = () => {
    // Replace the current step with a loading spinner
    const button = document.getElementsByClassName('introjs-nextbutton')[0]
    const spinner = <CircularProgress />
    if (button) button.innerHTML = ReactDOMServer.renderToString(spinner)
  }

  const startLoadingPrev = () => {
    // Replace the current step with a loading spinner
    const button = document.getElementsByClassName('introjs-prevbutton')[0]
    const spinner = <CircularProgress />
    if (button) button.innerHTML = ReactDOMServer.renderToString(spinner)
  }

  const stopLoading = () => {
    // Replace the loading spinner with the next button
    const nextButton = document.getElementsByClassName('introjs-nextbutton')[0]
    if (nextButton) nextButton.innerHTML = 'Next'

    // Replace the loading spinner with the previous button
    const prevButton = document.getElementsByClassName('introjs-prevbutton')[0]
    if (prevButton) prevButton.innerHTML = 'Back'
  }

  // Set the internal tutorial steps titles when the tutorial steps change
  useEffect(() => {
    if (tutorialSteps !== undefined) {
      setInternalTutorialSteps(
        tutorialSteps.map((step) => {
          if (step.moduleTitle !== undefined) {
            return {
              ...step,
              title: `<div class="group-title">${step.moduleTitle}</div><div class="step-title">${step.title}</div>`,
            }
          } else if (moduleTitle !== undefined) {
            return {
              ...step,
              title: `<div class="group-title">${moduleTitle}</div><div class="step-title">${step.title}</div>`,
            }
          } else {
            return {
              ...step,
              title: `<div class="group-title">${step.title}</div>`,
            }
          }
        }),
      )
    }
  }, [moduleTitle, tutorialSteps])

  useEffect(() => {
    if (stepsRef !== null && lastStepIndex !== null && internalTutorialSteps[lastStepIndex]) {
      stepsRef.updateStepElement(lastStepIndex)
      stepsRef.introJs.goToStep(lastStepIndex + 1)
    }
  }, [internalTutorialSteps, lastStepIndex, stepsRef])

  useEffect(() => {
    if (enabled) {
      setExitHandled(false)
    }
  }, [enabled])

  const internalHandleComplete = () => {
    setLastStepIndex(null)
    setInternalTutorialSteps([])
    setExitHandled(true)
  }

  // Return the Steps component from intro.js-react
  return (
    <Steps
      enabled={enabled}
      steps={internalTutorialSteps}
      initialStep={0}
      onExit={() => {
        if (!exitHandled) {
          internalHandleComplete()
          if (handleExit) handleExit()
        }
      }}
      onComplete={() => {
        // This runs before onExit if the user completes the tutorial
        internalHandleComplete()
        if (handleComplete) handleComplete()
      }}
      options={{
        showProgress: true,
        showBullets: false,
      }}
      ref={(steps) => setStepsRef(steps)}
      onBeforeChange={async (nextStepIndex) => {
        if (internalTutorialSteps[nextStepIndex]) {
          // Skip a step if the condition function is set and returns false
          const condition = internalTutorialSteps[nextStepIndex].condition
          if (
            condition !== undefined &&
            !condition() &&
            stepsRef !== null &&
            lastStepIndex !== null
          ) {
            if (internalTutorialSteps.length > nextStepIndex + 1) {
              if (nextStepIndex > lastStepIndex) {
                stepsRef.introJs.goToStep(nextStepIndex + 2)
                nextStepIndex++
              } else if (lastStepIndex > nextStepIndex) {
                stepsRef.introJs.goToStep(nextStepIndex)
                nextStepIndex--
              }
            }
          }

          if (lastStepIndex !== null) {
            if (nextStepIndex > lastStepIndex) {
              startLoadingNext()
              // We've gone forward a step
              const onNext = internalTutorialSteps[lastStepIndex].onNext
              if (onNext !== undefined) {
                onNext()
              }
              // Delay the next step if the a delay is set
              const delay = internalTutorialSteps[lastStepIndex].onNextDelay
              if (delay !== undefined) {
                await new Promise((resolve) => setTimeout(resolve, delay))
              }

              // Delay the next step if the element is a selector and is not found (up to 2 seconds)
              const element = internalTutorialSteps[nextStepIndex].element
              if (typeof element === 'string') {
                let loops = 0
                while (document.querySelector(element) === null) {
                  await new Promise((resolve) => setTimeout(resolve, 100))
                  loops++
                  if (loops > 20) {
                    // eslint-disable-next-line no-console
                    console.log('Element not found:', element)
                    break
                  }
                }
              }
              stopLoading()
            } else if (nextStepIndex < lastStepIndex) {
              startLoadingPrev()
              // We've gone back a step
              const onBack = internalTutorialSteps[lastStepIndex].onBack
              if (onBack !== undefined) {
                onBack()
              }
              // Delay the previous step if the a delay is set
              const delay = internalTutorialSteps[lastStepIndex].onBackDelay
              if (delay !== undefined) {
                await new Promise((resolve) => setTimeout(resolve, delay))
              }

              // Delay the previuos step if the element is a selector and is not found (up to 2 seconds)
              const element = internalTutorialSteps[nextStepIndex].element
              if (typeof element === 'string') {
                let loops = 0
                while (document.querySelector(element) === null) {
                  await new Promise((resolve) => setTimeout(resolve, 100))
                  loops++
                  if (loops > 20) {
                    // eslint-disable-next-line no-console
                    console.log('Element not found:', element)
                    break
                  }
                }
              }
              stopLoading()
            }
          }
        }
      }}
      onChange={(nextStepIndex) => {
        setLastStepIndex(nextStepIndex)
        // Run onStart if it is defined
        const onStart = internalTutorialSteps[nextStepIndex].onStart
        if (onStart !== undefined) {
          onStart()
        }
      }}
    />
  )
}
