import { BASE_API_URL } from '@src/app-constants'
import axios from '@src/utils/customAxios'
import { Content } from 'pdfmake/interfaces'
import { MapLayer, MapLayerType } from '@redux/map/mapSlice'
import { LayerDetailsReport } from '@src/components/Molecules/SideDrawerContent/data_fetchers/layerDetailsFetcher'
import { parseHtml } from '../../Report.utils'

export const getLayerDetailsReports = async (
  layers: MapLayer[],
  layerType: MapLayerType,
): Promise<{ [key: string]: LayerDetailsReport[] }> => {
  const toReturn: { [key: string]: LayerDetailsReport[] } = {}

  const layersToGet = layers.filter((layer) => layer.layerType == layerType)

  for (const layer of layersToGet) {
    let layerId = ''
    switch (layer.layerType) {
      case 'hazard':
        layerId = layer.assetTag
        break
      case 'asset':
        layerId = layer.type
        break
      case 'information':
        layerId = layer.id
        break
    }

    try {
      const layerDetailsResponse = await axios.get(`${BASE_API_URL}/api/layer/details/${layerId}`)
      const reports: LayerDetailsReport[] = layerDetailsResponse.data
      reports.forEach((report) => (report.title = layer.display_name))
      toReturn[layerId] = reports
    } catch (e) {
      // Do nothing
    }
  }
  return toReturn
}

export const extractLayerDetailsReportsImages = (layerDetailsReports: {
  [key: string]: LayerDetailsReport[]
}): { [key: string]: string } => {
  const toReturn: { [key: string]: string } = {}

  for (const layerId in layerDetailsReports) {
    const reports = layerDetailsReports[layerId]

    for (let reportIndex = 0; reportIndex < reports.length; reportIndex++) {
      const report = reports[reportIndex]
      if (!report.images) continue

      for (let imageIndex = 0; imageIndex < report.images.length; imageIndex++) {
        toReturn[`${layerId}_${reportIndex}_${imageIndex}`] = report.images[imageIndex].url
      }
    }
  }
  return toReturn
}

export const GenerateLayerDetails = (
  title: string,
  layerDetailsReports: {
    [key: string]: LayerDetailsReport[]
  },
): Content => {
  const toReturn: Content = []
  let isFirstReport = true

  // To avoid an orphaned title at the bottom of a page wrap the title and first report in
  // an unbreakeable stack
  const headerStack: Content = {
    stack: [{ text: title, style: 'h2', tocItem: true, tocMargin: [20, 0, 0, 0] }],
    unbreakable: true,
  }
  toReturn.push(headerStack)

  for (const layerId in layerDetailsReports) {
    const reports = layerDetailsReports[layerId]
    for (const report of reports) {
      if (!report.details) continue

      const reportContent: Content = { stack: [], unbreakable: true }

      reportContent.stack.push({ text: report.title, style: 'h3' })

      reportContent.stack.push({
        table: {
          body: [
            report.details.map((detail) => {
              return { text: detail.label, bold: true }
            }),
            report.details.map((detail) => parseHtml(detail.value)),
          ],
        },
        layout: 'noBorders',
      })

      if (isFirstReport) headerStack.stack.push(reportContent)
      else toReturn.push(reportContent)

      isFirstReport = false
    }
  }
  return toReturn
}

export const GenerateDataSourceContent = async (
  layers: MapLayer[],
  prefer_hazard_term: boolean,
): Promise<Content[]> => {
  const toReturn: Content[] = []

  const elementDetailsReports = await getLayerDetailsReports(layers, 'asset')
  const riskDetailsReports = await getLayerDetailsReports(layers, 'hazard')
  const informationDetailsReports = await getLayerDetailsReports(layers, 'information')

  toReturn.push({
    text: 'Data Sources',
    style: 'h1',
    pageBreak: 'before',
    tocItem: true,
    tocMargin: [0, 10, 0, 0],
  })

  if (Object.keys(elementDetailsReports).length > 0) {
    toReturn.push(GenerateLayerDetails('Elements', elementDetailsReports))
  }

  if (Object.keys(riskDetailsReports).length > 0) {
    toReturn.push(
      GenerateLayerDetails(prefer_hazard_term ? 'Hazards' : 'Risk Sources', riskDetailsReports),
    )
  }

  if (Object.keys(informationDetailsReports).length > 0) {
    toReturn.push(GenerateLayerDetails('Contextual', informationDetailsReports))
  }

  return toReturn
}
