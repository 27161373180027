const SidebarText = (prefer_hazard_term: boolean) => {
  const riskterm = prefer_hazard_term ? 'Hazard' : 'Risk Source'
  return {
    IDENTIFYELEMENTS: (
      <div className="column">
        You can open and close the sidebar using the highlighted thumbtab. The sidebar can be used
        to provide in-depth information on the current map view. This information is grouped into
        the Layers, Risk Summary, and Selected tabs.
      </div>
    ),
    LAYERSTAB: (
      <div className="column">
        <div>
          The Layers tab of the sidebar houses your controls for manipulating map layers. It
          contains two potential sections.
        </div>
        <div className="divider"></div>
        <div>
          The first is the <b>Map Layers</b> section shown here. The Map Layers section displays all
          the layers that are currently visible on the map, and lets you control their visibility,
          order, and more. For more information, see the Exploring Layers tutorial.
        </div>
      </div>
    ),
    SCENARIOCONTROLS: (
      <div className="column">
        <div>
          The second potential section is the <b>Scenario Controls</b> section. If you have a{' '}
          {riskterm.toLowerCase()} with multiple scenarios on the map, you can use the Scenario
          Controls section to switch between that {riskterm.toLowerCase()}&apos;s scenarios, and see
          how the risk to elements changes.
        </div>
        <div className="center-row">
          <img
            src="./images/tutorial/ScenarioControls.svg"
            alt="Scenario Controls Tab"
            className="l-image"
          />
        </div>
        <div className="divider"></div>
        <i>
          This section will only be visible if a valid {riskterm.toLowerCase()} has been added to
          the map.
        </i>
      </div>
    ),
    RISKSUMMARYTAB: (
      <div className="column">
        <div>
          The Risk Summary tab of the sidebar provides a detailed breakdown of the exposure and
          expected damage state of each element and {riskterm.toLowerCase()} pair on the map. The
          data sources and methodology behind the risk analysis are also provided.
        </div>
        <div className="divider"></div>
        <i>The risk charts that can be found in this tab include:</i>
        <div className="row">
          <img
            src="./images/tutorial/PercentageChart.svg"
            alt="Risk Summary Charts"
            className="s-image"
            style={{ alignSelf: 'flex-start' }}
          />
          <div>
            <b>Percent Exposed</b>, which gauges the percentage of an element that is exposed to a
            risk scenario.
          </div>
        </div>
        <div className="row">
          <img
            src="./images/tutorial/BarChart.svg"
            alt="Risk Summary Charts"
            className="m-image"
            style={{ alignSelf: 'flex-start' }}
          />
          <div>
            <b>Damage State</b>, which displays how much of an element is expected to receive each
            category of damage in a given risk scenario.
          </div>
        </div>
        <div className="row">
          <img
            src="./images/tutorial/ExposureHistogram.svg"
            alt="Risk Summary Charts"
            className="m-image"
            style={{ alignSelf: 'flex-start' }}
          />
          <div>
            <b>Exposure Distribution</b>, which shows the distribution of exposure values in a risk
            scenario, colored by expected damage states.
          </div>
        </div>
        <div className="row">
          <img
            src="./images/tutorial/ProjectedRisk.svg"
            alt="Risk Summary Charts"
            className="m-image"
            style={{ alignSelf: 'flex-start' }}
          />
          <div>
            <b>Projected Risk</b>, which shows the projected damage states of an element from a
            range of related scenarios. You can choose which scenarios to include and the property
            to display on the x axis using the controls at the top of the chart.
          </div>
        </div>
      </div>
    ),
    RISKSUMMARYFOOTER: (
      <div>
        In the footer of the Risk Summary tab, you can our <b>Feedback Spreadsheet</b>, where you
        can provide feedback on the map.
      </div>
    ),
    RISKSUMMARYMETHODOLOGYREPORT: (
      <div>
        Clicking the &quot;Methodology Report&ldquo; button will bring you to our{' '}
        <b>Methodology Report</b>, where we detail our data sources and methods.
      </div>
    ),
    RISKSUMMARYREPORT: (
      <div>
        You can generate a <b>Risk Summary Report</b> for your current map view by clicking the
        &quot;Download Risk Summary Report&ldquo; button.
      </div>
    ),
    SELECTEDTAB: (
      <div className="column">
        <div>
          The <b>Selected tab</b> of the sidebar provides detailed information on an element that
          you have selected on the map. This information includes the element&apos;s name, type, and
          risk statistics.
        </div>
        <div className="center-row">
          <img
            src="./images/tutorial/SelectedElement.svg"
            alt="Selected Element Tab"
            className="l-image"
            style={{ maxHeight: '40vh' }}
          />
        </div>
      </div>
    ),
    SELECTEDTAB2: (
      <div className="column">
        <div>
          If your selected element is exposed to a {riskterm.toLowerCase()} you can also view the
          scenario-specific risk statistics for the element here.
          <br />
          <br />
          Lastly, the <b>Known risks to element</b> section lists every known{' '}
          {riskterm.toLowerCase()} your selected element is exposed to.
        </div>
      </div>
    ),
  }
}

export default SidebarText
