import { css } from '@emotion/react'
import { Theme } from '@mui/material/styles'

import { ButtonProps } from './Button'

interface ButtonContainerProps {
  theme: Theme
  rest: ButtonProps
}

export const buttonContainer = ({ theme, rest }: ButtonContainerProps) => css`
  border-radius: ${theme.spacing(1)};
  white-space: nowrap;
  display: flex;
  flex-shrink: 0;
  font-size: ${rest.size === 'small' ? '0.875rem' : '1rem'};

  padding: ${rest.size === 'small' && `${theme.spacing(1)} ${theme.spacing(2)}`};
  padding: ${rest.size === 'medium' && `${theme.spacing(2)} ${theme.spacing(4)}`};
  padding: ${rest.size === 'large' && `${theme.spacing(3)} ${theme.spacing(5)}`};

  & > span:last-of-type {
    margin-left: ${rest.size === 'small' && theme.spacing(0.5)};
  }

  &:focus-visible {
    outline: 2px solid ${theme.palette.primary.main};
    outline-offset: 2px;
  }
`
