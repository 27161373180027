/** @jsxImportSource @emotion/react */
import { Box, Stack, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { useEffect, useState } from 'react'
import useSWR from 'swr'

import { GaugeCard } from '@uintel/ui-component-library'
import { Navigate } from 'react-router-dom'
import { getWorkbookData } from '../../../utils/workbook.utils'
import { strategyContainer, strategyContent } from './Strategy.styles'
import { StrategyLoading } from './StrategyLoading'
import { UiclAccordion } from '@src/components/Atoms/Accordion/UiclAccordion'

const WORKBOOK_NAME = 'strategy'

interface StrategyWorkbookData {
  division: string
  model_alignment: string
  sector: string
  action: string
  goal: string
  progress: number
}

export const Strategy = () => {
  const theme = useTheme()
  const [strategyData, setStrategyData] = useState<
    {
      modelTitle: string
      sections: { [k: string]: StrategyWorkbookData[] }
    }[]
  >()

  const { data, isLoading } = useSWR('/strategyData', getStrategyData)

  async function getStrategyData() {
    const workbookData = (await getWorkbookData(WORKBOOK_NAME)) as StrategyWorkbookData[]

    return workbookData
  }

  function hydrateData(workbookData: StrategyWorkbookData[]) {
    const hydratedData = []
    const models = new Map<string, StrategyWorkbookData[]>()

    const modelsTitles = new Set<string>()

    // Organize data by model alignment
    for (const row of workbookData) {
      modelsTitles.add(row.model_alignment)
      models.get(row.model_alignment)
        ? models.set(row.model_alignment, [...(models.get(row.model_alignment) as []), row])
        : models.set(row.model_alignment, [row])
    }

    // Group sections by model alignment
    for (const modelTitle of modelsTitles) {
      const sections = new Map<string, StrategyWorkbookData[]>()
      const modelSection = models.get(modelTitle)

      if (modelSection) {
        for (const sector of modelSection) {
          sections.get(sector.sector)
            ? sections.set(sector.sector, [...(sections.get(sector.sector) as []), sector])
            : sections.set(sector.sector, [sector])
        }
      }

      hydratedData.push({ modelTitle, sections: Object.fromEntries(sections) })
    }

    return hydratedData
  }

  useEffect(() => {
    if (!data) return

    setStrategyData(hydrateData(data))
  }, [data])

  if (isLoading) return <StrategyLoading />

  // TODO: remove this logic when data is ready for client
  const isReadyForScales = false
  if (!isReadyForScales) {
    return <Navigate to="/strategy/horizon-scan" />
  }

  return (
    <Box css={strategyContainer({ theme })}>
      <Box css={strategyContent({ theme })}>
        {strategyData?.map((item) => {
          const sectionContent = Object.entries(item.sections)

          return (
            <UiclAccordion
              key={item.modelTitle}
              header={<Typography variant="body1">{item.modelTitle}</Typography>}
              body={
                <Stack direction="row" gap={2} flexWrap="wrap">
                  {sectionContent.map(([title, sections]) => (
                    <GaugeCard
                      key={title}
                      title={title}
                      content={[
                        ...sections.map(({ action, goal, progress }) => ({
                          header: {
                            title: 'Action',
                            description: action ?? '-',
                          },
                          footer: {
                            title: 'Goal',
                            description: goal ?? '-',
                          },
                          percentage: progress,
                        })),
                      ]}
                    />
                  ))}
                </Stack>
              }
              defaultExpanded
            />
          )
        })}
      </Box>
    </Box>
  )
}
