/** @jsxImportSource @emotion/react */
import {
  Box,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  TextField,
  useTheme,
  Typography,
  Autocomplete,
} from '@mui/material'
import { useMemo, useState } from 'react'
import { createPortal } from 'react-dom'

import { MapLayer, MapLayerType } from '@redux/map/mapSlice'
import { BASE_API_URL } from '@src/app-constants'
import { Icon } from '@src/components/Atoms/Icon'
import { IconTypography, MoreInformationModal } from '@uintel/ui-component-library'
import {
  informationLayersContainer,
  informationLayerContent,
  informationInternalAccordionIcon,
  informationLayersFormAccordions,
  informationLayersFormInternalAccordion,
} from './InformationLayersForm.styles'
import { InformationDialogData } from '../AddLayersDialog.types'
import { UiclAccordion } from '@src/components/Atoms/Accordion/UiclAccordion'
import { firstLetterToUpperCase } from '@src/utils/strings.utils'
import { Tooltip } from '@src/components/Atoms/Tooltip/Tooltip'
import { autocompleteFields } from '../AddLayersDialog.styles'

export type InformationLayersFormProps = {
  selectedInformation: MapLayer[]
  informationDialogData: InformationDialogData
  onSelectInformationLayer: (informationLayer: MapLayer) => void
  onRemoveLayer: (layer: MapLayer, type: MapLayerType) => void
}

const flattenInfoData = (passedInfoData: InformationDialogData): MapLayer[] => {
  const toReturn: MapLayer[] = []
  const handledIds: { [key: string]: boolean } = {}

  Object.keys(passedInfoData).map((infoMenuKey) => {
    if (!(infoMenuKey in passedInfoData)) return
    if (!('groups' in passedInfoData[infoMenuKey])) return
    if (!passedInfoData[infoMenuKey].groups.length) return

    passedInfoData[infoMenuKey].groups.map((group) => {
      group.layers.map((infoDetail) => {
        if (handledIds[infoDetail.tilesets[0].id]) return

        toReturn.push(infoDetail)
        handledIds[infoDetail.tilesets[0].id] = true
      })
    })
  })

  toReturn.sort()
  return toReturn
}

export const InformationLayersForm = ({
  selectedInformation,
  informationDialogData,
  onSelectInformationLayer,
  onRemoveLayer,
}: InformationLayersFormProps) => {
  const theme = useTheme()
  const flatInfoList = flattenInfoData(informationDialogData)

  const [searchTerm, setSearchTerm] = useState('')
  const [searchFoundMapLayer, setSearchFoundMapLayer] = useState<MapLayer | null>(null)
  const [informationDetailsModal, setInformationDetailsModal] = useState({
    isOpen: false,
    helpFileName: '',
  })

  // filters flatInfoList and searchTerm to only show the contextual layers that match the search term
  const searchedInfoLayers = useMemo(() => {
    return flatInfoList.filter((infoLayer) => {
      return infoLayer.display_name.toLowerCase().includes(searchTerm.toLowerCase())
    })
  }, [flatInfoList, searchTerm])

  const isInfoLayerSelected = (selectedInformationLayer: MapLayer) => {
    const isAlreadySelected = selectedInformation.some(
      (infoLayer) => infoLayer.id === selectedInformationLayer.id,
    )
    return isAlreadySelected
  }

  const handleAddInformationLayer = (informationLayer: MapLayer) => {
    const foundInfoLayers = selectedInformation.find((infoLayer) => {
      return infoLayer.id === informationLayer.id
    })
    if (foundInfoLayers) {
      onRemoveLayer(informationLayer, 'information')
      return
    }
    const formattedInformationLayer = {
      ...informationLayer,
      visible: true,
      interactivityDisabled: false,
      layerType: 'information' as MapLayerType,
    }
    onSelectInformationLayer(formattedInformationLayer)
  }
  // Ungrouped key should be last
  const sortedInformationDialogKeys = useMemo(
    () =>
      Object.keys(informationDialogData).sort((a, b) => {
        if (a === 'Ungrouped') return 1
        if (b === 'Ungrouped') return -1
        return 0
      }),
    [informationDialogData],
  )

  if (!flatInfoList.length && searchedInfoLayers.length === 0) {
    return (
      <Typography variant="subtitle2" align="center" marginTop={1}>
        No contextual layers available
      </Typography>
    )
  }

  return (
    <>
      <Box css={informationLayersContainer({ theme })}>
        <Autocomplete
          css={autocompleteFields({ theme })}
          autoHighlight
          freeSolo // Stops making it look like a drop down however this means values can either be MapLayers or strings
          clearOnBlur
          inputValue={searchTerm} // Because of freeSolo the inputValue is a string
          value={searchFoundMapLayer} // while the value itself can be a string or MapLayer
          options={flatInfoList}
          isOptionEqualToValue={(option, value) =>
            option.id == value.id && option.type == value.type
          }
          getOptionLabel={(option) => (typeof option == 'string' ? option : option.display_name)}
          getOptionKey={(option) => (typeof option == 'string' ? option : option.id)}
          renderInput={(params) => (
            <TextField {...params} label="Search" placeholder="Search" autoComplete="off" />
          )}
          renderOption={(props, option, _state, ownerState) => {
            const { key, ...optionProps } = props
            return (
              <li key={key} {...optionProps}>
                {isInfoLayerSelected(option) ? (
                  <b>{ownerState.getOptionLabel(option)}</b>
                ) : (
                  ownerState.getOptionLabel(option)
                )}
              </li>
            )
          }}
          onChange={(_event, infoLayer) => {
            if (infoLayer && typeof infoLayer !== 'string') {
              // We found an actual MapLayer and because of freeSolo
              // it's not some random string which doesn't match anything
              handleAddInformationLayer(infoLayer)
              setSearchFoundMapLayer(null)
            }
          }}
          onInputChange={(_e, newInputValue, reason) => {
            // Clear the input when an item is selected
            if (reason == 'reset') setSearchTerm('')
            else setSearchTerm(newInputValue) // without this the text field doesn't update when typing
          }}
        />
        <Box css={informationLayerContent()}>
          {searchTerm.length === 0 ? (
            <Box css={informationLayersFormAccordions({ theme })}>
              {sortedInformationDialogKeys.map((informationMenuKey) => {
                const isAllUngrouped =
                  informationMenuKey === 'Ungrouped' &&
                  informationDialogData[informationMenuKey].groups.length === 1 &&
                  informationDialogData[informationMenuKey].groups[0].groupName === 'Ungrouped'
                const group = isAllUngrouped
                  ? informationDialogData[informationMenuKey].groups[0]
                  : null
                return isAllUngrouped && group ? (
                  <List key={group.groupName} disablePadding>
                    {group.layers.map((information) => {
                      return (
                        <ListItemButton
                          selected={isInfoLayerSelected(information)}
                          key={information.tilesets[0].id}
                          onClick={() => handleAddInformationLayer(information)}
                        >
                          <ListItemIcon css={informationInternalAccordionIcon}>
                            <IconTypography
                              icon={firstLetterToUpperCase(information.icon || '') as Icon}
                            />
                          </ListItemIcon>
                          <ListItemText
                            sx={{ paddingLeft: theme.spacing(1) }}
                            primary={information.display_name}
                          />
                          <ListItemIcon css={informationInternalAccordionIcon}>
                            {information.helpFileName && (
                              <Tooltip title={information.display_name}>
                                <IconButton
                                  size="small"
                                  onClick={(e) => {
                                    e.stopPropagation()
                                    setInformationDetailsModal({
                                      isOpen: true,
                                      helpFileName: information.helpFileName,
                                    })
                                  }}
                                >
                                  <Icon
                                    iconName="Info"
                                    colour={theme.palette.grey[500]}
                                    size="small"
                                  />
                                </IconButton>
                              </Tooltip>
                            )}
                          </ListItemIcon>
                        </ListItemButton>
                      )
                    })}
                  </List>
                ) : (
                  <UiclAccordion
                    key={informationMenuKey}
                    lazyContent
                    header={
                      <IconTypography icon={informationDialogData[informationMenuKey].icon}>
                        {informationDialogData[informationMenuKey].title}
                      </IconTypography>
                    }
                    body={
                      <Box css={informationLayersFormInternalAccordion({ theme })}>
                        {informationDialogData[informationMenuKey].groups.map((group) =>
                          // items that are ungrouped are displayed without an accordion
                          group.groupName === 'Ungrouped' ? (
                            <List key={group.groupName} disablePadding>
                              {group.layers.map((information) => {
                                return (
                                  <ListItemButton
                                    selected={isInfoLayerSelected(information)}
                                    key={information.tilesets[0].id}
                                    onClick={() => handleAddInformationLayer(information)}
                                  >
                                    <ListItemIcon css={informationInternalAccordionIcon}>
                                      <IconTypography
                                        icon={
                                          firstLetterToUpperCase(information.icon || '') as Icon
                                        }
                                      />
                                    </ListItemIcon>
                                    <ListItemText
                                      sx={{ paddingLeft: theme.spacing(1) }}
                                      primary={information.display_name}
                                    />
                                    <ListItemIcon css={informationInternalAccordionIcon}>
                                      {information.helpFileName && (
                                        <Tooltip title={information.display_name}>
                                          <IconButton
                                            size="small"
                                            onClick={(e) => {
                                              e.stopPropagation()
                                              setInformationDetailsModal({
                                                isOpen: true,
                                                helpFileName: information.helpFileName,
                                              })
                                            }}
                                          >
                                            <Icon
                                              iconName="Info"
                                              colour={theme.palette.grey[500]}
                                              size="small"
                                            />
                                          </IconButton>
                                        </Tooltip>
                                      )}
                                    </ListItemIcon>
                                  </ListItemButton>
                                )
                              })}
                            </List>
                          ) : (
                            <UiclAccordion
                              key={group.groupName}
                              header={
                                <IconTypography
                                  css={informationLayersFormInternalAccordion({ theme })}
                                  icon={group.icon}
                                >
                                  {group.groupName}
                                </IconTypography>
                              }
                              body={
                                <List disablePadding>
                                  {group.layers.map((information) => {
                                    return (
                                      <ListItemButton
                                        selected={isInfoLayerSelected(information)}
                                        key={information.tilesets[0].id}
                                        onClick={() => handleAddInformationLayer(information)}
                                      >
                                        <ListItemIcon css={informationInternalAccordionIcon}>
                                          <IconTypography
                                            icon={
                                              firstLetterToUpperCase(information.icon || '') as Icon
                                            }
                                          />
                                        </ListItemIcon>
                                        <ListItemText
                                          sx={{ paddingLeft: theme.spacing(1) }}
                                          primary={information.display_name}
                                        />
                                        <ListItemIcon css={informationInternalAccordionIcon}>
                                          {information.helpFileName && (
                                            <Tooltip title={information.display_name}>
                                              <IconButton
                                                size="small"
                                                onClick={(e) => {
                                                  e.stopPropagation()
                                                  setInformationDetailsModal({
                                                    isOpen: true,
                                                    helpFileName: information.helpFileName,
                                                  })
                                                }}
                                              >
                                                <Icon
                                                  iconName="Info"
                                                  colour={theme.palette.grey[500]}
                                                  size="small"
                                                />
                                              </IconButton>
                                            </Tooltip>
                                          )}
                                        </ListItemIcon>
                                      </ListItemButton>
                                    )
                                  })}
                                </List>
                              }
                            ></UiclAccordion>
                          ),
                        )}
                      </Box>
                    }
                  ></UiclAccordion>
                )
              })}
            </Box>
          ) : (
            // iterate over searchedInfoLayers without an accordion
            <Box css={informationLayersFormAccordions({ theme })}>
              <List disablePadding>
                {searchedInfoLayers.map((information) => {
                  return (
                    <ListItemButton
                      selected={isInfoLayerSelected(information)}
                      key={information.tilesets[0].id}
                      onClick={() => handleAddInformationLayer(information)}
                    >
                      <ListItemIcon css={informationInternalAccordionIcon}>
                        <IconTypography icon={firstLetterToUpperCase(information.icon) as Icon} />
                      </ListItemIcon>
                      <ListItemText
                        sx={{ paddingLeft: theme.spacing(1) }}
                        primary={information.display_name}
                      />
                      <ListItemIcon css={informationInternalAccordionIcon}>
                        {information.helpFileName && (
                          <Tooltip title={information.display_name}>
                            <IconButton
                              size="small"
                              onClick={(e) => {
                                e.stopPropagation()
                                setInformationDetailsModal({
                                  isOpen: true,
                                  helpFileName: information.helpFileName,
                                })
                              }}
                            >
                              <Icon iconName="Info" colour={theme.palette.grey[500]} size="small" />
                            </IconButton>
                          </Tooltip>
                        )}
                      </ListItemIcon>
                    </ListItemButton>
                  )
                })}
              </List>
            </Box>
          )}
        </Box>
      </Box>
      {informationDetailsModal.isOpen &&
        createPortal(
          <MoreInformationModal
            baseURL={BASE_API_URL}
            helpFileName={informationDetailsModal.helpFileName}
            isOpen={informationDetailsModal.isOpen}
            handleClose={() =>
              setInformationDetailsModal({
                isOpen: false,
                helpFileName: '',
              })
            }
          />,
          document.body,
        )}
    </>
  )
}
