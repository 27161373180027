export const BuildingIcon = ({ ...rest }) => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M24.2 25.4V3H5.8V25.4H3V27H27V25.4H24.2ZM22.6 25.4H15.8V22.2H14.2V25.4H7.4V4.6H22.6V25.4Z"
        fill="currentColor"
      />
      <path d="M11.7997 17.4H10.1997V19H11.7997V17.4Z" fill="currentColor" />
      <path d="M15.8002 17.4H14.2002V19H15.8002V17.4Z" fill="currentColor" />
      <path d="M19.8002 17.4H18.2002V19H19.8002V17.4Z" fill="currentColor" />
      <path d="M11.7997 12.6H10.1997V14.2H11.7997V12.6Z" fill="currentColor" />
      <path d="M15.8002 12.6H14.2002V14.2H15.8002V12.6Z" fill="currentColor" />
      <path d="M19.8002 12.6H18.2002V14.2H19.8002V12.6Z" fill="currentColor" />
      <path d="M11.7997 7.8H10.1997V9.4H11.7997V7.8Z" fill="currentColor" />
      <path d="M15.8002 7.8H14.2002V9.4H15.8002V7.8Z" fill="currentColor" />
      <path d="M19.8002 7.8H18.2002V9.4H19.8002V7.8Z" fill="currentColor" />
    </svg>
  )
}

export default BuildingIcon
