import { css } from '@emotion/react'
import { Theme } from '@mui/material/styles'

export const fullRiskSummary = ({ theme }: { theme: Theme }) => css`
  padding: ${theme.spacing(6)} ${theme.spacing(8)};
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing(2)};
  flex-grow: 1;
  overflow: auto;

  .MuiOutlinedInput-root {
    fieldset {
      border-color: #0b294820;
    }
    .MuiChip-filled {
      background-color: #0b294810;
      color: #0b2948;

      .MuiSvgIcon-root {
        color: #0b294840;
        transition: color 0.1s;

        &:hover {
          color: #0b2948;
        }
      }
    }
  }
`

export const filtersContainer = ({ theme }: { theme: Theme }) => css`
  display: flex;
  gap: ${theme.spacing(2)} ${theme.spacing(8)};
  flex-direction: row-reverse;
  flex-wrap: wrap;
  align-items: center;
  color: #2b455f;

  p {
    font-weight: 600;
    font-size: 12px;
  }

  .MuiFormGroup-root {
    display: flex;
    gap: ${theme.spacing(2)};
    align-items: center;
  }

  .MuiOutlinedInput-root {
    font-size: 12px;
    font-weight: 500;
    color: #2b455f;
    div[role='combobox'] {
      padding: ${theme.spacing(1)} ${theme.spacing(9)} ${theme.spacing(1)} ${theme.spacing(2)};
    }
    fieldset {
      border-color: #2b455f20;
    }
  }

  .MuiFormControl-root {
    width: 100%;
    max-width: 300px;
  }

  .MuiToggleButtonGroup-root {
    .MuiToggleButton-root {
      border: 1px solid #d4d9de;
      color: #0b2948;
      background-color: #fff8;
      padding: ${theme.spacing(1)} ${theme.spacing(2)};
      font-weight: 500;
      font-size: 12px;
      line-height: 1.25;
      text-transform: none;

      &.Mui-selected {
        background-color: #00455f0b;
      }
    }
  }
`

export const tableContainer = ({ theme }: { theme: Theme }) => css`
  width: 100%;
  overflow: auto;
  height: min-content;
  flex-grow: 1;
  flex-direction: column;
  display: flex;
  margin-top: ${theme.spacing(4)};

  > div {
    overflow: auto;
    flex-grow: 1;
  }

  th {
    line-height: 1.4;
  }

  td:nth-of-type(2n + 1) {
    background-color: ${theme.palette.primary.main}04;
  }
  td:first-of-type {
    background-color: ${theme.palette.primary.main}00;
    border-right: 1px solid ${theme.palette.primary.main}20;
    font-weight: 500;
    color: ${theme.palette.primary.main};
  }

  tr:last-of-type td {
    color: ${theme.palette.primary.main};
    font-weight: 600;
    border-top: 1px solid ${theme.palette.primary.main}40;
    border-bottom: none;
    padding-top: ${theme.spacing(2)};
    position: relative;

    &:before {
      content: '';
      display: block;
      border-top: 1px solid ${theme.palette.primary.main}20;
      width: 100%;
      position: absolute;
      top: 1px;
      left: 0;
    }
  }
`

export const dataGridContainer = ({ theme }: { theme: Theme }) => css`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing(4)};
  color: #0b2948;
  border: none;
  padding-bottom: ${theme.spacing(1)};
  flex-grow: 0;
  flex-shrink: 0;

  .MuiDataGrid-columnSeparator {
    opacity: 0;
    bottom: 0;
    height: 200%;
  }
  .MuiDataGrid-rowSeparator {
    display: none;
  }
  .MuiDataGrid-columnHeader:not([aria-colindex='1']) {
    border-left: 1px solid #0b294830;
    div {
      flex-direction: row;
      font-weight: 600;
    }
  }
  .MuiDataGrid-columnHeader--last {
    border-right: 1px solid #0b294830;
  }
  [aria-rowindex='1'] .MuiDataGrid-columnHeader:not([aria-colindex='1']) {
    border-bottom: 1px solid #0b294830;
    border-top: 1px solid #0b294830;
    height: auto !important;
    padding: ${theme.spacing(1)} ${theme.spacing(2)};

    .MuiDataGrid-columnHeaderTitleContainer {
      border-bottom: none;
      .MuiDataGrid-columnHeaderTitle {
        white-space: normal;
        line-height: 1.4;
      }
    }
  }
  [aria-rowindex='2'] .MuiDataGrid-columnHeader {
    border-bottom: 2px solid #0b294830;
    max-height: 32px;
    div {
      font-weight: 500;
    }
  }
  [aria-colindex='1'].MuiDataGrid-cell {
    font-weight: 500;
  }
  .MuiDataGrid-cell {
    border-color: #0b294830;
  }
  .MuiDataGrid-cell:not([aria-colindex='1']) {
    border-left: none;
    background-color: #0b294804;
    text-align: left;
  }
  .MuiDataGrid-cell:nth-of-type(2n + 1):not([aria-colindex='1']) {
    background-color: #0b294808;
  }
  .MuiDataGrid-cell.first-in-group {
    border-left: 1px solid #0b294830;
  }
  .MuiDataGrid-filler {
    --rowBorderColor: #0b294830 !important;
  }
  [aria-colindex='1'].MuiDataGrid-cell {
    border-left: 1px solid #0b294830;
  }

  div {
    &::-webkit-scrollbar {
      height: 8px !important;
      width: 8px !important;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #0b2948a0 !important;
      border-radius: 2px !important;
    }

    &::-webkit-scrollbar-track {
      background-color: #0b294810 !important;
      border-radius: 2px !important;
    }
  }

  .heat-0 {
    box-shadow: inset 0 0 0 1000px #0b397802;
  }
  .heat-1 {
    box-shadow: inset 0 0 0 1000px #0b397804;
  }
  .heat-2 {
    box-shadow: inset 0 0 0 1000px #0b397806;
  }
  .heat-3 {
    box-shadow: inset 0 0 0 1000px #0b397808;
  }
  .heat-4 {
    box-shadow: inset 0 0 0 1000px #0b39780a;
  }
  .heat-5 {
    box-shadow: inset 0 0 0 1000px #0b39780c;
  }
  .heat-6 {
    box-shadow: inset 0 0 0 1000px #0b39780e;
  }
  .heat-7 {
    box-shadow: inset 0 0 0 1000px #0b397810;
  }
  .heat-8 {
    box-shadow: inset 0 0 0 1000px #0b397812;
  }
  .heat-9 {
    box-shadow: inset 0 0 0 1000px #0b397814;
  }
  .heat-10 {
    box-shadow: inset 0 0 0 1000px #0b397816;
  }
`

export const tableHead = ({ theme }: { theme: Theme }) => css`
  th {
    font-weight: 600;
    color: ${theme.palette.primary.dark};
    border-bottom: 1px solid ${theme.palette.primary.dark}20;
    vertical-align: bottom;
  }

  tr:last-of-type {
    border-bottom: 2px solid ${theme.palette.primary.dark}40;
    th {
      font-weight: 500;
    }
  }
`
