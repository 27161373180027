import { css } from '@emotion/react'
import { Theme } from '@mui/material/styles'

export const signInModalContainer = ({ theme }: { theme: Theme }) => css`
  padding: ${theme.spacing(4)};

  min-width: 420px;

  display: flex;
  flex-direction: column;

  & .MuiStepConnector-root {
    flex: inherit;
    width: 40px;
  }
`

export const stepper = ({ theme }: { theme: Theme }) => css`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: ${theme.spacing(4)};
`

export const formWrapper = css`
  width: 100%;
`

export const stepperDivider = ({ theme }: { theme: Theme }) => css`
  opacity: 0.1;
  margin: ${theme.spacing(4, 0)};
`

export const modalLogo = ({ theme }: { theme: Theme }) => css`
  height: 50px;
  margin: ${theme.spacing(3)};
`

export const emptyBox = css`
  flex: 1 1 auto;
`

export const finalStep = ({ theme }: { theme: Theme }) => css`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${theme.spacing(2)};
  padding: ${theme.spacing(8)} 0;
`

export const passwordContainer = css`
  position: relative;
  width: 100%;
`

export const showPasswordButton = ({ theme }: { theme: Theme }) => css`
  position: absolute;
  right: ${theme.spacing(4)};
  color: ${theme.palette.grey[500]};
`

export const signInMarginBottom = ({ theme }: { theme: Theme }) => css`
  margin-bottom: ${theme.spacing(4)};
`

export const stepTitle = ({ theme }: { theme: Theme }) => css`
  text-align: center;
  margin: ${theme.spacing(3, 0)};
`

export const signInButton = css`
  min-width: 120px;
`

export const signInFooter = css`
  display: flex;
`

export const signInSpinner = ({ theme }: { theme: Theme }) => css`
  color: white;
  padding: ${theme.spacing(1)};
`

export const signInBodyContent = ({ theme }: { theme: Theme }) => css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${theme.spacing(4)} 0;
`

export const logoutBodyContent = ({ theme }: { theme: Theme }) => css`
  display: flex;
  flex-direction: column;
  align-items: space-between;
  justify-content: center;
  padding: ${theme.spacing(2)};
`

export const logoutClientName = ({ theme }: { theme: Theme }) => css`
  display: flex;
  align-items: center;
  justify-content: center;

  border: 1px solid ${theme.palette.primary.main};
  border-radius: ${theme.shape.borderRadius}px;

  padding: ${theme.spacing(2)};
`

export const resetPasswordButton = css`
  ${signInButton}
  min-width: 170px;
`

export const tabFocus = ({ theme }: { theme: Theme }) => css`
  &:focus-visible {
    outline: 2px solid ${theme.palette.primary.main};
    outline-offset: -2px;
  }
`

export const logoutContainer = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const loginContainer = ({ theme }: { theme: Theme }) => css`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  gap: ${theme.spacing(4)};
  margin: ${theme.spacing(2)} 0;
`

export const forgotPasswordContainer = ({ theme }: { theme: Theme }) => css`
  gap: ${theme.spacing(1)};

  &:focus-visible {
    outline: 2px solid ${theme.palette.primary.main};
    outline-offset: 2px;
  }
`

export const organizationContainer = ({ theme }: { theme: Theme }) => css`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: ${theme.spacing(2)} 0;
`

export const passwordConfirmationContainer = css`
  width: 100%;
`

export const formControlGap = ({ theme }: { theme: Theme }) => css`
  gap: ${theme.spacing(1)};
`

export const codeAndPasswordContainer = ({ theme }: { theme: Theme }) => css`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: ${theme.spacing(2)} 0;
`

export const codeAndPasswordContent = ({ theme }: { theme: Theme }) => css`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: ${theme.spacing(4)};
  gap: ${theme.spacing(2)};
`

export const codeAndPasswordModalContainer = ({ theme }: { theme: Theme }) => css`
  width: 100%;
  padding: ${theme.spacing(3)};
`

export const AutocompleteContainer = ({ theme }: { theme: Theme }) => css`
  width: 100%;
  min-width: 200px;
  margin: ${theme.spacing(4)} 0;
`
