import { css } from '@emotion/react'
import { Theme } from '@mui/material/styles'

export const legendHeader = ({ theme }: { theme: Theme }) => css`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;

  p {
    font-size: 0.7rem;
    font-weight: bold;
    line-height: 1.2;
  }

  svg {
    height: ${theme.spacing(4)};
    width: ${theme.spacing(4)};
    padding-right: ${theme.spacing(1)};
    opacity: 0.3;
  }
`

export const legendContent = ({ theme }: { theme: Theme }) => css`
  display: flex;
  flex-direction: column;

  flex-shrink: 0;
  overflow: hidden;
  transition: max-height 0.1s ease-in-out;
  padding-top: 1px;

  > div {
    border-top: 1px solid ${theme.palette.divider}20;
    margin-top: -1px;

    > p {
      font-size: 0.7rem;
      font-weight: 600;
      padding: ${theme.spacing(0.5)} ${theme.spacing(1)};
      color: ${theme.palette.text.primary};
      border-bottom: 1px solid ${theme.palette.divider}20;
      line-height: 1.3;
    }
  }
`

export const legendContainer = ({ theme }: { theme: Theme }) => css`
  display: flex;
  flex-direction: column;
  background-color: ${theme.palette.background.paper};
  width: ${theme.spacing(30)};
  border-radius: ${theme.spacing(1)};
  box-shadow: ${theme.shadows[1]};
  overflow: hidden;
  color: ${theme.palette.text.primary};
  cursor: pointer;
  user-select: none;
  transition: box-shadow 0.2s ease-in-out;
  pointer-events: all;

  .normal-header {
    opacity: 1;

    p {
      transform: translateX(0px);
    }
  }

  .minimize-header {
    opacity: 0;

    p {
      transform: translateX(4px);
    }
  }

  &:hover {
    box-shadow: ${theme.shadows[2]};

    .minimize-header {
      opacity: 1;
      p {
        transform: translateX(0px);
      }
    }
    .normal-header {
      opacity: 0;
      p {
        transform: translateX(-4px);
      }
    }
  }
`

export const normalHeader = ({ theme }: { theme: Theme }) => css`
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${theme.spacing(0.5)} 0;
  padding-left: ${theme.spacing(1)};
  transition: opacity 0.1s ease-in-out;

  p {
    transition: transform 0.1s ease-in-out;
  }
`

export const minimizeHeader = ({ theme }: { theme: Theme }) => css`
  ${normalHeader({ theme })}
  color: ${theme.palette.primary.main};
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  svg {
    opacity: 1;
  }

  p {
    font-weight: 500;
  }
`
