import { css } from '@emotion/react'
import { Theme } from '@mui/material/styles'

export const reportAssetRiskContainer = ({ theme }: { theme: Theme }) => css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${theme.spacing(2)};
  border-bottom: 1px solid ${theme.palette.grey[100]};
  color: ${theme.palette.primary.main};
  padding: 1px 0;
`

export const reportAssetRiskHorizontalSection = ({ theme }: { theme: Theme }) => css`
  display: flex;
  align-items: stretch;
  justify-content: start;
  flex-direction: row;
  gap: ${theme.spacing(1)};
  padding-right: ${theme.spacing(3)};
`

export const reportAssetRiskStackedSection = () => css`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 4px;
  gap: 16px 0;
`

export const chartsContainer = ({ theme }: { theme: Theme }) => css`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  align-content: center;

  gap: ${theme.spacing(6)} ${theme.spacing(2)};
  padding: ${theme.spacing(2)};

  overflow: auto;
`

export const horizontalBarChart = css`
  width: 100%;
  height: 100%;

  min-width: 20rem;

  overflow: hidden;
  position: relative;

  display: flex;
  flex-direction: column;
  gap: 1rem;

  p {
    font-weight: 800;
  }
`

export const chartContainer = () => css`
  display: flex;
  align-items: stretch;
  justify-content: center;
  flex-direction: column;
  box-sizing: border-box;
  flex-grow: 1;
  position: relative;
  min-width: 300px;
  overflow: hidden;
  min-height: 100px;
`

export const chartSizeProtector = () => css`
  width: 100%;
  height: 100%;
  position: absolute;
`

export const chartHeader = ({ theme: _ }: { theme: Theme }) => css`
  display: flex;
  align-items: stretch;
  flex-direction: column;

  p {
    font-weight: 800;
    font-size: 12px;
    color: #0b2948;
    padding-left: 48px;
  }
`

export const chartSection = ({ theme }: { theme: Theme }) => css`
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  flex-direction: column;
  gap: ${theme.spacing(1)};
  flex-grow: 1;
  min-height: 100px;
`

export const gaugeContainer = css`
  position: relative;
  color: #888;
`

export const vulnerabilityContainer = css`
  width: 100%;
  height: 15rem;

  overflow: hidden;
`

export const reportAssetRiskHazardsBox = ({ theme }: { theme: Theme }) => css`
  display: flex;
  align-items: stretch;
  justify-content: start;
  flex-direction: row;
  gap: ${theme.spacing(1)};
  padding-right: ${theme.spacing(3)};
  background-color: ${theme.palette.grey[100]};
  border-radius: ${theme.spacing(1)};
`

export const reportAssetRiskHazardsList = ({ theme }: { theme: Theme }) => css`
  display: flex;
  align-items: center;
  justify-content: start;
  flex-direction: row;
  gap: ${theme.spacing(1)};

  > p {
    background-color: ${theme.palette.grey[200]};
    border-radius: 200px;
    padding: ${theme.spacing(0.5)} ${theme.spacing(2)};
  }
`

export const fragilityCurveContainer = ({ theme }: { theme: Theme }) => css`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: ${theme.spacing(2)};
  padding: ${theme.spacing(2)};
`

export const referenceButtonsContainer = ({ theme }: { theme: Theme }) => css`
  width: 100%;

  display: flex;
  flex-wrap: wrap;
  gap: ${theme.spacing(1)};
`

export const referenceButton = css`
  flex: 1 1 auto;
`

export const buttonLinkIcon = ({ theme }: { theme: Theme }) => css`
  width: ${theme.spacing(4)};
`

export const link = css`
  text-decoration: none;
`

export const riskSummary = ({ theme }: { theme: Theme }) => css`
  color: ${theme.palette.text.primary};
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: ${theme.spacing(2)};
  flex-grow: 1;
`

export const consqMethImage = ({ theme }: { theme: Theme }) => css`
  width: 100%;
  height: auto;
  border-radius: ${theme.spacing(1)};
  margin: ${theme.spacing(2)} 0;
`

export const disclaimerText = ({ theme }: { theme: Theme }) => css`
  color: ${theme.palette.primary.dark};
  font-size: 0.75rem;
  margin-top: ${theme.spacing(4)};
  font-style: italic;
`
