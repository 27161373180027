/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import {
  Box,
  CircularProgress,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography,
} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { useCallback, useEffect, useState } from 'react'

import { PasswordInput } from '@src/components/Molecules/PasswordInput'
import { useSnackbars, validEmailRegex } from '@uintel/ui-component-library'
import { Button } from '@src/components/Atoms/Button'

import { LoadingButton, PasswordProps, ValidationInputProps } from '../SignInModal'
import {
  emptyBox,
  finalStep,
  formWrapper,
  signInBodyContent,
  signInButton,
  signInFooter,
  signInSpinner,
  stepTitle,
  stepper,
} from '../SignInModal.styles'
import { checkIfPasswordHasValidFormat } from '../SignInModal.utils'
import { BASE_API_URL } from '@src/app-constants'
import axios from '@src/utils/customAxios'
import { useCookies } from 'react-cookie'

export interface SignUpResponse {
  user_uuid: string
  clientName: string
}

interface SignUpTabProps {
  email: ValidationInputProps
  validationCode: ValidationInputProps
  password: PasswordProps
  confirmPassword: PasswordProps
  phone_number: ValidationInputProps
  preloadSignup?: { email: string; password: string }
  isLoading: boolean
  attemptLogin: () => Promise<boolean>
  handleClose: () => void
  handleSetLoading: (loading: boolean) => void
  handleChangeEmail: (email: ValidationInputProps) => void
  handleChangePhoneNumber: (phone_number: ValidationInputProps) => void
  handleChangeCode: (code: ValidationInputProps) => void
  handleChangePassword: (password: PasswordProps) => void
  handleChangeConfirmPassword: (confirmPassword: PasswordProps) => void
  applyLogin: () => Promise<void>
}

export const SignUpTab = ({
  email,
  validationCode,
  password,
  isLoading,
  confirmPassword,
  phone_number,
  attemptLogin,
  preloadSignup,
  handleClose,
  handleChangePassword,
  handleChangeConfirmPassword,
  handleChangeEmail,
  handleChangeCode,
  handleChangePhoneNumber,
  handleSetLoading,
  applyLogin,
}: SignUpTabProps) => {
  const [recentSignupEmailCookie, setRecentSignupEmailCookie] = useCookies(['recent_signup_email'])
  const [recentSignupUUIDCookie, setRecentSignupUUIDCookie] = useCookies(['recent_signup_uuid'])

  const theme = useTheme()
  const { displaySnackbar } = useSnackbars()
  const [userUuid, setUserUuid] = useState<string>(recentSignupUUIDCookie[`recent_signup_uuid`])

  const [activeStep, setActiveStep] = useState(preloadSignup ? 1 : 0)

  const steps = [
    {
      title: 'Email and Password',
      content: (
        <>
          <Typography css={stepTitle({ theme })}>
            Get started by entering your e-mail and password
          </Typography>
          <form autoComplete="on" css={formWrapper}>
            <Box
              css={css`
                width: 100%;
                display: flex;
                flex-direction: column;
                gap: ${theme.spacing(4)};
              `}
            >
              <TextField
                id="signup-email"
                name="email"
                autoComplete="on"
                variant="outlined"
                type="email"
                label="E-mail"
                value={email.value}
                error={email.error}
                helperText={email.helperText}
                onChange={(e) =>
                  handleChangeEmail({ error: false, helperText: '', value: e.target.value })
                }
                fullWidth
              />
              <TextField
                id="signup-phone"
                name="phone_number"
                autoComplete="off"
                variant="outlined"
                type="text"
                label="Phone number"
                value={phone_number.value}
                error={phone_number.error}
                helperText={phone_number.helperText}
                onChange={(e) =>
                  handleChangePhoneNumber({ error: false, helperText: '', value: e.target.value })
                }
                fullWidth
              />
              <Box
                css={css`
                  width: 100%;
                  display: flex;
                  flex-direction: column;
                  gap: ${theme.spacing(2)};
                `}
              >
                <PasswordInput
                  id="signup-password"
                  error={password.error}
                  helperTexts={password.helperTexts}
                  value={password.value}
                  handleChangePassword={handleChangePassword}
                />
                <PasswordInput
                  id="confirm-password"
                  label="Confirm password"
                  error={confirmPassword.error}
                  helperTexts={confirmPassword.helperTexts}
                  value={confirmPassword.value}
                  handleChangePassword={handleChangeConfirmPassword}
                />
              </Box>
            </Box>
          </form>
        </>
      ),
    },
    {
      title: 'Code validation',
      content: (
        <Box
          css={css`
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: ${theme.spacing(4)};
          `}
        >
          <Typography align="center">Add the confirmation code sent by email</Typography>
          <TextField
            id="confirmation-code"
            name="confirmation-code"
            autoComplete="off"
            variant="outlined"
            type="text"
            label="Code"
            value={validationCode.value}
            error={validationCode.error}
            helperText={validationCode.helperText}
            onChange={(e) =>
              handleChangeCode({ error: false, helperText: '', value: e.target.value })
            }
            fullWidth
          />
        </Box>
      ),
    },
  ]

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const handleReset = () => {
    setActiveStep(0)
  }

  const handleValidateFields = useCallback(() => {
    let validForm = true

    if (!validEmailRegex.test(email.value)) {
      handleChangeEmail({ ...email, error: true, helperText: 'Invalid email' })
      validForm = false
    }

    if (phone_number.value.length > 0) {
      const nonNumericRegex = new RegExp(/[^0-9]/g)
      const strippedPhoneNumber = phone_number.value.replaceAll(nonNumericRegex, '')
      if (strippedPhoneNumber.length < 4) {
        handleChangePhoneNumber({
          ...phone_number,
          error: true,
          helperText: 'Invalid phone number',
        })
        validForm = false
      }
    }

    const passwordErrors = checkIfPasswordHasValidFormat(password.value)

    if (passwordErrors.length) {
      handleChangePassword({ ...password, error: true, helperTexts: passwordErrors })
      validForm = false
    }

    if (password.value !== confirmPassword.value) {
      handleChangeConfirmPassword({
        ...confirmPassword,
        error: true,
        helperTexts: ['Passwords do not match'],
      })
      validForm = false
    }

    return validForm
  }, [
    confirmPassword,
    email,
    handleChangeConfirmPassword,
    handleChangeEmail,
    handleChangePassword,
    handleChangePhoneNumber,
    password,
    phone_number,
  ])

  const apiSignUp = useCallback(async () => {
    const nonNumericRegex = new RegExp(/[^0-9]/g)
    const strippedPhoneNumber = phone_number.value.replaceAll(nonNumericRegex, '')

    //Todo: This will need changing when we go multinational!
    const fullPhoneNumber = strippedPhoneNumber.length == 0 ? '' : `+64${strippedPhoneNumber}`

    try {
      const signUpResponse = await axios.post<SignUpResponse>(`${BASE_API_URL}/api/user/signup`, {
        email: email.value,
        password: password.value,
        phone_number: fullPhoneNumber,
      })

      return signUpResponse.data.user_uuid
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error)
      displaySnackbar({ message: 'Something went wrong.', type: 'error' })
    }
  }, [displaySnackbar, email.value, password.value, phone_number.value])

  const handleSubmitSignUp = useCallback(async () => {
    handleSetLoading(true)

    const validForm = handleValidateFields()

    if (recentSignupEmailCookie[`recent_signup_email`] === email.value) {
      displaySnackbar({
        message:
          'You have recently signed up with this email. Please verify the code by checking your email.',
        type: 'info',
      })
      setActiveStep((prevActiveStep) => prevActiveStep + 1)
      handleSetLoading(false)
      return
    }

    if (!validForm) {
      handleSetLoading(false)
      return
    }

    const responseUserUuid = await apiSignUp()

    if (!responseUserUuid) {
      handleSetLoading(false)
      return
    }

    setRecentSignupEmailCookie(`recent_signup_email`, email.value, {
      path: '/',
      maxAge: 60 * 60 * 24,
    })
    setRecentSignupUUIDCookie(`recent_signup_uuid`, responseUserUuid, {
      path: '/',
      maxAge: 60 * 60 * 24,
    })

    setUserUuid(responseUserUuid)
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
    displaySnackbar({ message: 'Please verify the code by checking your email.', type: 'info' })
    handleSetLoading(false)
  }, [
    apiSignUp,
    displaySnackbar,
    email,
    handleSetLoading,
    handleValidateFields,
    recentSignupEmailCookie,
    setRecentSignupEmailCookie,
    setRecentSignupUUIDCookie,
  ])

  const apiConfirmSignUpCode = useCallback(async () => {
    try {
      const confirmCodeResponse = await fetch(`${BASE_API_URL}/api/user/confirm_signup`, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({
          user_uuid: userUuid,
          email: email.value,
          confirmation_code: validationCode.value,
        }),
      })

      const isValidCode = confirmCodeResponse.status === 200
      return isValidCode
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error)
      return false
    }
  }, [email.value, userUuid, validationCode.value])

  const handleVerifyCode = useCallback(async () => {
    handleSetLoading(true)

    if (validationCode.value.match(/\D/g)) {
      handleChangeCode({ ...validationCode, error: true, helperText: 'Code must be only numbers' })
      handleSetLoading(false)
      return
    }

    if (validationCode.value.length !== 6) {
      handleChangeCode({ ...validationCode, error: true, helperText: 'Code must be 6 digits long' })
      handleSetLoading(false)
      return
    }

    const isValidCode = await apiConfirmSignUpCode()

    if (!isValidCode) {
      displaySnackbar({ message: 'Something went wrong.', type: 'error' })
      handleSetLoading(false)
      return
    }

    if (await attemptLogin()) {
      await applyLogin()
      displaySnackbar({ message: 'You have successfully signed up.', type: 'success' })
    }

    handleSetLoading(false)
    handleClose()
  }, [
    handleSetLoading,
    validationCode,
    apiConfirmSignUpCode,
    attemptLogin,
    applyLogin,
    displaySnackbar,
    handleClose,
    handleChangeCode,
  ])

  useEffect(() => {
    const handleEnterPress = (e: KeyboardEvent) => {
      if (e.key !== 'Enter') return

      if (activeStep === 0) {
        return handleSubmitSignUp()
      }

      if (activeStep === 1) {
        return handleVerifyCode()
      }
    }

    document.addEventListener('keydown', handleEnterPress)
    return () => document.removeEventListener('keydown', handleEnterPress)
  }, [email, password, confirmPassword, activeStep, handleSubmitSignUp, handleVerifyCode])

  return (
    <>
      <Stepper activeStep={activeStep} css={stepper({ theme })}>
        {steps.map((step) => {
          const stepProps: { completed?: boolean } = {}

          return (
            <Step key={step.title} {...stepProps}>
              <StepLabel>{step.title}</StepLabel>
            </Step>
          )
        })}
      </Stepper>

      {activeStep === steps.length ? (
        <>
          <Typography css={stepTitle({ theme })}>Remove this step and go back to map</Typography>
          <Box css={finalStep({ theme })}>
            <Box css={emptyBox} />

            <Button onClick={handleReset}>
              {isLoading ? (
                <CircularProgress size={28} css={signInSpinner({ theme })} />
              ) : (
                <Typography variant="button">Reset</Typography>
              )}
            </Button>
          </Box>
        </>
      ) : (
        <>
          <Box css={signInBodyContent({ theme })}>{steps[activeStep].content}</Box>
          <Box css={signInFooter}>
            {activeStep !== 0 && (
              <Button variant="outlined" onClick={handleBack}>
                Return
              </Button>
            )}
            <Box css={emptyBox} />

            {activeStep === steps.length - 1 ? (
              <Button onClick={handleVerifyCode} css={signInButton}>
                {isLoading ? <LoadingButton /> : <Typography variant="button">Verify</Typography>}
              </Button>
            ) : (
              <Button onClick={handleSubmitSignUp} fullWidth>
                {isLoading ? <LoadingButton /> : <Typography variant="button">Sign Up</Typography>}
              </Button>
            )}
          </Box>
        </>
      )}
    </>
  )
}
