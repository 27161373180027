import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { DataPoint } from '@src/components/Molecules/Charts/ProjectedExposureChart/ProjectedExposureChart'

export interface ProjectedExposureChartState {
  data: DataPoint[] | null
  parameter: string
  parameterTitle: string
  x_unit: string
  y_unit: string | null
}

export interface ProjectedExposureChartStateByHazardId {
  [hazard_id: string]: ProjectedExposureChartState
}

const initialState: ProjectedExposureChartStateByHazardId = {}

export const projectedExposureChartStateSlice = createSlice({
  name: 'projectedExposureChartStateSlice',
  initialState,
  reducers: {
    setProjectedExposureChartState: (
      state,
      action: PayloadAction<{
        hazard_id: string
        projectedExposureChartState: ProjectedExposureChartState
      }>,
    ) => {
      state[action.payload.hazard_id] = action.payload.projectedExposureChartState
      return state
    },
  },
})

export const { setProjectedExposureChartState } = projectedExposureChartStateSlice.actions
export const projectedExposureChartStateReducer = projectedExposureChartStateSlice.reducer
