import { css } from '@emotion/react'
import { Theme } from '@mui/material'

export const innerHtmlParentContainer = css`
  width: 100%;
  display: flex;
  flex-direction: column;
`

export const titlePlusMinusParentContainer = ({
  theme,
  disallowed,
}: {
  theme: Theme
  disallowed: boolean
}) => css`
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 8px;
  color: ${disallowed ? theme.palette.text.disabled : theme.palette.primary.dark};
`

export const disallowedStyling = ({
  theme,
  disallowed,
}: {
  theme: Theme
  disallowed: boolean
}) => css`
  color: ${disallowed ? theme.palette.text.disabled : theme.palette.primary.dark};
`
