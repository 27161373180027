import { updateMapStateProperty } from '@redux/map/mapSlice'
import { AnyAction, Dispatch } from '@reduxjs/toolkit'

export interface ChangeMapStyleProps {
  style: string
  dispatch: Dispatch<AnyAction>
}

export function handleChangeMapStyle({ style, dispatch }: ChangeMapStyleProps) {
  dispatch(updateMapStateProperty({ style }))
}
