/* Layout */
export const SIDEBAR_MENU_WIDTH = 68
export const SIDE_DRAWER_WIDTH = 20
export const TOP_NAVBAR_HEIGHT = 48

/* Mapbox */
export const BASE_MAP_LINZ =
  'mapbox://styles/urbanintelligence/cljgef8p2002t01r7fr970avz?optimize=true'
export const BASE_MAP_LIGHT_WITH_HOUSE_NUMBERS =
  'mapbox://styles/urbanintelligence/cm3cgoyko002o01rg46ofhud7?optimize=true'
export const BASE_MAP_CONTOURS = 'mapbox://styles/mapbox/outdoors-v12?optimize=true'

export const REGION_ZOOM_LEVEL = 11
export const NEW_ZEALAND_ZOOM_LEVEL = 5.7
export const NEW_ZEALAND_LAT = -41.1220914
export const NEW_ZEALAND_LGT = 171.6418355

/* API */
export const BASE_API_URL = import.meta.env.VITE_LOCAL_BASE_URL
  ? import.meta.env.VITE_LOCAL_BASE_URL
  : ''

/* Kotare */
export const BASE_KOTARE_URL = import.meta.env.VITE_LOCAL_KOTARE_URL
  ? import.meta.env.VITE_LOCAL_KOTARE_URL
  : ''

/* Validation */
export const validEmailRegex = new RegExp(
  // eslint-disable-next-line no-useless-escape
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
)

export const PUBLIC_ASSETS_BASE_URL = import.meta.env.VITE_PUBLIC_ASSETS_BASE_URL
  ? import.meta.env.VITE_PUBLIC_ASSETS_BASE_URL
  : 'https://d2ufl17mx4sema.cloudfront.net/public'

export * from '../constants'
