/** @jsxImportSource @emotion/react */
import { Box, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'

import {
  reviewContainer,
  reviewContentContainer,
  reviewImage,
  wipImageContainer,
  wipTitle,
} from './Review.styles'

import { Navigate } from 'react-router-dom'
import wipImage from '/images/illustrations/wip.svg'
import scalesReviewImage from '/images/pictures/wip-review.png'

export const Review = () => {
  const theme = useTheme()

  // TODO: remove this logic when data is ready for client
  const isReadyForScales = false
  if (!isReadyForScales) {
    return <Navigate to="/strategy/horizon-scan" />
  }

  return (
    <Box css={reviewContainer({ theme })}>
      <Box css={reviewContentContainer({ theme })}>
        <Box component="img" src={scalesReviewImage} css={reviewImage({ theme })} />
        <Box css={wipImageContainer({ theme })}>
          <Typography css={wipTitle({ theme })} variant="h5">
            Work in progress
          </Typography>
          <Box component="img" src={wipImage} />
        </Box>
      </Box>
    </Box>
  )
}
