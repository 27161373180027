/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { Theme } from '@mui/material/styles'

export const mapOverlay = () => css`
  z-index: 10;

  pointer-events: none;

  > button {
    pointer-events: all;
  }
`

export const layersControlContent = ({ theme }: { theme: Theme }) => css`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: start;
  gap: ${theme.spacing(3)};
`

export const hazardContent = ({ theme }: { theme: Theme }) => css`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: start;
  gap: ${theme.spacing(3)};
`

export const hazardLegend = ({ theme }: { theme: Theme }) => css`
  display: flex;
  align-items: start;
  justify-content: start;
  flex-direction: column;
  gap: ${theme.spacing(1)};
  margin-bottom: ${theme.spacing(2)};
`

export const elementFilterForm = () => css`
  display: flex;
  flex-direction: column;
`
