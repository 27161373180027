import { css } from '@emotion/react'

export const legendRowMainClass = () => {
  return css`
    margin: 0;
    display: flex;
    flex-direction: row;
    align-content: center;
    line-height: 1.2em;
    align-items: stretch;
    padding-right: 4px;

    p {
      line-height: 1.2em;
      padding: 2px 0;
      font-size: 0.7rem;
    }
  `
}
