export const MonitorIcon = ({ ...rest }) => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M26.25 2.5H3.75C3.4186 2.50039 3.10088 2.63221 2.86654 2.86654C2.63221 3.10088 2.50039 3.4186 2.5 3.75V21.25C2.50039 21.5814 2.63221 21.8991 2.86654 22.1335C3.10088 22.3678 3.4186 22.4996 3.75 22.5H14.1667V25.8333H10V27.5H20V25.8333H15.8333V22.5H26.25C26.5814 22.4996 26.8991 22.3678 27.1335 22.1335C27.3678 21.8991 27.4996 21.5814 27.5 21.25V3.75C27.4996 3.4186 27.3678 3.10088 27.1335 2.86654C26.8991 2.63221 26.5814 2.50039 26.25 2.5ZM25.8333 20.8333H4.16667V15.8333H25.8333V20.8333ZM25.8333 14.1667H4.16667V4.16667H25.8333V14.1667Z"
        fill="currentColor"
      />
      <path d="M24.1667 17.4996H22.5V19.1663H24.1667V17.4996Z" fill="currentColor" />
    </svg>
  )
}

export default MonitorIcon
