/** @jsxImportSource @emotion/react */

import { Box, useTheme } from '@mui/material'
import {
  sideDrawerContent,
  sideDrawerContentContainer,
  sideDrawerContentContainerParent,
} from './SideDrawerContent.styles'
import { useSelector } from 'react-redux'
import { RootState } from '@redux/store'
import { InformativePanel } from './tabs'
import { StoriesTab } from './tabs/StoriesTab/StoriesTab'
import { useEffect, useState } from 'react'

export const SideDrawerContent = () => {
  const theme = useTheme()

  const { tab } = useSelector((state: RootState) => state.sideDrawer)

  const [contentComponent, setContentComponent] = useState<React.ReactNode>(null)

  useEffect(() => {
    switch (tab) {
      case 'Risk':
        setContentComponent(<InformativePanel css={sideDrawerContent} />)
        break
      case 'Stories':
        setContentComponent(<StoriesTab css={sideDrawerContent} />)
        break
      default:
        setContentComponent(<InformativePanel css={sideDrawerContent} />)
    }
  }, [tab])

  return (
    <Box css={sideDrawerContentContainerParent({ theme })}>
      <Box css={sideDrawerContentContainer({ theme })}>{contentComponent}</Box>
    </Box>
  )
}
