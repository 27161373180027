import { css, keyframes } from '@emotion/react'
import { Theme } from '@mui/material'

const fadein = keyframes`
  from { opacity: 0; top: 10px }
  to   { opacity: 1; top: 0px }
`

export const storyMenuItemContainer = () => css`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`

export const imageContainer = css`
  overflow: hidden;
  position: relative;
  max-width: 160px;
  flex-shrink: 0;

  img {
    height: 100%;
    object-fit: cover;
    width: 100%;
  }
`

export const contentContainer = (theme: Theme) => css`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  gap: ${theme.spacing(8)};
  flex-grow: 1;
  position: relative;
  align-items: center;
  background-color: ${theme.palette.white.main};
  border-radius: ${theme.spacing(4)};
`

export const textContainer = (theme: Theme) => css`
  flex-grow: 1;
  color: ${theme.palette.text.primary};
  align-self: stretch;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: ${theme.spacing(4)};
  padding: ${theme.spacing(4)};

  p {
    line-height: 1.3em;
  }
`

export const playButton = (theme: Theme) => css`
  background-color: ${theme.palette.primary.main};
  flex-shrink: 0;
  border-radius: ${theme.spacing(2)};
  box-shadow: ${theme.shadows[4]};
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
  transition: background-color 0.1s, box-shadow 0.1s, max-width 0.1s;
  gap: ${theme.spacing(4)};
  flex-grow: 0;
  position: absolute;
  bottom: ${theme.spacing(2)};
  right: ${theme.spacing(2)};
  height: ${theme.spacing(8)};
  box-sizing: initial;
  max-width: ${theme.spacing(4)};
  z-index: 100;

  color: white;
  white-space: nowrap;
  padding: ${theme.spacing(2)} ${theme.spacing(4)};

  p {
    font-weight: 500;
    display: inline-block;
    opacity: 0;
    transition: opacity 0.1s, transform 0.1s;
    transform: translateX(8px);
  }
`

export const headerContainer = (theme: Theme) => css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${theme.spacing(2)} ${theme.spacing(4)};
  background-color: ${theme.palette.white.main};
  color: ${theme.palette.primary.main};
  border-bottom: 1px solid ${theme.palette.primary.main}20;

  h6 {
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.4em;
  }
`

export const footerContainer = (theme: Theme) => css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  padding: ${theme.spacing(2)} ${theme.spacing(4)};
  background-color: ${theme.palette.white.main};
  color: ${theme.palette.primary.main};
  font-size: 0.8rem;
  border-top: 1px solid ${theme.palette.primary.main}20;
  border-radius: 0 0 ${theme.spacing(1)} ${theme.spacing(1)};
  height: ${theme.spacing(6)};
  box-sizing: initial;
`

export const storyMenuItemParent = (theme: Theme) => css`
  display: flex;
  align-items: stretch;
  animation: ${fadein} 0.3s ease;
  border-top: 2px solid ${theme.palette.primary.main}20;
  position: relative;

  cursor: pointer;
  transition: box-shadow 0.1s;

  :hover {
    .play-button {
      background-color: ${theme.palette.primary.dark};
      box-shadow: ${theme.shadows[4]};
      max-width: ${theme.spacing(18)};

      p {
        opacity: 1;
        transform: translateX(0);
      }
    }

    .image-overlay {
      left: -80%;
    }

    .inner-image-overlay {
      left: -95%;
    }
  }
`

export const storyMenuItemRow = (theme: Theme) => css`
  display: flex;
  align-items: stretch;
  position: relative;
  background-color: ${theme.palette.white.main};
  flex-grow: 1;
  position: relative;
`

export const imageOverlay = (theme: Theme) => css`
  position: absolute;
  width: 100%;
  height: 100%;
  left: -100%;
  background-color: ${theme.palette.background.paper};
  z-index: 1;
  transform: skewX(10deg);
  transform-origin: 0 0%;
  transition: left 0.1s ease-in-out;
`
