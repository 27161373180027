import { css } from '@emotion/react'
import { Theme } from '@mui/material/styles'

export const layersOrganizerContainer = ({ theme }: { theme: Theme }) => css`
  height: 100%;
  display: flex;
  flex-direction: column;

  gap: ${theme.spacing(3)};
`
export const accordionContentContainer = ({ theme }: { theme: Theme }) => css`
  min-height: 100%;
  overflow: auto;

  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: start;

  padding-top: ${theme.spacing(1)};
  gap: ${theme.spacing(2)};
`

export const hiddenLayerTooltip = ({ theme }: { theme: Theme }) => css`
  display: flex;
  align-items: center;
  justify-content: center;

  transition: background-color 0.2s;

  border-radius: ${theme.spacing(5)};
  padding: ${theme.spacing(1)};

  & :hover {
    background-color: ${theme.palette.grey[100] + '40'};
  }
`

export const emptyAccordion = css`
  min-height: 30px;
  height: 0;
`

export const accordionInfoContentContainer = ({ theme }: { theme: Theme }) => css`
  min-height: ${theme.spacing(9)};

  overflow: auto;

  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: start;
`

export const draggableAccordionTitle = css`
  display: flex;
  align-items: center;
  justify-content: start;

  width: 100%;
`

export const accordionHazards = ({ theme }: { theme: Theme }) => css`
  .MuiAccordionDetails-root {
    background-color: ${theme.palette.white.main};
    padding: 0;
  }
`
