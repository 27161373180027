/** @jsxImportSource @emotion/react */
import { useTheme } from '@mui/material/styles'
import { dataDrivenBlocks, iconFooterBox } from './DataDrivenBlocks.styles'
import { Box } from '@mui/material'
import { BannerBlock, BannerBlockProps } from './BannerBlock'
import { SideImageBlock, SideImageBlockProps } from './SideImageBlock'
import { ContentBlock, ContentBlockProps } from './ContentBlock'
import { SideBoxBlockProps } from './SideBoxBlock'
import { SideBoxBlock } from './SideBoxBlock'
import { PUBLIC_ASSETS_BASE_URL } from '@src/app-constants'

export type ButtonInBlockProps = {
  text: string
  url: string
}

export type DataDrivenBlock =
  | BannerBlockProps
  | SideImageBlockProps
  | ContentBlockProps
  | SideBoxBlockProps

export interface DataDrivenBlocksProps {
  blocks: DataDrivenBlock[]
  iconFooter?: boolean
}

export const DataDrivenBlocks = ({ blocks, iconFooter = false }: DataDrivenBlocksProps) => {
  const theme = useTheme()

  return (
    <Box css={dataDrivenBlocks({ theme })}>
      {blocks.map((block, index) => {
        switch (block.type) {
          case 'banner':
            return (
              <BannerBlock
                key={index}
                type={block.type}
                header={block.header}
                content={block.content}
                videoUrl={block.videoUrl}
                backgroundImageUrl={block.backgroundImageUrl}
              />
            )
          case 'side-image':
          case 'side-image-first':
          case 'side-image-filled':
          case 'side-image-filled-first':
            return (
              <SideImageBlock
                key={index}
                type={block.type}
                header={block.header}
                imageUrl={block.imageUrl}
                content={block.content}
                buttons={block.buttons}
              />
            )
          case 'content':
          case 'content-filled':
            return (
              <ContentBlock
                key={index}
                type={block.type}
                header={block.header}
                content={block.content}
                buttons={block.buttons}
              />
            )
          case 'side-box':
          case 'side-box-first':
            return (
              <SideBoxBlock
                key={index}
                type={block.type}
                header={block.header}
                content={block.content}
                boxHeader={block.boxHeader}
                boxContent={block.boxContent}
                buttons={block.buttons}
              />
            )
          default:
            return null
        }
      })}
      {iconFooter && (
        <Box css={iconFooterBox({ theme })}>
          <img
            src={`${PUBLIC_ASSETS_BASE_URL}/logos/urbanintelligence/logo-simple.png`}
            alt="icon-footer"
          />
        </Box>
      )}
    </Box>
  )
}
