/** @jsxImportSource @emotion/react */
import { Box } from '@mui/material'
import { mapMarkerChildren, mapMarkerContainer } from './MapMarker.styles'

interface MapMarkerProps {
  colour?: string
  children: JSX.Element
}

export const MapMarker = ({ colour = '#0B2948', children }: MapMarkerProps) => {
  return (
    <Box css={mapMarkerContainer}>
      <Box css={mapMarkerChildren}>{children}</Box>

      <svg
        width="42"
        height="60"
        viewBox="0 0 42 60"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect y="38" width="42" height="22" rx="10" fill="url(#paint0_radial_2741_44852)" />
        <path
          d="M42 21C42 32.598 27.0939 44.4551 21 50.5491C14.38 43.929 0 32.598 0 21C0 9.40202 9.40202 0 21 0C32.598 0 42 9.40202 42 21Z"
          fill={colour}
        />
        <path
          d="M36.1585 20.8606C36.1585 29.3093 29.3094 36.1584 20.8607 36.1584C12.4119 36.1584 5.56286 29.3093 5.56286 20.8606C5.56286 12.4119 12.4119 5.5628 20.8607 5.5628C29.3094 5.5628 36.1585 12.4119 36.1585 20.8606Z"
          fill="white"
        />
        <defs>
          <radialGradient
            id="paint0_radial_2741_44852"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(21 49) rotate(90) scale(9.5 18.1364)"
          >
            <stop stopColor="#393636" stopOpacity={0.3} />
            <stop offset="1" stopColor="#525252" stopOpacity="0" />
          </radialGradient>
        </defs>
      </svg>
    </Box>
  )
}
