import { css } from '@emotion/react'
import { Theme } from '@mui/material/styles'
import { TOP_NAVBAR_HEIGHT } from '../../../../app-constants'

export const strategyLoadingContainer = ({ theme }: { theme: Theme }) => css`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 1 auto;

  width: 100%;
  height: calc(100svh - ${TOP_NAVBAR_HEIGHT}px);

  padding: ${theme.spacing(3)};
  overflow: auto;
`

export const strategyContentLoading = ({ theme }: { theme: Theme }) => css`
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-items: center;

  gap: ${theme.spacing(3)};
  width: 100%;
  max-width: 1300px;
`
