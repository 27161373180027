/** @jsxImportSource @emotion/react */
import { Map, RasterLayer } from 'mapbox-gl'

export interface RasterLayerProps {
  map: Map
  id: string
  beforeId: string
  isVisible: boolean
  shouldUpdate?: boolean
}

export const addRasterLayer = ({
  map,
  id,
  beforeId,
  isVisible,
  shouldUpdate = false,
}: RasterLayerProps) => {
  const rasterLayer: RasterLayer = {
    id,
    type: 'raster',
    source: id,
    layout: { visibility: isVisible ? 'visible' : 'none' },
  }
  if (shouldUpdate) {
    if (rasterLayer.layout) {
      for (const layoutProp of Object.entries(rasterLayer.layout)) {
        map.setLayoutProperty(id, layoutProp[0], layoutProp[1])
      }
    }
  } else {
    map.addSource(id, { type: 'raster', url: `mapbox://${id}` })
    map.addLayer(rasterLayer, beforeId)
  }
}
