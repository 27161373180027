import { FeatureFlag } from '@contexts/FeatureFlagsContext'
import { NavigateFunction } from 'react-router-dom'
import { lowOpacitySidebarButton } from './App.styles'
import { SidebarMenuButton } from '@src/components/Organisms/Sidebar'

export const defaultLogo = [
  {
    simple: 'UILogoSimpleWhite.svg',
    full: 'UILogoFullWhite.svg',
    title: 'Urban Intelligence',
    logoBackgroundColour: '',
  },
]

export function getSidebarMiddleMenuLinks({
  pathname,
  navigate,
  featuresFlags,
  clientName,
}: {
  pathname: string
  navigate: NavigateFunction
  featuresFlags: FeatureFlag[]
  clientName?: string
}): SidebarMenuButton[] {
  const middleNavbar: SidebarMenuButton[] = [
    {
      label: 'Home',
      icon: 'Home',
      active: pathname === '/home' || pathname === '/',
      onClick: () => navigate('home'),
    },
    {
      label: 'Risk',
      icon: 'Pin',
      active: pathname === '/risk',
      onClick: () => navigate('risk'),
      pointerHint:
        clientName && (pathname === '/home' || pathname === '/') ? 'Explore your risk' : '',
    },
    {
      label: 'Live',
      icon: 'Podcasts',
      active: pathname === '/live',
      onClick: () => navigate('live'),
      customClass: {
        iconClass: lowOpacitySidebarButton,
        labelClass: lowOpacitySidebarButton,
      },
    },
    {
      label: 'Summary',
      icon: 'ChartLineUp',
      active: pathname === '/summary',
      onClick: () => navigate('summary'),
      customClass: {
        iconClass: lowOpacitySidebarButton,
        labelClass: lowOpacitySidebarButton,
      },
    },
    {
      label: 'Access',
      icon: 'DirectionsWalk',
      active: pathname === '/access',
      onClick: () => navigate('access'),
      customClass: {
        iconClass: lowOpacitySidebarButton,
        labelClass: lowOpacitySidebarButton,
      },
    },
    {
      label: 'Adaptation',
      icon: 'ArrowLeftRight',
      active: pathname === '/adaptation',
      onClick: () => navigate('adaptation'),
      customClass: {
        iconClass: lowOpacitySidebarButton,
        labelClass: lowOpacitySidebarButton,
      },
    },
    {
      label: 'Landuse',
      icon: 'Land',
      active: pathname === '/landuse',
      onClick: () => navigate('landuse'),
      customClass: {
        iconClass: lowOpacitySidebarButton,
        labelClass: lowOpacitySidebarButton,
      },
    },
    // To be enabled later
    // {
    //   label: 'Transport',
    //   icon: 'EmojiTransportation',
    //   active: pathname === '/transport',
    //   onClick: () => navigate('transport'),
    //   iconColor: theme.palette.grey[600],
    //   labelColor: theme.palette.grey[600],
    // },
  ]

  featuresFlags.forEach((featureFlag) => {
    if (!featureFlag.enabled) return

    switch (featureFlag.feature) {
      case 'strategyPage':
        middleNavbar.push({
          label: 'Strategy',
          icon: 'ChartLineUp',
          active: pathname.includes(`/strategy/`),
          onClick: () => navigate('/strategy/horizon-scan'),
        } as SidebarMenuButton)
        break
      case 'summaryPage':
        {
          const summaryButton = middleNavbar.find((button) => button.label == 'Summary')
          if (summaryButton) summaryButton.customClass = undefined
        }
        break
      case 'riskModuleIsInDevelopment':
        {
          const riskButton = middleNavbar.find((button) => button.label == 'Risk')
          if (riskButton)
            riskButton.customClass = {
              iconClass: lowOpacitySidebarButton,
              labelClass: lowOpacitySidebarButton,
            }
        }
        break
      case 'onboardingPage':
        middleNavbar.splice(1, 0, {
          label: 'Onboarding',
          icon: 's3://waving',
          active: pathname === '/onboarding',
          onClick: () => navigate('onboarding'),
        })
        break
      default:
        return null
    }
  })

  return middleNavbar
}

export function getSidebarBottomMenuLinks({
  isUserSignedIn,
  handleSignIn,
  handleSignOut,
  userRole,
  pathname,
  navigate,
}: {
  isUserSignedIn: boolean
  handleSignOut: () => void
  handleSignIn: () => void
  userRole: string
  pathname: string
  navigate: NavigateFunction
}): SidebarMenuButton[] {
  const bottomMenuLinks = [
    {
      label: isUserSignedIn ? 'Sign Out' : 'Sign In',
      icon: isUserSignedIn ? 'SignOut' : 'SignIn',
      active: false,
      onClick: isUserSignedIn ? handleSignOut : handleSignIn,
    },
  ]

  if (userRole === 'admin') {
    bottomMenuLinks.push({
      label: 'Admin',
      icon: 'DragIcon',
      active: pathname === '/manage-users',
      onClick: () => navigate('manage-users'),
    })
  }
  return bottomMenuLinks
}
