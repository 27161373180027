import { PUBLIC_ASSETS_BASE_URL } from '@src/app-constants'
import axios from '@src/utils/customAxios'
import { AxiosResponse } from 'axios'
import { Content } from 'pdfmake/interfaces'
import { convertFigmaPatternsToImages } from '../../Report.utils'

export const GenerateTitlePageContent = async (clientName: string): Promise<Content[]> => {
  let svgCoverResponse: AxiosResponse
  try {
    svgCoverResponse = await axios.get(
      `${PUBLIC_ASSETS_BASE_URL}/reports-ui/cover_pages/${clientName}.svg`,
      { withCredentials: false },
    )
  } catch (_e) {
    // Load a default cover page if one isn't avilable for a client.
    svgCoverResponse = await axios.get(
      `${PUBLIC_ASSETS_BASE_URL}/reports-ui/cover_pages/default.svg`,
      { withCredentials: false },
    )
  }

  const svgCover = convertFigmaPatternsToImages(svgCoverResponse.data)

  return [
    {
      svg: svgCover,
      absolutePosition: { x: 0, y: 0 },
      pageBreak: 'after',
    },
  ]
}
