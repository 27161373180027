import { css } from '@emotion/react'
import { Theme } from '@mui/material/styles'

export const hazardBoxFormContainer = ({ theme }: { theme: Theme }) => css`
  display: flex;
  align-items: stretch;
  justify-content: stretch;
  flex-direction: column;
  height: 100%;
  gap: ${theme.spacing(2)};
`

export const hazardBoxYearSlider = ({ theme }: { theme: Theme }) => css`
  padding: ${theme.spacing(1)} ${theme.spacing(4)};
  width: 100%;
`

export const hazardBoxInfo = ({ theme }: { theme: Theme }) => css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${theme.spacing(1)};
  padding-bottom: 0;
`

export const hazardBoxSectionInfo = ({
  theme,
  hasSetValue,
}: {
  theme: Theme
  hasSetValue: boolean
}) => css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${theme.spacing(1)} 0;
  width: 100%;
  color: ${hasSetValue ? theme.palette.primary.contrastText : theme.palette.text.primary};
`

export const hazardBoxSLRSliderContainer = ({
  theme,
  hasSetValue,
}: {
  theme: Theme
  hasSetValue: boolean
}) => css`
  display: flex;
  flex-direction: column;
  height: auto;
  width: 100%;
  background-color: ${theme.palette.background.paper};
  box-sizing: border-box;

  align-self: baseline;

  flex-grow: 1;
  grid-column: span 2;
  border: ${hasSetValue
    ? '2px' + ' solid' + theme.palette.primary.main
    : '1px' + ' solid' + theme.palette.grey[100]};
  transition: border 0.15s;

  gap: ${theme.spacing(2)};
  padding: 0;
`

export const hazardBoxCheckboxContainer = ({
  theme,
  hasSetValue,
}: {
  theme: Theme
  hasSetValue: boolean
}) => css`
  display: flex;
  flex-direction: column;
  height: auto;
  box-sizing: border-box;
  height: 100%;
  background-color: ${theme.palette.white.main};
  flex-shrink: 1;
  margin: ${theme.spacing(2)} 0;
  border: ${hasSetValue
    ? '2px' + ' solid' + theme.palette.primary.main
    : '1px' + ' solid' + theme.palette.grey[100]};
  transition: border 0.15s;

  gap: ${theme.spacing(2)};
`

export const hazardBoxRadioGroupContainer = ({
  theme,
  hasSetValue,
  height,
}: {
  theme: Theme
  hasSetValue: boolean
  height: number
}) => css`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: auto;
  background-color: ${theme.palette.white.main};

  grid-row-end: ${height > 212 ? 'span 2' : ''};

  flex-shrink: 1;
  padding: 0;
  max-height: min-content;
  box-sizing: border-box;

  border: ${hasSetValue
    ? '2px' + ' solid' + theme.palette.primary.main
    : '1px' + ' solid' + theme.palette.grey[100]};
  transition: border 0.15s;

  gap: ${theme.spacing(2)};
  div:first-of-type {
    margin-top: 0 !important;
  }
`

export const hazardBoxSLRSlider = ({ theme }: { theme: Theme }) => css`
  padding: ${theme.spacing(2)} ${theme.spacing(6)};
  padding-top: ${theme.spacing(2)};
  width: 100%;

  p {
    font-size: 12px !important;
  }
`

export const sectionTitle = css`
  font-weight: 700;
  padding-left: 8px;
`

export const radioGroupContent = ({ theme }: { theme: Theme }) => css`
  margin-top: ${theme.spacing(1)};
  margin-left: ${theme.spacing(2)};
  margin-bottom: ${theme.spacing(1)};
`

export const checkboxLabel = ({ theme, disabled }: { theme: Theme; disabled: boolean }) => css`
  margin-right: 8px;
  color: ${disabled ? theme.palette.text.disabled : theme.palette.text.primary};
`

export const imageBox = ({ theme }: { theme: Theme }) => css`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: ${theme.spacing(140)};
  border-radius: ${theme.shape.borderRadius}px;
  border: 1px solid ${theme.palette.grey[100]};
  margin: ${theme.spacing(4)} 0;
`

export const fullHeight = () => css`
  height: 100%;
`

export const bold700 = css`
  font-weight: 700;
`

export const hazardDetailFormContainer = ({ theme }: { theme: Theme }) => css`
  width: 100%;
  height: 100%;
  display: flex;
  gap: 8px;
  flex-direction: column;
  align-items: stretch;
  .reset-filters {
    color: ${theme.palette.primary.main};
    text-align: right;
    font-size: 12px;
    font-weight: 600;
    line-height: 150%;
    letter-spacing: -0.132px;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
`

export const hazardControlsRow = css`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 16px;
  padding: 8px;
`

export const hazardControlRightColumn = css`
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-width: 200px;
  align-self: stretch;
`

export const hiddenOptionsNote = ({
  theme,
  shouldHideNote,
}: {
  theme: Theme
  shouldHideNote: boolean
}) => css`
  display: ${shouldHideNote ? 'none' : 'block'};
  font-size: 12px;
  color: #999;
  margin-top: 4px;
  border: 1px solid #fca311;
  background: rgba(255, 199, 0, 0.1);
  padding: ${theme.spacing(3)};

  p {
    color: ${theme.palette.text.primary};
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    padding-top: ${theme.spacing(1)};
  }

  h6 {
    color: #fca311;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 14px;
  }
`

export const disclaimerTopText = ({ theme }: { theme: Theme }) => css`
  font-size: 12px;
  color: ${theme.palette.primary.main};
  padding: ${theme.spacing(1)} ${theme.spacing(3)};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
`

export const hazardControlsColumn = css`
  display: flex;
  flex-direction: column;
`

export const accordionHeader = css`
  span {
    padding-left: 8px;
    font-weight: 400;
  }
`
