import React, { createContext, useContext, useState } from 'react'
import { LayerDetailsReport } from '@src/components/Molecules/SideDrawerContent/data_fetchers/layerDetailsFetcher'

export interface LayerDetailsProviderProps {
  children: React.ReactNode
}

export interface LayerDetailsContext {
  layerDetailsList: LayerDetailsReport[][] | null
  layerDetailsListCache: { [key: string]: LayerDetailsReport[][] | null }
  setLayerDetailsList: React.Dispatch<React.SetStateAction<LayerDetailsReport[][] | null>>
  setLayerDetailsListCache: React.Dispatch<
    React.SetStateAction<{ [key: string]: LayerDetailsReport[][] | null }>
  >
}

export const LayerDetails = createContext({} as LayerDetailsContext)

export const LayerDetailsProvider = ({ children }: LayerDetailsProviderProps) => {
  const [layerDetailsListCache, setLayerDetailsListCache] = useState<{
    [key: string]: LayerDetailsReport[][] | null
  }>({})
  const [layerDetailsList, setLayerDetailsList] = useState<LayerDetailsReport[][] | null>(null)

  return (
    <LayerDetails.Provider
      value={{
        layerDetailsList,
        layerDetailsListCache,
        setLayerDetailsList,
        setLayerDetailsListCache,
      }}
    >
      {children}
    </LayerDetails.Provider>
  )
}

export const useLayerDetails = () => useContext(LayerDetails)
