import { css } from '@emotion/react'
import { Theme } from '@mui/material/styles'

export const horizonScanMultipleBubbleChartContainer = ({ theme }: { theme: Theme }) => css`
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  gap: ${theme.spacing(2)};
  padding-top: ${theme.spacing(3)};

  overflow: auto;
  position: relative;
`

export const chartsContainer = ({ theme }: { theme: Theme }) => css`
  position: absolute;
  left: 62px;
  right: 16px;
  top: 24px;
  bottom: 50px;

  display: flex;
  flex-direction: column;
  gap: ${theme.spacing(2)};
`

export const chartRowContainer = ({ theme }: { theme: Theme }) => css`
  display: flex;
  align-items: center;
  justify-content: center;

  flex: 1 1 auto;

  width: 100%;
  height: 50%;

  gap: ${theme.spacing(2)};
`

export const chartContainer = ({ theme }: { theme: Theme }) => css`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  width: 100%;
  height: 100%;

  padding-top: ${theme.spacing(3)};

  border: 1px solid ${theme.palette.grey[200]};
  border-radius: ${theme.shape.borderRadius}px;

  position: relative;
`

export const emptyChartContainer = css`
  border: none;
`

export const chartTitle = ({ theme }: { theme: Theme }) => css`
  font-weight: 700;
  color: ${theme.palette.primary.main};
  text-align: center;
  margin-bottom: -10px;
`
