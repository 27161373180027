export const PackhouseIcon = ({ ...rest }) => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.6874 8.40029H8.0879C7.91468 8.40029 7.77539 8.261 7.77539 8.08783V5.31315C7.77539 5.13998 7.91468 5.00069 8.0879 5.00069L24.6874 5.00064C24.8606 5.00064 24.9999 5.13993 24.9999 5.3131V8.08772C24.9999 8.261 24.8606 8.40029 24.6874 8.40029ZM8.40042 7.77537H24.3749V5.62577H8.40042V7.77537Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.912 24.9999H5.31246C5.13929 24.9999 5 24.8607 5 24.6875V21.9129C5 21.7396 5.13929 21.6004 5.31246 21.6004H21.912C22.0851 21.6004 22.2244 21.7396 22.2244 21.9129V24.6873C22.2244 24.8605 22.0839 24.9998 21.912 24.9998V24.9999ZM5.62497 24.375H21.5994V22.2254L5.62497 22.2253V24.375Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.08767 22.2245H5.313C5.13983 22.2245 5.00054 22.0852 5.00054 21.912L5.00049 5.31246C5.00049 5.13929 5.13978 5 5.31295 5H8.08762C8.26079 5 8.40008 5.13929 8.40008 5.31246V21.912C8.40013 22.0839 8.26085 22.2245 8.08767 22.2245ZM5.62557 21.5996H7.77516L7.77521 5.62508H5.62562L5.62557 21.5996Z"
        fill="currentColor"
      />
      <path
        d="M19.1377 17.4885C18.9645 17.4885 18.8252 17.3492 18.8252 17.176V8.08799C18.8252 7.91482 18.9645 7.77553 19.1377 7.77553C19.3108 7.77553 19.4501 7.91482 19.4501 8.08799V17.1748C19.4501 17.348 19.3095 17.4886 19.1377 17.4886V17.4885Z"
        fill="currentColor"
      />
      <path
        d="M16.3618 14.7124C16.1886 14.7124 16.0493 14.5732 16.0493 14.4V8.08783C16.0493 7.91465 16.1886 7.77531 16.3618 7.77531C16.5349 7.77531 16.6742 7.91465 16.6742 8.08783V14.4C16.6743 14.5732 16.5349 14.7124 16.3618 14.7124Z"
        fill="currentColor"
      />
      <path
        d="M16.3618 22.2245C16.1886 22.2245 16.0493 22.0852 16.0493 21.912V18.3249C16.0493 18.1518 16.1886 18.0125 16.3618 18.0125C16.5349 18.0125 16.6742 18.1518 16.6742 18.3249V21.912C16.6743 22.0839 16.5349 22.2245 16.3618 22.2245Z"
        fill="currentColor"
      />
      <path
        d="M13.5869 11.938C13.4137 11.938 13.2744 11.7987 13.2744 11.6255V8.08783C13.2744 7.91465 13.4137 7.77531 13.5869 7.77531C13.7601 7.77531 13.8994 7.91465 13.8994 8.08783V11.6255C13.8994 11.7987 13.7601 11.938 13.5869 11.938Z"
        fill="currentColor"
      />
      <path
        d="M13.5869 22.2245C13.4137 22.2245 13.2744 22.0852 13.2744 21.912V15.5491C13.2744 15.3759 13.4137 15.2366 13.5869 15.2366C13.7601 15.2366 13.8994 15.3759 13.8994 15.5491V21.912C13.8994 22.0839 13.7601 22.2245 13.5869 22.2245Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.6877 24.9999H21.913C21.7399 24.9999 21.6006 24.8607 21.6006 24.6875V8.08799C21.6006 7.91482 21.7399 7.77553 21.913 7.77553H24.6877C24.8609 7.77553 25.0002 7.91482 25.0002 8.08799L25.0002 24.6875C25.0002 24.8607 24.8609 25 24.6877 25V24.9999ZM22.2256 24.375H24.3752V8.4005H22.2256V24.375Z"
        fill="currentColor"
      />
      <path
        d="M10.8125 22.2245C10.6393 22.2245 10.5 22.0852 10.5 21.912V12.7745C10.5 12.6013 10.6393 12.462 10.8125 12.462C10.9857 12.462 11.125 12.6013 11.125 12.7745V21.912C11.125 22.0839 10.9857 22.2245 10.8125 22.2245Z"
        fill="currentColor"
      />
      <path
        d="M19.9502 22.2245C19.8707 22.2245 19.7901 22.1945 19.7288 22.1333L7.86621 10.2707C7.74381 10.1483 7.74381 9.95035 7.86621 9.82929C7.98861 9.70689 8.18653 9.70689 8.30759 9.82929L20.1704 21.6921C20.2928 21.8145 20.2928 22.0124 20.1704 22.1335C20.1105 22.1934 20.0297 22.2246 19.9503 22.2246L19.9502 22.2245Z"
        fill="currentColor"
      />
      <path
        d="M21.9118 20.2631C21.8323 20.2631 21.7517 20.2331 21.6904 20.1719L9.82764 8.30915C9.70524 8.1867 9.70524 7.98883 9.82764 7.86777C9.95003 7.74671 10.148 7.74537 10.269 7.86777L22.1318 19.7306C22.2543 19.853 22.2543 20.0509 22.1318 20.1719C22.0719 20.2318 21.9912 20.2631 21.9118 20.2631Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default PackhouseIcon
