import { css } from '@emotion/react'
import { Theme } from '@mui/material/styles'

export const accordionClass = ({
  theme,
  fullHeight,
  isExpanded,
  disableExpand,
}: {
  theme: Theme
  fullHeight: boolean
  isExpanded: boolean
  disableExpand: boolean
}) => css`
  flex: ${fullHeight && isExpanded && '1 1 auto'};
  width: 100%;

  border: 1px solid ${theme.palette.grey[100]};
  border-radius: ${theme.shape.borderRadius}px;
  min-height: 32px;

  background-color: ${theme.palette.white.main};
  overflow: clip;

  &:before {
    display: none;
  }

  & .MuiAccordionSummary-root {
    min-height: 0px;
    padding: ${theme.spacing(1)} ${theme.spacing(2)};
    padding-right: ${disableExpand && 0};
    background-color: ${theme.palette.white.main};
  }

  & .MuiAccordionDetails-root {
    padding: ${theme.spacing(2)};
  }

  & .MuiAccordionSummary-content {
    margin: 0;
  }

  & .MuiAccordionDetails-root {
    overflow: auto;
    height: ${fullHeight && isExpanded && '100%'};
    height: ${disableExpand && 0};
    background-color: ${theme.palette.white.light + '80'};
  }
`
