import { css } from '@emotion/react'
import { Theme } from '@mui/material/styles'

export const addLayersSetupContainer = ({ theme }: { theme: Theme }) => css`
  display: flex;
  gap: ${theme.spacing(2)};

  flex-wrap: nowrap;

  height: 70svh;
  max-height: 800px;

  overflow: auto;

  @media (max-width: 1080px) {
    height: 100%;
    max-height: 100%;
  }

  > div:first-of-type {
    flex: 1 1 auto;
    height: 100%;
    max-width: 640px;
  }
`

export const addLayersSetupSection = css`
  flex: 1 1 auto;
  height: 100%;
  overflow: auto;
  max-width: 640px;
  border: none;
  padding: 0;

  @media (max-width: 1080px) {
    min-width: 100%;
  }
`

export const addLayersTabsContainer = css`
  flex: 1 1 auto;
  height: calc(100% - 49px);
`
