/** @jsxImportSource @emotion/react */
import { Expression, Map, SymbolLayer, CircleLayer } from 'mapbox-gl'

import { byAssetIdIn, getHazardAssetIcon, getHazardColourOfAsset } from '../RiskMapView.utilities'
import { AssetHazardInstances } from '@uintel/ui-component-library'
import { MapLayer } from '@redux/map/mapSlice'
import axios from '@src/utils/customAxios'
import { PUBLIC_ASSETS_BASE_URL } from '@src/app-constants'
import { MapLayerStyle } from '@contexts/MapContext'

export interface IconLayerProps {
  map: Map
  layerStyle: MapLayerStyle
  id: string
  beforeId: string
  isVisible: boolean
  sourceLayer: string
  icon: string | undefined
  assetHazardInstances: AssetHazardInstances | undefined
  hazardLayers: MapLayer[] | undefined
  vulnerabilityPalette: string[]
  assetIds?: { [asset_id: number]: boolean }
  sortKeyExpression?: Expression
  hasFilters?: boolean
  shouldUpdate?: boolean
}

export const addIconLayer = ({
  map,
  layerStyle,
  id,
  beforeId,
  isVisible,
  sourceLayer,
  icon = 'default',
  assetHazardInstances = {},
  hazardLayers = [],
  vulnerabilityPalette = [],
  assetIds,
  sortKeyExpression,
  hasFilters = false,
  shouldUpdate = false,
}: IconLayerProps) => {
  const symbolLayer: SymbolLayer = {
    id,
    type: 'symbol',
    source: id,
    'source-layer': sourceLayer,
    layout: {
      'icon-image': getHazardAssetIcon(icon, hazardLayers, assetHazardInstances),
      'icon-size': ['interpolate', ['linear'], ['zoom'], 10, 0.33, 18, 1.125],
      visibility: isVisible ? 'visible' : 'none',
      'icon-allow-overlap': true,
      'icon-anchor': 'bottom',
    },
    minzoom: 12,
  }

  const circleLayer: CircleLayer = {
    id: `${id}-zoomed-out`,
    type: 'circle',
    source: id,
    'source-layer': sourceLayer,
    layout: {
      visibility: isVisible ? 'visible' : 'none',
      'circle-sort-key': sortKeyExpression || 0,
    },
    paint: {
      'circle-color': getHazardColourOfAsset(
        hazardLayers,
        assetHazardInstances,
        vulnerabilityPalette,
      ),
      'circle-radius': ['interpolate', ['linear'], ['zoom'], 1, 4, 15, 4, 16, 8],
      ...layerStyle,
    },
    maxzoom: 12,
  }

  if (shouldUpdate) {
    if (symbolLayer.paint) {
      for (const paintProp of Object.entries(symbolLayer.paint)) {
        map.setPaintProperty(symbolLayer.id, paintProp[0], paintProp[1])
      }
    }
    if (symbolLayer.layout) {
      for (const layoutProp of Object.entries(symbolLayer.layout)) {
        map.setLayoutProperty(symbolLayer.id, layoutProp[0], layoutProp[1])
      }
    }
    if (circleLayer.paint) {
      for (const paintProp of Object.entries(circleLayer.paint)) {
        map.setPaintProperty(circleLayer.id, paintProp[0], paintProp[1])
      }
    }
    if (circleLayer.layout) {
      for (const layoutProp of Object.entries(circleLayer.layout)) {
        map.setLayoutProperty(circleLayer.id, layoutProp[0], layoutProp[1])
      }
    }
    if (!hasFilters && !assetIds) map.setFilter(id, null)
  } else {
    map.addSource(id, { type: 'vector', url: `mapbox://${id}` })
    map.addLayer(symbolLayer, beforeId)
    map.addLayer(circleLayer, beforeId)
  }
  loadIconAndApplyVulnerabilityPalette(map)

  /*
    Because MapBox can't handle multi-element symbols, ie combining a pin with a dynamically coloured background and an icon.
    So we:
      • load an svg icon as raw text
      • Nest the icon svg in a pin shape svg from the constant below.
        • have tried "import PinSvg from '@assets/icons/pin.svg'" without success
        • probably best to also fetch the pin svg - something for the future ;-)
      • The pin svg has a specific color: #777777.
    We then loop through the vulnerability palette and replace that specific color with each one from the palette in turn
    then adding it as a separate image to the map appended with the palette's index.

    E.g.: town_hall.svg is loaded, replace <!--ICONSVG--> in pinSvg with town_hall.svg and then town_hall-0, town_hall-1, ..., town_hall-4 are created and added to the map.
    
    Yes, svgs can have nested svgs which is nice.

    Have a look at getHazardAssetIcon() in MapViewUtilities to see how it gets used.
  */
  function loadIconAndApplyVulnerabilityPalette(map: Map) {
    // icon may be a simple name like "marae" in which case its source is an s3 bucket, or it can be explicitly prepended with "s3://marae"
    // or a fully qualified url if it begins with "http://"
    let iconUrl = ''
    let iconId = icon
    const lowerCaseIcon = icon.toLowerCase().replace('.svg', '')

    if (lowerCaseIcon.startsWith('s3://')) {
      iconId = lowerCaseIcon.replace('s3://', '')
      iconUrl = `${PUBLIC_ASSETS_BASE_URL}/icons/${iconId}.svg`
    } else if (lowerCaseIcon.startsWith('http://')) {
      iconId = icon.replace('http://', '').replace('.svg', '')
      iconUrl = icon
    } else {
      iconId = lowerCaseIcon
      iconUrl = `${PUBLIC_ASSETS_BASE_URL}/icons/${iconId}.svg`
    }

    if (map.hasImage(`${iconId}-0`)) return

    let pinSvg = PinSvg

    axios.get<string>(iconUrl, { withCredentials: false }).then((response) => {
      const iconSvg = stripWidthAndHeightFromSvg(response.data)
      pinSvg = pinSvg.replace('<!--ICONSVG-->', iconSvg)

      vulnerabilityPalette.forEach((color, index) => {
        const svgBitmap = new Image(42, 51)
        svgBitmap.onload = () => {
          if (!map.hasImage(`${iconId}-${index}`)) map.addImage(`${iconId}-${index}`, svgBitmap)
        }
        svgBitmap.src = 'data:image/svg+xml;base64,' + btoa(pinSvg.replace('#777777', color))
      })

      // Add UI blue for no hazard layers
      const svgBitmap = new Image(42, 51)
      svgBitmap.onload = () => {
        if (!map.hasImage(`${iconId}-default`)) map.addImage(`${iconId}-default`, svgBitmap)
      }
      svgBitmap.src = 'data:image/svg+xml;base64,' + btoa(pinSvg.replace('#777777', '#0b2a48'))
    })
  }
  if (hasFilters && assetIds) {
    const visibilityExpression: Expression = ['boolean', byAssetIdIn(assetIds)]
    map.setFilter(id, visibilityExpression)
    map.setFilter(`${id}-zoomed-out`, visibilityExpression)
  }
}

export const stripWidthAndHeightFromSvg = (svg: string): string => {
  // The following regexs should be able to match all possible ways a width and height attribute
  // can be formatted with spaces and single/double quotes. It also guarantees to match only attributes
  // inside the first svg element.

  const heightRegex = /<svg(.*?)\b(height\s*=\s*['"][a-z0-9]+['"])(.*?)>(.*)/is
  const widthRegex = /<svg(.*?)\b(width\s*=\s*['"][0-9a-z]+['"])(.*?)>(.*)/is

  const heightAttributeMatch = svg.match(heightRegex) || ['', '', '', '', '']
  let thesvg = `<svg${heightAttributeMatch[1]}${heightAttributeMatch[3]}>${heightAttributeMatch[4]}`

  const widthAttributeMatch = thesvg.match(widthRegex) || ['', '', '', '', '']
  thesvg = `<svg${widthAttributeMatch[1]}${widthAttributeMatch[3]}>${widthAttributeMatch[4]}`

  return thesvg
}

export const PinSvg = `<svg
        width="42"
        height="50.549"
        viewBox="0 0 42 50.549"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M42 21C42 32.598 27.0939 44.4551 21 50.5491C14.38 43.929 0 32.598 0 21C0 9.40202 9.40202 0 21 0C32.598 0 42 9.40202 42 21Z"
          fill="#777777"
        />
        <path
          d="M36.1585 20.8606C36.1585 29.3093 29.3094 36.1584 20.8607 36.1584C12.4119 36.1584 5.56286 29.3093 5.56286 20.8606C5.56286 12.4119 12.4119 5.5628 20.8607 5.5628C29.3094 5.5628 36.1585 12.4119 36.1585 20.8606Z"
          fill="white"
        />
        <g transform="translate(9, 9)">
          <svg width="24" height="24">
            <!--ICONSVG-->
          </svg>
        </g>
      </svg>
`
