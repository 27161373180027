/** @jsxImportSource @emotion/react */
import { useTheme } from '@mui/material'
import { videoStyle } from './Video.styles'

export const Video = ({ videoUrl }: { videoUrl: string }) => {
  const theme = useTheme()
  return (
    <video
      controls
      preload="auto"
      width="100%"
      controlsList="nodownload noremoteplayback noplaybackrate"
      disablePictureInPicture
      css={videoStyle({ theme })}
    >
      <source src={videoUrl} />
      <p>
        To view this video please enable JavaScript, and consider upgrading to a web browser that
        <a href="https://videojs.com/html5-video-support/" target="_blank" rel="noreferrer">
          supports HTML5 video
        </a>
      </p>
    </video>
  )
}
