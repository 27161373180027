import { css, keyframes } from '@emotion/react'
import { Theme } from '@mui/material/styles'

const fadein = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`

export const emptySideDrawerContainer = ({ theme }: { theme: Theme }) => css`
  width: 100%;

  display: flex;
  align-items: start;
  justify-content: start;
  flex-grow: 1;
  flex-direction: column;

  gap: ${theme.spacing(6)};
  user-select: none;
  justify-content: center;
  position: relative;

  background: radial-gradient(
    circle,
    ${theme.palette.primary.main}00 72%,
    ${theme.palette.primary.main}10 72%,
    ${theme.palette.primary.main}10 90%,
    ${theme.palette.primary.main}20 90%
  );
`

export const emptySideDrawerContent = () => css`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 12px;
  position: absolute;
  height: 80%;
  animation: ${fadein} 0.4s both;

  img {
    width: 80%;
    max-width: 250px;
    flex: 0;
    height: auto;
  }

  .smallicon {
    max-width: 200px;
  }
`

export const emptyTitle = ({ theme }: { theme: Theme }) => css`
  font-weight: 700;
  text-align: center;
  color: ${theme.palette.primary.main};
  padding: 0 ${theme.spacing(4)};
  max-width: 310px;
  line-height: 1.3;
`

export const buttonContainer = css`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 24px;

  keyframes fadein {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
`

export const addLayersButton = ({ theme }: { theme: Theme }) => css`
  display: flex;
  color: ${theme.palette.primary.main};
  font-size: 18px;
  font-weight: 700;
  width: 100%;
  border-radius: 8px;
  padding: ${theme.spacing(1)};
`

export const addLayersFabIcon = ({ theme }: { theme: Theme }) => css`
  background-color: ${theme.palette.primary.main};
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  z-index: 1;
  margin: 0;
  padding: 0;
  height: ${theme.spacing(8)};
  width: ${theme.spacing(8)};
  min-width: ${theme.spacing(8)};
  font-size: 12px;
  font-weight: 400;
  border-radius: 50%;
  transition: width color 0.2s ease-in-out, background-color 0.2s ease-in-out;
  overflow: hidden;
  margin-right: ${theme.spacing(2)};

  svg {
    width: 24px;
    height: 24px;
  }
`
