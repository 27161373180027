export const Proteins = ({ ...rest }) => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M15.9525 7.6898L11 3L6.0475 7.6898C3.3175 10.275 3.3175 14.4759 6.0475 17.0611C7.4125 18.3537 9.20625 19 11 19C12.7937 19 14.5875 18.3537 15.9525 17.0611C18.6825 14.4759 18.6825 10.275 15.9525 7.6898ZM11 17.3511C9.6 17.3511 8.27875 16.8374 7.29 15.8928C6.2925 14.9482 5.75 13.7053 5.75 12.3796C5.75 11.0539 6.2925 9.80269 7.29 8.86639L11 5.3449V17.3511Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default Proteins
