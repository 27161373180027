import { css } from '@emotion/react'
import { SIDEBAR_MENU_WIDTH } from '@src/app-constants'

export const mapViewContainer = css`
  width: calc(100% - ${SIDEBAR_MENU_WIDTH}px);
  height: 100%;

  position: fixed;
  z-index: 99;

  & .mapboxgl-popup {
    z-index: 100;
  }

  & .measureTooltip {
    background-color: white;
    border-radius: 4px;
  }

  & .measureTooltip > .mapboxgl-popup-content {
    width: unset !important;
    padding: 3px 6px !important;
  }

  & .measureTooltip > .mapboxgl-popup-tip {
    display: none;
  }

  & .mapboxgl-ctrl-group.mapboxgl-ctrl:nth-of-type(2) {
    margin-top: 4px;
  }

  & .mapboxgl-ctrl-bottom-left {
    width: 100%;
    height: 44px;

    & .mapboxgl-ctrl {
      margin: 0;
      width: 100%;
      height: 100%;

      & .mapboxgl-ctrl-logo {
        position: absolute;
        left: 50%;
        top: 0px;
        transform: translateX(-50%);
      }
    }

    & .mapboxgl-ctrl-scale {
      position: relative;
      left: 125px;
      height: 22px;
      bottom: -13px;
    }
  }

  & .mapboxgl-ctrl-bottom-right {
    right: 50%;
    transform: translateX(50%);

    & .mapboxgl-ctrl-attrib {
      margin: 0;
      padding: 0 8px;
    }
  }

  & .mapboxgl-ctrl-top-right .mapboxgl-ctrl {
    margin: 4px 8px;

    & button {
      width: 30px;
      height: 30px;
    }
  }
`

export const selectionMeasurementContainer = css`
  position: absolute;
  top: 8px;
  right: 48px;
  z-index: 99;
  background-color: white;
  padding: 4px 8px;
  border-radius: 4px;
  display: none;
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);
`

export const selectionMeasurementHeader = css`
  margin: 4px 0;
  color: #1c3855;
  font-size: 0.9rem;
  border-bottom: 1px solid #1c3855;
  padding-bottom: 4px;
`
