import { Feature, useFeatureFlags } from '@contexts/FeatureFlagsContext'
import { BASE_API_URL } from '@src/app-constants'
import axios from '@src/utils/customAxios'
import { Navigate, useLocation } from 'react-router-dom'

interface ProtectedRouteProps {
  feature: Feature
  children: JSX.Element
}

export const ProtectedRoute = ({ feature, children }: ProtectedRouteProps): JSX.Element => {
  const location = useLocation()
  const { features } = useFeatureFlags()

  const canUserAccessPage = features.find((item) => item.feature === feature)?.enabled

  if (!canUserAccessPage) {
    return <Navigate to="/" replace state={{ from: location }} />
  }

  return children
}

export const isAdminUser = async (): Promise<boolean> => {
  try {
    const response = await axios.get(`${BASE_API_URL}/api/user/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
    const data = response.data
    return !!(data && data.user_role === 'admin')
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error)
    return false
  }
}
