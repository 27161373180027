/** @jsxImportSource @emotion/react */

import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined'
import { Button, useTheme } from '@mui/material'
import { sourceButton, sourceButtonIcon } from './SourceButton.styles'

export interface SourceButtonProps {
  source: string
  width?: string
  children?: React.ReactNode
}

export const SourceButton = ({ source, width, children = 'Source' }: SourceButtonProps) => {
  const theme = useTheme()

  return (
    <Button
      component="a"
      href={source}
      target="_blank"
      rel="noreferrer"
      size="small"
      variant="outlined"
      css={sourceButton({ theme, width })}
    >
      <div>{children}</div>
      <OpenInNewOutlinedIcon css={sourceButtonIcon({ theme })} />
    </Button>
  )
}
