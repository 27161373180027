import { MapLayer } from '@redux/map/mapSlice'
import { BASE_API_URL } from '@src/app-constants'
import axios from '@src/utils/customAxios'
import { Feature } from 'geojson'
import { useEffect, useState } from 'react'

export interface ScenariosByAsset {
  hazard_scenario: string
  sentence: string
}

export const useScenariosFetcher = (
  selectedAsset: MapLayer | null,
  selectedFeature: Feature | null,
  setIsLoading: (isLoading: boolean) => void,
): ScenariosByAsset[] | null => {
  const [scenarios, setScenarios] = useState(null)

  useEffect(() => {
    if (!selectedAsset || !selectedFeature) {
      setIsLoading(false)
      return
    }
    setIsLoading(true)
    axios
      .get(
        `${BASE_API_URL}/api/hazard/scenarios/${selectedAsset?.type}/${selectedFeature?.properties?.asset_id}`,
      )
      .then((response) => {
        setScenarios(response.data)
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error('Error fetching scenarios:', error)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [selectedAsset, selectedFeature, setIsLoading])

  return scenarios
}
