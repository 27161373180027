/** @jsxImportSource @emotion/react */
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder'
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css'
import mapboxgl from 'mapbox-gl'
import { useEffect, useState } from 'react'
import { Box, useTheme } from '@mui/material'
import { useMap } from '@contexts/MapContext'
import { Icon } from '@src/components/Atoms/Icon'
import { geocoderButtonBox, geocoderContainer } from './GeocoderButton.styles'

const geocoderContainerId = 'geocoder-container'

export const GeocoderButton = () => {
  const { map } = useMap()
  const theme = useTheme()
  const [showGeocoder, setShowGeocoder] = useState(false)

  useEffect(() => {
    if (!map) return

    const existingDiv = document.getElementById(geocoderContainerId)
    if (!existingDiv) return

    while (existingDiv.firstChild) {
      existingDiv.firstChild.remove()
    }

    const geocoder = new MapboxGeocoder({
      accessToken: mapboxgl.accessToken,
      mapboxgl: mapboxgl,
      placeholder: 'Search by address',
    })

    existingDiv?.appendChild(geocoder.onAdd(map))
    geocoder.clear() // Also focuses the input
  }, [map, showGeocoder])

  const handleButtonClick = () => {
    setShowGeocoder(!showGeocoder)
  }

  return (
    <Box
      sx={{ marginY: theme.spacing(1), display: 'flex', flexDirection: 'row' }}
      className="tutorial-geocoder"
    >
      {showGeocoder && (
        <Box id={geocoderContainerId} component="span" css={geocoderContainer({ theme })} />
      )}
      <Box css={geocoderButtonBox({ theme })} onClick={handleButtonClick}>
        <Icon iconName="Search" />
      </Box>
    </Box>
  )
}
