import { css } from '@emotion/react'

export const modal = css`
  padding: 0.4rem;
  border-radius: 0.3rem;
  border: 0.375rem solid #0b2948;
  max-width: 30rem;
  min-width: 27.875rem;
  overflow: hidden;
  box-shadow: #0b294855 0px 0px 0px 5000px !important;
  padding-bottom: 0.1rem;
  font-family: 'Helvetica Neue', Inter, ui-sans-serif, 'Apple Color Emoji', Helvetica, Arial,
    sans-serif;

  button {
    background-color: #0b2948;
  }
  button:hover {
    background-color: #001f3f;
  }

  li {
    margin-left: 28px;
  }
`

export const menu = css`
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
  padding-top: 1.2rem;
  max-height: 50rem;
  overflow-y: auto;
  text-align: justify;
  line-height: 1.1rem;
  padding-bottom: 0.3rem;

  button {
    border: 1px solid #0b2948;
    background-color: white;
    color: #0b2948;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0;

    .icon {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 8px;
      border-right: 1px solid #001f3f08;
      background-color: #001f3f10;
      height: 40px;
      width: 40px;
    }

    &:hover {
      color: #fff;

      .icon {
        background-color: #fff1;
        border-right: 1px solid #ffffff08;
      }

      svg,
      img {
        filter: brightness(0) invert(1) !important;
      }
    }
  }
`

export const buttonBox = css`
  padding: 0.6rem;
  border-top: 1px solid #e0e0e0;
  display: flex;
  justify-content: flex-end;
`
export const contentBox = css`
  padding: 0.6rem;

  h1 {
    color: #0b2948;
    font-size: 1.2rem;
    font-weight: 800;
  }
`

export const heading = css`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
  margin-bottom: 12px;

  img {
    height: 2rem;
  }

  h1 {
    padding-top: 4px;
  }
`

export const exitButton = css`
  position: absolute;
  top: 0.8rem;
  right: 0.8rem;
  display: inline-block;
  color: #616161;
  font-size: 22px;
  cursor: pointer;
  font-weight: 700;
  text-align: center;
  text-decoration: none;
  width: 2.7rem;
  height: 2.7rem;
  line-height: 2.7rem;

  &:focus,
  &:hover {
    color: #212121;
    outline: 0;
    text-decoration: none;
  }
`
