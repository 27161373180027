import { css } from '@emotion/react'
import { Theme } from '@mui/material/styles'

type BaseMapButtonContainerProps = {
  thumbnail: string
  theme: Theme
}

export const baseMapButtonContainer = ({ theme }: { theme: Theme }) => css`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  margin-left: ${theme.spacing(2)};
  margin-bottom: ${theme.spacing(2)};

  gap: ${theme.spacing(2)};
  z-index: 10;
`

export const baseMapButton = ({ thumbnail, theme }: BaseMapButtonContainerProps) => css`
  padding: 0;
  margin: 0;
  box-sizing: border-box;

  background-image: url('${thumbnail}');

  display: flex;
  align-items: flex-end;

  width: 70px;
  height: 70px;

  border: 2px solid ${theme.palette.white.main};
  border-radius: ${theme.spacing(1)};

  transition: box-shadow 0.2s;

  &:hover {
    box-shadow: ${theme.shadows[5]};
    cursor: pointer;
  }
`

export const baseMapTitleWrapper = ({ theme }: { theme: Theme }) => css`
  width: 100%;
  height: ${theme.spacing(7)};

  display: flex;
  align-items: flex-end;
  justify-content: center;

  padding-bottom: ${theme.spacing(1)};
  border-radius: ${theme.spacing(1)};

  background: rgb(0, 0, 0);
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0.15) 80%,
    rgba(255, 255, 255, 0) 100%
  );

  & > svg {
    margin-left: ${theme.spacing(-1)};
  }
`

export const previewContainer = ({ theme }: { theme: Theme }) => css`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${theme.spacing(2)};

  padding: 0 ${theme.spacing(2)};
  padding-top: ${theme.spacing(1)};
  background-color: ${theme.palette.white.main};

  border-radius: ${theme.spacing(1)};

  box-shadow: ${theme.shadows[5]};
`

export const previewMapContainer = ({ theme }: { theme: Theme }) => css`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  &:hover {
    cursor: pointer;

    & > span {
      box-shadow: ${theme.shadows[2]};
    }
  }
`

export const previewMap = ({
  thumbnail,
  theme,
  isSelected,
}: {
  thumbnail: string
  theme: Theme
  isSelected: boolean
}) => css`
  width: 48px;
  height: 44px;

  border: ${isSelected
    ? `2px solid ${theme.palette.primary.main}`
    : `1px solid ${theme.palette.grey[100]}`};

  background-image: url('${thumbnail}');

  border-radius: ${theme.spacing(1)};

  transition: box-shadow 0.2s;
`
