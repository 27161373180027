/** @jsxImportSource @emotion/react */
import axios from '@src/utils/customAxios'
import { MapLayer } from '@redux/map/mapSlice'
import { Feature } from 'geojson'
import { TDocumentDefinitions } from 'pdfmake/interfaces'
import { pageSizeDimensions } from '@src/components/Pages/Map/MapTools/ExportMap/pdfGeneratorUtils'
import { GenerateSelectedElementMapContent } from './Sections/SelectedElementMap'
import {
  GenerateSelectedElementDetailsContent,
  GenerateSelectedElementExposureBySelectedRisks,
} from './Sections/SelectedElementDetails'
import { GenerateKnownRisksToSelectedElement } from './Sections/SelectedElementKnownRisks'
import { AssetDetails, Disclaimer } from '../Report.utils'
import { GenerateDataSourceContent } from '../RiskSummary/Pages/LayerDetails'
import { ProjectedExposureChartStateByHazardId } from '@redux/projectedExposureChartSlice'

export interface Size {
  width: number
  height: number
}

const pageMargins = { top: 90, bottom: 40, left: 40, right: 40 }
const pageDimensions = pageSizeDimensions['A4']
const a4Size: Size = { width: pageDimensions[0], height: pageDimensions[1] }
const bodySize: Size = {
  width: a4Size.width - pageMargins.left - pageMargins.right,
  height: a4Size.height - pageMargins.top - pageMargins.bottom,
}

export const GenerateSelectedElementReport = async (
  selectedAssetDetails: AssetDetails,
  selectedAssetLayer: MapLayer,
  selectedFeature: Feature,
  hazardLayers: MapLayer[],
  prefer_hazard_term: boolean,
  prefer_risk_term: boolean,
  projectedExposureChartState: ProjectedExposureChartStateByHazardId,
): Promise<TDocumentDefinitions> => {
  const uiLogoSimpleWhiteSvg: string = (await axios.get('/logos/UILogoSimpleWhite.svg')).data
  const uiLogoWhiteSvg: string = (await axios.get('/logos/UILogoFullWhite.svg')).data

  return {
    content: [
      GenerateSelectedElementDetailsContent(selectedAssetDetails),
      await GenerateSelectedElementMapContent(selectedFeature, bodySize, hazardLayers),
      GenerateSelectedElementExposureBySelectedRisks(
        selectedAssetDetails,
        prefer_risk_term,
        projectedExposureChartState,
      ),
      await GenerateKnownRisksToSelectedElement(selectedAssetDetails),
      ...(await GenerateDataSourceContent(
        [selectedAssetLayer, ...hazardLayers],
        prefer_hazard_term,
      )),
      {
        text: [
          { text: 'Disclaimer: ', bold: true },
          {
            text: Disclaimer,
          },
        ],
        marginTop: 20,
      },
    ],

    // Roboto used because Inter has severe problems with macrons
    defaultStyle: { font: 'Roboto', color: '#0b2948' },
    header: (_currentPage, _, pageSize) => [
      {
        canvas: [
          {
            type: 'rect',
            x: 0,
            y: 0,
            w: pageSize.width,
            h: pageMargins.top - 10,
            color: '#0b2948',
          },
        ],
        absolutePosition: { x: 0, y: 0 },
      },
      {
        marginTop: 20,
        marginLeft: pageMargins.left,
        marginRight: pageMargins.right,
        table: {
          widths: ['auto', '*'],
          body: [
            [
              {
                svg: uiLogoWhiteSvg,
                rowSpan: 2,
              },
              {
                text: 'Element report',
                bold: true,
                alignment: 'right',
                color: 'white',
              },
            ],
            [
              { text: '' },
              { text: new Date().toLocaleDateString(), alignment: 'right', color: 'white' },
            ],
          ],
        },
        layout: 'noBorders', //{ hLineColor: 'white', vLineColor: 'white' },
      },
    ],
    footer: (currentPage, _, pageSize) => {
      const even = currentPage % 2 == 0
      return [
        {
          canvas: [
            {
              type: 'rect',
              x: 0,
              y: 0,
              w: pageSize.width,
              h: 40,
              color: '#0b2948',
            },
          ],
          absolutePosition: { x: 0, y: 0 },
        },
        {
          table: {
            widths: ['*', 'auto', '*'],
            body: [
              even
                ? [
                    {
                      svg: uiLogoSimpleWhiteSvg,
                      height: 20,
                      marginTop: 8,
                    },
                    {
                      text: [
                        'Urban Intelligence® Resilience Explorer® - visit ',
                        {
                          text: 'www.resilience-explorer.com',
                          link: 'http://www.resilience-explorer.com',
                          decoration: 'underline',
                        },
                        ' for more insights',
                      ],
                      color: 'white',
                      fontSize: 10,
                      marginTop: 13,
                    },
                    {
                      text: currentPage,
                      alignment: 'center',
                      color: 'white',
                      fontSize: 10,
                      marginTop: 13,
                    },
                  ]
                : [
                    {
                      text: currentPage,
                      alignment: 'center',
                      color: 'white',
                      fontSize: 10,
                      marginTop: 13,
                    },
                    {
                      text: [
                        'Urban Intelligence® Resilience Explorer® - visit ',
                        {
                          text: 'www.resilience-explorer.com',
                          link: 'http://www.resilience-explorer.com',
                          decoration: 'underline',
                        },
                        ' for more insights',
                      ],
                      color: 'white',
                      fontSize: 10,
                      marginTop: 13,
                    },
                    {
                      svg: uiLogoSimpleWhiteSvg,
                      height: 20,
                      marginTop: 8,
                    },
                  ],
            ],
          },
          margin: [15, 0, 15, 0], // [left, top, right, bottom]
          layout: 'noBorders',
        },
      ]
    },
    styles: {
      h1: {
        fontSize: 24,
        bold: false,
        margin: [0, 10, 0, 10],
      },
      h2: {
        fontSize: 16,
        bold: true,
      },
      h3: {
        fontSize: 14,
        bold: true,
        margin: [0, 10, 0, 5],
      },
    },
    pageMargins: [pageMargins.left, pageMargins.top, pageMargins.right, pageMargins.bottom],
  }
}
