/** @jsxImportSource @emotion/react */
import { Box, Skeleton } from '@mui/material'
import { useTheme } from '@mui/material/styles'

import { strategyContentLoading, strategyLoadingContainer } from './StrategyLoading.styles'

export const StrategyLoading = () => {
  const theme = useTheme()

  return (
    <Box css={strategyLoadingContainer({ theme })}>
      <Box css={strategyContentLoading({ theme })}>
        <Skeleton variant="rounded" width="100%" height="50%" animation="wave" />
        <Skeleton variant="rounded" width="100%" height="50%" animation="wave" />
      </Box>
    </Box>
  )
}
