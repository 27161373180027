const mapBasics = {
  // properties and methods go here
  MOVEAROUND: (
    <div className="column">
      <div className="row">
        <img
          src="/images/tutorial/MouseClickDragArrowKeys.svg"
          alt="MouseClickDragArrowKeys"
          className="m-image"
        />
        Explore the map by clicking and dragging with your mouse or using the arrow keys on your
        keyboard.
      </div>
      <div className="center-row">
        <img src="/images/tutorial/PanExample.gif" alt="Pan Example" className="l-image" />
      </div>
    </div>
  ),
  ZOOM: (
    <div
      style={{
        display: 'flex',
        gap: '0.5rem',
        paddingLeft: '0.2rem',
        alignItems: 'center',
      }}
    >
      <img
        src="/images/tutorial/MouseScrollWheel.svg"
        alt="ScrollWheel"
        style={{
          height: '2.9rem',
          objectFit: 'contain',
        }}
      />
      Adjust the zoom level easily: scroll with your mouse, click the + or - buttons in the top
      right, or use the + and - keys on your keyboard.
    </div>
  ),
  BASEMAPS: (
    <div className="column">
      <div className="row">
        Switch the basemap by selecting the basemaps button in the lower left corner.
        <br />
        <br />
        Pick from three options: Default, Satellite, or Terrain.
      </div>
      <div className="center-row">
        <img src="/images/tutorial/BasemapExample.gif" alt="Basemap Example" className="l-image" />
      </div>
    </div>
  ),
  ROTATIONANDPERSPECTIVE: (
    <div className="column">
      <div className="row">
        <img src="/images/tutorial/MouseRightClickDrag.svg" alt="3D Button" className="m-image" />
        Rotate the map in three dimensions by right-clicking and dragging the map to change the
        orientation and pitch of your map. You can use the 3D view to add context and perspective to
        your data.
      </div>
      <div className="center-row">
        <img src="/images/tutorial/3DExample.gif" alt="3D Example" className="l-image" />
      </div>
    </div>
  ),
  COMPASS: (
    <div className="column">
      <div className="row">
        Feeling all turned around? Click the compass button in the top right to reset the map view.
      </div>
      <div className="center-row">
        <img src="/images/tutorial/CompassExample.gif" alt="3D Example" className="l-image" />
      </div>
    </div>
  ),
}

export default mapBasics
