import { css } from '@emotion/react'
import { Theme } from '@mui/material/styles'

import { SIDE_DRAWER_WIDTH, SIDEBAR_MENU_WIDTH, TOP_NAVBAR_HEIGHT } from '../../app-constants'

export const strategyPageContainer = css`
  width: 100%;
  height: 100%;

  display: flex;
  position: relative;
  overflow: hidden;
`

export const strategyPageContentWrapper = css`
  left: ${SIDE_DRAWER_WIDTH}px;
  right: 0;
  top: 0;
  bottom: 0;
  position: absolute;
`

export const topNavbarContainer = css`
  position: relative;
  z-index: 11;
`

export const strategyPageContentContainer = ({ theme }: { theme: Theme }) => css`
  color: ${theme.palette.text.primary};
`

export const strategyPageContent = ({ theme }: { theme: Theme }) => css`
  display: flex;
  align-items: start;
  justify-content: start;

  height: calc(100svh - ${TOP_NAVBAR_HEIGHT}px);
  width: calc(100vw - ${SIDEBAR_MENU_WIDTH}px - ${SIDE_DRAWER_WIDTH}px);

  overflow: clip;

  background-color: ${theme.palette.white.light};
`
