export const LeafIcon = ({ ...rest }) => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M20.7926 8.17562C19.3873 5.79462 17.3922 3.81585 14.9998 2.43011L14.5741 2.18393L14.1485 2.43011C11.7153 3.83433 9.6949 5.85462 8.29051 8.28769C6.88613 10.7208 6.14733 13.4808 6.14844 16.2901C6.14844 16.4731 6.15163 16.659 6.15784 16.8426C6.24242 19.3318 7.19437 21.4264 8.9108 22.8999C10.2243 24.0274 11.9091 24.7158 13.7243 24.88V27.8161H15.4243V14.216H13.7241V23.1691C10.8519 22.8364 7.99332 20.8074 7.85674 16.7848C7.85111 16.6204 7.84824 16.4539 7.84824 16.2901C7.8533 13.8644 8.4728 11.4795 9.64895 9.35793C10.8251 7.2364 12.5195 5.44741 14.5741 4.15791C16.6287 5.44743 18.3231 7.23643 19.4992 9.35796C20.6753 11.4795 21.2948 13.8644 21.2998 16.2901C21.2998 16.4539 21.2969 16.6204 21.2913 16.7849C21.1821 20.0028 19.3306 21.9448 17.1239 22.7634V24.5626C18.269 24.238 19.3309 23.6709 20.2375 22.8997C21.9539 21.4263 22.9058 19.3317 22.9904 16.8426C22.9966 16.6589 22.9998 16.4731 22.9998 16.29C23.0013 13.4367 22.2392 10.635 20.7926 8.17562Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default LeafIcon
