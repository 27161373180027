import { css, keyframes } from '@emotion/react'
import { Theme } from '@mui/material'

const loadingWave = keyframes`
0% {
  transform: translateX(-100%);
}
100% {
  transform: translateX(100%);
}
`

export const textPlaceholderContainer = ({ theme }: { theme: Theme }) => css`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${theme.spacing(2)} ${theme.spacing(4)};
  padding: ${theme.spacing(2)} 0;
`

export const placeholder = ({
  width,
  height = '1em',
  gridColumn = '1',
  theme,
}: {
  width: string
  height?: string
  gridColumn?: string
  theme: Theme
}) => css`
  width: ${width};
  height: ${height};
  background-color: #e8e8e8; /* Grey background color */
  position: relative;
  overflow: hidden;
  border-radius: ${theme.spacing(1)};
  grid-column: span ${gridColumn};
`

export const placeholderWave = () => css`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  background: linear-gradient(to right, #ccc0 0%, #ccc 35%, #ccc 65%, #ccc0 100%);
  animation: ${loadingWave} 3s infinite linear;
`

export const barChartPlaceholderContainer = ({ theme }: { theme: Theme }) => css`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-end;
  gap: ${theme.spacing(1)};
  width: 100%;
  height: 100%;
`

export const horizontalBarChartPlaceholderContainer = ({ theme }: { theme: Theme }) => css`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: ${theme.spacing(1)};
  width: 100%;
  height: 100%;
`

export const guagePlaceholder = ({ theme }: { theme: Theme }) => css`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${theme.spacing(33)};
  height: ${theme.spacing(17)};
  background-color: #e8e8e8;
  position: relative;
  overflow: hidden;
  border-radius: ${theme.spacing(18)} ${theme.spacing(18)} ${theme.spacing(1)} ${theme.spacing(1)};
`

export const guagePlaceholderContainer = () => css`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
`

export const chartsPlaceholderContainer = ({ theme }: { theme: Theme }) => css`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${theme.spacing(4)} ${theme.spacing(4)};
  padding: ${theme.spacing(1)};
  height: ${theme.spacing(60)};
`

export const placeholderAxii = ({ theme }: { theme: Theme }) => css`
  position: absolute;
  bottom: ${theme.spacing(4)};
  left: ${theme.spacing(4)};
  top: ${theme.spacing(4)};
  right: ${theme.spacing(4)};
  opacity: 0;
  border-left: 2px solid #f0f0f0;
  border-bottom: 2px solid #f0f0f0;
  mix-blend-mode: multiply;
`
