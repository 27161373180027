import { css } from '@emotion/react'
import { Theme } from '@mui/material/styles'

export const hazardBoxFormContainer = ({ theme }: { theme: Theme }) => css`
  display: flex;
  align-items: stretch;
  justify-content: stretch;
  flex-direction: column;
  height: 100%;
  gap: ${theme.spacing(2)};
`

export const hazardBoxFormContent = ({ theme }: { theme: Theme }) => css`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing(2)};

  padding-left: ${theme.spacing(2)};

  padding-top: ${theme.spacing(1)};
  padding-right: ${theme.spacing(2)};

  padding-bottom: ${theme.spacing(2)};
`

export const hazardBoxFormContentParent = ({ theme }: { theme: Theme }) => css`
  border: 1px solid ${theme.palette.grey[100]};
  border-radius: ${theme.shape.borderRadius}px;

  overflow-y: auto;
  overflow-x: hidden;

  height: 400px;
  flex-grow: 1;

  @media (max-width: 1080px) {
    height: 100%;
    padding: 8px;
    overflow: auto;
  }
`

export const hazardBoxYearSlider = ({ theme }: { theme: Theme }) => css`
  padding: ${theme.spacing(1)} ${theme.spacing(4)};
  width: 100%;
`

export const hazardBoxInfo = ({ theme }: { theme: Theme }) => css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${theme.spacing(1)};
  padding-bottom: 0;
`

export const hazardBoxSectionInfo = ({ theme }: { theme: Theme }) => css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: ${theme.spacing(2)};
  width: 100%;
`

export const hazardBoxSLRSliderContainer = ({ theme }: { theme: Theme }) => css`
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: ${theme.palette.white.main};

  gap: ${theme.spacing(3)};
`

export const hazardBoxCheckboxContainer = ({ theme }: { theme: Theme }) => css`
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: ${theme.palette.white.main};

  gap: ${theme.spacing(3)};
`

export const hazardBoxRadioGroupContainer = ({ theme }: { theme: Theme }) => css`
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: ${theme.palette.white.main};

  gap: ${theme.spacing(3)};
  div:first-of-type {
    margin-top: 0 !important;
  }
`

export const hazardBoxSLRSlider = ({ theme }: { theme: Theme }) => css`
  padding: ${theme.spacing(3)} ${theme.spacing(4)};
  padding-bottom: ${theme.spacing(3)};
  padding-top: ${theme.spacing(2)};
  width: 100%;
`

export const sectionTitle = css`
  font-weight: 700;
`

export const radioGroupContent = ({ theme }: { theme: Theme }) => css`
  margin-top: ${theme.spacing(1)};
  margin-left: ${theme.spacing(2)};
  margin-bottom: ${theme.spacing(1)};
`

export const checkboxLabel = ({ theme, disabled }: { theme: Theme; disabled: boolean }) => css`
  margin-left: 4px;
  color: ${disabled ? theme.palette.text.disabled : theme.palette.text.primary};
`

export const hazardBoxOption = ({ theme }: { theme: Theme }) => css`
  padding: ${theme.spacing(1)} ${theme.spacing(2)};
  border-radius: ${theme.shape.borderRadius}px;
  width: 100%;
  gap: ${theme.spacing(2)};

  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  font-size: 14px;
`

export const imageBox = ({ theme }: { theme: Theme }) => css`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: ${theme.spacing(140)};
  border-radius: ${theme.shape.borderRadius}px;
  border: 1px solid ${theme.palette.grey[100]};
  margin: ${theme.spacing(4)} 0;
`

export const fullHeight = () => css`
  height: 100%;
`

export const bold700 = css`
  font-weight: 700;
`

export const scenarioItem = ({ theme, isSelected }: { theme: Theme; isSelected: boolean }) => css`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;
  padding: ${theme.spacing(2)} ${theme.spacing(2)};
  margin-top: ${theme.spacing(2)};
  border-radius: ${theme.shape.borderRadius}px;
  background-color: ${isSelected ? theme.palette.white.light : theme.palette.white.main};
  color: ${isSelected ? theme.palette.grey[500] : theme.palette.text.primary};
  border: 1px solid ${theme.palette.grey[100]};
  width: 100%;
  gap: ${theme.spacing(1)};
  transition: background-color 0.2s;

  &:hover {
    cursor: pointer;
    background-color: ${theme.palette.white.light};
  }
`

export const scenarioItemTitle = ({
  theme,
  isSelected,
}: {
  theme: Theme
  isSelected: boolean
}) => css`
  font-weight: 700;
  color: ${!isSelected ? theme.palette.primary.main : theme.palette.grey[500]};
`

export const formElementsBox = ({ theme }: { theme: Theme }) => css`
  grid-column: span 2;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: ${theme.spacing(2)};
  width: 100%;
`
