import { css } from '@emotion/react'
import { Theme } from '@mui/material/styles'

export const storyNavigation = ({ theme }: { theme: Theme }) => css`
  position: absolute;
  top: 0;
  left: 0;
  width: 360px;
  z-index: 100;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
  border-radius: ${theme.spacing(1)};

  .title {
    background-color: ${theme.palette.primary.main};
    color: white;
    line-height: 1.1;
    display: flex;
    gap: ${theme.spacing(4)};
    font-weight: 500;
    padding: ${theme.spacing(2)} ${theme.spacing(2)};
    border-radius: ${theme.spacing(1)} ${theme.spacing(1)} 0 0;
  }

  .menu {
    max-height: 50vh;
    overflow: auto;
    background-color: ${theme.palette.background.paper};
    padding: ${theme.spacing(2)};
    padding-bottom: ${theme.spacing(3)};
    border-radius: 0 0 ${theme.spacing(1)} ${theme.spacing(1)};
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing(1)};

    .chapter {
      font-weight: 500;
      line-height: 1.2;
      padding-top: ${theme.spacing(2)};
      color: ${theme.palette.primary.main};
    }

    .step {
      cursor: pointer;
      transition: background-color 0.3s;
      background-color: white;
      color: ${theme.palette.primary.main};
      border: 1px solid ${theme.palette.primary.main}c0;
      border-radius: ${theme.spacing(1)};
      display: flex;
      gap: ${theme.spacing(2)};
      align-items: center;

      > div {
        height: 24px;
        padding: ${theme.spacing(1)} ${theme.spacing(1)};
        border-right: 1px solid ${theme.palette.primary.main}40;
        display: flex;
        align-items: center;

        svg {
          height: 20px;
        }
      }

      &:hover {
        background-color: ${theme.palette.primary.main}10;
      }

      &.active {
        border-color: transparent;
        background-color: ${theme.palette.primary.main}20;

        > div {
          border-color: white;
        }
      }
    }
  }
`
