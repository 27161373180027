import { css } from '@emotion/react'

export const onboardingPage = (hasBanner: boolean) => css`
  max-height: 100%;
  position: relative;
  height: 100vh;
  background-color: #0b294820;

  .MuiBox-root[role='tabpanel'] {
    padding: 0;
    overflow: auto;
    flex-grow: 1;
    ${hasBanner
      ? `
      `
      : `
      >.MuiBox-root:first-of-type {
        margin-top: 48px;
      }`}
  }
  .MuiTabs-root {
    margin-bottom: -1px;
    background-color: #fff;

    .MuiTabs-flexContainer {
      justify-content: flex-start;
      gap: 16px;
    }
  }
  .MuiTab-root {
    min-height: 0;

    &.Mui-selected {
      color: #0b2948;
    }
  }
  .MuiTabs-indicator {
    background-color: #0b2948;
  }
  > .MuiBox-root {
    border: none;
    max-height: 100vh;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }
`
