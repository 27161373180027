export const DrawCancelIcon = ({ ...rest }) => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <rect
        x="5.60432"
        y="5.60432"
        width="18.7914"
        height="18.7913"
        rx="0.906475"
        stroke="currentColor"
        strokeWidth="1.20863"
        strokeLinecap="square"
        strokeLinejoin="round"
        strokeDasharray="1.51 4.53"
      />
      <path
        d="M19.82 10.1799L10.1797 19.8202"
        stroke="currentColor"
        strokeWidth="1.20863"
        strokeLinecap="round"
      />
      <path
        d="M19.8921 19.8921L10.2518 10.2518"
        stroke="currentColor"
        strokeWidth="1.20863"
        strokeLinecap="round"
      />
    </svg>
  )
}

export default DrawCancelIcon
