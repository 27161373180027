import { css } from '@emotion/react'
import { Theme } from '@mui/material/styles'

export const sectionStyle = {
  display: 'flex',
  flex: '1 1 auto',
  height: '1px',
  overflow: 'clip',
  width: '100%',
}

export const horizonScanContainer = ({ theme }: { theme: Theme }) => css`
  padding: ${theme.spacing(4)};

  width: 100%;
  height: 100%;

  display: flex;
  flex: 1 1 auto;
  gap: ${theme.spacing(3)};

  overflow: auto;
`

export const leftSection = ({ theme }: { theme: Theme }) => css`
  width: 500px;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: start;
  flex-direction: column;
  flex-shrink: 0;
  gap: ${theme.spacing(2)};
`

export const sectionHeader = ({ theme }: { theme: Theme }) => css`
  width: 100%;
  background-color: ${theme.palette.white.main};
  padding: ${theme.spacing(2)};
`

export const sectionContent = ({ theme }: { theme: Theme }) => css`
  display: flex;
  flex: 1;
  width: 100%;
  height: 100%;

  padding: ${theme.spacing(2)} ${theme.spacing(7)};
  background-color: ${theme.palette.white.main + '70'};
  overflow: auto;
`
export const sectionContentList = ({ theme }: { theme: Theme }) => css`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing(2)};
  width: 100%;
`

export const rightSection = ({ theme }: { theme: Theme }) => css`
  display: flex;
  align-items: start;
  justify-content: center;
  flex: 1 1 auto;
  flex-direction: column;

  width: 100%;
  max-width: 1400px;
  height: 100%;

  margin: 0 auto;
  padding: ${theme.spacing(5)};

  color: ${theme.palette.text.primary};
  background-color: ${theme.palette.white.main};
  border-radius: ${theme.shape.borderRadius}px;
  border: 1px solid ${theme.palette.grey[200]};
`

export const selectContainer = ({ theme }: { theme: Theme }) => css`
  display: flex;
  align-items: center;
  justify-content: start;
  gap: ${theme.spacing(2)};

  flex-wrap: wrap;
`

export const selectInput = css`
  min-width: 180px;
`

export const toggleButtonContainer = ({ theme }: { theme: Theme }) => css`
  height: ${theme.spacing(11)};
  font-size: ${theme.typography.fontSize};

  & .MuiButtonBase-root {
    border: 1px solid ${theme.palette.grey[100]};
    color: ${theme.palette.text.primary};
    transition: all 0.2s;

    p {
      color: inherit;
    }

    &:hover {
      background-color: ${theme.palette.primary.light + '20'};
    }

    &[aria-pressed='true'] {
      color: ${theme.palette.primary.dark};
      background-color: ${theme.palette.primary.light + '50'};

      p {
        color: ${theme.palette.primary.dark};
        font-weight: 700;
      }
    }
  }
`

export const chartContainer = ({ theme }: { theme: Theme }) => css`
  width: 100%;
  height: 100%;

  color: ${theme.palette.text.primary};

  position: relative;
`

export const timeframeDropdownContainer = ({ theme }: { theme: Theme }) => css`
  position: relative;

  & .MuiAutocomplete-input {
    padding-left: ${theme.spacing(8)} !important;
    padding-right: ${theme.spacing(1)} !important;
  }
`

export const timeframeDropdownInfoButton = ({ theme }: { theme: Theme }) => css`
  position: absolute;
  top: 50%;
  left: ${theme.spacing(1)};
  transform: translateY(-50%);
`
