import { distinct } from '@uintel/ui-component-library'
import { HazardDetail } from '../AddLayersDialog.types'

// not in use currently
// export const getDefaultParameterValues = (
//   hazardArea: string,
//   hazardDetail: HazardDetail,
//   parameterGroupIndex: number,
// ): ParameterType => {
//   const hazardAreaScenarios = hazardDetail.scenarios.filter((scenario) =>
//     scenario.hazardAreas.includes(hazardArea),
//   )

//   const defaultParameterValues: ParameterType = {}

//   const parameterGroup = hazardDetail.parameter_groups
//     ? hazardDetail.parameter_groups[parameterGroupIndex].key
//     : null

//   const formElements = hazardDetail.form.filter(
//     (formElement) => !formElement.parameter_group || formElement.parameter_group == parameterGroup,
//   )

//   formElements.forEach((formElement) => {
//     const firstValue = firstOrUndefined(
//       distinct(hazardAreaScenarios.map((scenario) => scenario.parameters[formElement.key])),
//     )

//     if (firstValue === undefined) return

//     defaultParameterValues[formElement.key] = {
//       priority: formElement.priority,
//       value: firstValue,
//     }

//     // TODO: figure out something better than this. The analysts weren't able to send actual boolean values
//     // and could only send strings so if the value is "true" or "false" change it to boolean.
//     // This'll do for MVP
//     if (firstValue === 'true' || firstValue === 'false')
//       defaultParameterValues[formElement.key].value = anyToBoolean(firstValue)
//   })

//   return defaultParameterValues
// }

export const getAvailableHazardAreas = (hazardDetail: HazardDetail) => {
  return distinct(hazardDetail.scenarios.map((scenario) => scenario.hazardAreas).flat()).filter(
    (hazardArea) => !hazardDetail.hazardAreas[hazardArea].includes('SA1'),
  )
}

export function anyToBoolean(value: unknown): boolean {
  if (typeof value == 'string') return value === 'true'
  return !!value
}
