/** @jsxImportSource @emotion/react */

import { Box, Autocomplete, TextField } from '@mui/material'
import { theme, useSnackbars } from '@uintel/ui-component-library'
import { RootState } from '@redux/store'
import { useDispatch, useSelector } from 'react-redux'
import { useCallback } from 'react'
import {
  Client,
  setUserClientWelcomeSidebarContent,
  setUserRole,
  setupUserState,
} from '@redux/user/userSlice'
import * as Sentry from '@sentry/react'
import axios from '@src/utils/customAxios'
import { BASE_API_URL } from '@src/app-constants'
import { useMap } from '@contexts/MapContext'
import { ClientConfigResponse } from '../SignInModal'
import { AutocompleteContainer } from './ChangeClient.styles'
import SelectionTool from '@src/components/Pages/Map/MapTools/SelectionTool'
import { useCookies } from 'react-cookie'

const publicUserPool: Client = {
  client_name: 'openaccess',
  display_name: 'Open Access',
  accepted_tsncs: false,
}

export const ChangeClient = ({ onSelect }: { onSelect: () => void }) => {
  const userStore = useSelector((state: RootState) => state.user)

  const { availableClients, clientName } = userStore
  const { layers, drawAreas } = useSelector((state: RootState) => state.map)
  const [, setCookie] = useCookies(['client_name'])

  const {
    map,
    clearMap,
    updateLayers,
    deselectFeature,
    updateDrawAreas,
    handleChangeMapInitialLocation,
    handleSetClientLocation,
  } = useMap()
  const dispatch = useDispatch()
  const { displaySnackbar } = useSnackbars()

  const getRole = async (): Promise<string> => {
    try {
      const response = await axios.get(`${BASE_API_URL}/api/user/`, {
        headers: {
          'Content-Type': 'application/json',
        },
      })

      return response.data.user_role ?? ''
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
      return ''
    }
  }

  const apiGetClientConfig = useCallback(async () => {
    try {
      const clientConfigResponse = await axios.get<ClientConfigResponse>(
        `${BASE_API_URL}/api/client/config`,
      )
      return clientConfigResponse.data
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(`error: `, error)
      return null
    }
  }, [])

  const apiGetClientWelcomeSidebarContent = useCallback(async () => {
    try {
      const clientSidebarContentResponse = await axios.get<Record<string, unknown>>(
        `${BASE_API_URL}/api/client/welcome_sidebar_content`,
      )
      return clientSidebarContentResponse.data
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(`error: `, error)
      return null
    }
  }, [])

  const updateClientConfig = useCallback(
    async (new_client: Client | null) => {
      if (!new_client) return
      const clientConfig = await apiGetClientConfig()
      const clientSidebarContent = await apiGetClientWelcomeSidebarContent()
      if (clientConfig === null || clientSidebarContent === null) {
        displaySnackbar({
          message: 'Something went wrong. Please check if the client is valid',
          type: 'error',
        })
        return
      }

      const { theme, default_location, feedback_url, tsncs_html, methods_report_url } = clientConfig
      const { latitude, longitude, zoom } = default_location
      if (import.meta.env.VITE_ISLOCAL !== 'true')
        dispatch(setUserClientWelcomeSidebarContent(clientSidebarContent))
      dispatch(
        setupUserState({
          clientDisplayName: new_client.display_name,
          clientName: new_client.client_name,
          feedback_url: feedback_url,
          theme,
          accepted_tsncs: new_client.accepted_tsncs,
          tsncs_html,
          methods_report_url,
        }),
      )

      try {
        axios.post(`${BASE_API_URL}/api/user/increment_login`)
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(`Failed to increment_login ${error}`)
      }

      if (latitude && longitude) {
        handleChangeMapInitialLocation({ latitude, longitude, zoom })
        handleSetClientLocation({ latitude, longitude, zoom })
      }
    },
    [
      apiGetClientConfig,
      apiGetClientWelcomeSidebarContent,
      dispatch,
      displaySnackbar,
      handleChangeMapInitialLocation,
      handleSetClientLocation,
    ],
  )

  const applyClient = useCallback(
    async (new_client: Client | null) => {
      onSelect()
      if (!new_client) {
        applyClient(publicUserPool)
        Sentry.setTag('ui_client', 'openaccess')
        return
      }

      Sentry.setTag('ui_client', new_client.client_name)

      setCookie('client_name', new_client.client_name, { path: '/', maxAge: 60 * 60 * 24 * 365 })

      // UPDATE CLIENT CONFIG
      await updateClientConfig(new_client)
      const userRole = await getRole()
      dispatch(setUserRole(userRole))

      // Remove everything from map
      // only set to empty if layers already exist
      if (layers.length > 0) updateLayers({ layers: [] })
      if (map && drawAreas.length > 0) {
        updateDrawAreas({ drawAreas: [] })
        SelectionTool.deleteSelectionArea(map)
      }
      deselectFeature()

      if (map) {
        clearMap({ map })
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [updateClientConfig, updateLayers, updateDrawAreas, map, clearMap, dispatch],
  )

  return (
    <Box css={AutocompleteContainer({ theme })}>
      <Autocomplete
        id="organization"
        disableClearable
        fullWidth
        value={
          availableClients.find((user) => user.client_name === clientName) ?? availableClients[0]
        }
        onChange={(_event, newValue) => applyClient(newValue)}
        options={availableClients}
        disabled={availableClients.length === 0}
        getOptionLabel={(option) => option.display_name}
        renderInput={(params) => <TextField {...params} label="Organization" />}
      />
    </Box>
  )
}
