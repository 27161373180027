import { css } from '@emotion/react'
import { Theme } from '@mui/material/styles'

export const assetLayersFormContainer = ({ theme }: { theme: Theme }) => css`
  display: flex;
  flex-direction: column;
  flex: 1;

  width: 100%;
  height: 100%;

  gap: ${theme.spacing(3)};
  padding-top: ${theme.spacing(1)};
`
export const assetLayersFormContent = () => css`
  height: 100%;
  overflow: auto;
`

export const assetLayersFormAccordions = ({ theme }: { theme: Theme }) => css`
  overflow: auto;
  display: flex;
  align-items: stretch;
  justify-content: start;
  flex-direction: column;
  gap: ${theme.spacing(2)};
  overflow: visible;

  flex: 1;
  padding: ${theme.spacing(1)};
  padding-top: 0;
`
export const assetLayersFormInternalAccordion = ({ theme }: { theme: Theme }) => css`
  display: flex;
  align-items: stretch;
  justify-content: center;
  flex-direction: column;
  gap: ${theme.spacing(1)};

  overflow: auto;
`

export const assetInternalAccordionTitle = css`
  font-weight: 700;
`
export const assetInternalAccordionBody = css`
  overflow: auto;
`

export const assetInternalAccordionLabel = ({
  isAlreadySelected,
  theme,
}: {
  isAlreadySelected: boolean
  theme: Theme
}) => css`
  padding: ${theme.spacing(1)};
  display: flex;
  align-items: center;
  justify-content: space-between;

  color: ${isAlreadySelected && theme.palette.grey[500]};

  border-radius: ${theme.shape.borderRadius}px;
  background-color: ${isAlreadySelected && theme.palette.white.light};

  transition: background-color 0.2s;

  & > div > p {
    color: ${isAlreadySelected && theme.palette.grey[500]};
  }

  &:hover {
    cursor: pointer;
    background-color: ${!isAlreadySelected && theme.palette.white.light};
  }
`

export const assetInternalAccordionIcon = css`
  min-width: 0;
`

export const conditionalAssetItemStyling = ({
  theme,
  disallowed,
}: {
  theme: Theme
  disallowed: boolean
}) => css`
  color: ${disallowed ? theme.palette.text.disabled : theme.palette.primary.dark};
`
