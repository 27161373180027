import { css } from '@emotion/react'
import { Theme } from '@mui/material/styles'

export const sideBoxBlock = ({ theme }: { theme: Theme }) => css`
  display: flex;
  padding: 0 ${theme.spacing(16)};
  justify-content: center;

  > div {
    display: flex;
    width: 1000px;
    max-width: 1000px;
    justify-content: flex-start;
    gap: ${theme.spacing(32)};
  }

  .box {
    h1 {
      font-size: 16px;
      font-weight: 600;
      margin-top: 0;
      margin-bottom: ${theme.spacing(2)};
      color: ${theme.palette.common.white};
    }
    p {
      margin-left: 0;
    }
    background-color: #0b2948;
    color: ${theme.palette.common.white};
    padding: ${theme.spacing(6)};
    height: 100%;
  }

  img {
    max-height: 260px;
    max-width: 50%;
    border-radius: 4px;
  }

  h1 {
    color: #0b2948;
    font-size: 20px;
    line-height: 1;
    margin-bottom: ${theme.spacing(1)};
    margin-top: ${theme.spacing(1)};
  }

  p {
    margin-left: ${theme.spacing(2)};
    font-size: 15px;
  }
`
