import { css } from '@emotion/react'
import { Theme } from '@mui/material/styles'

export const multipleBubbleAxisContainer = css`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 99%;
  height: 100%;
  position: relative;
`

export const xAxis = css`
  display: flex;
  align-items: center;

  position: absolute;
  bottom: 0;
  left: 42px;
  right: 0;

  height: 70px;
`

export const xAxisStartLabel = ({ theme }: { theme: Theme }) => css`
  position: absolute;

  left: 48px;
  bottom: 0px;

  color: ${theme.palette.primary.main};

  padding-right: 12px;
`

export const xAxisLabel = ({ theme }: { theme: Theme }) => css`
  position: absolute;

  left: 50%;
  bottom: 0;

  transform: translateX(-50%);

  font-weight: 700;
  color: ${theme.palette.primary.main};
`

export const xAxisEndLabel = ({ theme }: { theme: Theme }) => css`
  position: absolute;

  right: 48px;
  bottom: 0px;

  color: ${theme.palette.primary.main};
`

export const xAxisLine = ({ theme }: { theme: Theme }) => css`
  width: 100%;
  height: 2px;

  left: 42px;
  right: 0;

  background-color: ${theme.palette.primary.main};
`
export const xAxisArrowTop = ({ theme }: { theme: Theme }) => css`
  width: 12px;
  height: 2px;

  position: absolute;

  right: 0;
  transform: rotate(45deg);
  transform-origin: bottom right;

  background-color: ${theme.palette.primary.main};
`
export const xAxisArrowBottom = ({ theme }: { theme: Theme }) => css`
  width: 12px;
  height: 2px;

  position: absolute;

  right: 0;
  transform: rotate(-45deg);
  transform-origin: top right;

  background-color: ${theme.palette.primary.main};
`

export const yAxis = css`
  display: flex;
  align-items: center;

  position: relative;
  width: 100%;
  height: 100%;
`

export const yAxisStartLabel = ({ theme }: { theme: Theme }) => css`
  position: absolute;

  transform: rotate(-90deg);
  left: 0;
  top: 52px;

  color: ${theme.palette.primary.main};
`
export const yAxisLabel = ({ theme }: { theme: Theme }) => css`
  position: absolute;
  transform: rotate(-90deg);
  transform-origin: top center;
  left: -48px;

  font-weight: 700;
  color: ${theme.palette.primary.main};
`

export const yAxisEndLabel = ({ theme }: { theme: Theme }) => css`
  position: absolute;
  transform: rotate(-90deg);
  left: 0;

  bottom: 82px;

  color: ${theme.palette.primary.main};
`

export const yAxisLine = ({ theme }: { theme: Theme }) => css`
  width: 2px;

  position: absolute;
  left: 42px;
  top: 0;
  bottom: 34px;

  background-color: ${theme.palette.primary.main};
`
export const yAxisArrowTop = ({ theme }: { theme: Theme }) => css`
  width: 2px;
  height: 14px;
  background-color: ${theme.palette.primary.main};

  position: absolute;

  left: 42px;
  top: 0;
  transform: rotate(45deg);
  transform-origin: top left;
`

export const yAxisArrowBottom = ({ theme }: { theme: Theme }) => css`
  width: 2px;
  height: 14px;
  background-color: ${theme.palette.primary.main};

  position: absolute;

  left: 42px;
  top: 0;
  transform: rotate(-45deg);
  transform-origin: top right;
`
