import { css } from '@emotion/react'
import { darken, lighten, Theme } from '@mui/material/styles'

export const riskPageContainer = () => css`
  width: 100%;
  height: 100%;

  display: flex;
  position: relative;

  overflow: hidden;
`
export const riskContainerStyle = () => css`
  width: 100%;
  height: 100%;
  position: relative;

  overflow: hidden;
`
export const sideDrawerContainer = css`
  position: relative;
  top: 0;
  left: 0;
  z-index: 100;
`

export const sideDrawerFab = ({ theme }: { theme: Theme }) => css`
  position: absolute;
  top: 32px;
  right: -16px;
  background-color: ${theme.palette.primary.main};
  color: white;
  z-index: 1;
  margin: 0;
  padding: 0;
  height: ${theme.spacing(8)};
  width: ${theme.spacing(8)};
  min-width: ${theme.spacing(8)};
  border-radius: 50%;
  transition: width color 0.2s ease-in-out, background-color 0.2s ease-in-out;
  display: flex;
  overflow: hidden;

  svg {
    width: 24px;
    height: 24px;
  }

  &:hover {
    background-color: ${lighten(theme.palette.primary.main, 0.2)};
    color: ${darken(theme.palette.primary.main, 0.4)};
  }
`
